var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div data-nested-page=\"first\" class=\"tt-container\">\n        <div class=\"tt-alert\">\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"summaryAvailabilityAdditionalInfo") || (depth0 != null ? lookupProperty(depth0,"summaryAvailabilityAdditionalInfo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"summaryAvailabilityAdditionalInfo","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":51}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n    </div>\n    ";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    <div class=\"tt-alert tt-alert--warning\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"mopShowBannerIfAvailabilityTooClose") || (depth0 != null ? lookupProperty(depth0,"mopShowBannerIfAvailabilityTooClose") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"mopShowBannerIfAvailabilityTooClose","hash":{},"data":data,"loc":{"start":{"line":8,"column":44},"end":{"line":8,"column":83}}}) : helper)))
    + "</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-alert tt-alert-row tt-customer-alert\">\n											<div class=\"tt-alert__icon tt-icon-clock\"></div>\n											<div>\n											"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Dispo Calendar Conflict",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":11},"end":{"line":24,"column":65}}})) != null ? stack1 : "")
    + "\n											</div>\n                    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"resource_face_img_link") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":35,"column":19}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "													<img class=\"tt-availability-summary-info-mobile__avatar\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"resource_face_img_link") || (depth0 != null ? lookupProperty(depth0,"resource_face_img_link") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"resource_face_img_link","hash":{},"data":data,"loc":{"start":{"line":32,"column":75},"end":{"line":32,"column":101}}}) : helper)))
    + "\" />\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "													<div class=\"tt-availability-summary-info-mobile__avatar--default tt-icon-user-md\"></div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"area_face_img_link") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":37,"column":26},"end":{"line":41,"column":33}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                              <img class=\"tt-availability-summary-info-mobile__avatar\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"area_face_img_link") || (depth0 != null ? lookupProperty(depth0,"area_face_img_link") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"area_face_img_link","hash":{},"data":data,"loc":{"start":{"line":38,"column":92},"end":{"line":38,"column":114}}}) : helper)))
    + "\" />\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "                              <div class=\"tt-availability-summary-info-mobile__avatar--default tt-icon-hospital-alt\"></div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\"javascript:\" class=\"tt-availability-summary-info-mobile__content-title goToResource\" data-machid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"machid") || (depth0 != null ? lookupProperty(depth0,"machid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"machid","hash":{},"data":data,"loc":{"start":{"line":49,"column":135},"end":{"line":49,"column":145}}}) : helper)))
    + "\">\n                              "
    + alias4(((helper = (helper = lookupProperty(helpers,"resourceName") || (depth0 != null ? lookupProperty(depth0,"resourceName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"resourceName","hash":{},"data":data,"loc":{"start":{"line":50,"column":30},"end":{"line":50,"column":46}}}) : helper)))
    + "\n                            </a>\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"resources_privacy_review") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":28},"end":{"line":65,"column":39}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"showDoctorReviews","==",1,{"name":"checkConfig","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":32},"end":{"line":64,"column":48}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div id=\"rating-container-"
    + alias4(((helper = (helper = lookupProperty(helpers,"rating_id") || (depth0 != null ? lookupProperty(depth0,"rating_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rating_id","hash":{},"data":data,"loc":{"start":{"line":54,"column":58},"end":{"line":54,"column":71}}}) : helper)))
    + "\" class=\"tt-availability-summary-info-mobile__content-review\">\n                                    <div id=\"rating-loader-"
    + alias4(((helper = (helper = lookupProperty(helpers,"rating_id") || (depth0 != null ? lookupProperty(depth0,"rating_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rating_id","hash":{},"data":data,"loc":{"start":{"line":55,"column":59},"end":{"line":55,"column":72}}}) : helper)))
    + "\" class=\"tt-deprecated-loader\" style=\"margin:10px auto;\"></div>\n                                    <input id=\"doctor-rating-"
    + alias4(((helper = (helper = lookupProperty(helpers,"rating_id") || (depth0 != null ? lookupProperty(depth0,"rating_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rating_id","hash":{},"data":data,"loc":{"start":{"line":56,"column":61},"end":{"line":56,"column":74}}}) : helper)))
    + "\" class=\"rating\" style=\"display: none;\"\n                                            data-min=\"0\"\n                                            data-max=\"5\"\n                                            data-step=\"0.5\"\n                                            data-size=\"xs\"\n                                            data-disabled=\"true\"\n                                            data-showcaption=\"false\">\n                                </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a class=\"tt-availability-summary-info-mobile__content-location\" href=\"javascript:\" class=\"goToLocation\" data-areaid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"areaid") || (depth0 != null ? lookupProperty(depth0,"areaid") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"areaid","hash":{},"data":data,"loc":{"start":{"line":68,"column":146},"end":{"line":68,"column":156}}}) : helper)))
    + "\">\n                              "
    + ((stack1 = (lookupProperty(helpers,"formattedAreaTitle")||(depth0 && lookupProperty(depth0,"formattedAreaTitle"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"webTitle") : depth0),(depth0 != null ? lookupProperty(depth0,"areaTitle") : depth0),{"name":"formattedAreaTitle","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":30},"end":{"line":69,"column":95}}})) != null ? stack1 : "")
    + "\n                            </a>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <div class=\"tt-info\">\n                      <div class=\"tt-info__content\">\n                        <div class=\"tt-info__content-title\">\n                                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Total Price",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":32},"end":{"line":79,"column":78}}})) != null ? stack1 : "")
    + ":\n                        </div>\n                        <span class=\"tt-info__content-text--right\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"currencySymbol") || (depth0 != null ? lookupProperty(depth0,"currencySymbol") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currencySymbol","hash":{},"data":data,"loc":{"start":{"line":81,"column":67},"end":{"line":81,"column":85}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"activityPrice") || (depth0 != null ? lookupProperty(depth0,"activityPrice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityPrice","hash":{},"data":data,"loc":{"start":{"line":81,"column":85},"end":{"line":81,"column":102}}}) : helper)))
    + "</span>\n                        <div class=\"tt-info__content-text\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"activityPriceInfo") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":59},"end":{"line":82,"column":112}}})) != null ? stack1 : "")
    + "</div>\n                    </div>\n                  </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdminLoggedIn") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":18},"end":{"line":118,"column":30}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"discountedLabel") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":18},"end":{"line":134,"column":25}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"additional_surcharges") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":136,"column":18},"end":{"line":149,"column":25}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"activityPriceInfo") || (depth0 != null ? lookupProperty(depth0,"activityPriceInfo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"activityPriceInfo","hash":{},"data":data,"loc":{"start":{"line":82,"column":84},"end":{"line":82,"column":105}}}) : helper)));
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showPrice") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":20},"end":{"line":117,"column":27}}})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"coupon") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":22},"end":{"line":116,"column":33}}})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                          <div data-nested-page=\"first\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"couponid") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":90,"column":30},"end":{"line":114,"column":42}}})) != null ? stack1 : "")
    + "                          </div>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "																<div class=\"tt-container tt-bg-white\">\n																	<div class=\"tt-input-container\">\n                                      <input class=\"tt-input tt-no-margin\" type=\"text\" name=\"coupon_number\" id=\"coupon_number\" autocomplete=\"off\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Coupon Placeholder",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":159},"end":{"line":93,"column":212}}})) != null ? stack1 : "")
    + "\">\n                                  </div>\n                                  <div class=\"text-center\">\n                                      <input type=\"hidden\" name=\"coupon_number_alert\" />\n                                  </div>\n                                  <div class=\"tt-container\">\n                                      <button type=\"button\" id=\"verifyCoupon\" class=\"tt-button tt-button--primary\">\n                                          "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Coupon Button",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":42},"end":{"line":100,"column":90}}})) != null ? stack1 : "")
    + "\n                                      </button>\n                                  </div>\n																</div>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                  <div class=\"item item-body\" style=\"background-color:transparent;\">\n                                      <span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Coupon Success",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":44},"end":{"line":106,"column":89}}})) != null ? stack1 : "")
    + "</span> (<a id=\"cancelCoupon\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Remove",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":119},"end":{"line":106,"column":156}}})) != null ? stack1 : "")
    + "</a>)\n                                      <span class=\"item-note\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"discountAmount") || (depth0 != null ? lookupProperty(depth0,"discountAmount") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"discountAmount","hash":{},"data":data,"loc":{"start":{"line":107,"column":62},"end":{"line":107,"column":80}}}) : helper)))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showCouponPayMessage") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":38},"end":{"line":112,"column":45}}})) != null ? stack1 : "")
    + "                                  </div>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                         <br>\n                                         <br>\n                                         "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Coupon Pay Only",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":41},"end":{"line":111,"column":87}}})) != null ? stack1 : "")
    + "\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-info\">\n                        <div class=\"tt-info__content\">\n                            <div class=\"tt-info__content-title\">\n                                "
    + alias4(((helper = (helper = lookupProperty(helpers,"discountedLabel") || (depth0 != null ? lookupProperty(depth0,"discountedLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"discountedLabel","hash":{},"data":data,"loc":{"start":{"line":124,"column":32},"end":{"line":124,"column":51}}}) : helper)))
    + "\n                            </div>\n                            <div class=\"tt-info__content-text--right\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"discountAmount") || (depth0 != null ? lookupProperty(depth0,"discountAmount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"discountAmount","hash":{},"data":data,"loc":{"start":{"line":126,"column":70},"end":{"line":126,"column":88}}}) : helper)))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showDiscountPayMessage") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":28},"end":{"line":131,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"tt-info__content-text\">\n                                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Pay To Benefit Discount",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":129,"column":32},"end":{"line":129,"column":86}}})) != null ? stack1 : "")
    + "\n                            </div>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"additional_surcharges") : depth0),{"name":"each","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":20},"end":{"line":148,"column":29}}})) != null ? stack1 : "");
},"40":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-info\">\n                        <div class=\"tt-info__content\">\n                            <div class=\"tt-info__content-title\">\n                                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\n                            </div>\n                            <div class=\"tt-info__content-text--right\">\n                                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"amount") : depth0), depth0))
    + "\n                            </div>\n                        </div>\n                    </div>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showTotalPriceBox") : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":18},"end":{"line":164,"column":25}}})) != null ? stack1 : "");
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-info\">\n                        <div class=\"tt-info__content\">\n                            <div class=\"tt-info__content-title\">\n                                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Total",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":157,"column":32},"end":{"line":157,"column":68}}})) != null ? stack1 : "")
    + "\n                            </div>\n                            <div class=\"tt-info__content-text--right tt-info__content-text--right--highlight-bold\">\n                                "
    + alias4(((helper = (helper = lookupProperty(helpers,"currencySymbol") || (depth0 != null ? lookupProperty(depth0,"currencySymbol") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currencySymbol","hash":{},"data":data,"loc":{"start":{"line":160,"column":32},"end":{"line":160,"column":50}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"online_activityPrice") || (depth0 != null ? lookupProperty(depth0,"online_activityPrice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"online_activityPrice","hash":{},"data":data,"loc":{"start":{"line":160,"column":50},"end":{"line":160,"column":74}}}) : helper)))
    + "\n                            </div>\n                        </div>\n                    </div>\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-info\">\n                        <div class=\"tt-info__content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":170,"column":28},"end":{"line":173,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"resourceNotice") : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":174,"column":28},"end":{"line":177,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Announcement",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":54},"end":{"line":171,"column":97}}})) != null ? stack1 : "")
    + "</div>\n                                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":172,"column":32},"end":{"line":172,"column":49}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Resource Announcement",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":175,"column":54},"end":{"line":175,"column":110}}})) != null ? stack1 : "")
    + "</div>\n                                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"resourceNotice") || (depth0 != null ? lookupProperty(depth0,"resourceNotice") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"resourceNotice","hash":{},"data":data,"loc":{"start":{"line":176,"column":32},"end":{"line":176,"column":52}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<div class=\"choose_client\" data-nested-page=\"first\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowFamilies") : depth0),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":185,"column":8},"end":{"line":191,"column":15}}})) != null ? stack1 : "")
    + "								<div class=\"tt-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"parents") : depth0),{"name":"each","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":193,"column":10},"end":{"line":207,"column":19}}})) != null ? stack1 : "")
    + "								</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowFamilies") : depth0),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":209,"column":8},"end":{"line":219,"column":15}}})) != null ? stack1 : "")
    + "						</div>\n";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotOneTimeUser") || (depth0 != null ? lookupProperty(depth0,"isNotOneTimeUser") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isNotOneTimeUser","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":186,"column":10},"end":{"line":190,"column":31}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isNotOneTimeUser")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "												<div class=\"tt-label\">\n														"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"MOP Book For",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":188,"column":14},"end":{"line":188,"column":57}}})) != null ? stack1 : "")
    + "\n												</div>\n";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n										<div class=\"tt-input-radio-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":195,"column":47},"end":{"line":195,"column":81}}})) != null ? stack1 : "")
    + "\"  data-trigger=\"user:selectByPatient\" data-trigger-param=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n											<label class=\"tt-input-radio js-no-required-validation\">\n														<input class=\"tt-input-radio__input\" type=\"radio\" name=\"group\" value=\"\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":197,"column":86},"end":{"line":197,"column":131}}})) != null ? stack1 : "")
    + ">\n														<div class=\"tt-input-radio__content\">\n																<div class=\"tt-input-radio__content-text\">\n																		"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n																</div>\n																<i class=\"tt-input-radio__content-icon tt-icon-check\"></i>\n														</div>\n												</label>\n										</div>\n\n";
},"55":function(container,depth0,helpers,partials,data) {
    return "active";
},"57":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"59":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotOneTimeUser") || (depth0 != null ? lookupProperty(depth0,"isNotOneTimeUser") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isNotOneTimeUser","hash":{},"fn":container.program(60, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":210,"column":8},"end":{"line":218,"column":29}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isNotOneTimeUser")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"60":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "										<div class=\"tt-container\">\n												<a href=\"javascript:\" class=\"tt-action\" data-trigger=\"addParent:click\">\n														<span class=\"tt-action__icon tt-icon-user-plus\"></span>\n														<div class=\"tt-action__content\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Add Relatives",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":214,"column":46},"end":{"line":214,"column":90}}})) != null ? stack1 : "")
    + "</div>\n														<span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n												</a>\n										</div>\n";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div style=\"display:;\" data-nested-page=\"first\">\n                    <div class=\"select-user-methods\">\n                        <div class=\"tt-label\">\n                            "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"MOP Book For",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":225,"column":28},"end":{"line":225,"column":71}}})) != null ? stack1 : "")
    + "\n                        </div>\n                      \n                            <div class=\"js-search-user-container tt-h-44\">\n                                <div class=\"tt-input-container\">\n                                <input class=\"form-control tt-input\" id=\"search_user_div\" autocomplete=\"off\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Search Client Mop",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":230,"column":122},"end":{"line":230,"column":170}}})) != null ? stack1 : "")
    + "\">\n                                </div>\n                                <div class=\"tt-hide typehead-loading\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Searching",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":232,"column":70},"end":{"line":232,"column":110}}})) != null ? stack1 : "")
    + " ...</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"recentResUser") : depth0),{"name":"if","hash":{},"fn":container.program(63, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":233,"column":32},"end":{"line":237,"column":39}}})) != null ? stack1 : "")
    + "                            </div>\n                            <div id=\"patient_data_container\"></div>\n                            <a href=\"#\" class=\"tt-action register_patient tt-mt-10\" data-trigger=\"addPatient:click\">\n                                <span class=\"tt-action__icon tt-icon-user-plus\"></span>\n                                <div class=\"tt-action__content\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Register User",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":242,"column":64},"end":{"line":242,"column":108}}})) != null ? stack1 : "")
    + "</div>\n                                <span class=\"tt_action__arrow tt-icon-angle-right\"></span>\n                            </a>\n                     \n                        <div class=\"tt-hide\">\n                            <button type=\"button\" class=\"tt-button tt-button--secondary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Waiting List",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":247,"column":89},"end":{"line":247,"column":132}}})) != null ? stack1 : "")
    + "</button>\n                            <button type=\"button\" class=\"tt-button tt-button--primary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Most Recent Patient",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":248,"column":87},"end":{"line":248,"column":137}}})) != null ? stack1 : "")
    + "</button>\n                        </div>\n                    </div>\n                   \n                        <div class=\"tt-hide\">\n                            <h3 class=\"tt-deprecated-h3\">\n                                <button type=\"button\" class=\"tt-button-close\">\n                                    <span aria-hidden=\"true\">&times;</span>\n                                    <span class=\"tt-sr-only\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Close",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":256,"column":61},"end":{"line":256,"column":97}}})) != null ? stack1 : "")
    + "</span>\n                                </button>\n                            </h3>\n                            <div class=\"list-group choose_client\">\n                                <a href=\"#\" class=\"list-group-item\">Mario Rossi. +39335238229 - m.rossi@mail.it</a>\n                                <a href=\"#\" class=\"list-group-item\">Paolo Bianco, +32738233827376 - p.bianco@hotmail.com</a>\n                                <a href=\"#\" class=\"list-group-item\">Marina Burli, +32362883876 - m.burli@yahoo.it</a>\n                            </div>\n                        </div>\n                    \n                </div>\n";
},"63":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <a href=\"javascript:\" class=\"tt-button tt-button--operator tt-button--primary\" id=\"recent-user-btn\" style=\"margin-top:15px;\">\n                                        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Most Recent Patient",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":235,"column":40},"end":{"line":235,"column":90}}})) != null ? stack1 : "")
    + "\n                                    </a>\n";
},"65":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showContactFieldForm") : depth0),{"name":"if","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":270,"column":12},"end":{"line":284,"column":19}}})) != null ? stack1 : "");
},"66":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"tt-label js-summary-communication-container\" data-nested-page=\"first\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Resume Specitfy Contact",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":271,"column":96},"end":{"line":271,"column":154}}})) != null ? stack1 : "")
    + "</div>\n              <div class=\"tt-container\" data-nested-page=\"first\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationPhone") : depth0),"!=",0,{"name":"ifCond","hash":{},"fn":container.program(67, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":273,"column":16},"end":{"line":277,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationEmail") : depth0),"!=",0,{"name":"ifCond","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":278,"column":16},"end":{"line":282,"column":27}}})) != null ? stack1 : "")
    + "              </div>\n";
},"67":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-input-container tt-input-container--link tt-m-input "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationPhone") : depth0),"==",2,{"name":"ifCond","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":274,"column":83},"end":{"line":274,"column":159}}})) != null ? stack1 : "")
    + "\">\n                  <input class=\"tt-input js-summary-communication-phone\" name=\"communication_phone\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Specify Phone Placeholder",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":275,"column":113},"end":{"line":275,"column":173}}})) != null ? stack1 : "")
    + "\" value="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"phone") || (depth0 != null ? lookupProperty(depth0,"phone") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"phone","hash":{},"data":data,"loc":{"start":{"line":275,"column":181},"end":{"line":275,"column":190}}}) : helper)))
    + ">\n                </div>\n";
},"68":function(container,depth0,helpers,partials,data) {
    return "tt-input-container--required";
},"70":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-input-container tt-input-container--link tt-m-input "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationEmail") : depth0),"==",2,{"name":"ifCond","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":279,"column":83},"end":{"line":279,"column":159}}})) != null ? stack1 : "")
    + "\">\n                  <input class=\"tt-input js-summary-communication-email\" name=\"communication_email\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Specify Email Placeholder",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":280,"column":113},"end":{"line":280,"column":173}}})) != null ? stack1 : "")
    + "\" value="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":280,"column":181},"end":{"line":280,"column":190}}}) : helper)))
    + ">\n                </div>\n";
},"72":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n										<div class=\"tt-input-checkbox-container tt-padding-75-h tt-deprecated-input-checkbox-container\" data-nested-page=\"first\">\n											<li class=\"tt-checkbox\">\n												<label class=\"tt-checkbox__label\">\n													<input class=\"tt-checkbox__label-input\"\n														type=\"checkbox\" name=\"waiting_list\"\n														id=\"waiting_list\"\n														value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"machid") || (depth0 != null ? lookupProperty(depth0,"machid") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"machid","hash":{},"data":data,"loc":{"start":{"line":303,"column":21},"end":{"line":303,"column":31}}}) : helper)))
    + "\"\n														"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"waitingListChecked") : depth0),{"name":"if","hash":{},"fn":container.program(73, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":304,"column":14},"end":{"line":304,"column":54}}})) != null ? stack1 : "")
    + "\n														/>\n												</label>\n												<div class=\"tt-checkbox__text\">\n													"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mobile Waiting List",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":308,"column":13},"end":{"line":308,"column":63}}})) != null ? stack1 : "")
    + "\n												</div>\n											</li>\n										</div>\n\n";
},"73":function(container,depth0,helpers,partials,data) {
    return "checked";
},"75":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<div class=\"tt-padding-75-h\">\n							<a href=\"#\" id=\"new_note_link\" class=\"tt-action nestedPageLink tt-no-margin-bottom\" role=\"button\" data-display=\"summary_textarea\" data-nested-page=\"first\" data-next-nested-page=\"summary_note\">\n								<span class=\"tt-action__icon tt-icon-pen tt-mr-4\"></span>\n								<div class=\"tt-action__content\" id=\"summary_text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"MOP Note Button",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":320,"column":58},"end":{"line":320,"column":104}}})) != null ? stack1 : "")
    + "</div>\n								<span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n							</a>\n						</div>\n";
},"77":function(container,depth0,helpers,partials,data) {
    return "hide";
},"79":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div id=\"prescriptionContainer\" class=\"tt-padding-75-h\">\n                    <div data-prescription-config=\"mopCode\" data-nested-page=\"first\" class=\"tt-input-container\">\n                        <input type=\"text\" class=\"tt-input tt-no-margin-bottom\" name=\"prescription_code\" maxlenght=\"16\"\n                            placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Prescription Code",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":345,"column":41},"end":{"line":345,"column":89}}})) != null ? stack1 : "")
    + "\">\n                    </div>\n                    <a data-prescription-config=\"mopPriority\" class=\"tt-action nestedPageLink tt-no-margin-bottom\" data-next-nested-page=\"summary_priority\" data-nested-page=\"first\" data-next-nested-page=\"summary_priority\">\n                        <div class=\"tt-action__content\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Prescription Priority",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":348,"column":56},"end":{"line":348,"column":108}}})) != null ? stack1 : "")
    + "</div>\n                        <span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n                    </a>\n                    <div data-nested-page=\"summary_priority\" style=\"display: none;\">\n                        <div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Prescription Priority",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":352,"column":46},"end":{"line":352,"column":98}}})) != null ? stack1 : "")
    + "</div>\n                        <div class=\"tt-input-group\">\n								<label class=\"tt-input-radio\">\n										<input class=\"tt-input-radio__input\" type=\"radio\"  name=\"prescription_priority\" value=\"\">\n										<div class=\"tt-input-radio__content\">\n												<div class=\"tt-input-radio__content-text\">\n														"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"No Prescription Priority",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":358,"column":14},"end":{"line":358,"column":69}}})) != null ? stack1 : "")
    + "\n												</div>\n												<i class=\"tt-input-radio__content-icon tt-icon-check\"></i>\n										</div>\n								</label>\n								<label class=\"tt-input-radio\">\n										<input class=\"tt-input-radio__input\" type=\"radio\"  name=\"prescription_priority\" value=\"U\">\n										<div class=\"tt-input-radio__content\">\n												<div class=\"tt-input-radio__content-text\">\n														U - Urgente\n												</div>\n												<i class=\"tt-input-radio__content-icon tt-icon-check\"></i>\n										</div>\n								</label>\n								<label class=\"tt-input-radio\">\n										<input class=\"tt-input-radio__input\" type=\"radio\"  name=\"prescription_priority\" value=\"B\">\n										<div class=\"tt-input-radio__content\">\n												<div class=\"tt-input-radio__content-text\">\n														B - Breve\n												</div>\n												<i class=\"tt-input-radio__content-icon tt-icon-check\"></i>\n										</div>\n								</label>\n								<label class=\"tt-input-radio\">\n										<input class=\"tt-input-radio__input\" type=\"radio\"  name=\"prescription_priority\" value=\"D\">\n										<div class=\"tt-input-radio__content\">\n												<div class=\"tt-input-radio__content-text\">\n														D - Differibile\n												</div>\n												<i class=\"tt-input-radio__content-icon tt-icon-check\"></i>\n										</div>\n								</label>\n								<label class=\"tt-input-radio\">\n										<input class=\"tt-input-radio__input\" type=\"radio\"  name=\"prescription_priority\" value=\"P\">\n										<div class=\"tt-input-radio__content\">\n												<div class=\"tt-input-radio__content-text\">\n														P - Programmabile\n												</div>\n												<i class=\"tt-input-radio__content-icon tt-icon-check\"></i>\n										</div>\n								</label>\n						</div>\n\n                <!-- <select name=\"prescription_priority\" class=\"selectpicker\">\n                            <option value=\"\" selected=\"selected\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"No Prescription Priority",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":402,"column":65},"end":{"line":402,"column":120}}})) != null ? stack1 : "")
    + " </option>\n                            <option value=\"U\">U - Urgente</option>\n                            <option value=\"B\">B - Breve</option>\n                            <option value=\"D\">D - Differibile</option>\n                            <option value=\"P\">P - Programmabile</option>\n                        </select> -->\n            </div>\n            <div data-prescription-config=\"mopDoctor\" data-nested-page=\"first\" class=\"tt-input-container\">\n                <input name=\"prescription_doctor\" class=\"tt-input\" type=\"text\" id=\"prescription_doctor_value\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Prescription Doctor",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":410,"column":123},"end":{"line":410,"column":173}}})) != null ? stack1 : "")
    + "\">\n            </div>\n            <div data-prescription-config=\"mopSummary\" class=\"tt-input-container\" data-nested-page=\"first\">\n                <textarea value=\"prescription_summary\" class=\"custom_fields_textarea tt-w-100\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Prescription Diagnosis",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":413,"column":108},"end":{"line":413,"column":161}}})) != null ? stack1 : "")
    + "\"></textarea>\n            </div>\n        </div>\n";
},"81":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div data-nested-page=\"first\">\n                <div class=\"tt-alert tt-customer-alert\">\n                    <div>\n                    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"discountMessage") || (depth0 != null ? lookupProperty(depth0,"discountMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"discountMessage","hash":{},"data":data,"loc":{"start":{"line":426,"column":20},"end":{"line":426,"column":41}}}) : helper))) != null ? stack1 : "")
    + "\n                    </div>\n                </div>\n            </div>\n";
},"83":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"tt-input-checkbox-container tt-deprecated-input-checkbox-container\" data-nested-page=\"first\">\n						<li class=\"tt-checkbox\">\n							<label class=\"tt-checkbox__label\">\n								<input class=\"tt-checkbox__label-input\" type=\"checkbox\" id=\"reservation_privacy\" autocomplete=\"off\" name=\"privacy\">\n							</label>\n							<div class=\"tt-checkbox__text\" id=\"privacy_url\">\n								"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Privacy Member","conf:title",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":439,"column":8},"end":{"line":439,"column":66}}})) != null ? stack1 : "")
    + "\n							</div>\n						</li>\n					</div>\n";
},"85":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"tt-column\">\n                <button type=\"button\" class=\"tt-button js-goToPaymentMethod\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Mop Add Payment Method",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":450,"column":77},"end":{"line":450,"column":130}}})) != null ? stack1 : "")
    + "</button>\n            </div>\n            ";
},"87":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showReserveButton") : depth0),{"name":"if","hash":{},"fn":container.program(88, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":452,"column":21},"end":{"line":459,"column":19}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPayButton") : depth0),{"name":"if","hash":{},"fn":container.program(93, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":459,"column":20},"end":{"line":463,"column":19}}})) != null ? stack1 : "")
    + " ";
},"88":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n            <div class=\"tt-column\">\n                <button type=\"button\" class=\"tt-button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPayButton") : depth0),{"name":"if","hash":{},"fn":container.program(89, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":454,"column":55},"end":{"line":454,"column":103}}})) != null ? stack1 : "")
    + " js-book-btn submit tt-button--primary tt-customer-book "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdminLoggedIn") : depth0),{"name":"if","hash":{},"fn":container.program(91, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":454,"column":159},"end":{"line":454,"column":196}}})) != null ? stack1 : "")
    + "\"\n                    data-submit-origin=\"0\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"Mop Book Button",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":456,"column":20},"end":{"line":456,"column":66}}})) != null ? stack1 : "")
    + "\n                </button>\n            </div>\n            ";
},"89":function(container,depth0,helpers,partials,data) {
    return "tt-button--secondary";
},"91":function(container,depth0,helpers,partials,data) {
    return "tt-hide";
},"93":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n            <div class=\"tt-column\">\n                <button type=\"button\" class=\"tt-button submit tt-button--primary\" data-submit-origin=\"1\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Buy Reservation",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":461,"column":105},"end":{"line":461,"column":151}}})) != null ? stack1 : "")
    + "</button>\n            </div>\n            ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"summaryAvailabilityAdditionalInfo") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mopShowBannerIfAvailabilityTooClose") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "\n<form>\n\n        <div class=\"list summary_container item-text-wrap\">\n                <div class=\"appointment_block item-text-wrap\" data-nested-page=\"first\">\n                    <div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"App Your Search",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":42},"end":{"line":15,"column":88}}})) != null ? stack1 : "")
    + "</div>\n                    <div class=\"tt-label tt-label--middle appointment_service tt-mb-10\">\n                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"typologyTitle") || (depth0 != null ? lookupProperty(depth0,"typologyTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typologyTitle","hash":{},"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":17,"column":41}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"activityTitle") || (depth0 != null ? lookupProperty(depth0,"activityTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityTitle","hash":{},"data":data,"loc":{"start":{"line":17,"column":44},"end":{"line":17,"column":61}}}) : helper)))
    + "\n                    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showConflictBanner") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":27,"column":27}}})) != null ? stack1 : "")
    + "\n                    <div class=\"tt-availability-summary-info-mobile\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showResource") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":30,"column":22},"end":{"line":42,"column":29}}})) != null ? stack1 : "")
    + "\n                        <div class=\"tt-availability-summary-info-mobile__content\">\n                            <div class=\"tt-availability-summary-info-mobile__content-title\">\n                                    "
    + ((stack1 = (lookupProperty(helpers,"humanDateLongFormat")||(depth0 && lookupProperty(depth0,"humanDateLongFormat"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"start_date_day_abbr_week") : depth0),(depth0 != null ? lookupProperty(depth0,"start_date") : depth0),(depth0 != null ? lookupProperty(depth0,"start_date_month_abbr") : depth0),{"name":"humanDateLongFormat","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":36},"end":{"line":46,"column":142}}})) != null ? stack1 : "")
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"startTime") || (depth0 != null ? lookupProperty(depth0,"startTime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"startTime","hash":{},"data":data,"loc":{"start":{"line":46,"column":145},"end":{"line":46,"column":158}}}) : helper)))
    + "\n                            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showResource") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":28},"end":{"line":66,"column":31}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAreas") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":24},"end":{"line":71,"column":31}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPrice") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":16},"end":{"line":150,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPrice") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":16},"end":{"line":165,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAdditionalDataDiv") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":167,"column":16},"end":{"line":180,"column":23}}})) != null ? stack1 : "")
    + "            		</div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdminLoggedIn") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(50, data, 0),"inverse":container.program(62, data, 0),"data":data,"loc":{"start":{"line":183,"column":12},"end":{"line":267,"column":24}}})) != null ? stack1 : "")
    + "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotAdminLoggedIn") || (depth0 != null ? lookupProperty(depth0,"isNotAdminLoggedIn") : depth0)) != null ? helper : alias2),(options={"name":"isNotAdminLoggedIn","hash":{},"fn":container.program(65, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":269,"column":12},"end":{"line":285,"column":35}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNotAdminLoggedIn")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n        \n                <div class=\"tt-hide\" id=\"custom_fields_container\">\n                    <div class=\"tt-label\" data-nested-page=\"first\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Additional Info",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":289,"column":67},"end":{"line":289,"column":113}}})) != null ? stack1 : "")
    + "</div>\n                    <div id=\"custom_fields\" class=\"tt-container\"></div>\n                </div>\n                \n                <div class=\"tt-label\" data-nested-page=\"first\" id=\"otherLabel\" style\"margin-bottom: 0;\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Other Singular",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":293,"column":104},"end":{"line":293,"column":149}}})) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mopClientsWaitingList") : depth0),{"name":"if","hash":{},"fn":container.program(72, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":295,"column":16},"end":{"line":313,"column":23}}})) != null ? stack1 : "")
    + "            \n\n"
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"disableReservationsNotes","==",0,{"name":"checkConfig","hash":{},"fn":container.program(75, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":316,"column":12},"end":{"line":324,"column":28}}})) != null ? stack1 : "")
    + "\n            <div class=\""
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"summaryFromLStorage") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(77, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":326,"column":24},"end":{"line":326,"column":72}}})) != null ? stack1 : "")
    + " tt-padding-75-h\" id=\"summary_textarea\" data-nested-page=\"summary_note\" style=\"display:none;\">\n               \n							<div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Write Note",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":328,"column":29},"end":{"line":328,"column":74}}})) != null ? stack1 : "")
    + "</div>\n							<div class=\"tt-container\">\n									<div class=\"tt-input-container\">\n											<textarea class=\"tt-input\" name=\"summary\" id=\"summary\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"summaryFromLStorage") || (depth0 != null ? lookupProperty(depth0,"summaryFromLStorage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"summaryFromLStorage","hash":{},"data":data,"loc":{"start":{"line":331,"column":66},"end":{"line":331,"column":89}}}) : helper)))
    + "</textarea>\n									</div>\n							</div>\n             \n                <div class=\"tt-container\">\n                    <button type=\"button\" class=\"tt-button tt-button--primary nestedPageLinkBack writeNestedPageSelection\" writeNested-ToId=\"summary_text\" getNestedData-fromId=\"summary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Insert",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":336,"column":186},"end":{"line":336,"column":223}}})) != null ? stack1 : "")
    + "</button>\n                </div>\n            </div>\n\n\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdminLoggedIn") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(79, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":341,"column":12},"end":{"line":416,"column":20}}})) != null ? stack1 : "")
    + "  \n    </div>\n    <!-- end list -->\n\n\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"discountMessage") : depth0),"&&",(depth0 != null ? lookupProperty(depth0,"showPayButton") : depth0),{"name":"ifCond","hash":{},"fn":container.program(81, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":422,"column":8},"end":{"line":430,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPortalInstancePrivacy") : depth0),{"name":"if","hash":{},"fn":container.program(83, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":432,"column":8},"end":{"line":443,"column":15}}})) != null ? stack1 : "")
    + " \n\n    <div class=\"tt-container\">\n        <div class=\"tt-columns is-mobile\" data-nested-page=\"first\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showGoToAddPaymentMethod") : depth0),{"name":"if","hash":{},"fn":container.program(85, data, 0),"inverse":container.program(87, data, 0),"data":data,"loc":{"start":{"line":448,"column":12},"end":{"line":463,"column":27}}})) != null ? stack1 : "")
    + "\n        </div>\n    </div>\n\n</form>\n\n</div>";
},"useData":true});