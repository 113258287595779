var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"appointment_block clearfix\">\n  <div class=\"appointment_date col-sm-2 tt-text-center\">\n    <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"start_date_day_abbr_week") || (depth0 != null ? lookupProperty(depth0,"start_date_day_abbr_week") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start_date_day_abbr_week","hash":{},"data":data,"loc":{"start":{"line":4,"column":10},"end":{"line":4,"column":38}}}) : helper)))
    + "</span>\n    <div class=\"day_number\">"
    + alias4((lookupProperty(helpers,"start_date_day")||(depth0 && lookupProperty(depth0,"start_date_day"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"start_date_timestamp") : depth0),{"name":"start_date_day","hash":{},"data":data,"loc":{"start":{"line":5,"column":28},"end":{"line":5,"column":67}}}))
    + "</div>\n    <div class=\"text-uppercase\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"start_date_month_abbr") || (depth0 != null ? lookupProperty(depth0,"start_date_month_abbr") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start_date_month_abbr","hash":{},"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":57}}}) : helper)))
    + "</div>\n    <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"startTime") || (depth0 != null ? lookupProperty(depth0,"startTime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"startTime","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":25}}}) : helper)))
    + "</strong>\n  </div>\n  <div class=\"appointment_summary col-sm-8\" style=\"display: flex; align-items: center; flex-direction: row;\">\n    <div>\n      <span class=\"appointment_service clear\">\n        <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"typologyTitle") || (depth0 != null ? lookupProperty(depth0,"typologyTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typologyTitle","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":33}}}) : helper)))
    + "</strong>\n        <br>\n        <div class=\"appointment_activity\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"activityTitle") || (depth0 != null ? lookupProperty(depth0,"activityTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityTitle","hash":{},"data":data,"loc":{"start":{"line":14,"column":42},"end":{"line":14,"column":59}}}) : helper)))
    + "</div>\n      </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"showResource") : depths[1]),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":6},"end":{"line":20,"column":13}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"showAreas") : depths[1]),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":14},"end":{"line":25,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n\n\n  <!-- per ora disabilito il pulsante note -->\n  <div class=\"summary_addons col-sm-2 tt-text-center\" style=\"display: flex; align-items: center; flex-direction: row;\">\n    <div>\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"showPrice") : depths[1]),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":6},"end":{"line":34,"column":101}}})) != null ? stack1 : "")
    + "\n      <button class=\"tt-button tt-button--secondary summary-note-link\" data-display=\"summary_textarea\">\n        <span class=\"tt-icon-pen tt-mr-4\"></span> "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"MOP Note Button",{"name":"translate","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":50},"end":{"line":36,"column":96}}})) != null ? stack1 : "")
    + "\n      </button>\n    </div>\n  </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":2},"end":{"line":45,"column":9}}})) != null ? stack1 : "")
    + "\n  <div class=\""
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"summaryFromLStorage") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":14},"end":{"line":47,"column":65}}})) != null ? stack1 : "")
    + " summary_addons summary_textarea untrusted-textarea\">\n    <button type=\"button\" class=\"tt-button-close\">\n      <span class=\"tt-icon-times\" style=\"display: block; margin-bottom: 3px;\"></span>\n      <span class=\"tt-sr-only\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Close",{"name":"translate","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":31},"end":{"line":50,"column":67}}})) != null ? stack1 : "")
    + "</span>\n    </button>\n    <textarea name=\"summary\" class=\"form-control summary\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"summaryFromLStorage") || (depth0 != null ? lookupProperty(depth0,"summaryFromLStorage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"summaryFromLStorage","hash":{},"data":data,"loc":{"start":{"line":52,"column":58},"end":{"line":52,"column":81}}}) : helper)))
    + "</textarea>\n  </div>\n\n\n</div>\n<!-- end appointment_block -->\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"appointment_resource\">\n        <strong>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"resourceName") || (depth0 != null ? lookupProperty(depth0,"resourceName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"resourceName","hash":{},"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":18,"column":32}}}) : helper)))
    + "</strong>\n      </div>\n      ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n      <div class=\"appointment_location\">\n        <span class=\"tt-icon-map-marker-alt\"></span>\n        <strong>"
    + ((stack1 = (lookupProperty(helpers,"formattedAreaTitle")||(depth0 && lookupProperty(depth0,"formattedAreaTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"webTitle") : depth0),(depth0 != null ? lookupProperty(depth0,"areaTitle") : depth0),{"name":"formattedAreaTitle","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":23,"column":81}}})) != null ? stack1 : "")
    + "</strong>\n      </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"activityPrice") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":26},"end":{"line":34,"column":94}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n      <div class=\"tt-price tt-customer-price\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"activityPrice") || (depth0 != null ? lookupProperty(depth0,"activityPrice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityPrice","hash":{},"data":data,"loc":{"start":{"line":34,"column":46},"end":{"line":34,"column":63}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"currencySymbol") || (depth0 != null ? lookupProperty(depth0,"currencySymbol") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currencySymbol","hash":{},"data":data,"loc":{"start":{"line":34,"column":63},"end":{"line":34,"column":81}}}) : helper)))
    + "</div>";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"clear summary_addons\">\n    </br>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":43,"column":9},"end":{"line":43,"column":26}}}) : helper))) != null ? stack1 : "")
    + "\n  </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "tt-hide";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"availabilities") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":58,"column":9}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Untrustable Alert Text",{"name":"translate","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":10},"end":{"line":58,"column":63}}})) != null ? stack1 : "")
    + "\n<br>\n<br>\n<div class=\"tt-columns\">\n  <div class=\"tt-column\">\n    <button type=\"button\" class=\"tt-button submit tt-button--primary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Deferred Email Btn",{"name":"translate","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":70},"end":{"line":63,"column":119}}})) != null ? stack1 : "")
    + "</button>\n  </div>\n  <div class=\"tt-column\">\n    <a class=\"tt-button tt-button--secondary back_to_availabilities\" href=\"#\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Back",{"name":"translate","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":78},"end":{"line":66,"column":113}}})) != null ? stack1 : "")
    + "</a>\n  </div>\n</div>";
},"useData":true,"useDepths":true});