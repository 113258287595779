var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"modal-body\">\n              <div class=\"tt-text-bold tt-mb-10\">\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Patients Search Options Title",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":80}}})) != null ? stack1 : "")
    + "\n              </div>\n\n              <div class=\"tt-switch tt-margin-20\">\n                <input type=\"checkbox\" id=\"js-admin-show-only-future-toggle\" "
    + ((stack1 = (lookupProperty(helpers,"mopLocalStorageKeyNotExistOrTrue")||(depth0 && lookupProperty(depth0,"mopLocalStorageKeyNotExistOrTrue"))||alias2).call(alias1,"mop-admin-show-only-future-toggle",{"name":"mopLocalStorageKeyNotExistOrTrue","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":77},"end":{"line":10,"column":194}}})) != null ? stack1 : "")
    + " />\n                <label for=\"js-admin-show-only-future-toggle\">\n                  "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Patients Search Options Future Appts Only",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":18},"end":{"line":12,"column":94}}})) != null ? stack1 : "")
    + "\n                </label>\n              </div>\n\n                <div class=\"tt-switch tt-margin-20\">\n                <input type=\"checkbox\" id=\"js-admin-show-cancel-reservation-toggle\" "
    + ((stack1 = (lookupProperty(helpers,"mopLocalStorageKeyIsTrue")||(depth0 && lookupProperty(depth0,"mopLocalStorageKeyIsTrue"))||alias2).call(alias1,"mop-admin-show-cancel-reservation-toggle",{"name":"mopLocalStorageKeyIsTrue","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":84},"end":{"line":17,"column":192}}})) != null ? stack1 : "")
    + " />\n                <label for=\"js-admin-show-cancel-reservation-toggle\">\n                  "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Patients Search Options Show Cancelled Appts",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":18},"end":{"line":19,"column":97}}})) != null ? stack1 : "")
    + "\n                </label>\n              </div>\n\n            </div>\n            <div class=\"modal-footer\">\n              <div class=\"tt-flexend-container tt-dialog-button js-buttons-container\">\n\n                    <button type=\"button\" class=\"tt-button tt-button--secondary\" data-dismiss=\"modal\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Clear",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":102},"end":{"line":27,"column":138}}})) != null ? stack1 : "")
    + "</button>\n\n\n                    <button type=\"button\" class=\"tt-button tt-button--primary\" id=\"yes_click_modal\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"OK",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":100},"end":{"line":30,"column":133}}})) != null ? stack1 : "")
    + "</button>\n\n              </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});