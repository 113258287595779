import React from 'react';
import PropTypes from 'prop-types';
import * as style from "./style";
import { Label } from '../Label';

export const CustomFieldContainer = ({
  title = null,
  description = null,
  isRequired = false,
  children = null
}) => {

  return (
    <div css={style.base}>
      {title &&
        <Label size={"medium"} weight={"medium"}>
          {title} {isRequired ? " * " : ""}
        </Label>
      }
      {children}
      {description && <Label size={"medium"} color={"text_light"} dangerouslySetInnerHTML={description} />}
    </div>
  );

};

CustomFieldContainer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isRequired: PropTypes.bool,
  children: PropTypes.node
};