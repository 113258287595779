var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <!-- 1 caso: in cui c'è la sede -->\n        <td data-align=\"left\" class=\"info-col tt-availabilities-days-doctor-col\">\n          <div class=\"tt-w-100\">\n\n        <div class=\"tt-availabilities-doctor-info tt-availabilities-doctor-info-desktop\">\n        \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showResource") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"tt-availabilities-doctor-info__content\">\n					<a href=\"javascript:\" class=\"tt-availabilities-doctor-info__content-name\" data-machid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"machid") || (depth0 != null ? lookupProperty(depth0,"machid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"machid","hash":{},"data":data,"loc":{"start":{"line":23,"column":92},"end":{"line":23,"column":102}}}) : helper)))
    + "\"> \n					</a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showResource") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":6},"end":{"line":62,"column":15}}})) != null ? stack1 : "")
    + "        \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAreas") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":4},"end":{"line":85,"column":15}}})) != null ? stack1 : "")
    + "        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"activityPriceInfo") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":8},"end":{"line":86,"column":109}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPrice") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":4},"end":{"line":89,"column":15}}})) != null ? stack1 : "")
    + "				</div>\n		</div>\n \n    </div>\n     \n        </td>\n    ";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"resource_face_img_link") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":9,"column":10},"end":{"line":13,"column":17}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <img class=\"tt-availabilities-doctor-info__avatar\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"resource_face_img_link") || (depth0 != null ? lookupProperty(depth0,"resource_face_img_link") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"resource_face_img_link","hash":{},"data":data,"loc":{"start":{"line":10,"column":68},"end":{"line":10,"column":94}}}) : helper)))
    + "\" data-machid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"machid") || (depth0 != null ? lookupProperty(depth0,"machid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"machid","hash":{},"data":data,"loc":{"start":{"line":10,"column":109},"end":{"line":10,"column":119}}}) : helper)))
    + "\"/>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"tt-availabilities-doctor-info__avatar--default tt-icon-user-md\" data-machid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"machid") || (depth0 != null ? lookupProperty(depth0,"machid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"machid","hash":{},"data":data,"loc":{"start":{"line":12,"column":101},"end":{"line":12,"column":111}}}) : helper)))
    + "\"></div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"area_face_img_link") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":15,"column":10},"end":{"line":19,"column":17}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <img class=\"tt-availabilities-doctor-info__avatar\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"area_face_img_link") || (depth0 != null ? lookupProperty(depth0,"area_face_img_link") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"area_face_img_link","hash":{},"data":data,"loc":{"start":{"line":16,"column":68},"end":{"line":16,"column":90}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAreas") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":92},"end":{"line":16,"column":140}}})) != null ? stack1 : "")
    + "/>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-areaid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"areaid") || (depth0 != null ? lookupProperty(depth0,"areaid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"areaid","hash":{},"data":data,"loc":{"start":{"line":16,"column":122},"end":{"line":16,"column":132}}}) : helper)))
    + "\"";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"tt-availabilities-doctor-info__avatar--default tt-icon-hospital-alt\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showAreas") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":93},"end":{"line":18,"column":141}}})) != null ? stack1 : "")
    + "></div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"resource_cv_link") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":34,"column":19}}})) != null ? stack1 : "")
    + "					\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"resources_privacy_review") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":11},"end":{"line":49,"column":22}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"showResourceLangs","==",1,{"name":"checkConfig","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":6},"end":{"line":61,"column":27}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"tt-availabilities-doctor-info__content-link\">\n                <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"resource_cv_link") || (depth0 != null ? lookupProperty(depth0,"resource_cv_link") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"resource_cv_link","hash":{},"data":data,"loc":{"start":{"line":28,"column":25},"end":{"line":28,"column":45}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"resourceName") || (depth0 != null ? lookupProperty(depth0,"resourceName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"resourceName","hash":{},"data":data,"loc":{"start":{"line":28,"column":63},"end":{"line":28,"column":79}}}) : helper)))
    + "</a>\n              </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<div class=\"tt-availabilities-doctor-info__content-name-text\">\n							"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"resourceName") || (depth0 != null ? lookupProperty(depth0,"resourceName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"resourceName","hash":{},"data":data,"loc":{"start":{"line":32,"column":7},"end":{"line":32,"column":23}}}) : helper)))
    + "		\n						</div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"showDoctorReviews","==",1,{"name":"checkConfig","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":48,"column":28}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "               <div class=\"tt-availabilities-doctor-info__content-review tt-rating tt-js-rating-container-"
    + alias4(((helper = (helper = lookupProperty(helpers,"rating_id") || (depth0 != null ? lookupProperty(depth0,"rating_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rating_id","hash":{},"data":data,"loc":{"start":{"line":38,"column":106},"end":{"line":38,"column":119}}}) : helper)))
    + "\">\n                 <div id=\"rating-loader-"
    + alias4(((helper = (helper = lookupProperty(helpers,"rating_id") || (depth0 != null ? lookupProperty(depth0,"rating_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rating_id","hash":{},"data":data,"loc":{"start":{"line":39,"column":40},"end":{"line":39,"column":53}}}) : helper)))
    + "\" class=\"tt-deprecated-loader\"></div>\n                 <input id=\"doctor-rating-"
    + alias4(((helper = (helper = lookupProperty(helpers,"rating_id") || (depth0 != null ? lookupProperty(depth0,"rating_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rating_id","hash":{},"data":data,"loc":{"start":{"line":40,"column":42},"end":{"line":40,"column":55}}}) : helper)))
    + "\" class=\"rating\" style=\"display: none;\"\n                        data-min=\"0\"\n                        data-max=\"5\"\n                        data-step=\"0.5\"\n                        data-size=\"xs\"\n                        data-disabled=\"true\"\n                        data-showcaption=\"false\">\n              </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"resources_langs") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":12},"end":{"line":60,"column":21}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "               <div class=\"tt-availabilities-doctor-info__content-lang\">\n               <div class=\"tt-availabilities-doctor-info__content-lang-text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"Mop Doctor Spoken Languages",{"name":"translate","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":77},"end":{"line":53,"column":135}}})) != null ? stack1 : "")
    + "</div>\n								 <div class=\"tt-availabilities-doctor-info__content-lang-flags\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"resources_langs") : depth0),{"name":"each","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":16},"end":{"line":57,"column":17}}})) != null ? stack1 : "")
    + "               </div>\n               </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "										<img class=\"tt-availabilities-doctor-info__content-lang-flags__flag tt-bg-white\" src=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"getImgPath") || (depth0 != null ? lookupProperty(depth0,"getImgPath") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"getImgPath","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":96},"end":{"line":56,"column":126}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"getImgPath")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "/countries-flags/"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + ".svg\">\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"javascript:\" class=\"tt-availabilities-doctor-info__content-location\" data-areaid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"areaid") || (depth0 != null ? lookupProperty(depth0,"areaid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"areaid","hash":{},"data":data,"loc":{"start":{"line":65,"column":103},"end":{"line":65,"column":113}}}) : helper)))
    + "\">\n                <div class=\"tt-availabilities-doctor-info__content-location__data\">\n                  <div class=\"tt-availabilities-doctor-info__content-location__data-name\">\n                    <span class=\"tt-icon-map-marker-alt\"></span>"
    + ((stack1 = (lookupProperty(helpers,"formattedAreaTitle")||(depth0 && lookupProperty(depth0,"formattedAreaTitle"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"webTitle") : depth0),(depth0 != null ? lookupProperty(depth0,"areaTitle") : depth0),{"name":"formattedAreaTitle","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":64},"end":{"line":68,"column":129}}})) != null ? stack1 : "")
    + "\n                  </div>\n                  <div class=\"tt-availabilities-doctor-info__content-location__data-address\">\n                    <div class=\"availabilities_address\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"address") || (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"address","hash":{},"data":data,"loc":{"start":{"line":71,"column":56},"end":{"line":71,"column":67}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"city") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":67},"end":{"line":71,"column":96}}})) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"distance") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":20},"end":{"line":81,"column":27}}})) != null ? stack1 : "")
    + "                  </div>\n              </div>\n            </a>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ", "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"city") || (depth0 != null ? lookupProperty(depth0,"city") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"city","hash":{},"data":data,"loc":{"start":{"line":71,"column":81},"end":{"line":71,"column":89}}}) : helper)));
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-text-center\">\n                      <span>\n                        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Distance km",{"name":"translate","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":24},"end":{"line":75,"column":66}}})) != null ? stack1 : "")
    + "\n                      </span>\n                      <span>\n                        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"distance") || (depth0 != null ? lookupProperty(depth0,"distance") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"distance","hash":{},"data":data,"loc":{"start":{"line":78,"column":24},"end":{"line":78,"column":36}}}) : helper)))
    + "\n                      </span>\n                    </div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"insurance_isHiddenPrice") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":33},"end":{"line":86,"column":102}}})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"activityPriceInfo") || (depth0 != null ? lookupProperty(depth0,"activityPriceInfo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"activityPriceInfo","hash":{},"data":data,"loc":{"start":{"line":86,"column":69},"end":{"line":86,"column":90}}}) : helper)));
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"tt-availabilities-doctor-info__content-price\">"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"insurance_isHiddenPrice") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(36, data, 0),"inverse":container.program(38, data, 0),"data":data,"loc":{"start":{"line":88,"column":70},"end":{"line":88,"column":225}}})) != null ? stack1 : "")
    + "</div>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias4(((helper = (helper = lookupProperty(helpers,"currencySymbol") || (depth0 != null ? lookupProperty(depth0,"currencySymbol") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currencySymbol","hash":{},"data":data,"loc":{"start":{"line":88,"column":106},"end":{"line":88,"column":124}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"activityPrice") || (depth0 != null ? lookupProperty(depth0,"activityPrice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityPrice","hash":{},"data":data,"loc":{"start":{"line":88,"column":124},"end":{"line":88,"column":141}}}) : helper)));
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Mop Insurance Price Hidden Mobile",{"name":"translate","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":149},"end":{"line":88,"column":213}}})) != null ? stack1 : "");
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <!-- 2 caso: in cui non c'è la sede o la sede è unica -->\n        <td data-align=\"left\" class=\"info-col availabilities_nolocation tt-availabilities-days-doctor-col\">\n        <div class=\"tt-w-100\">\n            <div class=\"tt-availabilities-doctor-info tt-availabilities-doctor-info-desktop\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showResource") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(41, data, 0),"data":data,"loc":{"start":{"line":100,"column":8},"end":{"line":112,"column":15}}})) != null ? stack1 : "")
    + "        \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showResource") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":6},"end":{"line":149,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPrice") : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":150,"column":6},"end":{"line":152,"column":13}}})) != null ? stack1 : "")
    + "      </div>\n      \n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"activityPriceInfo") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":155,"column":6},"end":{"line":155,"column":107}}})) != null ? stack1 : "")
    + "\n\n    </div>\n            </div>\n        </td>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"area_face_img_link") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(44, data, 0),"data":data,"loc":{"start":{"line":107,"column":10},"end":{"line":111,"column":17}}})) != null ? stack1 : "");
},"42":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <img class=\"tt-availabilities-doctor-info__avatar\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"area_face_img_link") || (depth0 != null ? lookupProperty(depth0,"area_face_img_link") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"area_face_img_link","hash":{},"data":data,"loc":{"start":{"line":108,"column":68},"end":{"line":108,"column":90}}}) : helper)))
    + "\"/>\n";
},"44":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"tt-availabilities-doctor-info__avatar--default tt-icon-hospital-alt\"></div>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"tt-availabilities-doctor-info__content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"resource_cv_link") : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.program(49, data, 0),"data":data,"loc":{"start":{"line":116,"column":8},"end":{"line":122,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"resources_privacy_review") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":123,"column":8},"end":{"line":136,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"showResourceLangs","==",1,{"name":"checkConfig","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":10},"end":{"line":148,"column":26}}})) != null ? stack1 : "");
},"47":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-availabilities-doctor-info__content-link\">\n          <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"resource_cv_link") || (depth0 != null ? lookupProperty(depth0,"resource_cv_link") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"resource_cv_link","hash":{},"data":data,"loc":{"start":{"line":118,"column":19},"end":{"line":118,"column":39}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"resourceName") || (depth0 != null ? lookupProperty(depth0,"resourceName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"resourceName","hash":{},"data":data,"loc":{"start":{"line":118,"column":57},"end":{"line":118,"column":73}}}) : helper)))
    + "</a>\n        </div>\n";
},"49":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-availabilities-doctor-info__content-name-text\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"resourceName") || (depth0 != null ? lookupProperty(depth0,"resourceName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"resourceName","hash":{},"data":data,"loc":{"start":{"line":121,"column":70},"end":{"line":121,"column":86}}}) : helper)))
    + "</div>\n";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"showDoctorReviews","==",1,{"name":"checkConfig","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":10},"end":{"line":135,"column":26}}})) != null ? stack1 : "");
},"52":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"tt-availabilities-doctor-info__content-review tt-rating tt-js-rating-container-"
    + alias4(((helper = (helper = lookupProperty(helpers,"rating_id") || (depth0 != null ? lookupProperty(depth0,"rating_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rating_id","hash":{},"data":data,"loc":{"start":{"line":125,"column":103},"end":{"line":125,"column":116}}}) : helper)))
    + "\" id=\"rating-container-"
    + alias4(((helper = (helper = lookupProperty(helpers,"rating_id") || (depth0 != null ? lookupProperty(depth0,"rating_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rating_id","hash":{},"data":data,"loc":{"start":{"line":125,"column":139},"end":{"line":125,"column":152}}}) : helper)))
    + "\">\n              <div id=\"rating-loader-"
    + alias4(((helper = (helper = lookupProperty(helpers,"rating_id") || (depth0 != null ? lookupProperty(depth0,"rating_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rating_id","hash":{},"data":data,"loc":{"start":{"line":126,"column":37},"end":{"line":126,"column":50}}}) : helper)))
    + "\" class=\"tt-deprecated-loader\"></div>\n              <input id=\"doctor-rating-"
    + alias4(((helper = (helper = lookupProperty(helpers,"rating_id") || (depth0 != null ? lookupProperty(depth0,"rating_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rating_id","hash":{},"data":data,"loc":{"start":{"line":127,"column":39},"end":{"line":127,"column":52}}}) : helper)))
    + "\" class=\"rating\" style=\"display: none;\"\n                      data-min=\"0\"\n                      data-max=\"5\"\n                      data-step=\"0.5\"\n                      data-size=\"xs\"\n                      data-disabled=\"true\"\n                      data-showcaption=\"false\">\n            </div>\n";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"tt-availabilities-doctor-info__content-price\">"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"insurance_isHiddenPrice") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(36, data, 0),"inverse":container.program(38, data, 0),"data":data,"loc":{"start":{"line":151,"column":68},"end":{"line":151,"column":223}}})) != null ? stack1 : "")
    + "</div>\n";
},"56":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <td data-align=\"center\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"slots") : depth0),{"name":"each","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":164,"column":10},"end":{"line":171,"column":19}}})) != null ? stack1 : "")
    + "        </td>\n";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button type=\"button\" class=\"tt-button tt-button--available "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"class") : depth0), depth0))
    + " availability-btn\" data-model-id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":165,"column":120},"end":{"line":165,"column":126}}}) : helper)))
    + "\">\n                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"startTime") : depth0), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"startTimeFormat") : depth0),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":167,"column":16},"end":{"line":169,"column":23}}})) != null ? stack1 : "")
    + "            </button>\n";
},"58":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-button__format\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"startTimeFormat") : depth0), depth0))
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr>\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAreas") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(40, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":160,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"days") : depth0),{"name":"each","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":162,"column":4},"end":{"line":173,"column":13}}})) != null ? stack1 : "")
    + "</tr>\n\n<tr>\n    <td class=\"before-more-hours\"></td>\n    <td class=\"more_hours tt-more-availabilities\">\n        <a href=\"#\" class=\"results_manager js-expand-results-desktop\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Other Times",{"name":"translate","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":179,"column":70},"end":{"line":179,"column":112}}})) != null ? stack1 : "")
    + " <span class=\"tt-icon-angle-down\"></span></a>\n        <a href=\"#\" class=\"results_manager js-close-results-desktop tt-hide\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Close Times",{"name":"translate","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":180,"column":77},"end":{"line":180,"column":119}}})) != null ? stack1 : "")
    + " <span class=\"tt-icon-angle-up\"></span></a>\n    </td>\n</tr>";
},"useData":true});