var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "tt-container";
},"3":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-container\"";
},"5":function(container,depth0,helpers,partials,data) {
    return "tt-columns";
},"7":function(container,depth0,helpers,partials,data) {
    return "tt-column is-8 is-offset-2";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "        <div id=\"cartasi_user_payment_container\">\n            <div class=\"tt-form-divider\">\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Pay Mode",{"name":"translate","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":55}}})) != null ? stack1 : "")
    + "\n            </div>\n            <div id=\"cartasi_user_payment_list_container\">\n              <div class=\"tt-action\">\n                <i class=\"tt-action__icon "
    + ((stack1 = (lookupProperty(helpers,"getCreditCardIcon")||(depth0 && lookupProperty(depth0,"getCreditCardIcon"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"user_payment") : depth0)) != null ? lookupProperty(stack1,"card_type") : stack1),{"name":"getCreditCardIcon","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":42},"end":{"line":17,"column":109}}})) != null ? stack1 : "")
    + "\"></i>\n                <div class=\"tt-action__content\">\n                ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"creditCardStars") || (depth0 != null ? lookupProperty(depth0,"creditCardStars") : depth0)) != null ? helper : alias2),(options={"name":"creditCardStars","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":19,"column":56}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"creditCardStars")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"user_payment") : depth0)) != null ? lookupProperty(stack1,"last_four") : stack1), depth0))
    + "\n                </div>\n              </div>\n            </div>\n        </div>\n\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <input type=\"hidden\" name=\"mail\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data,"loc":{"start":{"line":34,"column":54},"end":{"line":34,"column":63}}}) : helper)))
    + "\"/>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "          <div id=\"cartasi_payment_submit_container\" class=\"tt-columns tt-mt-20\">\n              <div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":19},"end":{"line":54,"column":67}}})) != null ? stack1 : "")
    + ">\n                  <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":30},"end":{"line":55,"column":86}}})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":87},"end":{"line":55,"column":136}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"payment_required") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":22},"end":{"line":63,"column":34}}})) != null ? stack1 : "")
    + "                      <div class=\"tt-column\">\n                          <button data-e2eid=\"payment-paynow\" type=\"button\" id=\"cartasi-submit-payment-button\" class=\"tt-button tt-button--primary\" mopSelector=\"submitPaymentButton\">\n                              "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Pay Now",{"name":"translate","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":30},"end":{"line":66,"column":68}}})) != null ? stack1 : "")
    + "\n                          </button>\n                      </div>\n                  </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_payment") : depth0),{"name":"unless","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":18},"end":{"line":83,"column":29}}})) != null ? stack1 : "")
    + "              </div>\n          </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-column\"";
},"17":function(container,depth0,helpers,partials,data) {
    return "tt-columns tt-column-flex";
},"19":function(container,depth0,helpers,partials,data) {
    return "tt-column-flex-reverse ";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                          <div class=\"tt-column\">\n                              <a href=\"javascript:\" class=\"tt-button tt-button--secondary\" mopSelector=\"clearPayment\"\n                                  data-default-page=\"reservations\">\n                                  "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Clear",{"name":"translate","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":34},"end":{"line":60,"column":70}}})) != null ? stack1 : "")
    + "\n                              </a>\n                          </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),0,"==",1,{"name":"checkConfig","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":22},"end":{"line":82,"column":38}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                          <div class=\"tt-input-checkbox-container tt-deprecated-input-checkbox-container\">\n                          <li class=\"tt-checkbox\">\n                            <label class=\"tt-checkbox__label\">\n                              <input class=\"tt-checkbox__label-input\" type=\"checkbox\" id=\"cartaSiSavePayment\"/>\n                            </label>\n                            <div class=\"tt-checkbox__text\">\n                              "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Cartasi Deny Card Save",{"name":"translate","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":30},"end":{"line":78,"column":83}}})) != null ? stack1 : "")
    + "\n                            </div>\n                          </li>\n                        </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "        <div class=\"payment-condition\" id=\"paymentConditionContainer\">\n\n          <div id=\"common_condition_checkbox_container\" class=\"tt-mb-20\">\n          </div>\n\n          <div ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":15},"end":{"line":94,"column":61}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n            <div class=\"tt-columns tt-flex\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"payment_required") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":14},"end":{"line":103,"column":26}}})) != null ? stack1 : "")
    + "              <div class=\"tt-column\">\n                <div id=\"cartasi_payment_submit_container\">\n                  <div id=\"cartasi_payment_submit_sub_container\">\n                    <button data-e2eid=\"payment-proceed\" type=\"button\" class=\"tt-button tt-button--primary\" mopSelector=\"submitPaymentButton\"\n                            id=\"cartasi-submit-payment-button\">\n                            "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Proceed",{"name":"translate","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":28},"end":{"line":109,"column":66}}})) != null ? stack1 : "")
    + "\n                    </button>\n                  </div>\n                </div>\n              </div>\n            </div>\n          </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_payment") : depth0),{"name":"unless","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":10},"end":{"line":126,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-column\">\n                    <a href=\"javascript:\" class=\"tt-button tt-button--secondary\" mopSelector=\"clearPayment\"\n                        data-default-page=\"reservations\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Clear",{"name":"translate","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":24},"end":{"line":100,"column":60}}})) != null ? stack1 : "")
    + "\n                    </a>\n                </div>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),0,"==",1,{"name":"checkConfig","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":118,"column":10},"end":{"line":125,"column":26}}})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"tt-switch tt-margin-20\">\n              <input id=\"cartaSiSavePayment\" type=\"checkbox\" />\n              <label for=\"cartaSiSavePayment\">\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Cartasi Deny Card Save",{"name":"translate","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":16},"end":{"line":122,"column":69}}})) != null ? stack1 : "")
    + "\n              </label>\n            </div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),0,"==",1,{"name":"checkConfig","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":6},"end":{"line":155,"column":22}}})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div id=\"payment_cancel_container\">\n            <div class=\"tt-separator\">\n              <span class=\"tt-separator__text tt-separator__text--mobile\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Or",{"name":"translate","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":134,"column":74},"end":{"line":134,"column":107}}})) != null ? stack1 : "")
    + "</span>\n            </div>\n            <div id=\"cartasi_payment_submit_container\">\n                <div class=\"tt-columns\">\n                  <div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":138,"column":23},"end":{"line":138,"column":88}}})) != null ? stack1 : "")
    + ">\n                      <button data-e2eid=\"payment-modify-card\" type=\"button\" class=\"tt-button tt-button--secondary\" mopSelector=\"submitPaymentButton\"\n                          id=\"cartasi-submit-payment-button-with-new\">\n                          "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Cartasi Modify Card",{"name":"translate","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":141,"column":26},"end":{"line":141,"column":76}}})) != null ? stack1 : "")
    + "\n                      </button>\n                  </div>\n                </div>\n            </div>\n\n            <div class=\"tt-switch tt-margin-20\">\n              <input id=\"cartaSiSavePaymentWithNew\" type=\"checkbox\" />\n              <label for=\"cartaSiSavePaymentWithNew\">\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Cartasi Deny Card Save",{"name":"translate","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":150,"column":16},"end":{"line":150,"column":69}}})) != null ? stack1 : "")
    + "\n              </label>\n            </div>\n\n        </div>\n";
},"34":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-column is-8 is-offset-2\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, alias5=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div id=\"billing_information_regions\"></div>\n<div data-nested-page=\"first\">\n  <div id=\"payment_summary_container\" class=\"payment-resume tt-padding-top-30 tt-mb-30 ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":87},"end":{"line":3,"column":125}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"></div>\n\n  <div id=\"billing_information_container\" ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":42},"end":{"line":5,"column":88}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "></div>\n\n  <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":14},"end":{"line":7,"column":55}}})) != null ? stack1 : "")
    + "\">\n    <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":73}}})) != null ? stack1 : "")
    + "\">\n  <div id=\"payment_container\" ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":30},"end":{"line":9,"column":76}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_payment") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":25,"column":13}}})) != null ? stack1 : "")
    + "      <form action=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"endpoint") || (depth0 != null ? lookupProperty(depth0,"endpoint") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"endpoint","hash":{},"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":26,"column":32}}}) : helper)))
    + "\" method=\"post\" id=\"cartasiForm\" name=\"cartasiForm\" target=\"_parent\" style=\"display: none;\">\n          <input type=\"hidden\" name=\"divisa\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"divisa") || (depth0 != null ? lookupProperty(depth0,"divisa") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"divisa","hash":{},"data":data,"loc":{"start":{"line":27,"column":52},"end":{"line":27,"column":62}}}) : helper)))
    + "\"/>\n          <input type=\"hidden\" name=\"importo\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"importo") || (depth0 != null ? lookupProperty(depth0,"importo") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"importo","hash":{},"data":data,"loc":{"start":{"line":28,"column":53},"end":{"line":28,"column":64}}}) : helper)))
    + "\"/>\n          <input type=\"hidden\" name=\"codTrans\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"codTrans") || (depth0 != null ? lookupProperty(depth0,"codTrans") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"codTrans","hash":{},"data":data,"loc":{"start":{"line":29,"column":54},"end":{"line":29,"column":66}}}) : helper)))
    + "\"/>\n          <input type=\"hidden\" name=\"url\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"return") || (depth0 != null ? lookupProperty(depth0,"return") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"return","hash":{},"data":data,"loc":{"start":{"line":30,"column":49},"end":{"line":30,"column":59}}}) : helper)))
    + "\"/>\n          <input type=\"hidden\" name=\"urlpost\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"notify_url") || (depth0 != null ? lookupProperty(depth0,"notify_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"notify_url","hash":{},"data":data,"loc":{"start":{"line":31,"column":53},"end":{"line":31,"column":67}}}) : helper)))
    + "\"/>\n          <input type=\"hidden\" name=\"url_back\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"cancel_return") || (depth0 != null ? lookupProperty(depth0,"cancel_return") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cancel_return","hash":{},"data":data,"loc":{"start":{"line":32,"column":54},"end":{"line":32,"column":71}}}) : helper)))
    + "\"/>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":10},"end":{"line":35,"column":17}}})) != null ? stack1 : "")
    + "          <input type=\"hidden\" name=\"TCONTAB\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"TCONTAB") || (depth0 != null ? lookupProperty(depth0,"TCONTAB") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"TCONTAB","hash":{},"data":data,"loc":{"start":{"line":36,"column":53},"end":{"line":36,"column":64}}}) : helper)))
    + "\"/>\n          <input type=\"hidden\" name=\"languageId\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"languageId") || (depth0 != null ? lookupProperty(depth0,"languageId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"languageId","hash":{},"data":data,"loc":{"start":{"line":37,"column":56},"end":{"line":37,"column":70}}}) : helper)))
    + "\"/>\n          <input type=\"hidden\" name=\"modo_gestione_consegna\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"modo_gestione_consegna") || (depth0 != null ? lookupProperty(depth0,"modo_gestione_consegna") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modo_gestione_consegna","hash":{},"data":data,"loc":{"start":{"line":38,"column":68},"end":{"line":38,"column":94}}}) : helper)))
    + "\"/>\n          <input type=\"hidden\" name=\"mac\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"mac") || (depth0 != null ? lookupProperty(depth0,"mac") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"mac","hash":{},"data":data,"loc":{"start":{"line":39,"column":49},"end":{"line":39,"column":56}}}) : helper)))
    + "\"/>\n          <input type=\"hidden\" name=\"Note1\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"Note1") || (depth0 != null ? lookupProperty(depth0,"Note1") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Note1","hash":{},"data":data,"loc":{"start":{"line":40,"column":51},"end":{"line":40,"column":60}}}) : helper)))
    + "\"/>\n          <input type=\"hidden\" name=\"Note2\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"Note2") || (depth0 != null ? lookupProperty(depth0,"Note2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Note2","hash":{},"data":data,"loc":{"start":{"line":41,"column":51},"end":{"line":41,"column":60}}}) : helper)))
    + "\"/>\n          <input type=\"hidden\" name=\"Note3\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"Note3") || (depth0 != null ? lookupProperty(depth0,"Note3") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Note3","hash":{},"data":data,"loc":{"start":{"line":42,"column":51},"end":{"line":42,"column":60}}}) : helper)))
    + "\"/>\n          <input type=\"hidden\" name=\"session_id\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"custom") || (depth0 != null ? lookupProperty(depth0,"custom") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom","hash":{},"data":data,"loc":{"start":{"line":43,"column":56},"end":{"line":43,"column":66}}}) : helper)))
    + "\"/>\n          <input type=\"hidden\" name=\"xpayTimeout\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"xpayTimeout") || (depth0 != null ? lookupProperty(depth0,"xpayTimeout") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"xpayTimeout","hash":{},"data":data,"loc":{"start":{"line":44,"column":57},"end":{"line":44,"column":72}}}) : helper)))
    + "\"/>\n\n          <div class=\"tt-hide\">\n              <input class=\"\" type=\"submit\" value=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Buy Reservation",{"name":"translate","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":51},"end":{"line":47,"column":97}}})) != null ? stack1 : "")
    + "\" />\n          </div>\n          <br/>\n      </form>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"enablePaymentTerm") : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":6},"end":{"line":86,"column":17}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"enablePaymentTerm") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":4},"end":{"line":128,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_payment") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":130,"column":4},"end":{"line":156,"column":11}}})) != null ? stack1 : "")
    + "\n  </div>\n  </div>\n  </div>\n\n  <div id=\"dialog_payment_condition_container\">\n  </div>\n</div>";
},"useData":true});