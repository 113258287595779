var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tt-container\">\n	<div class=\"tt-label tt-label--left\">\n		"
    + alias4(((helper = (helper = lookupProperty(helpers,"introText") || (depth0 != null ? lookupProperty(depth0,"introText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"introText","hash":{},"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":3,"column":15}}}) : helper)))
    + "\n	</div>\n	<div class=\"tt-input-container tt-input-container--required tt-margin-bottom\">\n		<input class=\"tt-input js-send-issue-check js-send-issue-subject\" type=\"text\" name=\"name\" id=\"name\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Talk To Doctor Chat Subject",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":115},"end":{"line":6,"column":173}}})) != null ? stack1 : "")
    + "\">\n	</div>\n\n	<div class=\"tt-input-container tt-input-container--required tt-textarea tt-margin-bottom\">\n		<textarea name=\"message\" cols=\"30\" rows=\"5\" class=\"js-send-issue-check js-send-issue-note\"\n							placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Message",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":11,"column":58}}})) != null ? stack1 : "")
    + "\"></textarea>\n	</div>\n\n	<button type=\"button\" class=\"tt-button tt-button--primary js-send-issue-btn\" resourceid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"resourceid") || (depth0 != null ? lookupProperty(depth0,"resourceid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"resourceid","hash":{},"data":data,"loc":{"start":{"line":14,"column":90},"end":{"line":14,"column":104}}}) : helper)))
    + "\" disabled>\n		"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Send",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":2},"end":{"line":15,"column":37}}})) != null ? stack1 : "")
    + "\n	</button>\n    \n</div>\n\n\n";
},"useData":true});