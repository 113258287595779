var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tt-alert tt-customer-alert\">\n    <div>\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"summaryAvailabilityAdditionalInfo") || (depth0 != null ? lookupProperty(depth0,"summaryAvailabilityAdditionalInfo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"summaryAvailabilityAdditionalInfo","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":47}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"alert alert-warning\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"mopShowBannerIfAvailabilityTooClose") || (depth0 != null ? lookupProperty(depth0,"mopShowBannerIfAvailabilityTooClose") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"mopShowBannerIfAvailabilityTooClose","hash":{},"data":data,"loc":{"start":{"line":8,"column":33},"end":{"line":8,"column":72}}}) : helper)))
    + "</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<div class=\"tt-availability-summary-info-desktop__details-title\">\n							"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"resourceName") || (depth0 != null ? lookupProperty(depth0,"resourceName") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"resourceName","hash":{},"data":data,"loc":{"start":{"line":36,"column":7},"end":{"line":36,"column":23}}}) : helper)))
    + "\n						</div>\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"resources_privacy_review") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":7},"end":{"line":51,"column":19}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"showDoctorReviews","==",1,{"name":"checkConfig","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":50,"column":25}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "									<div class=\"tt-availability-summary-info-desktop__details-review\" id=\"rating-container-"
    + alias4(((helper = (helper = lookupProperty(helpers,"rating_id") || (depth0 != null ? lookupProperty(depth0,"rating_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rating_id","hash":{},"data":data,"loc":{"start":{"line":40,"column":96},"end":{"line":40,"column":109}}}) : helper)))
    + "\">\n										<div id=\"rating-loader-"
    + alias4(((helper = (helper = lookupProperty(helpers,"rating_id") || (depth0 != null ? lookupProperty(depth0,"rating_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rating_id","hash":{},"data":data,"loc":{"start":{"line":41,"column":33},"end":{"line":41,"column":46}}}) : helper)))
    + "\" class=\"tt-deprecated-loader\" style=\"margin:10px auto;\"></div>\n										<input id=\"doctor-rating-"
    + alias4(((helper = (helper = lookupProperty(helpers,"rating_id") || (depth0 != null ? lookupProperty(depth0,"rating_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rating_id","hash":{},"data":data,"loc":{"start":{"line":42,"column":35},"end":{"line":42,"column":48}}}) : helper)))
    + "\" class=\"rating\" style=\"display: none;\"\n											data-min=\"0\"\n											data-max=\"5\"\n											data-step=\"0.5\"\n											data-size=\"xs\"\n											data-disabled=\"true\"\n											data-showcaption=\"false\">\n									</div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "								<div class=\"tt-availability-summary-info-desktop__details-title\"><span class=\"tt-availability-summary-info-desktop__details-title__icon tt-icon-map-marker-alt\"></span>"
    + ((stack1 = (lookupProperty(helpers,"formattedAreaTitle")||(depth0 && lookupProperty(depth0,"formattedAreaTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"webTitle") : depth0),(depth0 != null ? lookupProperty(depth0,"areaTitle") : depth0),{"name":"formattedAreaTitle","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":175},"end":{"line":54,"column":240}}})) != null ? stack1 : "")
    + "</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "								<div class=\"tt-availability-summary-info-desktop__info-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"currencySymbol") || (depth0 != null ? lookupProperty(depth0,"currencySymbol") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currencySymbol","hash":{},"data":data,"loc":{"start":{"line":61,"column":70},"end":{"line":61,"column":88}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"activityPrice") || (depth0 != null ? lookupProperty(depth0,"activityPrice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityPrice","hash":{},"data":data,"loc":{"start":{"line":61,"column":88},"end":{"line":61,"column":105}}}) : helper)))
    + "</div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "								<div class=\"tt-container\">\n									<button class=\"tt-button tt-button--secondary\" data-display=\"summary_textarea\" id=\"new_note_link\">\n											<span class=\"tt-icon-pen tt-mr-4\"></span> "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"MOP Note Button",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":53},"end":{"line":66,"column":99}}})) != null ? stack1 : "")
    + "\n									</button>\n								</div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":5},"end":{"line":84,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"resourceNotice") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":5},"end":{"line":92,"column":12}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"tt-info\">\n						<div class=\"tt-info__content\">\n							<div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Announcement",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":29},"end":{"line":80,"column":72}}})) != null ? stack1 : "")
    + "</div>\n								"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":81,"column":8},"end":{"line":81,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\n						</div>\n					</div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"tt-info\">\n						<div class=\"tt-info__content\">\n							<div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Resource Announcement",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":29},"end":{"line":88,"column":85}}})) != null ? stack1 : "")
    + "</div>\n								"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"resourceNotice") || (depth0 != null ? lookupProperty(depth0,"resourceNotice") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"resourceNotice","hash":{},"data":data,"loc":{"start":{"line":89,"column":8},"end":{"line":89,"column":28}}}) : helper))) != null ? stack1 : "")
    + "\n						</div>\n					</div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "tt-hide";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-container tt-container--15 clear summary_description\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"discountedActivityString") || (depth0 != null ? lookupProperty(depth0,"discountedActivityString") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"discountedActivityString","hash":{},"data":data,"loc":{"start":{"line":105,"column":77},"end":{"line":105,"column":105}}}) : helper)))
    + "</div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-container tt-container--15 clear summary_description\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"activityPriceInfo") || (depth0 != null ? lookupProperty(depth0,"activityPriceInfo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"activityPriceInfo","hash":{},"data":data,"loc":{"start":{"line":108,"column":77},"end":{"line":108,"column":98}}}) : helper)))
    + "</div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdminLoggedIn") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":12},"end":{"line":141,"column":32}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"discountedLabel") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":143,"column":12},"end":{"line":159,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"additional_surcharges") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":161,"column":12},"end":{"line":175,"column":19}}})) != null ? stack1 : "")
    + "\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"coupon") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":16},"end":{"line":141,"column":19}}})) != null ? stack1 : "")
    + " ";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-container tt-container--center tt-container--border tt-padding-top-20 tt-bg-white\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"couponid") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(33, data, 0),"data":data,"loc":{"start":{"line":114,"column":12},"end":{"line":138,"column":24}}})) != null ? stack1 : "")
    + "        </div>\n\n        ";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"tt-columns tt-flex\">\n                <div class=\"tt-column is-four-fifths\">\n									<div class=\"tt-input-container\">\n                    <input class=\"tt-input tt-no-margin tt-deprecated-input-height\" name=\"coupon_number\" id=\"coupon_number\" autocomplete=\"off\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Coupon Placeholder",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":118,"column":156},"end":{"line":118,"column":209}}})) != null ? stack1 : "")
    + "\">\n									</div>\n                </div>\n                <div class=\"tt-column\">\n                    <a type=\"button\" id=\"verifyCoupon\" class=\"tt-button tt-button--operator tt-button--primary\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Coupon Button",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":123,"column":24},"end":{"line":123,"column":72}}})) != null ? stack1 : "")
    + "\n                    </a>\n                </div>\n            </div>\n            <div>\n                <input type=\"hidden\" name=\"coupon_number_alert\" />\n            </div>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div style=\"float: left;\">\n                <span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Coupon Success",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":22},"end":{"line":132,"column":67}}})) != null ? stack1 : "")
    + "</span> (\n                <a id=\"cancelCoupon\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Remove",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":133,"column":37},"end":{"line":133,"column":74}}})) != null ? stack1 : "")
    + "</a>)</div>\n            <div style=\"float: right;\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"discountAmount") || (depth0 != null ? lookupProperty(depth0,"discountAmount") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"discountAmount","hash":{},"data":data,"loc":{"start":{"line":134,"column":39},"end":{"line":134,"column":57}}}) : helper)))
    + "</div>\n            <div style=\"clear: both;\"></div>\n            <br>\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showCouponPayMessage") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":12},"end":{"line":137,"column":126}}})) != null ? stack1 : "")
    + "\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <p style=\"max-width: 90%\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Coupon Pay Only",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":68},"end":{"line":137,"column":114}}})) != null ? stack1 : "")
    + "</p> ";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-info\">\n                    <div class=\"tt-info__content\">\n                        <div class=\"tt-info__content-title\">\n                            "
    + alias4(((helper = (helper = lookupProperty(helpers,"discountedLabel") || (depth0 != null ? lookupProperty(depth0,"discountedLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"discountedLabel","hash":{},"data":data,"loc":{"start":{"line":147,"column":28},"end":{"line":147,"column":47}}}) : helper)))
    + "\n                        </div>\n                        <span class=\"tt-info__content-text tt-info__content-text--right tt-info__content-text--right--highlight tt-mt-0 tt-pr-0\">\n													"
    + alias4(((helper = (helper = lookupProperty(helpers,"discountAmount") || (depth0 != null ? lookupProperty(depth0,"discountAmount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"discountAmount","hash":{},"data":data,"loc":{"start":{"line":150,"column":13},"end":{"line":150,"column":31}}}) : helper)))
    + "\n												</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showDiscountPayMessage") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":23},"end":{"line":156,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"tt-info__content-text\">\n                            "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Pay To Benefit Discount",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":154,"column":28},"end":{"line":154,"column":82}}})) != null ? stack1 : "")
    + "\n                        </div>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"additional_surcharges") : depth0),{"name":"each","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":163,"column":16},"end":{"line":174,"column":25}}})) != null ? stack1 : "");
},"40":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-info\">\n                        <div class=\"tt-info__content\">\n                            <div class=\"tt-info__content-title\">\n                                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\n                            </div>\n                            <span class=\"tt-info__content-text tt-info__content-text--right tt-info__content-text--right--highlight tt-mt-0 tt-pr-0\">\n															"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"amount") : depth0), depth0))
    + "\n														</span>\n                        </div>\n                    </div>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showTotalPriceBox") : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":178,"column":22},"end":{"line":187,"column":13}}})) != null ? stack1 : "");
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <div class=\"tt-info\">\n            <div class=\"tt-info__content\">\n                <div class=\"tt-info__content-title\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Total",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":182,"column":20},"end":{"line":182,"column":56}}})) != null ? stack1 : "")
    + "\n                    <span class=\"tt-info__content-text tt-info__content-text--right tt-info__content-text--right--highlight-bold tt-mt-0 tt-pr-0\"><strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"currencySymbol") || (depth0 != null ? lookupProperty(depth0,"currencySymbol") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currencySymbol","hash":{},"data":data,"loc":{"start":{"line":183,"column":154},"end":{"line":183,"column":172}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"online_activityPrice") || (depth0 != null ? lookupProperty(depth0,"online_activityPrice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"online_activityPrice","hash":{},"data":data,"loc":{"start":{"line":183,"column":172},"end":{"line":183,"column":196}}}) : helper)))
    + "</strong></span>\n                </div>\n            </div>\n        </div>\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-input-checkbox-container tt-deprecated-input-checkbox-container\">\n					<li class=\"tt-checkbox\">\n						<label class=\"tt-checkbox__label\">\n							<input class=\"tt-checkbox__label-input\"\n								type=\"checkbox\"\n								name=\"waiting_list\"\n								id=\"waiting_list\"\n								value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"machid") || (depth0 != null ? lookupProperty(depth0,"machid") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"machid","hash":{},"data":data,"loc":{"start":{"line":198,"column":15},"end":{"line":198,"column":25}}}) : helper)))
    + "\"\n								"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"waitingListChecked") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":199,"column":8},"end":{"line":199,"column":48}}})) != null ? stack1 : "")
    + "\n								>\n						</label>\n						<div class=\"tt-checkbox__text\">\n							"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"MOP Waiting List",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":203,"column":7},"end":{"line":203,"column":54}}})) != null ? stack1 : "")
    + "\n						</div>\n					</li>\n        </div>\n";
},"46":function(container,depth0,helpers,partials,data) {
    return "checked";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"tt-panel tt-panel-default tt-mt-20\">\n        <div class=\"tt-panel-heading\">\n            <div class=\"tt-columns tt-flex is-vcentered\">\n                <div class=\"tt-column\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"MOP Book For",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":218,"column":20},"end":{"line":218,"column":63}}})) != null ? stack1 : "")
    + "\n                </div>\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowFamilies") : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":220,"column":16},"end":{"line":227,"column":45}}})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n\n        <div class=\"tt-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"parents") : depth0),{"name":"each","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":232,"column":12},"end":{"line":244,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotOneTimeUser") || (depth0 != null ? lookupProperty(depth0,"isNotOneTimeUser") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isNotOneTimeUser","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":220,"column":38},"end":{"line":227,"column":37}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isNotOneTimeUser")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + " ";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                <div class=\"tt-column is-narrow\">\n                    <a href=\"javascript:\" class=\"tt-button tt-button--add-member tt-button--primary\" data-trigger=\"addParent:click\">\n                        <i class=\"tt-button__icon tt-icon-user-plus\"></i>\n                        <span class=\"tt-button__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Add Relatives",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":224,"column":54},"end":{"line":224,"column":98}}})) != null ? stack1 : "")
    + "</span>\n                    </a>\n                </div>\n                ";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "								<div class=\"tt-input-radio-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":233,"column":45},"end":{"line":233,"column":79}}})) != null ? stack1 : "")
    + "\" data-trigger=\"user:selectByPatient\" data-trigger-param=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n									<label class=\"tt-input-radio js-no-required-validation\">\n									<input class=\"tt-input-radio__input\" name=\"group\" type=\"radio\" value=\"\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":235,"column":81},"end":{"line":235,"column":126}}})) != null ? stack1 : "")
    + ">\n										<div class=\"tt-input-radio__content\">\n											<div class=\"tt-input-radio__content-text\">\n												"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n											</div>\n											<i class=\"tt-input-radio__content-icon tt-icon-check\"></i>\n										</div>\n               		</label>\n								</div>\n";
},"53":function(container,depth0,helpers,partials,data) {
    return "active";
},"55":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    <div style=\"display:;\">\n        <div class=\"select-user-methods tt-mt-20\">\n            <div>\n                <div class=\"tt-columns is-vcentered tt-flex\">\n                    <div class=\"tt-column tt-text-left\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"MOP Book For",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":254,"column":24},"end":{"line":254,"column":67}}})) != null ? stack1 : "")
    + "\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"recentResUser") : depth0),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":256,"column":20},"end":{"line":262,"column":27}}})) != null ? stack1 : "")
    + "                    <div class=\"tt-column is-narrow\">\n                        <a href=\"javascript:\" class=\"tt-button tt-button--add-member register_patient tt-button--primary\" data-trigger=\"addPatient:click\">\n                            <i class=\"tt-button__icon tt-icon-user-plus\"></i>\n                            <span class=\"tt-button__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Register User",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":266,"column":58},"end":{"line":266,"column":102}}})) != null ? stack1 : "")
    + "</span>\n                        </a>\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"hideSelectUser") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(60, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":268,"column":24},"end":{"line":272,"column":36}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n            <div class=\"tt-container tt-container--15 js-search-user-container\">\n                <div class=\"tt-input-container\">\n                    <input class=\"tt-input\" id=\"search_user_div\" autocomplete=\"off\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Search Client Mop",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":278,"column":97},"end":{"line":278,"column":145}}})) != null ? stack1 : "")
    + "\">\n                </div>\n                <div class=\"tt-hide typehead-loading\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Searching",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":280,"column":54},"end":{"line":280,"column":94}}})) != null ? stack1 : "")
    + " ...</div>\n            </div>\n            <div id=\"patient_data_container\" class=\"list-group\"></div>\n            <div class=\"tt-hide\">\n                <button type=\"button\" class=\"tt-button tt-button--secondary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Waiting List",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":284,"column":77},"end":{"line":284,"column":120}}})) != null ? stack1 : "")
    + "</button>\n                <button type=\"button\" class=\"tt-button tt-button--primary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Most Recent Patient",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":285,"column":75},"end":{"line":285,"column":125}}})) != null ? stack1 : "")
    + "</button>\n            </div>\n        </div>\n\n        <div class=\"tt-hide tt-mt-20\">\n            <div>\n                <h3 class=\"tt-deprecated-h3\">\n                    <button type=\"button\" class=\"tt-button-close\">\n                        <span aria-hidden=\"true\">&times;</span>\n                        <span class=\"tt-sr-only\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Close",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":294,"column":49},"end":{"line":294,"column":85}}})) != null ? stack1 : "")
    + "</span>\n                    </button>\n                </h3>\n            </div>\n            <div class=\"list-group choose_client\">\n                <a href=\"#\" class=\"list-group-item\">Mario Rossi. +39335238229 - m.rossi@mail.it</a>\n                <a href=\"#\" class=\"list-group-item\">Paolo Bianco, +32738233827376 - p.bianco@hotmail.com</a>\n                <a href=\"#\" class=\"list-group-item\">Marina Burli, +32362883876 - m.burli@yahoo.it</a>\n            </div>\n        </div>\n\n    </div>\n";
},"58":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-column is-narrow\">\n                        <a href=\"javascript:\" class=\"tt-button tt-button--operator pull-right tt-button--primary\" id=\"recent-user-btn\">\n                            "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Most Recent Patient",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":259,"column":28},"end":{"line":259,"column":78}}})) != null ? stack1 : "")
    + "\n                        </a>\n                    </div>\n";
},"60":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a href=\"javascript:\" class=\"tt-button tt-button--operator tt-button--secondary change-patient tt-hide\" data-trigger=\"changePatient:click\">\n                            "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Change Client",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":270,"column":28},"end":{"line":270,"column":72}}})) != null ? stack1 : "")
    + "\n                        </a>\n";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showContactFieldForm") : depth0),{"name":"if","hash":{},"fn":container.program(63, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":309,"column":4},"end":{"line":327,"column":11}}})) != null ? stack1 : "");
},"63":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"js-summary-communication-container tt-panel tt-panel-default tt-mt-20\">\n      <div class=\"tt-panel-heading\">\n          <h3 class=\"tt-panel-title tt-deprecated-h3\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Resume Specitfy Contact",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":312,"column":54},"end":{"line":312,"column":112}}})) != null ? stack1 : "")
    + "</h3>\n      </div>\n      <div class=\"tt-container tt-container--15\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationPhone") : depth0),"!=",0,{"name":"ifCond","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":315,"column":8},"end":{"line":319,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationEmail") : depth0),"!=",0,{"name":"ifCond","hash":{},"fn":container.program(67, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":320,"column":8},"end":{"line":324,"column":19}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n";
},"64":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-input-container "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationPhone") : depth0),"==",2,{"name":"ifCond","hash":{},"fn":container.program(65, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":316,"column":39},"end":{"line":316,"column":115}}})) != null ? stack1 : "")
    + "\">\n          <input class=\"tt-input js-summary-communication-phone\" name=\"communication_phone\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Specify Phone Placeholder",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":317,"column":105},"end":{"line":317,"column":165}}})) != null ? stack1 : "")
    + "\" value="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"phone") || (depth0 != null ? lookupProperty(depth0,"phone") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"phone","hash":{},"data":data,"loc":{"start":{"line":317,"column":173},"end":{"line":317,"column":182}}}) : helper)))
    + ">\n        </div>\n";
},"65":function(container,depth0,helpers,partials,data) {
    return "tt-input-container--required";
},"67":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-input-container "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationEmail") : depth0),"==",2,{"name":"ifCond","hash":{},"fn":container.program(65, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":321,"column":39},"end":{"line":321,"column":115}}})) != null ? stack1 : "")
    + "\">\n          <input class=\"tt-input js-summary-communication-email\" name=\"communication_email\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Specify Email Placeholder",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":322,"column":105},"end":{"line":322,"column":165}}})) != null ? stack1 : "")
    + "\" value="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":322,"column":173},"end":{"line":322,"column":182}}}) : helper)))
    + ">\n        </div>\n";
},"69":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"tt-panel tt-panel-default tt-mt-20\" id=\"prescriptionContainer\">\n        <div class=\"tt-panel-heading\">\n            <h3 class=\"tt-panel-title tt-deprecated-h3\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Other Singular",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":341,"column":56},"end":{"line":341,"column":101}}})) != null ? stack1 : "")
    + "</h3>\n        </div>\n        <div class=\"tt-container tt-container--15\">\n            <!-- Prescription -->\n            <div data-prescription-config=\"mopCode\" class=\"row\">\n                <label class=\"col-sm-4\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Prescription Code",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":346,"column":40},"end":{"line":346,"column":88}}})) != null ? stack1 : "")
    + "</label>\n                <div class=\"col-sm-8\">\n                    <div class=\"tt-input-container\">\n                        <input type=\"text\" name=\"prescription_code\" maxlenght=\"16\" class=\"tt-input\" />\n                    </div>\n                </div>\n            </div>\n\n            <div data-prescription-config=\"mopPriority\" class=\"row\">\n                <label class=\"col-sm-4\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Prescription Priority",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":355,"column":40},"end":{"line":355,"column":92}}})) != null ? stack1 : "")
    + "</label>\n                <div class=\"col-sm-8\">\n                    <select name=\"prescription_priority\" class=\"selectpicker\">\n                        <option value=\"\" selected=\"selected\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"No Prescription Priority",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":358,"column":61},"end":{"line":358,"column":116}}})) != null ? stack1 : "")
    + "</option>\n                        <option value=\"U\">U - Urgente</option>\n                        <option value=\"B\">B - Breve</option>\n                        <option value=\"D\">D - Differibile</option>\n                        <option value=\"P\">P - Programmabile</option>\n                    </select>\n                </div>\n            </div>\n\n            <div data-prescription-config=\"mopDoctor\" class=\"row tt-relative\">\n                <label class=\"col-sm-4\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Prescription Doctor",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":368,"column":40},"end":{"line":368,"column":90}}})) != null ? stack1 : "")
    + "</label>\n                <div class=\"col-sm-8\">\n									<div class=\"tt-input-container\">\n                    <input name=\"prescription_doctor\" type=\"text\" class=\"tt-input\" id=\"prescription_doctor_value\">\n									</div>\n                </div>\n            </div>\n\n            <div data-prescription-config=\"mopSummary\" class=\"row\">\n                <label class=\"col-sm-4\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Prescription Diagnosis",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":377,"column":40},"end":{"line":377,"column":93}}})) != null ? stack1 : "")
    + "</label>\n                <div class=\"col-sm-8\">\n                    <div class=\"tt-input-container\">\n                        <textarea value=\"prescription_summary\" class=\"custom_fields_textarea\"></textarea>\n                    </div>\n                </div>\n            </div>\n            <!-- Prescription - Fine -->\n        </div>\n    </div>\n";
},"71":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"tt-input-checkbox-container tt-deprecated-input-checkbox-container\">\n		<li class=\"tt-checkbox\">\n			<label class=\"tt-checkbox__label\">\n				<input class=\"tt-checkbox__label-input\" type=\"checkbox\" id=\"reservation_privacy\" autocomplete=\"off\" name=\"privacy\"  />\n			</label>\n			<div class=\"tt-checkbox__text\" id=\"privacy_url\">\n				"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Privacy Member","conf:title",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":396,"column":4},"end":{"line":396,"column":62}}})) != null ? stack1 : "")
    + "\n			</div>\n		</li>\n	</div>\n";
},"73":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-column\">\n            <button type=\"button\" class=\"tt-button js-goToPaymentMethod\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Mop Add Payment Method",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":408,"column":73},"end":{"line":408,"column":126}}})) != null ? stack1 : "")
    + "</button>\n        </div>\n        ";
},"75":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showReserveButton") : depth0),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":410,"column":17},"end":{"line":416,"column":15}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPayButton") : depth0),{"name":"if","hash":{},"fn":container.program(81, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":416,"column":16},"end":{"line":420,"column":15}}})) != null ? stack1 : "")
    + " ";
},"76":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "\n        <div class=\"tt-column js-book-btn";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(77, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":411,"column":41},"end":{"line":411,"column":80}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdminLoggedIn") : depth0),{"name":"if","hash":{},"fn":container.program(79, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":411,"column":80},"end":{"line":411,"column":118}}})) != null ? stack1 : "")
    + "\">\n            <button type=\"button\" class=\"tt-button submit tt-customer-book tt-button--primary\" data-submit-origin=\"0\">\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Book Button",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":413,"column":16},"end":{"line":413,"column":62}}})) != null ? stack1 : "")
    + "\n            </button>\n        </div>\n        ";
},"77":function(container,depth0,helpers,partials,data) {
    return " tt-container";
},"79":function(container,depth0,helpers,partials,data) {
    return " tt-hide";
},"81":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <div class=\"tt-column\">\n            <button type=\"button\" class=\"tt-button submit tt-button--primary\" data-submit-origin=\"1\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Buy Reservation",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":418,"column":101},"end":{"line":418,"column":147}}})) != null ? stack1 : "")
    + "</button>\n        </div>\n        ";
},"83":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tt-alert tt-customer-alert\">\n    <div>\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"discountMessage") || (depth0 != null ? lookupProperty(depth0,"discountMessage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"discountMessage","hash":{},"data":data,"loc":{"start":{"line":428,"column":8},"end":{"line":428,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"summaryAvailabilityAdditionalInfo") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":7,"column":7}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mopShowBannerIfAvailabilityTooClose") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":9,"column":7}}})) != null ? stack1 : "")
    + "\n\n<form>\n	<div class=\"tt-columns tt-column-flex is-gapless tt-availability-summary-info-desktop tt-margin-top-20\">\n\n				<div class=\"tt-column is-one-fifth\">\n					<div class=\"tt-availability-summary-info-desktop__date\">\n						<div class=\"tt-availability-summary-info-desktop__date-day\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"start_date_day_abbr_week") || (depth0 != null ? lookupProperty(depth0,"start_date_day_abbr_week") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start_date_day_abbr_week","hash":{},"data":data,"loc":{"start":{"line":17,"column":66},"end":{"line":17,"column":94}}}) : helper)))
    + "</div>\n						<div class=\"tt-availability-summary-info-desktop__date-number\">"
    + alias4((lookupProperty(helpers,"start_date_day")||(depth0 && lookupProperty(depth0,"start_date_day"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"start_date_timestamp") : depth0),{"name":"start_date_day","hash":{},"data":data,"loc":{"start":{"line":18,"column":69},"end":{"line":18,"column":108}}}))
    + "</div>\n						<div class=\"tt-availability-summary-info-desktop__date-month\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"start_date_month_abbr") || (depth0 != null ? lookupProperty(depth0,"start_date_month_abbr") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start_date_month_abbr","hash":{},"data":data,"loc":{"start":{"line":19,"column":68},"end":{"line":19,"column":93}}}) : helper)))
    + "</div>\n						<div class=\"tt-availability-summary-info-desktop__date-year\">"
    + ((stack1 = (lookupProperty(helpers,"humanDateToDateObjYear")||(depth0 && lookupProperty(depth0,"humanDateToDateObjYear"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"start_date") : depth0),{"name":"humanDateToDateObjYear","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":67},"end":{"line":20,"column":132}}})) != null ? stack1 : "")
    + "</div>\n						<div class=\"tt-availability-summary-info-desktop__date-hour\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"startTime") || (depth0 != null ? lookupProperty(depth0,"startTime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"startTime","hash":{},"data":data,"loc":{"start":{"line":21,"column":67},"end":{"line":21,"column":80}}}) : helper)))
    + "</div>\n					</div>\n				</div>\n\n				<div class=\"tt-column\">\n					<div class=\"tt-availability-summary-info-desktop__details\">\n						<div class=\"tt-availability-summary-info-desktop__details-title\">\n							"
    + alias4(((helper = (helper = lookupProperty(helpers,"typologyTitle") || (depth0 != null ? lookupProperty(depth0,"typologyTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typologyTitle","hash":{},"data":data,"loc":{"start":{"line":28,"column":7},"end":{"line":28,"column":24}}}) : helper)))
    + "\n						</div>\n						<div class=\"tt-availability-summary-info-desktop__details-text tt-customer-availability-text\">\n							"
    + alias4(((helper = (helper = lookupProperty(helpers,"activityTitle") || (depth0 != null ? lookupProperty(depth0,"activityTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityTitle","hash":{},"data":data,"loc":{"start":{"line":31,"column":7},"end":{"line":31,"column":24}}}) : helper)))
    + "\n						</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showResource") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":6},"end":{"line":52,"column":14}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAreas") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":7},"end":{"line":55,"column":14}}})) != null ? stack1 : "")
    + "							</div>\n						</div>\n						<div class=\"tt-column is-one-fifth\">\n							<div class=\"tt-availability-summary-info-desktop__info\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPrice") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":8},"end":{"line":62,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"disableReservationsNotes","==",0,{"name":"checkConfig","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":8},"end":{"line":69,"column":24}}})) != null ? stack1 : "")
    + "							</div>\n						</div>\n\n				</div>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAdditionalDataDiv") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":4},"end":{"line":93,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"tt-container tt-container--15 tt-container--border tt-container--white clear "
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"summaryFromLStorage") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":97},"end":{"line":95,"column":148}}})) != null ? stack1 : "")
    + "\" id=\"summary_textarea\">\n            <button type=\"button\" class=\"tt-button-close\">\n                <span class=\"tt-icon-times tt-ml-8\"></span>\n                <span class=\"tt-sr-only\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Close",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":41},"end":{"line":98,"column":77}}})) != null ? stack1 : "")
    + "</span>\n            </button>\n            <div class=\"tt-input-container\">\n            	<textarea name=\"summary\" id=\"summary\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"summaryFromLStorage") || (depth0 != null ? lookupProperty(depth0,"summaryFromLStorage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"summaryFromLStorage","hash":{},"data":data,"loc":{"start":{"line":101,"column":51},"end":{"line":101,"column":74}}}) : helper)))
    + "</textarea>\n						</div>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"discountedActivityString") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":104,"column":8},"end":{"line":106,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"activityPriceInfo") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":8},"end":{"line":109,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPrice") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":8},"end":{"line":177,"column":15}}})) != null ? stack1 : "")
    + "    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPrice") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":178,"column":4},"end":{"line":188,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mopClientsWaitingList") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":190,"column":4},"end":{"line":207,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n    </div>\n    <!-- end appointment_block -->\n\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdminLoggedIn") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(48, data, 0),"inverse":container.program(57, data, 0),"data":data,"loc":{"start":{"line":213,"column":4},"end":{"line":306,"column":16}}})) != null ? stack1 : "")
    + "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotAdminLoggedIn") || (depth0 != null ? lookupProperty(depth0,"isNotAdminLoggedIn") : depth0)) != null ? helper : alias2),(options={"name":"isNotAdminLoggedIn","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":308,"column":4},"end":{"line":328,"column":27}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNotAdminLoggedIn")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n    <div class=\"tt-hide tt-panel tt-panel-default tt-mt-20\" id=\"custom_fields_container\">\n        <div class=\"tt-panel-heading\">\n            <h3 class=\"tt-panel-title tt-deprecated-h3\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Additional Info",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":332,"column":56},"end":{"line":332,"column":102}}})) != null ? stack1 : "")
    + "</h3>\n        </div>\n        <div class=\"tt-container tt-container--15\" id=\"custom_fields\">\n        </div>\n    </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdminLoggedIn") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(69, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":338,"column":4},"end":{"line":387,"column":12}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPortalInstancePrivacy") : depth0),{"name":"if","hash":{},"fn":container.program(71, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":389,"column":0},"end":{"line":400,"column":7}}})) != null ? stack1 : "")
    + "\n    <div class=\"tt-columns tt-column-flex\">\n        <div class=\"tt-column\">\n            <a class=\"back_to_availabilities tt-button tt-button--secondary\" href=\"#\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Back",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":404,"column":86},"end":{"line":404,"column":121}}})) != null ? stack1 : "")
    + "</a>\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showGoToAddPaymentMethod") : depth0),{"name":"if","hash":{},"fn":container.program(73, data, 0),"inverse":container.program(75, data, 0),"data":data,"loc":{"start":{"line":406,"column":8},"end":{"line":420,"column":23}}})) != null ? stack1 : "")
    + "\n    </div>\n</form>\n\n\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"discountMessage") : depth0),"&&",(depth0 != null ? lookupProperty(depth0,"showPayButton") : depth0),{"name":"ifCond","hash":{},"fn":container.program(83, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":425,"column":0},"end":{"line":431,"column":11}}})) != null ? stack1 : "");
},"useData":true});