import React from 'react';
import PropTypes from 'prop-types';
import * as style from "./style";
import { Label } from "ui/Label";

export const ReservationPrint = ({ children = <></>, note = null, status = "gray", text = null }) => {

  return (
    <div data-e2eid="reservation-print-page" css={style.base}>
      <div css={[style.header, style[status]]}>
        <Label size="medium" weight="bold">{text}</Label>
        <div style={{ marginLeft: "auto" }}>
          <Label size="medium" weight="bold">{note}</Label>
        </div>
      </div>
      <div css={style.content}>
        {children}
      </div>
    </div>
  );
};

ReservationPrint.propTypes = {
  children: PropTypes.node.isRequired,
  note: PropTypes.string,
  status: PropTypes.oneOf(["success", "warning", "gray"]),
  text: PropTypes.string
};