var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tt-alert tt-customer-alert tt-customer-alert-info\">\n  <div>\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"summaryAvailabilityAdditionalInfo") || (depth0 != null ? lookupProperty(depth0,"summaryAvailabilityAdditionalInfo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"summaryAvailabilityAdditionalInfo","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":43}}}) : helper))) != null ? stack1 : "")
    + "\n  </div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"tt-availability-summary-info-desktop tt-margin-top-20 tt-mb-10\">\n			<div class=\"tt-columns tt-column-flex is-gapless tt-no-margin-bottom\">\n				<div class=\"tt-column is-one-fifth\">\n					<div class=\"tt-availability-summary-info-desktop__date\">\n						<div class=\"tt-availability-summary-info-desktop__date-day\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"start_date_day_abbr_week") || (depth0 != null ? lookupProperty(depth0,"start_date_day_abbr_week") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start_date_day_abbr_week","hash":{},"data":data,"loc":{"start":{"line":15,"column":66},"end":{"line":15,"column":94}}}) : helper)))
    + "</div>\n						<div class=\"tt-availability-summary-info-desktop__date-number\">"
    + alias4((lookupProperty(helpers,"start_date_day")||(depth0 && lookupProperty(depth0,"start_date_day"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"start_date_timestamp") : depth0),{"name":"start_date_day","hash":{},"data":data,"loc":{"start":{"line":16,"column":69},"end":{"line":16,"column":108}}}))
    + "</div>\n						<div class=\"tt-availability-summary-info-desktop__date-month\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"start_date_month_abbr") || (depth0 != null ? lookupProperty(depth0,"start_date_month_abbr") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start_date_month_abbr","hash":{},"data":data,"loc":{"start":{"line":17,"column":68},"end":{"line":17,"column":93}}}) : helper)))
    + "</div>\n						<div class=\"tt-availability-summary-info-desktop__date-year\">"
    + ((stack1 = (lookupProperty(helpers,"humanDateToDateObjYear")||(depth0 && lookupProperty(depth0,"humanDateToDateObjYear"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"start_date") : depth0),{"name":"humanDateToDateObjYear","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":67},"end":{"line":18,"column":132}}})) != null ? stack1 : "")
    + "</div>\n						<div class=\"tt-availability-summary-info-desktop__date-hour\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"startTime") || (depth0 != null ? lookupProperty(depth0,"startTime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"startTime","hash":{},"data":data,"loc":{"start":{"line":19,"column":67},"end":{"line":19,"column":80}}}) : helper)))
    + "</div>\n					</div>\n				</div>\n\n				<div class=\"tt-column\">\n					<div class=\"tt-availability-summary-info-desktop__details\">\n						<div class=\"tt-availability-summary-info-desktop__details-title\">\n							"
    + alias4(((helper = (helper = lookupProperty(helpers,"typologyTitle") || (depth0 != null ? lookupProperty(depth0,"typologyTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typologyTitle","hash":{},"data":data,"loc":{"start":{"line":26,"column":7},"end":{"line":26,"column":24}}}) : helper)))
    + "\n						</div>\n						<div class=\"tt-availability-summary-info-desktop__details-text tt-customer-availability-text\">\n							"
    + alias4(((helper = (helper = lookupProperty(helpers,"activityTitle") || (depth0 != null ? lookupProperty(depth0,"activityTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityTitle","hash":{},"data":data,"loc":{"start":{"line":29,"column":7},"end":{"line":29,"column":24}}}) : helper)))
    + "\n						</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showResource") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":6},"end":{"line":36,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"showAreas") : depths[1]),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":6},"end":{"line":39,"column":13}}})) != null ? stack1 : "")
    + "							</div>\n						</div>\n\n						<div class=\"tt-column is-one-fifth\">\n							<div class=\"tt-availability-summary-info-desktop__info\">\n								"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"showPrice") : depths[1]),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":8},"end":{"line":47,"column":22}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"disableReservationsNotes","==",0,{"name":"checkConfig","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":8},"end":{"line":55,"column":24}}})) != null ? stack1 : "")
    + "							</div>\n              </div>\n              <div class=\"tt-container tt-container--15 "
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"summaryFromLStorage") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":56},"end":{"line":58,"column":107}}})) != null ? stack1 : "")
    + " summary_addons summary_textarea\" container-note-id=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"solutionid") : depths[1]), depth0))
    + alias4(((helper = (helper = lookupProperty(helpers,"activityid") || (depth0 != null ? lookupProperty(depth0,"activityid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityid","hash":{},"data":data,"loc":{"start":{"line":58,"column":177},"end":{"line":58,"column":191}}}) : helper)))
    + "\">\n                <button type=\"button\" class=\"tt-button-close\">\n                  <span aria-hidden=\"true\">&times;</span>\n                  <span class=\"tt-sr-only\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Close",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":43},"end":{"line":61,"column":79}}})) != null ? stack1 : "")
    + "</span>\n                </button>\n                <textarea name=\"summary\" class=\"form-control summary\" data-availability-id=\""
    + alias4(alias5((depths[1] != null ? lookupProperty(depths[1],"solutionid") : depths[1]), depth0))
    + alias4(((helper = (helper = lookupProperty(helpers,"activityid") || (depth0 != null ? lookupProperty(depth0,"activityid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityid","hash":{},"data":data,"loc":{"start":{"line":63,"column":109},"end":{"line":63,"column":123}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"summaryFromLStorage") || (depth0 != null ? lookupProperty(depth0,"summaryFromLStorage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"summaryFromLStorage","hash":{},"data":data,"loc":{"start":{"line":63,"column":125},"end":{"line":63,"column":148}}}) : helper)))
    + "</textarea>\n              </div>\n					</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":12},"end":{"line":70,"column":19}}})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"discountedActivityString") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":12},"end":{"line":72,"column":148}}})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"activityPriceInfo") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":12},"end":{"line":73,"column":134}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"mopClientsWaitingList") : depth0),"==","1",{"name":"ifCond","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":12},"end":{"line":88,"column":23}}})) != null ? stack1 : "")
    + "				</div>\n\n  <div class=\"tt-hide tt-panel tt-panel-default\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"multiple_tmp_id") || (depth0 != null ? lookupProperty(depth0,"multiple_tmp_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"multiple_tmp_id","hash":{},"data":data,"loc":{"start":{"line":91,"column":53},"end":{"line":91,"column":72}}}) : helper)))
    + "_custom_fields_container\">\n    <div class=\"tt-panel-heading\">\n      <h3 class=\"tt-deprecated-h3\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Additional Info",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":35},"end":{"line":93,"column":81}}})) != null ? stack1 : "")
    + "</h3>\n    </div>\n    <div class=\"tt-container tt-container--15\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"multiple_tmp_id") || (depth0 != null ? lookupProperty(depth0,"multiple_tmp_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"multiple_tmp_id","hash":{},"data":data,"loc":{"start":{"line":95,"column":51},"end":{"line":95,"column":70}}}) : helper)))
    + "_custom_fields\">\n    </div>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<div class=\"tt-availability-summary-info-desktop__details-title\">\n							"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"resourceName") || (depth0 != null ? lookupProperty(depth0,"resourceName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"resourceName","hash":{},"data":data,"loc":{"start":{"line":34,"column":7},"end":{"line":34,"column":23}}}) : helper)))
    + "\n						</div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<div class=\"tt-availability-summary-info-desktop__details-title\"><span class=\"tt-availability-summary-info-desktop__details-title__icon tt-icon-map-marker-alt\"></span>"
    + ((stack1 = (lookupProperty(helpers,"formattedAreaTitle")||(depth0 && lookupProperty(depth0,"formattedAreaTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"webTitle") : depth0),(depth0 != null ? lookupProperty(depth0,"areaTitle") : depth0),{"name":"formattedAreaTitle","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":174},"end":{"line":38,"column":239}}})) != null ? stack1 : "")
    + "</div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"activityPrice") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":28},"end":{"line":47,"column":15}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n								<div class=\"tt-availability-summary-info-desktop__info-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"currencySymbol") || (depth0 != null ? lookupProperty(depth0,"currencySymbol") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currencySymbol","hash":{},"data":data,"loc":{"start":{"line":46,"column":70},"end":{"line":46,"column":88}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"activityPrice") || (depth0 != null ? lookupProperty(depth0,"activityPrice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityPrice","hash":{},"data":data,"loc":{"start":{"line":46,"column":88},"end":{"line":46,"column":105}}}) : helper)))
    + "</div>\n								";
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <div class=\"tt-container\">\n                    <button class=\"tt-button tt-button--secondary summary-note-link\" data-display=\"summary_textarea\" note-id=\""
    + alias1(container.lambda((depths[1] != null ? lookupProperty(depths[1],"solutionid") : depths[1]), depth0))
    + alias1(((helper = (helper = lookupProperty(helpers,"activityid") || (depth0 != null ? lookupProperty(depth0,"activityid") : depth0)) != null ? helper : alias3),(typeof helper === "function" ? helper.call(alias2,{"name":"activityid","hash":{},"data":data,"loc":{"start":{"line":50,"column":143},"end":{"line":50,"column":157}}}) : helper)))
    + "\">\n                        <span class=\"tt-icon-pen tt-mr-4\"></span> "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias3).call(alias2,"MOP Note Button",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":66},"end":{"line":51,"column":112}}})) != null ? stack1 : "")
    + "\n                    </button>\n                  </div>\n								</div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "tt-hide";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"tt-container tt-container--15 tt-container--border summary_addons "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":92},"end":{"line":67,"column":134}}})) != null ? stack1 : "")
    + "\">\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":68,"column":16},"end":{"line":68,"column":33}}}) : helper))) != null ? stack1 : "")
    + "\n              </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "tt-bg-white";
},"20":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tt-container tt-container--15 summary_description\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"discountedActivityString") || (depth0 != null ? lookupProperty(depth0,"discountedActivityString") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"discountedActivityString","hash":{},"data":data,"loc":{"start":{"line":72,"column":107},"end":{"line":72,"column":135}}}) : helper)))
    + "</div>";
},"22":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tt-container tt-container--15 summary_description\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"activityPriceInfo") || (depth0 != null ? lookupProperty(depth0,"activityPriceInfo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"activityPriceInfo","hash":{},"data":data,"loc":{"start":{"line":73,"column":100},"end":{"line":73,"column":121}}}) : helper)))
    + "</div>";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"tt-container tt-container--15 tt-container--white summary_description\">\n                <div class=\"tt-input-checkbox-container tt-deprecated-input-checkbox-container\">\n                  <li class=\"tt-checkbox\">\n                    <label class=\"tt-checkbox__label\">\n                      <input class=\"tt-checkbox__label-input\" type=\"checkbox\" name=\"waiting_list\" id=\"waiting_list\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"machid") || (depth0 != null ? lookupProperty(depth0,"machid") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"machid","hash":{},"data":data,"loc":{"start":{"line":80,"column":123},"end":{"line":80,"column":133}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"waitingListFromLStorage") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":135},"end":{"line":80,"column":180}}})) != null ? stack1 : "")
    + " />\n                    </label>\n                      <div class=\"tt-checkbox__text\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"MOP Waiting List",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":24},"end":{"line":83,"column":71}}})) != null ? stack1 : "")
    + "\n                      </div>\n                    </li>\n                  </div>\n              </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "checked";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div style=\"display:;\">\n        <div class=\"select-user-methods\">\n          <div>\n            <div class=\"tt-columns is-vcentered tt-flex\">\n              <div class=\"tt-column\">\n                <h3 class=\"tt-deprecated-h3\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"MOP Book For",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":45},"end":{"line":106,"column":88}}})) != null ? stack1 : "")
    + "</h3>\n              </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"recentResUser") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":14},"end":{"line":114,"column":23}}})) != null ? stack1 : "")
    + "              <div class=\"text-right tt-column is-narrow\">\n                <a href=\"javascript:\" class=\"tt-button tt-button--add-member register_patient tt-button--primary\" data-trigger=\"addPatient:click\">\n                    <i class=\"tt-button__icon tt-icon-user-plus\"></i>\n                    <span class=\"tt-button__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Register User",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":118,"column":50},"end":{"line":118,"column":94}}})) != null ? stack1 : "")
    + "</span>\n                </a>\n                <a href=\"javascript:\" class=\"tt-button tt-button--operator change-patient tt-button--secondary tt-hide\" data-trigger=\"changePatient:click\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Change Client",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":20},"end":{"line":121,"column":64}}})) != null ? stack1 : "")
    + "\n                </a>\n              </div>\n            </div>\n          </div>\n            <div class=\"tt-container tt-container--15\"> <!-- deve scomparire insieme alla input -->\n            <div class=\"tt-input-container\">\n                <input class=\"form-control tt-input\" id=\"search_user_div\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Search Client Mop",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":128,"column":87},"end":{"line":128,"column":135}}})) != null ? stack1 : "")
    + "\">\n            </div>\n                <div class=\"tt-hide typehead-loading\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Searching",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":130,"column":54},"end":{"line":130,"column":94}}})) != null ? stack1 : "")
    + " ...</div>\n            </div>\n            <div id=\"patient_data_container\" class=\"list-group\"></div>\n            <div class=\"tt-hide\">\n                <button type=\"button\" class=\"tt-button tt-button--secondary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Waiting List",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":134,"column":77},"end":{"line":134,"column":120}}})) != null ? stack1 : "")
    + "</button>\n                <button type=\"button\" class=\"tt-button tt-button--primary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Most Recent Patient",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":135,"column":75},"end":{"line":135,"column":125}}})) != null ? stack1 : "")
    + "</button>\n            </div>\n        </div>\n\n    <div class=\"tt-hide tt-mt-20\">\n      <div>\n        <h3 class=\"tt-deprecated-h3\">\n          <button type=\"button\" class=\"close\">\n            <span aria-hidden=\"true\">&times;</span>\n            <span class=\"tt-sr-only\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Close",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":37},"end":{"line":144,"column":73}}})) != null ? stack1 : "")
    + "</span>\n          </button>\n        </h3>\n      </div>\n      <div class=\"list-group choose_client\">\n        <a href=\"#\" class=\"list-group-item\">Mario Rossi. +39335238229 - m.rossi@mail.it</a>\n        <a href=\"#\" class=\"list-group-item\">Paolo Bianco, +32738233827376 - p.bianco@hotmail.com</a>\n        <a href=\"#\" class=\"list-group-item\">Marina Burli, +32362883876 - m.burli@yahoo.it</a>\n      </div>\n    </div>\n\n  </div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"tt-column is-narrow\">\n                    <a href=\"javascript:\" class=\"tt-button tt-button--operator tt-button--primary pull-right\" id=\"recent-user-btn\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Most Recent Patient",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":24},"end":{"line":111,"column":74}}})) != null ? stack1 : "")
    + "\n                    </a>\n                    </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n  <div class=\"tt-panel tt-panel-default\">\n    <div class=\"tt-panel-heading\">\n      <div class=\"tt-columns tt-flex\">\n        <div class=\"tt-column\">\n          <h3 class=\"tt-deprecated-h3\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"MOP Book For",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":162,"column":39},"end":{"line":162,"column":82}}})) != null ? stack1 : "")
    + "\n            <!-- se operatore: Scegli Cliente + tt-button Aggiungi -->\n          </h3>\n        </div>\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowFamilies") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":166,"column":8},"end":{"line":173,"column":37}}})) != null ? stack1 : "")
    + "\n      </div>\n    </div>\n\n        <div class=\"tt-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"reservationUsers") : depth0),{"name":"each","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":178,"column":10},"end":{"line":191,"column":19}}})) != null ? stack1 : "")
    + "\n    </div>\n    </div>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotOneTimeUser") || (depth0 != null ? lookupProperty(depth0,"isNotOneTimeUser") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isNotOneTimeUser","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":166,"column":30},"end":{"line":173,"column":29}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isNotOneTimeUser")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + " ";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <div class=\"tt-column is-narrow\">\n          <a href=\"javascript:\" class=\"tt-button tt-button--add-member tt-button--primary\" data-trigger=\"addParent:click\">\n            <i class=\"tt-button__icon tt-icon-user-plus\"></i>\n            <span class=\"tt-button__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Add Relatives",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":170,"column":42},"end":{"line":170,"column":86}}})) != null ? stack1 : "")
    + "</span>\n          </a>\n        </div>\n        ";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"tt-input-radio-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":179,"column":47},"end":{"line":179,"column":81}}})) != null ? stack1 : "")
    + "\" data-trigger=\"user:selectByPatient\" data-trigger-param=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n\n              <label class=\"tt-input-radio js-no-required-validation\">\n                <input class=\"tt-input-radio__input\" name=\"group\" type=\"radio\" value=\"\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":182,"column":88},"end":{"line":182,"column":133}}})) != null ? stack1 : "")
    + ">\n                  <div class=\"tt-input-radio__content\">\n                    <div class=\"tt-input-radio__content-text\">\n                      "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n                    </div>\n                    <i class=\"tt-input-radio__content-icon tt-icon-check\"></i>\n                  </div>\n              </label>\n          </div>\n";
},"35":function(container,depth0,helpers,partials,data) {
    return "active";
},"37":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showContactFieldForm") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":198,"column":2},"end":{"line":216,"column":9}}})) != null ? stack1 : "");
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"js-summary-communication-container\">\n    <div>\n        <h3 class=\"tt-deprecated-h3\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Resume Specitfy Contact",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":201,"column":37},"end":{"line":201,"column":95}}})) != null ? stack1 : "")
    + "</h3>\n    </div>\n    <div class=\"tt-container tt-container--15\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationPhone") : depth0),"!=",0,{"name":"ifCond","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":204,"column":6},"end":{"line":208,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationEmail") : depth0),"!=",0,{"name":"ifCond","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":209,"column":6},"end":{"line":213,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"tt-input-container "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationPhone") : depth0),"==",2,{"name":"ifCond","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":205,"column":37},"end":{"line":205,"column":113}}})) != null ? stack1 : "")
    + "\">\n        <input class=\"tt-input js-summary-communication-phone\" name=\"communication_phone\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Specify Phone Placeholder",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":206,"column":103},"end":{"line":206,"column":163}}})) != null ? stack1 : "")
    + "\" value="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"phone") || (depth0 != null ? lookupProperty(depth0,"phone") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"phone","hash":{},"data":data,"loc":{"start":{"line":206,"column":171},"end":{"line":206,"column":180}}}) : helper)))
    + ">\n      </div>\n";
},"42":function(container,depth0,helpers,partials,data) {
    return "tt-input-container--required";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"tt-input-container "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationEmail") : depth0),"==",2,{"name":"ifCond","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":210,"column":37},"end":{"line":210,"column":113}}})) != null ? stack1 : "")
    + "\">\n        <input class=\"tt-input js-summary-communication-email\" name=\"communication_email\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Specify Email Placeholder",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":211,"column":103},"end":{"line":211,"column":163}}})) != null ? stack1 : "")
    + "\" value="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":211,"column":171},"end":{"line":211,"column":180}}}) : helper)))
    + ">\n      </div>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "   <div class=\"tt-input-checkbox-container tt-deprecated-input-checkbox-container\">\n    <li class=\"tt-checkbox\">\n      <label class=\"tt-checkbox__label\">\n        <input class=\"tt-checkbox__label-input\" type=\"checkbox\" id=\"reservation_privacy\" autocomplete=\"off\" name=\"privacy\"  />\n      </label>\n        <div class=\"tt-checkbox__text\" id=\"privacy_url\">\n          "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Privacy Member","conf:title",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":228,"column":10},"end":{"line":228,"column":68}}})) != null ? stack1 : "")
    + "\n        </div>\n      </li>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"summaryAvailabilityAdditionalInfo") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":7,"column":7}}})) != null ? stack1 : "")
    + "\n<form>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"availabilities") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":2},"end":{"line":98,"column":11}}})) != null ? stack1 : "")
    + "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isAdminLoggedIn") || (depth0 != null ? lookupProperty(depth0,"isAdminLoggedIn") : depth0)) != null ? helper : alias2),(options={"name":"isAdminLoggedIn","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.program(30, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":100,"column":0},"end":{"line":195,"column":22}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isAdminLoggedIn")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotAdminLoggedIn") || (depth0 != null ? lookupProperty(depth0,"isNotAdminLoggedIn") : depth0)) != null ? helper : alias2),(options={"name":"isNotAdminLoggedIn","hash":{},"fn":container.program(39, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":197,"column":2},"end":{"line":217,"column":25}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNotAdminLoggedIn")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n  <!-- Tolta gestione prescription -->\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPortalInstancePrivacy") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":221,"column":2},"end":{"line":232,"column":9}}})) != null ? stack1 : "")
    + "\n  <div class=\"tt-columns tt-flex\">\n    <div class=\"tt-column\">\n      <button type=\"button\" class=\"js-book-btn tt-button submit tt-customer-book tt-button--primary ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isAdminLoggedIn") || (depth0 != null ? lookupProperty(depth0,"isAdminLoggedIn") : depth0)) != null ? helper : alias2),(options={"name":"isAdminLoggedIn","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":236,"column":100},"end":{"line":236,"column":147}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isAdminLoggedIn")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\" data-submit-origin=\"0\">\n        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Book Button",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":237,"column":8},"end":{"line":237,"column":54}}})) != null ? stack1 : "")
    + "\n      </button>\n    </div>\n    <div class=\"tt-column\">\n      <a class=\"back_to_availabilities tt-button tt-button--secondary\" href=\"#\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Back",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":241,"column":80},"end":{"line":241,"column":115}}})) != null ? stack1 : "")
    + "</a>\n    </div>\n  </div>\n</form>\n";
},"useData":true,"useDepths":true});