var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"tt-mb-10\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Results Share Email",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":34},"end":{"line":4,"column":84}}})) != null ? stack1 : "")
    + "</div>\n            <div class=\"modal-body\">\n\n                <form id=\"share_form\">\n                    <strong>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Results Share Address",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":80}}})) != null ? stack1 : "")
    + "</strong>:<br>\n                    <div class=\"tt-input-container\">\n                        <input type=\"text\" id=\"share-contact\" name=\"address\" class=\"tt-input\"\n                    data-validation-error=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"A Valid Email is Required",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":43},"end":{"line":11,"column":99}}})) != null ? stack1 : "")
    + "\" required/>\n                    </div>\n                    <br>\n                    <strong>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Results Share Body",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":28},"end":{"line":14,"column":77}}})) != null ? stack1 : "")
    + "</strong>:<br>\n                    <div class=\"tt-input-container\">\n                        <textarea style=\"resize: vertical\" type=\"text\" name=\"body\" id=\"share-body\" class=\"tt-input\"  data-validation-error=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Share Body Required",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":140},"end":{"line":16,"column":190}}})) != null ? stack1 : "")
    + "\" required></textarea>\n                    </div>\n                    <div class=\"downloads_note\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Results Share Privacy",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":48},"end":{"line":18,"column":100}}})) != null ? stack1 : "")
    + "</div>\n                </form>\n            </div>\n            <div class=\"modal-footer tt-flexend-container tt-dialog-button\">\n                \n                    <button type=\"button\" id=\"share-download-file\" class=\"tt-button tt-button--primary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Results Share Send",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":104},"end":{"line":23,"column":153}}})) != null ? stack1 : "")
    + "</button>\n                \n                \n                    <a class=\"tt-button tt-button--secondary dismiss\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Clear",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":70},"end":{"line":26,"column":106}}})) != null ? stack1 : "")
    + "</a>\n                \n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});