var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"timezonedSendingtime") || (depth0 != null ? lookupProperty(depth0,"timezonedSendingtime") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"timezonedSendingtime","hash":{},"data":data,"loc":{"start":{"line":2,"column":91},"end":{"line":2,"column":115}}}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"sendingtime") || (depth0 != null ? lookupProperty(depth0,"sendingtime") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"sendingtime","hash":{},"data":data,"loc":{"start":{"line":2,"column":123},"end":{"line":2,"column":138}}}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    return "<span class=\"tt-icon-comments-alt\"></span>";
},"9":function(container,depth0,helpers,partials,data) {
    return "<span class=\"tt-icon-envelope\"></span>";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a href=\"javascript:\"\n           data-entity-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"reservationid") || (depth0 != null ? lookupProperty(depth0,"reservationid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reservationid","hash":{},"data":data,"loc":{"start":{"line":11,"column":27},"end":{"line":11,"column":44}}}) : helper)))
    + "\"\n           data-entity-waiting-reservationid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"waiting_reservationid") || (depth0 != null ? lookupProperty(depth0,"waiting_reservationid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"waiting_reservationid","hash":{},"data":data,"loc":{"start":{"line":12,"column":46},"end":{"line":12,"column":71}}}) : helper)))
    + "\"\n           data-entity-replace-reservation-text=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"replace_reservation_text") || (depth0 != null ? lookupProperty(depth0,"replace_reservation_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"replace_reservation_text","hash":{},"data":data,"loc":{"start":{"line":13,"column":49},"end":{"line":13,"column":77}}}) : helper)))
    + "\" class=\"replace-reservation\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Wait Swap Reservation Anchor",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":107},"end":{"line":13,"column":166}}})) != null ? stack1 : "")
    + "</a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"reservationid") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":5},"end":{"line":17,"column":12}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	        <a href=\"javascript:\" data-entity-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"reservationid") || (depth0 != null ? lookupProperty(depth0,"reservationid") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"reservationid","hash":{},"data":data,"loc":{"start":{"line":16,"column":47},"end":{"line":16,"column":64}}}) : helper)))
    + "\" class=\"notification-resid\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Reservation",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":93},"end":{"line":16,"column":135}}})) != null ? stack1 : "")
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td data-align=\"center\" data-title=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Date",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":36},"end":{"line":1,"column":71}}})) != null ? stack1 : "")
    + "\"\n    class=\"appointment_block appointment_date\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"sendingdate") || (depth0 != null ? lookupProperty(depth0,"sendingdate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sendingdate","hash":{},"data":data,"loc":{"start":{"line":2,"column":47},"end":{"line":2,"column":62}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"timezonedSendingtime") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":2,"column":63},"end":{"line":2,"column":145}}})) != null ? stack1 : "")
    + "</td>\n<td data-align=\"center\" data-title=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Dashboard Message Type",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":36},"end":{"line":3,"column":89}}})) != null ? stack1 : "")
    + "\" class=\"message_channel\">\n    "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"channel") : depth0),"==","push",{"name":"ifCond","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":134}}})) != null ? stack1 : "")
    + "\n    "
    + alias4(((helper = (helper = lookupProperty(helpers,"channel") || (depth0 != null ? lookupProperty(depth0,"channel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"channel","hash":{},"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":15}}}) : helper)))
    + "\n</td>\n<td data-align=\"left\" data-title=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Text",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":34},"end":{"line":7,"column":69}}})) != null ? stack1 : "")
    + "\" class=\"message_text\" >"
    + alias4(((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":7,"column":93},"end":{"line":7,"column":101}}}) : helper)))
    + "</td>\n<td data-align=\"left\" data-title=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Appointments",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":34},"end":{"line":8,"column":81}}})) != null ? stack1 : "")
    + "\" >\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"waiting_reservationid") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":18,"column":8}}})) != null ? stack1 : "")
    + "</td>";
},"useData":true});