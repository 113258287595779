import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style.js";
import { useTheme } from '@emotion/react';
import { Button, Container, Divider, Label } from '../../../../../index';

import { useMediaQuery } from "react-responsive";

import { TTPolyglot } from "@frontend/tt-polyglot";

export const Slot = (
  {
    title = "", 
    subtitle = "", 
    coloredHeader = false,
    onClick = () => {},
    price = "", 
    slotCount = 0, 
    time = ""
  }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.layout.mobileView})`,
  });

  return ( 
    <Container e2eid={coloredHeader ? "comparison-compared-slot" : "comparison-slot"} width="100%" shadow>
      
      <div css={[style.baseContainer(theme), style.headerContainer(coloredHeader, theme)]}>
        {isMobile ? 
          <>
            <Label size="large" weight="semiBold">{title}</Label>
            <Label>{subtitle}</Label>
          </> : 
          <Label size="large" weight="semiBold">{`${title} ${subtitle}`}</Label>}
      </div>
      
      <div css={style.headerDividerContainer(theme)}> 
        <Divider/>
      </div>  

      <div css={[style.baseContainer(theme), style.rowBaseContainer]}>
        <div css={[style.rowBase, style.rowLabel]}>
          <Label>{TTPolyglot.t('Mop Compare Insurance Slot From Time')}</Label>
        </div> 
        <div css={[style.rowBase, style.rowValue]}>
          <Label weight="semiBold">{time}</Label>
        </div> 
      </div>
      
      <div css={style.dividerContainer(theme)}> 
        <Divider/>
      </div>  

      {price && 
      <>
        <div data-e2eid={coloredHeader ? "comparison-compared-price" : "comparison-price"} css={[style.baseContainer(theme), style.rowBaseContainer]}>
          <div css={[style.rowBase, style.rowLabel]}>
            <Label>{TTPolyglot.t('Mop Compare Insurance Slot From Price')}</Label>
          </div> 
          <div css={[style.rowBase, style.rowValue]}>
            <Label weight="semiBold">{price}</Label>
          </div> 
        </div>
        <div css={style.dividerContainer(theme)}>
          <Divider/>
        </div>
      </>}

      <div css={[style.baseContainer(theme), style.rowBaseContainer]}>
        <div css={style.rowBase}>            
          <Label weight="semiBold">{slotCount.toString()}</Label>  
              &nbsp;           
          <Label>{TTPolyglot.t('MOP Compare Insurance Availability Number')}</Label>
        </div> 
      </div>

      <div css={[style.baseContainer(theme)]}>
        <Button e2eid={coloredHeader ? "comparison-compared-slot-select" : "comparison-slot-select"} type="light" width="100%" size="small" onClick={onClick}>
          {TTPolyglot.t('Select')} 
        </Button>  
      </div>     

    </Container>
  );
};

Slot.propTypes = {
  title: PropTypes.string, 
  subtitle: PropTypes.string, 
  coloredHeader: PropTypes.bool, 
  onClick: PropTypes.func,
  price: PropTypes.string, 
  slotCount: PropTypes.number, 
  time: PropTypes.string
};