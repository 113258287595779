var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"tt-mb-20\">\n                <span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Resend Vcode Registration Number Wrong",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":22},"end":{"line":18,"column":91}}})) != null ? stack1 : "")
    + "</span>\n                <a href=\"javascript:\" id=\"wrong_address\" class=\"link small_text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Click Here To Edit",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":81},"end":{"line":19,"column":130}}})) != null ? stack1 : "")
    + "</a>\n              </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n  <div class=\"modal-dialog tt-dialog-w-small\">\n    <div class=\"modal-content\">\n      <div class=\"tt-24-v-padding tt-no-border\">\n        <div class=\"js-close-dialog tt-modal__close\">\n          <span><i class=\"tt-icon-times\"></i></span>\n        </div>\n      </div>\n      <div class=\"modal-body tt-pt-0 tt-no-padding-b\">\n\n        <div class=\"tt-card-info\">\n          <div class=\"tt-card-info__icon tt-icon-thumbs-up tt-mb-10\"></div>\n          <div class=\"tt-card-info__text\">\n            <div>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Resend Vcode Registration Number",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":17},"end":{"line":14,"column":80}}})) != null ? stack1 : "")
    + "</div>\n            <div class=\"tt-text-size-22 tt-margin-20\"><b>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"receiver") || (depth0 != null ? lookupProperty(depth0,"receiver") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"receiver","hash":{},"data":data,"loc":{"start":{"line":15,"column":57},"end":{"line":15,"column":69}}}) : helper)))
    + "</b></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showWrongAddressOption") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":21,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});