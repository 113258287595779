var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td data-align=\"center\" data-title=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Typology",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":36},"end":{"line":1,"column":75}}})) != null ? stack1 : "")
    + "\" >"
    + alias4(((helper = (helper = lookupProperty(helpers,"typologyTitle") || (depth0 != null ? lookupProperty(depth0,"typologyTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typologyTitle","hash":{},"data":data,"loc":{"start":{"line":1,"column":78},"end":{"line":1,"column":95}}}) : helper)))
    + "</td>\n<td data-align=\"center\" data-title=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Concept",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":36},"end":{"line":2,"column":74}}})) != null ? stack1 : "")
    + "\" >"
    + alias4(((helper = (helper = lookupProperty(helpers,"activityTitle") || (depth0 != null ? lookupProperty(depth0,"activityTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityTitle","hash":{},"data":data,"loc":{"start":{"line":2,"column":77},"end":{"line":2,"column":94}}}) : helper)))
    + "</td>\n<td data-align=\"center\" data-title=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Price",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":36},"end":{"line":3,"column":72}}})) != null ? stack1 : "")
    + "\" >"
    + alias4(((helper = (helper = lookupProperty(helpers,"price") || (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"price","hash":{},"data":data,"loc":{"start":{"line":3,"column":75},"end":{"line":3,"column":84}}}) : helper)))
    + "</td>\n<td data-align=\"center\" data-title=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Waiting time",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":36},"end":{"line":4,"column":79}}})) != null ? stack1 : "")
    + "\" >"
    + alias4(((helper = (helper = lookupProperty(helpers,"waiting_time_in_days") || (depth0 != null ? lookupProperty(depth0,"waiting_time_in_days") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"waiting_time_in_days","hash":{},"data":data,"loc":{"start":{"line":4,"column":82},"end":{"line":4,"column":106}}}) : helper)))
    + "</td>\n<td data-align=\"center\" data-title=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Area Mop Title",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":36},"end":{"line":5,"column":81}}})) != null ? stack1 : "")
    + "\" >"
    + alias4(((helper = (helper = lookupProperty(helpers,"first_availability_areaTitle") || (depth0 != null ? lookupProperty(depth0,"first_availability_areaTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"first_availability_areaTitle","hash":{},"data":data,"loc":{"start":{"line":5,"column":84},"end":{"line":5,"column":116}}}) : helper)))
    + "</td>\n";
},"useData":true});