import React from 'react';
import PropTypes from 'prop-types';
import { TTPolyglot } from "@frontend/tt-polyglot";
import * as style from "./style";

import { Label } from "ui/Label";
import { Banner } from "ui/Banner";
import { Button } from "ui/Button";

export const OneTimeUser = ({ onClick = () => null }) => {

  return (
    <div css={style.base}>
      <Banner type="warning">
        <div css={style.content}>
          <Label size="medium" dangerouslySetInnerHTML={TTPolyglot.t("Onetime Kill Session Description")} />
          <div css={style.buttonContainer}>
            <Button onClick={() => onClick()}>
              {TTPolyglot.t("Onetime Kill Session")}
            </Button>
          </div>
        </div>
      </Banner>
    </div>
  );
};

OneTimeUser.propTypes = {
  onClick: PropTypes.func,
};