import { useTheme } from '@emotion/react';
import React, { useRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";

import { ButtonIcon } from "ui/ButtonIcon";
import { Label } from "ui/Label";
import { Action } from 'ui/Action';

import { useMediaQuery } from 'react-responsive';

export const Map = (
  {
    address = null,
    addressOnClick = null,
    apiKey = null,
    areaName = null,
    disablePointerEvents = false,
    lat = null,
    lng = null,
    mapOnClick = null,
    phone = null,
    showMap = false
  }) => {
  const theme = useTheme();
  const ref = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);



  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.layout.mobileView})`,
  });

  useLayoutEffect(() => {
    if (ref.current && containerWidth === 0) {
      setContainerWidth(ref.current.offsetWidth);
    }
  }, [ref.current]);

  return (
    <div css={style.base(theme)(disablePointerEvents)}>
      <Action
        arrow={addressOnClick && isMobile}
        onClick={addressOnClick ? () => addressOnClick() : null}
        ref={ref}>
        <div css={style.labelsContainer(theme)}>
          <Label color="text" size="medium" weight="medium">{areaName}</Label>
          {
            address &&
            <Label color="text_light" size="medium">{address}</Label>
          }
          {
            phone &&
            <Label color="text_light" size="medium">{phone}</Label>
          }
        </div>
      </Action>
      {
        (containerWidth > 0 &&
          showMap &&
          apiKey) &&
        <div css={style.mapContainer(theme)(containerWidth)}>
          <div css={style.map(theme)(`https://maps.googleapis.com/maps/api/staticmap?key=${apiKey}&center=${lat},${lng}&zoom=15&size=${containerWidth}x250&markers=${lat},${lng}`)} />
          <div css={style.iconContainer}>
            <ButtonIcon color="tufts_blue" onClick={mapOnClick ? () => mapOnClick() : null} icon="LocationArrowSolid" iconColor="white" iconSize="large" />
          </div>
        </div>
      }
    </div>
  );
};

Map.propTypes = {
  address: PropTypes.string,
  addressOnClick: PropTypes.func,
  apiKey: PropTypes.string.isRequired,
  areaName: PropTypes.string,
  disablePointerEvents: PropTypes.bool,
  lat: PropTypes.number,
  lng: PropTypes.number,
  mapOnClick: PropTypes.func,
  phone: PropTypes.string,
  showMap: PropTypes.bool,
};