var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"selectedAvl") : depth0)) != null ? lookupProperty(stack1,"text") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":6,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-container tt-container--mobile\">\n          <div class=\"tt-info tt-info--reminder\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"selectedAvl") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "</div>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showLoginIntro") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":12},"end":{"line":99,"column":19}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Use Default Account",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":38},"end":{"line":98,"column":88}}})) != null ? stack1 : "")
    + "</div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"fromRegister") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":12},"end":{"line":105,"column":24}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <!-- <div class=\"row\">\n                        <div class=\"col-lg-12\" align=\"center\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Login Intro",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":62},"end":{"line":103,"column":104}}})) != null ? stack1 : "")
    + "</div>\n                    </div> -->\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <h2 class=\"h5 tt-deprecated-h2\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Mop Login Deferred",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":48},"end":{"line":121,"column":97}}})) != null ? stack1 : "")
    + "</h2>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <h2 class=\"h5 tt-deprecated-h2\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"panelTitle") || (depth0 != null ? lookupProperty(depth0,"panelTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"panelTitle","hash":{},"data":data,"loc":{"start":{"line":123,"column":48},"end":{"line":123,"column":62}}}) : helper)))
    + "</h2>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"samlButtons") : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":129,"column":4},"end":{"line":133,"column":13}}})) != null ? stack1 : "")
    + "      <div class=\"tt-container\">\n        <div class=\"tt-separator-login\">\n            <hr class=\"tt-mr\">\n            <span class=\"tt-separator-login__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"Mop Login Or",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":51},"end":{"line":137,"column":94}}})) != null ? stack1 : "")
    + "</span>\n            <hr class=\"tt-ml\">\n        </div>\n      </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"tt-container tt-container--15\">\n        <a class=\"tt-js-loginWithSaml tt-avoid-inside-events tt-button tt-button--primary tt-button-login\" saml-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" role=\"button\" style=\"background-color: #"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"button") : depth0)) != null ? lookupProperty(stack1,"color") : stack1), depth0))
    + ";\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"button") : depth0)) != null ? lookupProperty(stack1,"text") : stack1),{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":193},"end":{"line":131,"column":238}}})) != null ? stack1 : "")
    + "</a>\n      </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Or",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":133},"end":{"line":148,"column":166}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Idnumber",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":167},"end":{"line":148,"column":206}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"parentsPhone") || (depth0 != null ? lookupProperty(depth0,"parentsPhone") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"parentsPhone","hash":{},"data":data,"loc":{"start":{"line":149,"column":55},"end":{"line":149,"column":71}}}) : helper)));
},"23":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"parentsEmail") || (depth0 != null ? lookupProperty(depth0,"parentsEmail") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"parentsEmail","hash":{},"data":data,"loc":{"start":{"line":149,"column":98},"end":{"line":149,"column":114}}}) : helper)));
},"25":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"fromRegister") || (depth0 != null ? lookupProperty(depth0,"fromRegister") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fromRegister","hash":{},"data":data,"loc":{"start":{"line":149,"column":141},"end":{"line":149,"column":157}}}) : helper)));
},"27":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"fromForgot") || (depth0 != null ? lookupProperty(depth0,"fromForgot") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fromForgot","hash":{},"data":data,"loc":{"start":{"line":149,"column":182},"end":{"line":149,"column":196}}}) : helper)));
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"isStringNotEmpty")||(depth0 && lookupProperty(depth0,"isStringNotEmpty"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"legacyAuthenticationPlaceholder") : depth0),{"name":"isStringNotEmpty","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":20},"end":{"line":161,"column":41}}})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "											<div class=\"tt-input-container tt-input-login\">\n												<input \n													class=\"tt-input\" \n													name=\"legacy_authentication_factor\" \n													type=\"text\" \n													placeholder=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"legacyAuthenticationPlaceholder") || (depth0 != null ? lookupProperty(depth0,"legacyAuthenticationPlaceholder") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"legacyAuthenticationPlaceholder","hash":{},"data":data,"loc":{"start":{"line":159,"column":26},"end":{"line":159,"column":61}}}) : helper)))
    + "\" />\n											</div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Or",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":110},"end":{"line":171,"column":143}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Ticket",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":144},"end":{"line":171,"column":181}}})) != null ? stack1 : "");
},"34":function(container,depth0,helpers,partials,data) {
    return "tt-disabled";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"loginWithSaml") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":188,"column":4},"end":{"line":203,"column":20}}})) != null ? stack1 : "");
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"allowGuestRegistration") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":189,"column":8},"end":{"line":202,"column":15}}})) != null ? stack1 : "");
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"tt-pt-0\">\n              <div class=\"tt-container\">\n                  <div class=\"tt-separator-login\">\n                      <hr class=\"tt-mr\">\n                      <span class=\"tt-separator-login__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Login Or",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":194,"column":61},"end":{"line":194,"column":104}}})) != null ? stack1 : "")
    + "</span>\n                      <hr class=\"tt-ml\">\n                  </div>\n              </div>\n              <div class=\"tt-container tt-container--15 tt-pt-0\">\n                <a data-e2eid=\"goto-registration\" class=\"js-GoToPage tt-avoid-inside-events tt-button tt-button--secondary tt-button-login "
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"notify","==",0,{"name":"checkConfig","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":199,"column":139},"end":{"line":199,"column":198}}})) != null ? stack1 : "")
    + "\" href=\"#\" role=\"button\" data-target-page=\"profile\" data-target-hash=\"register\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Register",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":199,"column":278},"end":{"line":199,"column":317}}})) != null ? stack1 : "")
    + "</a>\n            </div>\n			</div>\n";
},"40":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "      <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(41, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":208,"column":18},"end":{"line":208,"column":59}}})) != null ? stack1 : "");
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(43, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":208,"column":59},"end":{"line":208,"column":114}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n        <div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(45, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":209,"column":13},"end":{"line":209,"column":78}}})) != null ? stack1 : "")
    + ">\n            \n          <a class=\"tt-js-loginWithSpid tt-button tt-button--primary tt-button-login\" role=\"button\">\n            <img src=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"getImgPath") || (depth0 != null ? lookupProperty(depth0,"getImgPath") : depth0)) != null ? helper : alias2),(options={"name":"getImgPath","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":212,"column":22},"end":{"line":212,"column":52}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"getImgPath")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "/spid/spid-ico-circle-bb.svg\" style=\"width: 20px; margin-right: 5px\"/>\n            "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"MOP Login With Spid",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":213,"column":12},"end":{"line":213,"column":62}}})) != null ? stack1 : "")
    + "\n          </a>\n\n          <div class=\"tt-column tt-spid-buttons-container\" style=\"display: none\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"spidIdpAvailable") : depth0),{"name":"each","hash":{},"fn":container.program(47, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":217,"column":12},"end":{"line":221,"column":21}}})) != null ? stack1 : "")
    + "          </div>\n\n          <div class=\"tt-spid-logo-container\" id=\"spid-agid-logo-lb\">\n            <img src=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"getImgPath") || (depth0 != null ? lookupProperty(depth0,"getImgPath") : depth0)) != null ? helper : alias2),(options={"name":"getImgPath","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":225,"column":22},"end":{"line":225,"column":52}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"getImgPath")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "/spid/spid-agid-logo-lb.png\" alt=\"spid-agid-logo-lb\" />\n          </div>\n\n        </div>\n      </div>\n";
},"41":function(container,depth0,helpers,partials,data) {
    return "tt-columns";
},"43":function(container,depth0,helpers,partials,data) {
    return "tt-container tt-container--15";
},"45":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-column is-8 is-offset-2\"";
},"47":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-js-spid-button tt-spid-button\" id=\""
    + alias2(alias1(depth0, depth0))
    + "\">\n                  <img src=\""
    + alias2(lookupProperty(helpers,"lookup").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"spidIdpLogos") : depths[1]),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":219,"column":28},"end":{"line":219,"column":59}}}))
    + "\" alt=\""
    + alias2(alias1(depth0, depth0))
    + "\" />\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selectedAvl") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "\n    <!-- primo tentativo -->\n        <!-- Not waiting -->\n        <div id=\"forgot-first-attempt-registration\" class=\"tt-hide\">\n            <div class=\"tt-alert tt-customer-alert\">\n                <div>\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Registration Completed",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":69}}})) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </div>\n\n        <div id=\"forgot-first-attempt\" class=\"tt-hide tt-alert tt-customer-alert\">\n            <div>\n                <div id=\"translate1\"></div>\n                <a href='#' class=\"showable\" data-showable=\"passNotReceivedQuest\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Password Not Received Question",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":20},"end":{"line":23,"column":81}}})) != null ? stack1 : "")
    + "\n                </a>\n            </div>\n            <div class=\"tt-hide\" id=\"passNotReceivedQuest\">\n                <div><a class=\"new-forgot-trigger tt-inline-block tt-no-margin\" href=\"#\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Ask for New Password",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":89},"end":{"line":27,"column":140}}})) != null ? stack1 : "")
    + " </a>\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Password Not Received 01",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":28,"column":71}}})) != null ? stack1 : "")
    + "</div>\n                <div>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Signup Wrong Contact Alert",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":21},"end":{"line":29,"column":78}}})) != null ? stack1 : "")
    + "</div>\n            </div>\n        </div>\n\n        <!-- Waiting -->\n        <div id=\"forgot-first-waiting-attempt\" class=\"tt-hide\">\n            <div class=\"tt-alert tt-customer-alert\">\n                <div>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Wait For Password",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":21},"end":{"line":36,"column":69}}})) != null ? stack1 : "")
    + "</div>\n                <div><a class=\"forgot-trigger tt-inline-block tt-no-margin\" href=\"#\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Ask for New Password",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":85},"end":{"line":37,"column":136}}})) != null ? stack1 : "")
    + "</a> "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Password Not Received 01",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":141},"end":{"line":37,"column":196}}})) != null ? stack1 : "")
    + "</div>\n                <div>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Signup Wrong Contact Alert",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":21},"end":{"line":38,"column":78}}})) != null ? stack1 : "")
    + "</div>\n            </div>\n        </div>\n    <!-- fine primo tentativo -->\n\n    <!-- secondo tentativo -->\n        <div id=\"forgot-second-attempt\" class=\"tt-hide\">\n            <div class=\"tt-alert tt-customer-alert\">\n                <div id=\"translate2\"></div>\n                <a data-config=\"notReceivedPasswordNotification\" href='#' class=\"tt-hide showable\" data-showable=\"passNotReceivedQuest_2\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Password Not Received Question 02",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":20},"end":{"line":48,"column":84}}})) != null ? stack1 : "")
    + "\n                </a>\n\n                <div data-config=\"notReceivedPasswordNotification\" class=\"tt-hide\" id=\"passNotReceivedQuest_2\">\n                    <div>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Password Not Received 02",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":25},"end":{"line":52,"column":80}}})) != null ? stack1 : "")
    + "</div>\n                    <div>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Signup Wrong Contact Alert",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":25},"end":{"line":53,"column":82}}})) != null ? stack1 : "")
    + "</div>\n                    <div>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Otherwise Registration",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":25},"end":{"line":54,"column":78}}})) != null ? stack1 : "")
    + "</div>\n                <a class=\"link tt-inline-block tt-no-margin\" href=\"#\" id=\"js-contact-center-request\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Click Here",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":101},"end":{"line":55,"column":142}}})) != null ? stack1 : "")
    + "</a>\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Center Contact For Password",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":16},"end":{"line":56,"column":74}}})) != null ? stack1 : "")
    + "\n            </div>\n            </div>\n        </div>\n\n        <!-- Waiting -->\n        <div id=\"forgot-second-waiting-attempt\" class=\"tt-hide\">\n            <div class=\"tt-alert tt-alert--column tt-customer-alert\">\n                <div>\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Wait For Password",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":20},"end":{"line":65,"column":68}}})) != null ? stack1 : "")
    + "\n                </div>\n                <a class=\"new-forgot-trigger\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Click Here",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":46},"end":{"line":67,"column":87}}})) != null ? stack1 : "")
    + "</a>\n            </div>\n        </div>\n    <!-- fine secondo tentativo -->\n\n    <!-- terzo tentativo -->\n    <!-- Invio ultimo tentativo -->\n    <div id=\"forgot-third-attempt\" class=\"tt-hide new-forgot-trigger\">\n        <div class=\"tt-alert tt-customer-alert\">\n            <div>\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Center Contact For Password Received",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":16},"end":{"line":77,"column":83}}})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n    </div>\n    <!-- fine terzo tentativo -->\n\n    <!-- Invio solo per email -->\n    <div id=\"forgot-email-attempt\" class=\"tt-hide\">\n        <div class=\"tt-alert tt-customer-alert\">\n            <div>\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Your new password has been emailed to you.",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":16},"end":{"line":87,"column":89}}})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n    </div>\n    <!-- fine Invio solo per email -->\n\n\n<div id=\"loginContainer\" class=\"tt-login-container\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromParentPage") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":96,"column":8},"end":{"line":106,"column":15}}})) != null ? stack1 : "")
    + "				<div class=\"tt-hide tt-container\" id=\"login-alert-div\">\n					<div class=\"tt-alert tt-alert--danger\">\n						<div data-e2eid=\"login-alert\">\n							"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Incorrect login",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":7},"end":{"line":110,"column":53}}})) != null ? stack1 : "")
    + "\n						</div>\n					</div>\n				</div>\n				<div class=\"tt-hide\" id=\"login-alert-div-custom\">\n					<div class=\"tt-alert tt-alert--danger\" id=\"login-alert-div-custom-text\">\n					</div>\n				</div>\n    <div class=\"tt-container tt-container--15\">\n        <div class=\"tt-ph-2\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDeferred") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":120,"column":12},"end":{"line":124,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loginWithSaml") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":128,"column":4},"end":{"line":141,"column":11}}})) != null ? stack1 : "")
    + "\n    <form role=\"form\" autocomplete=\"off\">\n            <div class=\"tt-container tt-container--15 tt-pb-0\">\n				<div class=\"tt-container-list-radius tt-mb-20\">\n                    <div class=\"tt-input-container tt-input-login\">\n                        <input data-e2eid=\"username-input\" name=\"user\" autocomplete=\"new-user\" type=\"text\" id=\"inputUserAddress\" class=\"tt-input tt-dynamic-text\" autocomplete=\"new-text\"\n                            placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Email address or Mobile number",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":41},"end":{"line":148,"column":102}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"idnumberMopRegistration") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":102},"end":{"line":148,"column":213}}})) != null ? stack1 : "")
    + "\"\n                            value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"parentsPhone") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":35},"end":{"line":149,"column":78}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"parentsEmail") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":78},"end":{"line":149,"column":121}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromRegister") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":121},"end":{"line":149,"column":164}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromForgot") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":164},"end":{"line":149,"column":203}}})) != null ? stack1 : "")
    + "\" />\n                    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"legacyAuthentication") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":20},"end":{"line":162,"column":27}}})) != null ? stack1 : "")
    + "\n                    <div class=\"tt-input-container tt-input-container--password tt-input-login\">\n                      <input name=\"password\"\n                          type=\"password\"\n                          class=\"tt-input js-toggle-password-visibility-input\"\n                          id=\"inputUserPassword\"\n                          autocomplete=\"new-password\"\n                          data-e2eid=\"password-input\"\n                          placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Password",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":39},"end":{"line":171,"column":78}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"idnumberMopRegistration") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":78},"end":{"line":171,"column":188}}})) != null ? stack1 : "")
    + "\">\n                      <a href=\"javascript:;\" class=\"tt-input-container--password-text js-toggle-password-visibility-btn\" data-e2eid=\"show-hide-password\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Show",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":172,"column":153},"end":{"line":172,"column":188}}})) != null ? stack1 : "")
    + "</a>\n                    </div>\n\n							</div>\n\n              <div id=\"login-forgot-link\" class=\"tt-text-center\">\n                <a data-e2eid=\"forgot-password-link\" href=\"#\" class=\"forgot-trigger stable "
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"notify","==",0,{"name":"checkConfig","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":178,"column":91},"end":{"line":178,"column":150}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"I Forgot My Password",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":178,"column":152},"end":{"line":178,"column":203}}})) != null ? stack1 : "")
    + "</a>\n              </div>\n\n							<button id=\"loginButton\" data-e2eid=\"login-button\" type=\"submit\" class=\"tt-button tt-mt-20 tt-button--primary tt-button-login\">\n									"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Start Session",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":182,"column":9},"end":{"line":182,"column":53}}})) != null ? stack1 : "")
    + "\n							</button>\n        </div>\n    </form>\n\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"fromParentPage") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":187,"column":4},"end":{"line":204,"column":16}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loginWithSpid") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":207,"column":4},"end":{"line":230,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true,"useDepths":true});