var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-column is-8 is-offset-2\"";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"loginAddress") || (depth0 != null ? lookupProperty(depth0,"loginAddress") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"loginAddress","hash":{},"data":data,"loc":{"start":{"line":9,"column":117},"end":{"line":9,"column":133}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"forgot_container\">\n    <div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"This will change your password to a new, randomly generated one.",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":26},"end":{"line":2,"column":121}}})) != null ? stack1 : "")
    + "</div>\n    <form role=\"form\">\n        <div class=\"tt-columns\">\n            <div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":17},"end":{"line":5,"column":82}}})) != null ? stack1 : "")
    + ">\n                <div class=\"tt-form-divider tt-hide\" data-compulsory=\"mobile\" >"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Loginpanel Username",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":79},"end":{"line":6,"column":133}}})) != null ? stack1 : "")
    + "</div>\n                <div class=\"tt-form-divider tt-hide\" data-compulsory=\"email\" >"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Loginpanel Username",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":78},"end":{"line":7,"column":132}}})) != null ? stack1 : "")
    + "</div>\n                <div class=\"tt-input-container\">\n                    <input id=\"username\" name=\"username\" class=\"tt-input tt-input-border\" value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loginAddress") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":97},"end":{"line":9,"column":140}}})) != null ? stack1 : "")
    + "\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Forgot Media Label",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":155},"end":{"line":9,"column":204}}})) != null ? stack1 : "")
    + "\">\n                </div>\n                <div class=\"tt-mt-10\">\n                <span class=\"tt-hide\" data-compulsory=\"mobile\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Password will be sent by sms",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":13,"column":79}}})) != null ? stack1 : "")
    + "\n                </span>\n                <span class=\"tt-hide\" data-compulsory=\"email\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Password will be sent by email",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":16,"column":81}}})) != null ? stack1 : "")
    + "\n                </span>\n                </div>\n            </div>\n        </div>\n        <div class=\"tt-columns\">\n            <div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":17},"end":{"line":22,"column":82}}})) != null ? stack1 : "")
    + ">\n                <button type=\"button\" class=\"tt-button tt-button--primary\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Change Password",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":24,"column":66}}})) != null ? stack1 : "")
    + "\n                </button>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});