var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"tt-alert tt-js-download-result-box--second-banner tt-hide\" data-download-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"downloadid") : depth0), depth0))
    + "\">\n\n        <div class=\"tt-js-download-result-box\" align=\"left\">\n            <div>\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias4).call(alias3,"Results Keep",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":59}}})) != null ? stack1 : "")
    + "\n                <span class=\"clear download_name\">\n                    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "\n                    <br>\n                </span>\n                <br>\n                <div class=\"tt-text-center download_img tt-hide\">\n                    <button type=\"button\" class=\"tt-button tt-button--primary download-download-file\" data-download-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"downloadid") : depth0), depth0))
    + "\">\n                        <span class=\"tt-icon-arrow-circle-down tt-mr-4\"></span> "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias4).call(alias3,"Results Keep Save",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":80},"end":{"line":14,"column":128}}})) != null ? stack1 : "")
    + "\n                    </button>\n                </div>\n                <br> "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"online_expiration") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":21},"end":{"line":22,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n \n\n    <div class=\"tt-hide share-download-actions\">\n        <div style=\"line-height:30px;\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias4).call(alias3,"More Actions",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":39},"end":{"line":28,"column":82}}})) != null ? stack1 : "")
    + "</div>\n        <div class=\"downloads_actions_button_container\">\n            <div>\n                <div class=\"share-download-email tt-hide download-actions\" style=\"margin-bottom: 10px;\">\n                    <button type=\"button\" class=\"tt-button tt-button--primary email-download-file download-action\" data-download-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"downloadid") : depth0), depth0))
    + "\">\n                        <span class=\"tt-icon-envelope\"></span> &nbsp;"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias4).call(alias3,"Results Share",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":69},"end":{"line":33,"column":113}}})) != null ? stack1 : "")
    + "\n                    </button>\n                </div>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotMobileApp") || (depth0 != null ? lookupProperty(depth0,"isNotMobileApp") : depth0)) != null ? helper : alias4),(options={"name":"isNotMobileApp","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":16},"end":{"line":40,"column":35}}}),(typeof helper === "function" ? helper.call(alias3,options) : helper));
  if (!lookupProperty(helpers,"isNotMobileApp")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                <!--\n                    <button type=\"button\" class=\"print-file\" data-download-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"downloadid") : depth0), depth0))
    + "\">\n                      <span class=\"glyphicon glyphicon-print\"></span> "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias4).call(alias3,"Print",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":70},"end":{"line":43,"column":106}}})) != null ? stack1 : "")
    + "\n                    </button>\n                    <button type=\"button\" id=\"sms-download-file\" class=\"download-action\"><span class=\"glyphicon glyphicon-envelope\"></span> "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias4).call(alias3,"SMS",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":140},"end":{"line":45,"column":174}}})) != null ? stack1 : "")
    + "\n                    </button>\n                    -->\n            </div>\n        </div>\n    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                <span style=\"font-size: 13px;\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Results Until",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":19,"column":64}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"online_expiration") : depth0), depth0))
    + "\n                    <!-- Stampo solo la prima data di disponibilità -->\n                </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"share-download-dropbox tt-hide download-actions\">\n                    <button class=\"tt-button tt-button--primary dropbox-saver\"></button>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"downloads") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":52,"column":9}}})) != null ? stack1 : "");
},"useData":true});