import React, { useState, useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';

import * as style from "./style.js";
import { useTheme, Global } from '@emotion/react';

import { Icon } from "../Icon";
import { Label } from "../Label";

import { TTPolyglot } from "@frontend/tt-polyglot";
import { formatMonth, formatMonthYear, formatWeekDay } from './utils.js';

const CALENDAR_HEIGHT = 295;


export const DatePicker = ({ value = null, placeholder = "gg/mm/aaaa", onChange = null, disabled = false, error = false }) => {

  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [dateString, setDateString] = useState(value);
  const [date, setDate] = useState(new Date())
  const containerRef = useRef(null);

  useEffect(() => {
    setDateString(value);
  }, [value])


  const handleOnChange = value => {
    setDate(value);
    setOpen(false);
    onChange(value);
  };

  const calculateTopPositionCalendar = () => {

    const container = containerRef?.current;

    if (container) {

      const { top: topContainer, height: heightContainer } = container.getBoundingClientRect();
      const { offsetTop } = container;

      // Vado a vedere quanto sarà il valore di scroll del calendario aperto
      const containerScroll = topContainer + heightContainer + CALENDAR_HEIGHT;
      const windowHeight = (window.innerHeight || document.documentElement.clientHeight);

      if (containerScroll > windowHeight) {
        // Se entriamo qui vuol dire che il calendario, una volta aperto, andrà in overflow rispetto alla window.
        // Andiamo a posizionarlo più in alto in modo da farlo rientrare completamente nella window

        const scrollDifference = containerScroll - windowHeight;
        return `${offsetTop - scrollDifference}px`;
      }

    }
  }

  return (
    <div>
      <div onClick={() => setOpen(!open)} css={style.inputContainer(theme)({ disabled, error })} ref={containerRef}>
        <div css={style.input(theme)({ disabled, error })}>
          <Label size={"medium"} color={(!dateString || disabled) ? "manatee" : "text"}>
            {dateString || placeholder}
          </Label>
        </div>
        <Icon name={"CalendarDaysLight"} color={"primary"} size={"medium"} />
      </div>
      {
        open &&
        <div css={style.baseCalendarContainer(theme)({ top: calculateTopPositionCalendar() })}>
          <Global
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            styles={{
              ".base": style.baseCalendar(theme)({ width: containerRef?.current?.offsetWidth || null, maxHeight: `${CALENDAR_HEIGHT}px` }),
              ".base button": style.baseButton,
              ".base .react-calendar__navigation": style.navigation,
              ".base .react-calendar__navigation span": {
                ...style.generalFont(theme),
              },
              ".base .react-calendar__navigation__prev-button": {
                ...style.arrowsFont(theme),
              },
              ".base .react-calendar__navigation__next-button": {
                ...style.arrowsFont(theme),
              },
              ".base .react-calendar__navigation__prev2-button": {
                display: "none",
              },
              ".base .react-calendar__navigation__next2-button": {
                display: "none",
              },
              ".base .react-calendar__viewContainer": style.container,
              ".base .react-calendar__viewContainer abbr": {
                ...style.daysFont(theme),
              },
              ".base .react-calendar__month-view__weekdays abbr":
                style.monthView(theme),
              ".base button.react-calendar__month-view__days__day":
                style.monthViewDays,
              ".base .react-calendar__year-view__months button": style.monthViewBtn,
              ".base .react-calendar__decade-view__years button":
                style.monthViewBtn,
              ".base .react-calendar__century-view__decades button":
                style.monthViewBtn,
              ".base .react-calendar__month-view__days__day--neighboringMonth": {
                color: theme.colors.text,
              },
              ".base .react-calendar__tile--rangeBothEnds": style.selected(theme),
              ".base .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__year-view__months__month":
                style.selected(theme),
              ".base .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeBothEnds.react-calendar__year-view__months__month":
                style.selected(theme),
              ".base .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__decade-view__years__year":
                style.selected(theme),
              ".base .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeBothEnds.react-calendar__decade-view__years__year":
                style.selected(theme),
              ".base .react-calendar__tile--range.react-calendar__tile--rangeStart":
                style.selectedRangeStart(theme),
              ".base .react-calendar__tile--range.react-calendar__tile--active":
                style.selectedRangeBetween(theme),
              ".base .react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart":
                style.selectedRangeStart(theme),
              ".base .react-calendar__tile.react-calendar__tile--range.react-calendar__tile--rangeEnd":
                style.selectedRangeEnd(theme),
              ".react-calendar__navigation__label": style.monthButton,
            }}
          />
          <Calendar
            onChange={handleOnChange}
            value={date}
            tileDisabled={({ date }) => {
              // sono disabilitati tutti i giorni nel futuro
              return date > new Date();
            }}
            className="base"
            minDetail="decade"
            nextLabel={<Icon name="ArrowRightLight" size="micro" />}
            prevLabel={<Icon name="ArrowLeftLight" size="micro" />}
            formatMonth={(locale, date) => formatMonth(date)}
            formatMonthYear={(locale, date) => formatMonthYear(date)}
            formatShortWeekday={(locale, date) => formatWeekDay(date)}
          />
        </div>
      }
    </div>
  );

};


DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
};