import { useTheme } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";

/**
 * Primary UI component for user interaction
 */
export const Scrollable = ({ backgroundColor = null, border = false, children = <></>, height, hideScrollbar = false, padding = null, shadow = false, width = null, e2eid}) => {

  const theme = useTheme();

  return(
    <div {...e2eid ? {"data-e2eid":e2eid } : null} css={style.base(theme)({shadow, border, height, hideScrollbar, width, backgroundColor})}>
      <div css={style.wrapper(theme)({padding})}>{children}</div>
    </div>
  );
};

Scrollable.propTypes = {
  backgroundColor: PropTypes.string,
  border: PropTypes.bool,
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
  hideScrollbar: PropTypes.bool,
  padding: PropTypes.oneOf(["micro", "xsmall", "small", "standard", "medium", "large"]),
  shadow: PropTypes.bool,
  width: PropTypes.string,
};