var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-padding-75-h\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"tt-availability-multiple-slot-list-item\">\n						<div class=\"tt-availability-multiple-slot-list-item-detail\">\n							<div class=\"tt-availability-multiple-slot-list-item-detail__avatar\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showResource") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":29,"column":7},"end":{"line":41,"column":14}}})) != null ? stack1 : "")
    + "							</div>\n							<div class=\"tt-availability-multiple-slot-list-item-detail-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showResource") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":8},"end":{"line":46,"column":15}}})) != null ? stack1 : "")
    + "								<div class=\"tt-availability-multiple-slot-list-item-detail-content__typology\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"correctTypologyTitle") : depth0), depth0))
    + "<span>: </span>"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"correctActivityTitle") : depth0), depth0))
    + "</div>\n							</div>\n						</div>\n						<div class=\"tt-availability-multiple-slot-list-item__time\">\n							"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"startTime") : depth0), depth0))
    + "\n						</div>\n					</div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"resource_face_img_link") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":34,"column":15}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "									<img src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"resource_face_img_link") || (depth0 != null ? lookupProperty(depth0,"resource_face_img_link") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"resource_face_img_link","hash":{},"data":data,"loc":{"start":{"line":31,"column":19},"end":{"line":31,"column":45}}}) : helper)))
    + "\" />\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "									<i class=\"tt-icon-user-md\"></i>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"area_face_img_link") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":40,"column":15}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "									<img src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"area_face_img_link") || (depth0 != null ? lookupProperty(depth0,"area_face_img_link") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"area_face_img_link","hash":{},"data":data,"loc":{"start":{"line":37,"column":19},"end":{"line":37,"column":41}}}) : helper)))
    + "\" />\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "									<i class=\"tt-icon-hospital-alt\"></i>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "								<div class=\"tt-availability-multiple-slot-list-item-detail-content__name\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"resourceName") : depth0), depth0))
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.lambda, alias5=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":5},"end":{"line":1,"column":54}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n	<div class=\"tt-availability-multiple-slot-container\">\n		<div class=\"tt-availability-multiple-slot-info\">\n      \n      <div class=\"tt-availability-multiple-slot-info--row\">\n        <i class=\"tt-availability-multiple-slot-info--row-icon tt-icon-calendar-alt\"></i>\n        <div>\n          "
    + ((stack1 = (lookupProperty(helpers,"humanDateLongFormat")||(depth0 && lookupProperty(depth0,"humanDateLongFormat"))||alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"availabilities") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"start_date_day_abbr_week") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"availabilities") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"start_date") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"availabilities") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"start_date_month") : stack1),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"availabilities") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"start_date_year") : stack1),{"name":"humanDateLongFormat","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":8,"column":195}}})) != null ? stack1 : "")
    + "\n        </div>\n      </div>\n			<div class=\"tt-availability-multiple-slot-info--row tt-mt-10\">\n        <i class=\"tt-availability-multiple-slot-info--row-icon tt-icon-hospital-alt\"></i>\n				<div class=\"tt-availability-multiple-slot-info--row-area\">\n          <div>\n            "
    + alias5(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"availabilities") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"areaTitle") : stack1), depth0))
    + "\n          </div>\n          <div class=\"tt-text-small\">\n            "
    + alias5(alias4(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"availabilities") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"address") : stack1), depth0))
    + "\n          </div>\n        </div>\n\n      </div>\n		</div>\n		<div class=\"tt-availability-multiple-slot-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"availabilities") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":54,"column":13}}})) != null ? stack1 : "")
    + "				<div class=\"tt-availability-multiple-slot-list-action\">\n					<div class=\"tt-availability-multiple-slot-list-action-total\">\n						<div class=\"tt-availability-multiple-slot-list-action-total__title\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Multiple Duration",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":74},"end":{"line":57,"column":126}}})) != null ? stack1 : "")
    + "</div>\n						<div class=\"tt-availability-multiple-slot-list-action-total__duration\">"
    + alias5(((helper = (helper = lookupProperty(helpers,"formattedDuration") || (depth0 != null ? lookupProperty(depth0,"formattedDuration") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"formattedDuration","hash":{},"data":data,"loc":{"start":{"line":58,"column":77},"end":{"line":58,"column":98}}}) : helper)))
    + "</div>\n					</div>		\n					<button type=\"button\" class=\"tt-button tt-button--secondary availability-btn\" data-model-id="
    + alias5(alias4((depth0 != null ? lookupProperty(depth0,"solutionid") : depth0), depth0))
    + ">\n							"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Choose",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":7},"end":{"line":61,"column":44}}})) != null ? stack1 : "")
    + "\n					</button>\n				</div>\n		</div>\n	</div>\n</div>";
},"useData":true});