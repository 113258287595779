import Backbone from 'backbone-shim';
import _ from 'underscore';
import $ from 'jquery';
import LocalStorage from 'local_storage';
import CookieStorage from 'cookie_storage';
import SessionStorage from 'session_storage';

export default Backbone.Model.extend({
    MopStorage: undefined,

    _loadRightStorage: function () {
        if (!this.MopStorage) {
            var storageType = this.get('type') || 'localStorage';

            switch (storageType) {
                case 'localStorage':
                    this.MopStorage = LocalStorage;
                    break;
                case 'sessionStorage':
                    this.MopStorage = SessionStorage;
                    break;
                case 'cookie':
                    this.MopStorage = CookieStorage;
                    break;
            }
        }
    },

    initialize: function () {
        this.on('change', this.save, this);
    },

    fetch: function () {
        var deferred = new $.Deferred();
        var _this = this;

        this._loadRightStorage();

        this.MopStorage
            .getItem(this.id)
            .done(function (response) {
                _this.set((typeof response === 'string') ? JSON.parse(response) : response);

                deferred.resolve();
            });

        return deferred;
    },

    save: function () {
        var _this = this;
        var deferred = new $.Deferred();

        this._loadRightStorage();

        this.MopStorage
            .setItem(this.id, JSON.stringify(_this.attributes))
            .done(function () {
                deferred.resolve();

                return true;
            })
            .fail(function (err) {
                var errCode = err.code || err.number || err.message;

                // La localStorage è piena.
                if (errCode === 22) {
                    _this.MopStorage.removeItem(_this.get('backboneKey'))
                        .done(function () {
                            return _this.save();
                        });
                } else {
                    throw (err);
                }
            });

        return deferred;
    },

    destroy: function () {
        this._loadRightStorage();

        this.MopStorage.removeItem(this.id);
    },

    isEmpty: function () {
        return _.size(this.attributes) <= 1; // just 'id'
    }
});