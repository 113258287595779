import MOP from 'app';
import {Start} from '../index';
import { createRoot } from 'react-dom/client';


MOP.module("HeaderApp.View", (View, MOP, Backbone, Marionette) => {

  let root = undefined;

  View.Index = Marionette.CompositeView.extend({
    template: () => '<div></div>',
    onShow: function () {            
      
      root = createRoot(this.el);

      Start(
        root,
        {forceDisabled: Marionette.getOption(this, 'forceDisabled')},
      );
      
    },
    remove: function () {
      root.unmount();
      Backbone.View.prototype.remove.call(this);
    }
  });
});

export default MOP.HeaderApp.View;
