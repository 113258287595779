var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div>\n            <div class=\"js-comparison-insurance-result tt-panel tt-panel-default tt-container\" "
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"firstAvailabilityComparisonInsurance") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":95},"end":{"line":5,"column":178}}})) != null ? stack1 : "")
    + ">\n                <div class=\"tt-panel-heading tt-columns\">\n                    <h3 class=\"tt-panel-title tt-column tt-deprecated-h3\"><strong>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Compare Insurance With",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":82},"end":{"line":7,"column":139}}})) != null ? stack1 : "")
    + " <span class=\"js-comparison-insurance-result-title \">"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"firstAvailabilityComparisonInsurance") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</span></strong></h3>\n                    <div class=\"tt-column is-narrow\">\n                        <button class=\"js-button-show-comparison-insurance tt-button tt-button--operator tt-button--primary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Compare Insurance Goto Availabilities",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":125},"end":{"line":9,"column":197}}})) != null ? stack1 : "")
    + "</button>\n                    </div>\n                </div>\n                <div class=\"tt-panel-heading tt-columns\">\n                    <div class=\"tt-column\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Compare Insurance Price",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":43},"end":{"line":13,"column":101}}})) != null ? stack1 : "")
    + " <br><strong class=\"js-comparison-insurance-result-price\">"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"firstAvailabilityComparisonInsurance") : depth0)) != null ? lookupProperty(stack1,"price") : stack1), depth0))
    + "</strong></div>\n                    <div class=\"tt-column\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Compare Insurance First Availability",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":43},"end":{"line":14,"column":114}}})) != null ? stack1 : "")
    + " <br><strong class=\"js-comparison-insurance-result-date\">"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"firstAvailabilityComparisonInsurance") : depth0)) != null ? lookupProperty(stack1,"start_date") : stack1), depth0))
    + "</strong></div>\n                    <div class=\"js-comparison-insurance-result-resources tt-column\">"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"firstAvailabilityComparisonInsurance") : depth0)) != null ? lookupProperty(stack1,"resources") : stack1), depth0))
    + "</div>\n                </div>\n            </div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"4":function(container,depth0,helpers,partials,data) {
    return "";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-panel tt-panel-default tt-container\">\n            <div class=\"tt-panel-heading tt-columns\">\n                <h3 class=\"tt-panel-title tt-column tt-deprecated-h3\"><strong>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Compare Insurance Yours",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":78},"end":{"line":24,"column":136}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"availabilitiesBeforeComparisonInsuranceTitle") || (depth0 != null ? lookupProperty(depth0,"availabilitiesBeforeComparisonInsuranceTitle") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"availabilitiesBeforeComparisonInsuranceTitle","hash":{},"data":data,"loc":{"start":{"line":24,"column":137},"end":{"line":24,"column":185}}}) : helper)))
    + "</strong></h3>\n                <div class=\"tt-column is-narrow\">\n                    <button class=\"js-button-show-comparison-insurance-back tt-button tt-button--operator tt-button--primary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Compare Insurance Back Button",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":126},"end":{"line":26,"column":190}}})) != null ? stack1 : "")
    + "</button>\n                </div>\n            </div>\n        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"tt-columns tt-flex\">\n        <div class=\"tt-column\">\n        </div>\n        <div class=\"tt-column is-narrow tt-text-right\">\n            <a class=\"tt-button tt-button--operator tt-button--secondary tt-margin-top-8\" href=\"javascript:\" data-calendar-activator>\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Reschedule Change Date",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":38,"column":69}}})) != null ? stack1 : "")
    + "\n            </a>\n        </div>\n        <div class=\"tt-column is-narrow tt-text-right\">\n            <a class=\"tt-button tt-button--operator tt-button--secondary tt-margin-top-8 js-cancelRescheduling\" href=\"javascript:\">\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Cancel",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":43,"column":53}}})) != null ? stack1 : "")
    + "\n            </a>\n        </div>\n    </div>\n    <div data-tt-calendar></div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"tt-columns tt-flex is-vcentered tt-margin-top-8\">\n        <div class=\"tt-column availabilities_title\">\n            <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"typologyTitle") || (depth0 != null ? lookupProperty(depth0,"typologyTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typologyTitle","hash":{},"data":data,"loc":{"start":{"line":51,"column":20},"end":{"line":51,"column":37}}}) : helper)))
    + "</strong>\n            <br>"
    + alias4(((helper = (helper = lookupProperty(helpers,"activityTitle") || (depth0 != null ? lookupProperty(depth0,"activityTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityTitle","hash":{},"data":data,"loc":{"start":{"line":52,"column":16},"end":{"line":52,"column":33}}}) : helper)))
    + "\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"insurance_title") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":12},"end":{"line":53,"column":82}}})) != null ? stack1 : "")
    + "\n        </div>\n        <div class=\"tt-column is-narrow\">\n            <span class=\"change_date\">\n                <a class=\"tt-button tt-button--operator tt-button--secondary tt-customer-change-date\" href=\"javascript:\" data-calendar-activator>\n                    <span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Change Date",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":26},"end":{"line":58,"column":68}}})) != null ? stack1 : "")
    + "</span>\n                </a>\n            </span>\n        </div>\n        <div class=\"tt-column is-narrow\">\n            <span class=\"change_search\">\n                <a class=\"tt-button tt-button--operator tt-button--secondary tt-customer-change-search\" href=\"javascript:\" id=\"changeSearch\">\n                    <span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Search Again",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":26},"end":{"line":65,"column":69}}})) != null ? stack1 : "")
    + "</span>\n                </a>\n            </span>\n        </div>\n         <div class=\"tt-column is-narrow\">\n            <a class=\"tt-button tt-button--operator tt-button--primary\" href=\"javascript:\" id=\"copySearch\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Copy Link",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":107},"end":{"line":70,"column":151}}})) != null ? stack1 : "")
    + "</a>\n        </div>\n      </div>\n      <div data-tt-calendar></div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<br><strong>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"insurance_title") || (depth0 != null ? lookupProperty(depth0,"insurance_title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"insurance_title","hash":{},"data":data,"loc":{"start":{"line":53,"column":47},"end":{"line":53,"column":66}}}) : helper)))
    + "</strong>";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<div class=\"tt-admin-availabilities-key tt-margin-20\">\n					<div class=\"tt-admin-availabilities-key-item\">\n						<div class=\"tt-admin-availabilities-key-item__color\"></div>\n						<div class=\"tt-admin-availabilities-key-item__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Regular Availabilities Slot",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":58},"end":{"line":79,"column":120}}})) != null ? stack1 : "")
    + "</div>\n					</div>\n					<div class=\"tt-admin-availabilities-key-item tt-admin-availabilities-key-item--suggest\">\n						<div class=\" tt-admin-availabilities-key-item__color\"></div>\n						<div class=\"tt-admin-availabilities-key-item__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Risky Availabilities Slot",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":58},"end":{"line":83,"column":118}}})) != null ? stack1 : "")
    + "</div>\n					</div>\n				</div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"javascript:\" class=\"tt-button tt-button--paginator tt-button--primary js-prev-results\">\n                <i class=\"tt-button__icon tt-button__icon--left tt-icon-angle-left\"></i>\n                 <span class=\"tt-button__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Previous",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":47},"end":{"line":94,"column":86}}})) != null ? stack1 : "")
    + "</span>\n            </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"admin-availabilities-container\" class=\"operator_availabilities\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showInsuranceComparison") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAvailabilitiesBeforeComparison") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":30,"column":11}}})) != null ? stack1 : "")
    + "  \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showCalendarInSearchAvailabilities") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":74,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"promoteSlotMinRiskPercent","!=",100,{"name":"checkConfig","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":3},"end":{"line":86,"column":19}}})) != null ? stack1 : "")
    + "</div>\n\n<div class=\"tt-columns tt-flex\">\n    <div class=\"tt-column\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPrevResults") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":8},"end":{"line":96,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"tt-column\">\n        <a href=\"javascript:\" class=\"tt-button tt-button--paginator tt-button--primary js-next-results\">\n            <i class=\"tt-button__icon tt-button__icon--right tt-icon-angle-right\"></i>\n             <span class=\"tt-button__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Next",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":43},"end":{"line":101,"column":78}}})) != null ? stack1 : "")
    + "</span>\n        </a>\n    </div>\n</div>";
},"useData":true});