var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-column is-8 is-offset-2\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "															value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"result_code") || (depth0 != null ? lookupProperty(depth0,"result_code") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"result_code","hash":{},"data":data,"loc":{"start":{"line":21,"column":22},"end":{"line":21,"column":37}}}) : helper)))
    + "\"\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "															value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"user_idnumber") || (depth0 != null ? lookupProperty(depth0,"user_idnumber") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"user_idnumber","hash":{},"data":data,"loc":{"start":{"line":41,"column":22},"end":{"line":41,"column":39}}}) : helper)))
    + "\"\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<!-- RICERCA REFERTO -->\n<div class=\"tt-columns tt-block\">\n	<div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":6},"end":{"line":3,"column":71}}})) != null ? stack1 : "")
    + ">\n		<form role=\"form\" class=\"tt-margin-top-20\">\n						<div class=\"tt-mb-10\">\n								<div class=\"tt-form-divider tt-form-divider--icon\">\n							<div class=\"tt-form-divider__text\">\n								"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Insert Medical Result ID",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":63}}})) != null ? stack1 : "")
    + "\n							</div>\n							<div class=\"tt-form-divider__icon\">\n								<span data-toggle=\"popover\"\n															class=\"downloads-tooltip tt-hidden-xs tt-icon-question-circle\"\n															border=\"0\" width=\"17\" height=\"16\"\n															data-content=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"MOP Medical Results Test Result Number",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":29},"end":{"line":14,"column":98}}})) != null ? stack1 : "")
    + "\"></span>\n							</div>\n							</div>\n										\n						<div class=\"tt-input-container tt-input-container--required\">\n														<input type=\"text\" id=\"description\" class=\"tt-input\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"result_code") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":15},"end":{"line":22,"column":22}}})) != null ? stack1 : "")
    + "														/>\n										</div>\n						</div>\n						<div class=\"tt-mb-10\">\n										<div class=\"tt-form-divider tt-form-divider--icon\">\n							<div class=\"tt-form-divider__text\">\n								"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Idnumber",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":29,"column":47}}})) != null ? stack1 : "")
    + "\n							</div>\n							<div class=\"tt-form-divider__icon\">\n								<span data-toggle=\"popover\"\n															class=\"downloads-tooltip tt-hidden-xs tt-icon-question-circle\"\n															src=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"getImgPath") || (depth0 != null ? lookupProperty(depth0,"getImgPath") : depth0)) != null ? helper : alias2),(options={"name":"getImgPath","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":20},"end":{"line":34,"column":50}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"getImgPath")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "/help.svg\" border=\"0\" width=\"17\" height=\"16\"\n															data-content=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"MOP Medical Results Codice Fiscale",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":29},"end":{"line":35,"column":94}}})) != null ? stack1 : "")
    + "\"></span>\n							</div>\n						</div>\n										<div class=\"tt-input-container tt-input-container--required\">\n														<input type=\"text\" id=\"user-idnumber\" class=\"tt-input\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_idnumber") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":18},"end":{"line":42,"column":22}}})) != null ? stack1 : "")
    + "														/>\n										</div>\n						</div>\n						<div class=\"tt-text-center\">\n								<button type=\"button\" id=\"search-downloads-button\" class=\"tt-button tt-button--primary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Search",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":96},"end":{"line":47,"column":133}}})) != null ? stack1 : "")
    + "</button>\n						</div>\n		</form>			\n	</div>\n</div>";
},"useData":true});