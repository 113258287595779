import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";

import { Label } from "ui/Label";
import { Loading } from "ui/Loading";
import { useTheme } from '@emotion/react';
import { useMediaQuery } from 'react-responsive';
import useFetchCheckinImage from 'common-lib/fetchCheckinImage';

export const Barcode = ({
  imgUrl,
  code,
  fontSize = null,
  message = null
}) => {
  const theme = useTheme();

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.layout.mobileView})`,
  });

  const { data, loading, error } = useFetchCheckinImage(imgUrl);

  error && console.log(error);

  return (
    <div css={style.base}>
      <Label color="text_light" size="medium">{message}</Label>

      {loading &&
        <div css={style.loading}>
          <Loading />
        </div>
      }


      <div css={style.barcodeContainer}>
        <img css={style.barcode} src={data} />
        {isMobile ?
          <div css={style.number(theme)(fontSize)}>
            {code}
          </div> : null
        }
      </div>


      {!isMobile ?
        <div css={style.number(theme)(fontSize)}>
          {code}
        </div> : null
      }
    </div>
  );
};

Barcode.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  message: PropTypes.string,
};