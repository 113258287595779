import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@emotion/react';

import { Label } from '../../Label';
import { Dialog } from '../../Dialog';
import { Input } from '../../Input';
import { Checkbox } from '../../Checkbox';
import { Button } from '../../Button';

import { TTPolyglot } from "@frontend/tt-polyglot";

import {
  STATUS_FILE_NOT_SUPPORTED,
  STATUS_FILE_SUPPORTED,
  STATUS_POLICY_ACCEPTED,
  STATUS_POLICY_DELETE_FILE,
  STATUS_READ_POLICY,
  STATUS_START
} from "../constants";

import { useUploadContext } from '../Context';


export const PolicyDialog = ({ policy = null }) => {

  const {
    status,
    setStatus,
    inputRef,
    isAdmin,
    uploadDoc,
    uploadRef,
    privacyAccepted,
    setPrivacyAccepted
  } = useUploadContext();

  const [privacyError, setPrivacyError] = useState(false);

  const checkPrivacyUpload = async () => {

    if (!privacyAccepted) {
      return setPrivacyError(true);
    }

    // Prendo il file dall'input e lo carico con la funzione di upload
    const [file] = inputRef?.current?.files || null;
    uploadDoc && await uploadDoc(file);

    // Setto lo status corretto
    setStatus(STATUS_POLICY_ACCEPTED);
  };

  const checkPrivacy = () => {
    // Check/uncheck della privacy e in ogni caso puliamo il privacy error
    setPrivacyAccepted(!privacyAccepted);
    setPrivacyError(false);
  }

  const renderConfirmDialogContent = () => {

    switch (status) {
      case STATUS_FILE_SUPPORTED: {
        return (
          <Dialog.Content>
            <Input
              placeholder={inputRef?.current?.files[0].name || null}
              disabled={true}
              icon={"FileArrowUpLight"}
            />
            { // Gli admin non vedono la checkbox della privacy, loro hanno la privacy sempre accettata di default
              !isAdmin &&
              <Checkbox
                onClick={checkPrivacy}
                checked={privacyAccepted}
                error={privacyError ? TTPolyglot.t("MOP Dialog Upload Document Privacy Validation Error") : null}
              >
                <Label onClick={() => setStatus(STATUS_READ_POLICY)} >
                  {TTPolyglot.t("MOP Dialog Upload Document Privacy")}
                </Label>
              </Checkbox>
            }
          </Dialog.Content>
        );

        break;
      }

      case STATUS_READ_POLICY: {
        return (
          <>
            <Label
              onClick={() => setStatus(STATUS_FILE_SUPPORTED)}
              icon={{
                name: "AngleLeftSolid",
                color: "text"
              }}>

            </Label>
            <div dangerouslySetInnerHTML={{ __html: policy }}></div>
          </>

        )
        break;
      }

      default: {
        return null
        break;
      }

    }
  };

  const renderConfirmDialogFooter = () => {
    if (status === STATUS_FILE_SUPPORTED) {
      return (
        <Dialog.Footer>
          <Button type={"solid"} onClick={checkPrivacyUpload}>{TTPolyglot.t("Insert")}</Button>
        </Dialog.Footer>
      );
    }

    return null;
  };

  const onClose = () => {
    inputRef.current.value = null; // Resetto l'input
    setStatus(STATUS_START); // Setto lo status a false
    setPrivacyAccepted(isAdmin);
    setPrivacyError(false);
  }


  return (
    <Dialog
      open={status === STATUS_FILE_SUPPORTED || status === STATUS_READ_POLICY}
      onClose={onClose}
      closeButton={true}
      outClick={true}
      anchorEl={uploadRef?.current}
      title={stauts === STATUS_FILE_SUPPORTED ? TTPolyglot.t("MOP Dialog Upload Document Title") : null}
    >
      {renderConfirmDialogTitle()}
      {renderConfirmDialogContent()}
      {renderConfirmDialogFooter()}
    </Dialog>
  );
};
