var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "				<i class=\"tt-info__icon tt-icon-comments-alt\"></i>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "				<i class=\"tt-info__icon tt-icon-envelope\"></i>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<a href=\"javascript:\"\n				data-entity-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"reservationid") || (depth0 != null ? lookupProperty(depth0,"reservationid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reservationid","hash":{},"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":16,"column":37}}}) : helper)))
    + "\"\n				data-entity-waiting-reservationid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"waiting_reservationid") || (depth0 != null ? lookupProperty(depth0,"waiting_reservationid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"waiting_reservationid","hash":{},"data":data,"loc":{"start":{"line":17,"column":39},"end":{"line":17,"column":64}}}) : helper)))
    + "\"\n				data-entity-replace-reservation-text=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"replace_reservation_text") || (depth0 != null ? lookupProperty(depth0,"replace_reservation_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"replace_reservation_text","hash":{},"data":data,"loc":{"start":{"line":18,"column":42},"end":{"line":18,"column":70}}}) : helper)))
    + "\"\n				class=\"tt-action replace-reservation\">\n					<div class=\"tt-action__content\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Wait Swap Reservation Anchor",{"name":"translate","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":37},"end":{"line":20,"column":96}}})) != null ? stack1 : "")
    + "</div>\n					<span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n			</a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"reservationid") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":2},"end":{"line":31,"column":9}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"tt-container tt-pt-0\">\n				<a href=\"javascript:\" data-entity-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"reservationid") || (depth0 != null ? lookupProperty(depth0,"reservationid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reservationid","hash":{},"data":data,"loc":{"start":{"line":26,"column":42},"end":{"line":26,"column":59}}}) : helper)))
    + "\" class=\"tt-action notification-resid\">\n						<div class=\"tt-action__content\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Reservation",{"name":"translate","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":38},"end":{"line":27,"column":80}}})) != null ? stack1 : "")
    + "</div>\n						<span data-entity-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"reservationid") || (depth0 != null ? lookupProperty(depth0,"reservationid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reservationid","hash":{},"data":data,"loc":{"start":{"line":28,"column":28},"end":{"line":28,"column":45}}}) : helper)))
    + "\" class=\"tt-action__arrow tt-icon-angle-right\"></span>\n				</a>\n			</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tt-card-notification\">\n	<div class=\"tt-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"sendingDate") || (depth0 != null ? lookupProperty(depth0,"sendingDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sendingDate","hash":{},"data":data,"loc":{"start":{"line":2,"column":23},"end":{"line":2,"column":38}}}) : helper)))
    + "</div>\n	<div class=\"tt-info tt-no-border\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"channel") : depth0),"==","push",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":3},"end":{"line":8,"column":14}}})) != null ? stack1 : "")
    + "			<div class=\"tt-info__content\">\n					"
    + alias4(((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":10,"column":5},"end":{"line":10,"column":13}}}) : helper)))
    + "\n			</div>\n	</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"waiting_reservationid") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":14,"column":1},"end":{"line":32,"column":8}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});