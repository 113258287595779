var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"summary_addons col-sm-2 text-center tt-container tt-container--15\" style=\"display: flex; align-items: center; flex-direction: row;\">\n        <div class=\"tt-price tt-customer-price\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"price") || (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"price","hash":{},"data":data,"loc":{"start":{"line":10,"column":48},"end":{"line":10,"column":57}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"currencySymbol") || (depth0 != null ? lookupProperty(depth0,"currencySymbol") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currencySymbol","hash":{},"data":data,"loc":{"start":{"line":10,"column":58},"end":{"line":10,"column":76}}}) : helper)))
    + "</div>\n      </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotOneTimeUser") || (depth0 != null ? lookupProperty(depth0,"isNotOneTimeUser") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isNotOneTimeUser","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":24},"end":{"line":32,"column":45}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isNotOneTimeUser")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\"javascript:\" class=\"tt-button tt-button--primary\" data-trigger=\"addParent:click\">\n                                <i class=\"tt-button__icon tt-icon-user-plus\"></i>\n                                <span class=\"tt-button__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Add Relatives",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":62},"end":{"line":30,"column":106}}})) != null ? stack1 : "")
    + "</span>\n                            </a>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"tt-input-radio-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":49},"end":{"line":40,"column":83}}})) != null ? stack1 : "")
    + "\" data-trigger=\"user:selectByPatient\" data-trigger-param=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n							<label class=\"tt-input-radio js-no-required-validation\">\n									<input class=\"tt-input-radio__input\" type=\"radio\" name=\"group\" value=\"\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":81},"end":{"line":42,"column":126}}})) != null ? stack1 : "")
    + ">\n									<div class=\"tt-input-radio__content\">\n											<div class=\"tt-input-radio__content-text\" data-trigger=\"user:selectByPatient\" data-trigger-param=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</div>\n											<i class=\"tt-input-radio__content-icon tt-icon-check\"></i>\n									</div>\n							</label>\n					  </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "active";
},"11":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showContactFieldForm") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":2},"end":{"line":72,"column":9}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"tt-panel tt-panel-default js-summary-communication-container\">\n    <div class=\"tt-panel-heading\">\n        <h3 class=\"tt-panel-title tt-deprecated-h3\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Resume Specitfy Contact",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":52},"end":{"line":57,"column":110}}})) != null ? stack1 : "")
    + "</h3>\n    </div>\n    <div class=\"tt-container tt-container--15\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationPhone") : depth0),"!=",0,{"name":"ifCond","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":6},"end":{"line":64,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationEmail") : depth0),"!=",0,{"name":"ifCond","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":6},"end":{"line":69,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"tt-input-container "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationPhone") : depth0),"==",2,{"name":"ifCond","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":37},"end":{"line":61,"column":113}}})) != null ? stack1 : "")
    + "\">\n        <input class=\"tt-input js-summary-communication-phone\" name=\"communication_phone\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Specify Phone Placeholder",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":103},"end":{"line":62,"column":163}}})) != null ? stack1 : "")
    + "\" value="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"phone") || (depth0 != null ? lookupProperty(depth0,"phone") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"phone","hash":{},"data":data,"loc":{"start":{"line":62,"column":171},"end":{"line":62,"column":180}}}) : helper)))
    + ">\n      </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "tt-input-container--required";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"tt-input-container "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationEmail") : depth0),"==",2,{"name":"ifCond","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":37},"end":{"line":66,"column":113}}})) != null ? stack1 : "")
    + "\">\n        <input class=\"tt-input js-summary-communication-email\" name=\"communication_email\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Specify Email Placeholder",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":103},"end":{"line":67,"column":163}}})) != null ? stack1 : "")
    + "\" value="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":67,"column":171},"end":{"line":67,"column":180}}}) : helper)))
    + ">\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<form>\n  <div class=\"tt-panel tt-panel-default clearfix\" style=\"margin-top: 20px;\">\n    <div class=\"col-sm-10 tt-container tt-container--15\" style=\"display: flex; align-items: center; flex-direction: row; min-height: 139px;\">\n      <div>\n        <strong>"
    + alias4(((helper = (helper = lookupProperty(helpers,"typologyTitle") || (depth0 != null ? lookupProperty(depth0,"typologyTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typologyTitle","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":33}}}) : helper)))
    + "</strong> <br><span class=\"appointment_activity\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"activityTitle") || (depth0 != null ? lookupProperty(depth0,"activityTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityTitle","hash":{},"data":data,"loc":{"start":{"line":5,"column":82},"end":{"line":5,"column":99}}}) : helper)))
    + "</span>\n      </div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPrice") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n\n\n  <div class=\"tt-panel tt-panel-default\">\n      <div class=\"tt-panel-heading\">\n            <div class=\"tt-columns tt-flex\">\n                <div class=\"tt-column\">\n                    <h3 class=\"tt-panel-title tt-deprecated-h3\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"MOP Book For",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":24},"end":{"line":21,"column":67}}})) != null ? stack1 : "")
    + "\n                        <!-- se operatore: Scegli Cliente <button type=\"button\"><span class=\"glyphicon glyphicon-plus\"></span> Aggiungi</button> -->\n                    </h3>\n                </div>\n                <div class=\"tt-column is-narrow\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowFamilies") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":33,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n      </div>\n\n      <div class=\"tt-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"parents") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":10},"end":{"line":49,"column":19}}})) != null ? stack1 : "")
    + "      </div>\n  </div>\n\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotAdminLoggedIn") || (depth0 != null ? lookupProperty(depth0,"isNotAdminLoggedIn") : depth0)) != null ? helper : alias2),(options={"name":"isNotAdminLoggedIn","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":2},"end":{"line":73,"column":25}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNotAdminLoggedIn")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n  <div class=\"tt-panel tt-panel-default tt-hide\" id=\"custom_fields_container\">\n      <div class=\"tt-panel-heading\">\n          <h3 class=\"tt-panel-title tt-deprecated-h3\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Additional Info",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":54},"end":{"line":77,"column":100}}})) != null ? stack1 : "")
    + "</h3>\n      </div>\n      <div class=\"tt-container tt-container--15\" id=\"custom_fields\">\n\n      </div>\n  </div>\n\n  <div class=\"tt-panel tt-panel-default\" id=\"prescriptionContainer\">\n      <div class=\"tt-panel-heading\">\n          <h3 class=\"tt-panel-title tt-deprecated-h3\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Other Singular",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":54},"end":{"line":86,"column":99}}})) != null ? stack1 : "")
    + "</h3>\n      </div>\n      <div class=\"tt-container tt-container--15\">\n          <!-- Prescription -->\n          <div data-prescription-config=\"mopCode\" class=\"row\">\n              <label class=\"col-sm-4\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Prescription Code",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":38},"end":{"line":91,"column":86}}})) != null ? stack1 : "")
    + "</label>\n              <div class=\"col-sm-8\">\n                  <div class=\"tt-input-container\">\n                    <input type=\"text\" name=\"prescription_code\" maxlenght=\"16\" class=\"form-control tt-input\"/>\n                  </div>\n              </div>\n          </div>\n\n          <div data-prescription-config=\"mopPriority\" class=\"row\">\n              <label class=\"col-sm-4\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Prescription Priority",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":38},"end":{"line":100,"column":90}}})) != null ? stack1 : "")
    + "</label>\n              <div class=\"col-sm-8\">\n                  <select name=\"prescription_priority\" class=\"selectpicker\">\n                      <option value=\"\" selected=\"selected\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"No Prescription Priority",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":59},"end":{"line":103,"column":114}}})) != null ? stack1 : "")
    + "</option>\n                      <option value=\"U\">U - Urgente</option>\n                      <option value=\"B\">B - Breve</option>\n                      <option value=\"D\">D - Differibile</option>\n                      <option value=\"P\">P - Programmabile</option>\n                  </select>\n              </div>\n          </div>\n\n          <div data-prescription-config=\"mopDoctor\" class=\"row\">\n              <label class=\"col-sm-4\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Prescription Doctor",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":113,"column":38},"end":{"line":113,"column":88}}})) != null ? stack1 : "")
    + "</label>\n              <div class=\"col-sm-8\">\n                  <div class=\"tt-input-container\">\n                    <input name=\"prescription_doctor\" type=\"text\" class=\"form-control tt-input\" id=\"prescription_doctor_value\">\n                  </div>\n              </div>\n          </div>\n\n          <div data-prescription-config=\"mopSummary\" class=\"row\">\n              <label class=\"col-sm-4\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Prescription Diagnosis",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":38},"end":{"line":122,"column":91}}})) != null ? stack1 : "")
    + "</label>\n              <div class=\"col-sm-8\">\n                  <textarea value=\"prescription_summary\" name=\"prescription_summary\" class=\"custom_fields_textarea form-control\"></textarea>\n              </div>\n          </div>\n          <!-- Prescription - Fine -->\n      </div>\n  </div>\n\n  <div class=\"tt-container\">\n      <button class=\"js-book-btn tt-button submit tt-button--primary tt-customer-book\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Deferred Resume Confirm",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":87},"end":{"line":132,"column":141}}})) != null ? stack1 : "")
    + "</button>\n  </div>\n</form>";
},"useData":true});