import MOP from "app";
import _ from 'underscore';

import { TTPolyglot } from "@frontend/tt-polyglot";

MOP.module("Common.Views", function (Views, MOP, Backbone, Marionette, $, _) {
  Views.Loading = Marionette.ItemView.extend({
    template: MOP.loadRightTemplate('common', "loading"),

    title: "",
    message: "",

    styleContainer: "height:200px; text-align:center; margin-bottom:200px; margin-top:130px;",

    lines: 13, // The number of lines to draw
    length: 20, // The length of each line
    width: 10, // The line thickness
    radius: 30, // The radius of the inner circle
    corners: 1, // Corner roundness (0..1)
    rotate: 0, // The rotation offset
    direction: 1, // 1: clockwise, -1: counterclockwise
    color: "#003366", // #rgb or #rrggbb
    speed: 1, // Rounds per second
    trail: 60, // Afterglow percentage
    shadow: false, // Whether to render a shadow
    hwaccel: false, // Whether to use hardware acceleration
    zIndex: 2e9, // The z-index (defaults to 2000000000)
    top: '20%', // Top position relative to parent
    left: 'auto', // Left position relative to parent

    initialize: function () {
      this.message = TTPolyglot.t("Searching") + "...";
    },

    serializeData: function () {
      return {
        title: Marionette.getOption(this, "title"),
        message: Marionette.getOption(this, "message")
      };
    },

    onShow: function () {
      return true;
      var opts = {
        lines: Marionette.getOption(this, "lines"),
        length: Marionette.getOption(this, "length"),
        width: Marionette.getOption(this, "width"),
        radius: Marionette.getOption(this, "radius"),
        corners: Marionette.getOption(this, "corners"),
        rotate: Marionette.getOption(this, "rotate"),
        direction: Marionette.getOption(this, "direction"),
        color: Marionette.getOption(this, "color"),
        speed: Marionette.getOption(this, "speed"),
        trail: Marionette.getOption(this, "trail"),
        shadow: Marionette.getOption(this, "shadow"),
        hwaccel: Marionette.getOption(this, "hwaccel"),
        className: Marionette.getOption(this, "className"),
        zIndex: Marionette.getOption(this, "zIndex"),
        top: Marionette.getOption(this, "top"),
        left: Marionette.getOption(this, "left")
      };
      $("#mop_loading_container").attr('style', Marionette.getOption(this, "styleContainer"));
      $("#mop_loading").spin(opts);
    }
  });

  Views.Alert = Marionette.ItemView.extend({
    template: MOP.loadRightTemplate('common', "alert"),

    events: {
      'click #change_search': 'changeSearch',
      'click #reload_page': 'reloadPage',
      'click #vcodeLink': 'goToVcodePage',
      'click .pageLink': 'goToPage',
      'click .js-alert-close': 'closeAlert',
    },

    onShow: function () {
      MOP.TM.scrollTop();
    },

    serializeData: function () {
      
      if (Marionette.getOption(this, 'showChangeSearch') || this.model.has('showChangeSearch')) {
        this.model.set('changeSearch', TTPolyglot.t('Search Again'));
      }

      if (Marionette.getOption(this, 'showReloadPage') || this.model.has('showReloadPage')) {
        this.model.set('reloadPage', TTPolyglot.t('Try now'));
      }

      if(Marionette.getOption(this, 'hideCloseAlertButton')) {
        this.model.set('hideCloseAlertButton', Marionette.getOption(this, 'hideCloseAlertButton'));
      }

      return this.model.toJSON();
    },

    reloadPage: function () {
      MOP.execute('MOP:clearAlert');
      window.location.reload();
    },

    changeSearch: function (e) {
      e.preventDefault();

      if (MOP.Utilities.empty(MOP.config.getInstanceConfig('overridechangeSearchBannerUrl'))) {
        MOP.execute('MOP:clearAlert');

        //Mostro manualmente main-region in quanto nascosta dall'Alert (la sezione viene caricata ma rimane hidden)
        //Imposto timeout per non sovrapporre effetto fadeout dell'Alert (default 400ms) 
        setTimeout(() => {
          // E' stata forzata la visualizzazione della main-region in tal modo perchè la visibiltià del div era stata impostata 
          // tramite unblockLoading ed è stato deciso di non modificare le logiche centralizzate del MOP
          MOP.TM.updateVisibility({show: [$('#main-region')]});

          if(MOP.isSearchPage(MOP.getCurrentPage())){
            if ((!MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage')) && !MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage')['search'])) || 
                  !MOP.Utilities.empty(MOP.querystring['enableNewSearch'])) {
              MOP.trigger('search:reset');
            } else {
              MOP.trigger('search:change', undefined, undefined, MOP.config.isAllowMultipleReservationsEnabled());
            }
          }else{
            MOP.changePage('search');
          }
        }, 500); 
      } else {
        window.location.href = MOP.config.getInstanceConfig('overridechangeSearchBannerUrl');
      }
    },

    goToVcodePage: function () {
      MOP.changePage('login', 'vcode');
    },

    goToPage: function (e) {
      MOP.TM.stopPropagation(e);
      MOP.CVM.goToPage(e);
    },

    closeAlert: function () {
      MOP.execute('MOP:clearAlert');
    }
  });

  Views.Dialog = Marionette.ItemView.extend({
    template: MOP.loadRightTemplate('common', "dialog")
  });

  Views.NativeDialog = Marionette.ItemView.extend({
    template: MOP.loadRightTemplate('common', "native_dialog"),

    events: {
      'click .dismiss': 'destroyDialog'
    },

    serializeData: function () {
      return this.model;
    },

    destroyDialog: function (e) {

      var $target = $(e.target);
      var url = $target.attr('open-link');

      MOP.execute('MOP:dialog:destroy');

      if (!MOP.Utilities.empty(this.model.external)) {
        window.open(url, '_system');
      }

    }
  });

  Views.LoadingAdv = Marionette.ItemView.extend({
    template: MOP.loadRightTemplate('common', "loading_advertising"),

    serializeData: function () {
      return Views.view_functions.getAdvertisers();
    },

    onShow: function () {
      MOP.TM.scrollTop();
      return Views.view_functions.startAdvLoading();
    },

    onDestroy: function () {
      return Views.view_functions.stopAdvLoading();
    }
  });
});

MOP.Common.Views.view_functions = (function ($) {
  return {
    timeoutID: 0,

    startAdvLoading: function (current) {
      var advsElem = $('.js-adv-elem');
      current = current || 0;

      this.timeoutID = setTimeout(function () {
        var nextElem = (current == advsElem.length - 1) ? 0 : current + 1;
        MOP.TM.hide(advsElem[current]);
        MOP.TM.show(advsElem[nextElem]);
        MOP.Common.Views.view_functions.startAdvLoading(nextElem);
      }, 10000);
    },

    stopAdvLoading: function () {
      clearTimeout(this.timeoutID);
    },

    /**
     * Return an array containing the mopLoadingAdvertising values
     * @return {Array}
     */
    getAdvertisers: (function () {
      var advertiser = [];

      return function () {
        if (advertiser.length === 0) {
          advertiser = _.reduce([1, 2, 3], function (acc, index) {
            /**
             * var item {Object} - singolo config mopLoadingAdvertising
             * item.title {String} - title of the advertisement
             * item.text {String} - text of the advertisement
             * item.image {String} - image of the advertisement
             * item.link {String} - link of the advertisement
             */
            var item = MOP.config.getInstanceConfig('mopLoadingAdvertising' + index);

            (!MOP.Utilities.empty(item.image) || !MOP.Utilities.empty(item.title) || !MOP.Utilities.empty(item.text) || !MOP.Utilities.empty(item.link)) ? acc.push(item) : true;

            return acc;
          }, []);
        }
        return advertiser;
      };
    })(),

    /**
     * In base alle options passate costruisce la vista relativa al loading
     * normale o il loading contenente gli adv configurati dall'utente.
     * @param  {Object} loadingOptions
     * @return {Object} - MOP.Common.Views.LoadingAdv|MOP.Common.Views.Loading
     */
    loadingFactory: function (loadingOptions) {
      if (this.getAdvertisers().length > 0) {
        return new MOP.Common.Views.LoadingAdv();
      } else {
        return new MOP.Common.Views.Loading(loadingOptions);
      }
    },

    loadingAppDialog: function (dialog_content) {
      return new MOP.Common.Views.NativeDialog({
        model: dialog_content
      });
    }
  };
})(jQuery);

export default MOP.Common.Views;