var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"selectedAvl") : depth0)) != null ? lookupProperty(stack1,"text") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"tt-card-info\">\n        <div class=\"tt-card-info__title\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"selectedAvl") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "</div>\n      </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tt-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selectedAvl") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":8,"column":9}}})) != null ? stack1 : "")
    + "</div>\n  <div id=\"loginContainer\" class=\"external_login\">\n    <br>\n    <form role=\"form\">\n      <div class=\"tt-container\">\n        <button id=\"externalLoginBtn\" type=\"submit\" class=\"tt-button tt-button--primary\">\n          "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop External Login Button",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":10},"end":{"line":15,"column":66}}})) != null ? stack1 : "")
    + "\n        </button>\n        <br>\n          <div class=\"tt-text-right\">\n              <div id=\"externalRegisterBtn\">\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"signupLink") || (depth0 != null ? lookupProperty(depth0,"signupLink") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"signupLink","hash":{},"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":20,"column":32}}}) : helper))) != null ? stack1 : "")
    + "\n              </div>\n          </div>\n      </div>\n    </form>\n    <div class=\"tt-separator\">\n      <span class=\"tt-separator__text tt-separator__text--mobile\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Or",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":66},"end":{"line":26,"column":99}}})) != null ? stack1 : "")
    + "</span>\n    </div>\n    <div class=\"tt-container\">\n      <a class=\"js-GoToPage tt-avoid-inside-events tt-button tt-button--secondary tt-button-login\" href=\"#\" role=\"button\" data-target-page=\"login\" data-target-hash=\"forced\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Goto One Time Registration",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":173},"end":{"line":29,"column":230}}})) != null ? stack1 : "")
    + "</a>\n    </div>\n  </div>\n\n</div>";
},"useData":true});