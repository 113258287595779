var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"modal-body\">\n             <div class=\"tt-card-info tt-card-info--small tt-card-info--row\">\n                <div class=\"tt-card-info__icon tt-icon-info-circle\"></div>\n                <div class=\"tt-card-info__content\">\n                  <div class=\"tt-card-info__text\">\n                    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"msg") || (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\n                  </div>\n                </div>\n              </div>\n            </div>\n            <div class=\"modal-footer\">\n              <div class=\"tt-flexend-container tt-dialog-button\">\n                <button type=\"button\" class=\"tt-button tt-button--secondary\" data-dismiss=\"modal\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Callbacks Reason No",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":98},"end":{"line":16,"column":148}}})) != null ? stack1 : "")
    + "</button>\n                <button type=\"button\" class=\"tt-button tt-button--primary\" id=\"yes_click_modal\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Show Neighbour Availabilities",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":96},"end":{"line":17,"column":160}}})) != null ? stack1 : "")
    + "</button>\n              </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});