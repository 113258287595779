var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<br><strong>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"insurance_title") || (depth0 != null ? lookupProperty(depth0,"insurance_title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"insurance_title","hash":{},"data":data,"loc":{"start":{"line":6,"column":123},"end":{"line":6,"column":142}}}) : helper)))
    + "</strong>";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"tt-container\">\n					<div class=\"tt-admin-availabilities-key tt-margin-20\">\n							<div class=\"tt-admin-availabilities-key-item\">\n									<div class=\"tt-admin-availabilities-key-item__color\"></div>\n									<div class=\"tt-admin-availabilities-key-item__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Regular Availabilities Slot",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":61},"end":{"line":14,"column":123}}})) != null ? stack1 : "")
    + "</div>\n							</div>\n							<div class=\"tt-admin-availabilities-key-item tt-admin-availabilities-key-item--suggest\">\n									<div class=\" tt-admin-availabilities-key-item__color\"></div>\n									<div class=\"tt-admin-availabilities-key-item__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Risky Availabilities Slot",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":61},"end":{"line":18,"column":121}}})) != null ? stack1 : "")
    + "</div>\n							</div>\n					</div>\n			</div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-container insurance_block\">\n            <div class=\"js-comparison-insurance-result\" "
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"firstAvailabilityComparisonInsurance") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":56},"end":{"line":25,"column":139}}})) != null ? stack1 : "")
    + ">\n                <strong>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Compare Insurance With",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":26,"column":81}}})) != null ? stack1 : "")
    + " <span class=\"js-comparison-insurance-result-title\">"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"firstAvailabilityComparisonInsurance") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "</span></strong>\n                <div class=\"tt-container\">\n                    <li>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Compare Insurance Price",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":24},"end":{"line":28,"column":82}}})) != null ? stack1 : "")
    + " <strong class=\"js-comparison-insurance-result-price\">"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"firstAvailabilityComparisonInsurance") : depth0)) != null ? lookupProperty(stack1,"price") : stack1), depth0))
    + "</strong></li>\n                    <li>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Compare Insurance First Availability",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":24},"end":{"line":29,"column":95}}})) != null ? stack1 : "")
    + " <strong class=\"js-comparison-insurance-result-date\">"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"firstAvailabilityComparisonInsurance") : depth0)) != null ? lookupProperty(stack1,"start_date") : stack1), depth0))
    + "</strong></li>\n                    <li class=\"js-comparison-insurance-result-resources\">"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"firstAvailabilityComparisonInsurance") : depth0)) != null ? lookupProperty(stack1,"resources") : stack1), depth0))
    + "</li>\n                </div>\n                <button class=\"js-button-show-comparison-insurance tt-button tt-button--primary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Compare Insurance Goto Availabilities",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":97},"end":{"line":32,"column":169}}})) != null ? stack1 : "")
    + "</button>\n            </div>\n        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"tt-column\">\n                <a href=\"javascript:\" class=\"tt-button tt-button--paginator tt-button--primary js-prev-results\" role=\"button\">\n                    <i class=\"tt-button__icon tt-button__icon--left tt-icon-angle-left\"></i>\n                    <span class=\"tt-button__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Previous",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":50},"end":{"line":44,"column":89}}})) != null ? stack1 : "")
    + "</span>\n                </a>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"admin-availabilities-container\" class=\"operator_availabilities\">\n    <a class=\"tt-button tt-button--operator-copy\" href=\"javascript:\" id=\"copySearch\"><span class=\"tt-icon-copy light\"></span></a>\n    <div class=\"tt-container tt-grey-border tt-text-center\">\n        <div class=\"tt-label-container\">\n            <div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"App Your Search",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":34},"end":{"line":5,"column":80}}})) != null ? stack1 : "")
    + "</div>\n            <div class=\"tt-label tt-label--middle\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"typologyTitle") || (depth0 != null ? lookupProperty(depth0,"typologyTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typologyTitle","hash":{},"data":data,"loc":{"start":{"line":6,"column":51},"end":{"line":6,"column":68}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"activityTitle") || (depth0 != null ? lookupProperty(depth0,"activityTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityTitle","hash":{},"data":data,"loc":{"start":{"line":6,"column":71},"end":{"line":6,"column":88}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"insurance_title") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":88},"end":{"line":6,"column":158}}})) != null ? stack1 : "")
    + "</div>\n        </div>\n    </div>\n"
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"promoteSlotMinRiskPercent","!=",100,{"name":"checkConfig","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":2},"end":{"line":22,"column":18}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showInsuranceComparison") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":35,"column":11}}})) != null ? stack1 : "")
    + "</div>\n\n<div class=\"tt-container\">\n    <div class=\"tt-columns is-mobile\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPrevResults") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":8},"end":{"line":47,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"tt-column\">\n            <a href=\"javascript:\" class=\"tt-button tt-button--paginator tt-button--primary js-next-results\" role=\"button\">\n                <i class=\"tt-button__icon tt-button__icon--right tt-icon-angle-right\"></i>\n                <span class=\"tt-button__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Next",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":46},"end":{"line":51,"column":81}}})) != null ? stack1 : "")
    + "</span>\n            </a>\n        </div>\n    </div>\n</div>";
},"useData":true});