var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    return "";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"tt-container\">\n    <div class=\"js-password-rules-container tt-password-hints\">\n      "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"Mop Password Should Contain",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":9,"column":64}}})) != null ? stack1 : "")
    + "\n      <ul class=\"tt-password-hints-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"pwdRules") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":13,"column":17}}})) != null ? stack1 : "")
    + "      </ul>\n    </div>\n  </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li class=\"tt-password-hints-list__item\" pwd-rule-key="
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":12,"column":64},"end":{"line":12,"column":72}}}) : helper)))
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":12,"column":73},"end":{"line":12,"column":82}}}) : helper)))
    + "</li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tt-input-container tt-input-container--password js-password-input-container tt-input-container--required\">\n    <input data-e2eid=\"password-input\" class=\"tt-input tt-no-margin js-toggle-password-visibility-input js-password-input\" autocomplete=\"new-password\" type=\"password\" name=\"password\" placeholder=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"password_placeholder") || (depth0 != null ? lookupProperty(depth0,"password_placeholder") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"password_placeholder","hash":{},"data":data,"loc":{"start":{"line":2,"column":196},"end":{"line":2,"column":220}}}) : helper)))
    + "\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"cannot_edit") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":222},"end":{"line":2,"column":271}}})) != null ? stack1 : "")
    + ">\n    <div data-e2eid=\"password-loader\" class=\"tt-deprecated-loader js-password-strength-check-loader\" style=\"display: none;\"></div>\n    <a href=\"javascript:;\" data-e2eid=\"show-hide-password\" class=\"tt-input-container--password-text js-toggle-password-visibility-btn\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Show",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":135},"end":{"line":4,"column":170}}})) != null ? stack1 : "")
    + "</a>\n</div>\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"pwdRules") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),"!=",0,{"name":"ifCond","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":17,"column":11}}})) != null ? stack1 : "");
},"useData":true});