var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"loginAddress") || (depth0 != null ? lookupProperty(depth0,"loginAddress") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"loginAddress","hash":{},"data":data,"loc":{"start":{"line":12,"column":112},"end":{"line":12,"column":128}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tt-label\">\n    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"This will change your password to a new, randomly generated one.",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":99}}})) != null ? stack1 : "")
    + "\n</div>\n\n<form role=\"form\">\n	<div class=\"tt-container\">\n		<div class=\"tt-form-divider\">\n			<span class=\"tt-hide\" data-compulsory=\"mobile\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Loginpanel Username",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":50},"end":{"line":8,"column":104}}})) != null ? stack1 : "")
    + "</span>\n			<span class=\"tt-hide\" data-compulsory=\"email\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Loginpanel Username",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":49},"end":{"line":9,"column":103}}})) != null ? stack1 : "")
    + "</span>\n		</div>\n		<div class=\"tt-input-container\">\n			<input id=\"username\" class=\"tt-input tt-input-border\" name=\"username\" type=\"text\" value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loginAddress") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":92},"end":{"line":12,"column":135}}})) != null ? stack1 : "")
    + "\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Forgot Media Label",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":150},"end":{"line":12,"column":199}}})) != null ? stack1 : "")
    + "\">\n		</div>\n	</div>\n	<div class=\"tt-alert tt-customer-alert\">\n			<div class=\"tt-hide\" data-compulsory=\"mobile\">\n					"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Password will be sent by sms",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":5},"end":{"line":17,"column":64}}})) != null ? stack1 : "")
    + "\n			</div>\n			<div class=\"tt-hide\" data-compulsory=\"email\">\n					"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Password will be sent by email",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":5},"end":{"line":20,"column":66}}})) != null ? stack1 : "")
    + "\n			</div>\n	</div>\n\n    <div class=\"tt-container\">\n        <button type=\"button\" class=\"tt-button tt-button--login tt-button--primary\">\n            "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Change Password",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":26,"column":58}}})) != null ? stack1 : "")
    + "\n        </button>\n    </div>\n</form>\n";
},"useData":true});