import _ from 'underscore';
import Backbone from 'backbone-shim';
	
	export default Backbone.Model.extend({
	
		initialize: function(){
			//Extend your model with the Selectable instance to make your model selectable directly.
			var selectable = new Backbone.Picky.Selectable(this);
			_.extend(this, selectable);

			this.on("change:" + this.getIdFieldName(), 
					function(model, value, options){
						this.set('id', value);
					}, 
					this);
		},
		
		/*
		 * to implement in concrete classes...
		 */
		fetch_fn: null,
		
		/*
		 * to implement in concrete classes...
		 */
		getIdFieldName : function() {
			return "id";
		},
		
		parse: function(data){
			data = data || {};
			
			var entity_data = {};
			
			// data from ajax json response
			if( _.has(data, 'return') ){
				if( data['result'] == 'OK' && data['return'] ){
					entity_data = data['return']['results'] ? data['return']['results'] : data['return'];
					this.specializeParseFromAjaxResponse( data, entity_data );
				}
			// otherwise will contain one of the items returned from the collection's 'parse' function 
			}else{
				entity_data = data;
			}
			
			if( !(entity_data['id']) ){
				entity_data['id'] = entity_data[this.getIdFieldName()];
			}
			
			this.specializeParse( entity_data );
			
		    return entity_data;    
		},

		fetch: function () {
			var _this = this;

			return this.constructor.staticFetch.apply(this, arguments);
		},
		
		/*
		 * to implement in concrete classes...
		 */
		specializeParseFromAjaxResponse : function( data, entity_data ) {

		},
		
		/*
		 * to implement in concrete classes...
		 */
		specializeParse : function( entity_data ) {

		}
	}, {
		staticFetch: function (MOP, params) {
			var deferred = new $.Deferred();

			params.ajaxFunction = Backbone.Model.prototype.fetch;
			params.fetch_fn = this.fetch_fn || params.fetch_fn;
			
			if (this.searchPath || params.searchPath) {
				
				if (!params.searchPath) {
					params.searchPath = this.searchPath;
				}
				
				var request_options = {};
				
				if (params.ajax && params.ajax.options) {
					request_options = params.ajax.options;
				} else if (!params.ajax) {
					params.ajax = {};
				}
				
				var ajax_options = {
					method: 'GET'
				}
				
				// L'extend fa il merge di un solo livello non mergia oggetti innestati, 
				// quindi ho estrapolato solo options invece che tutto ajax
				params.ajax.options = _.extend(ajax_options, request_options);
			}
			
			MOP.Utilities.ajax(MOP, params, this)
				.done(function (model, resp) {
					try {
						
						MOP.checkAjaxResponse(resp);

						deferred.resolve(model, resp);
					} catch (e) {
						MOP.Utilities.log(MOP, e);

						if (params.preventThrowException) {
							deferred.reject(resp, e);

							return;
						}

						throw e;
		            }
						}).fail(function (resp) {
              if(resp === MOP.constants.AJAX_OFFLINE_REJECT) return;
              try {
                  if (!params.bypassCheckResponse) {
                      MOP.checkAjaxResponse(resp);
                  }
                  return deferred.resolve(resp);
              } catch (e) {
                  MOP.Utilities.log(MOP, e);
                  
                  if (params.preventThrowException) {
                      deferred.reject();
                  }

                  throw e;
              }
            });

	        return deferred;
		}
	});