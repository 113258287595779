var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "tt-container";
},"3":function(container,depth0,helpers,partials,data) {
    return "tt-columns";
},"5":function(container,depth0,helpers,partials,data) {
    return "tt-column is-8 is-offset-2";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "            <div class=\"tt-form-divider\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Privacy Communication Policy Title",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":41},"end":{"line":6,"column":110}}})) != null ? stack1 : "")
    + "</div>\n            <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":24},"end":{"line":7,"column":63}}})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":64},"end":{"line":7,"column":102}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Privacy Communication Policy Desc",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":104},"end":{"line":7,"column":172}}})) != null ? stack1 : "")
    + "</div>\n            <div class=\"tt-list\">\n              <div class=\"tt-input-checkbox-container tt-deprecated-input-checkbox-container tt-mb-list-item-space\" data-nested-page=\"first\">\n                <li class=\"tt-checkbox\">\n                  <label class=\"tt-checkbox__label\">\n                      <input data-e2eid=\"advanced-options-privacy-checkbox\" class=\"tt-checkbox__label-input\" name=\"\" type=\"checkbox\" checked disabled>\n                  </label>\n                  <div class=\"tt-checkbox__text \">\n                    <div>\n                      <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"privacyLabelPre") || (depth0 != null ? lookupProperty(depth0,"privacyLabelPre") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"privacyLabelPre","hash":{},"data":data,"loc":{"start":{"line":16,"column":28},"end":{"line":16,"column":47}}}) : helper)))
    + "</span>\n                      <span class=\"tt-text-link-action tt-pointer js-privacy-policy\" data-e2eid=\"advanced-options-privacy-link\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Open Privacy Dialog",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":128},"end":{"line":17,"column":182}}})) != null ? stack1 : "")
    + "</span>\n                      "
    + ((stack1 = (lookupProperty(helpers,"isNotEmpty")||(depth0 && lookupProperty(depth0,"isNotEmpty"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"privacyLabelPost") : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":22},"end":{"line":18,"column":102}}})) != null ? stack1 : "")
    + "\n                    </div>\n                  </div>\n                </li>\n              </div>\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"showPrivacyPromotion") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":14},"end":{"line":35,"column":25}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"showPrivacySurvey") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":14},"end":{"line":48,"column":25}}})) != null ? stack1 : "")
    + "              <div id=\"custom_fields_privacy_container\">\n              </div>\n            </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "";
},"10":function(container,depth0,helpers,partials,data) {
    return "tt-pb-20";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"privacyLabelPost") || (depth0 != null ? lookupProperty(depth0,"privacyLabelPost") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"privacyLabelPost","hash":{},"data":data,"loc":{"start":{"line":18,"column":60},"end":{"line":18,"column":80}}}) : helper)))
    + "</span>";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"tt-input-checkbox-container tt-deprecated-input-checkbox-container tt-mb-list-item-space\" data-nested-page=\"first\">\n                <li class=\"tt-checkbox\" data-config=\"mopPrivacyPromotion\">\n                  <label class=\"tt-checkbox__label\">\n                    <div class=\"tt-deprecated-loader tt-deprecated-loader--xsmall js-privacy_promotions-loader\" style=\"display: none;\"></div>\n                    <input data-e2eid=\"advanced-options-privacy-promotions-checkbox\" class=\"tt-checkbox__label-input js-privacy_promotions-input\" name=\"privacy_promotions\" type=\"checkbox\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"privacy_promotions") : depth0),"==","1",{"name":"ifCond","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":188},"end":{"line":28,"column":258}}})) != null ? stack1 : "")
    + ">\n                  </label>\n                  <div class=\"tt-checkbox__text\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"MOP Privacy2 Checkbox",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":31,"column":72}}})) != null ? stack1 : "")
    + "\n                  </div>\n                </li>\n              </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "value=\"true\" checked";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"tt-input-checkbox-container tt-deprecated-input-checkbox-container tt-mb-list-item-space\" data-nested-page=\"first\">\n                <li class=\"tt-checkbox\" data-config=\"mopPrivacySurvey\">\n                  <label class=\"tt-checkbox__label\">\n                      <div class=\"tt-deprecated-loader tt-deprecated-loader--xsmall js-privacy_review-loader\" style=\"display: none;\"></div>\n                      <input data-e2eid=\"advanced-options-privacy-review-checkbox\" class=\"tt-checkbox__label-input js-privacy_review-input\" name=\"privacy_review\" type=\"checkbox\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"privacy_review") : depth0),"==","1",{"name":"ifCond","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":178},"end":{"line":41,"column":231}}})) != null ? stack1 : "")
    + ">\n                  </label>\n                  <div class=\"tt-checkbox__text\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Privacy Survey Checkbox",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":20},"end":{"line":44,"column":78}}})) != null ? stack1 : "")
    + "\n                  </div>\n                </li>\n              </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "checked";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "          <div class=\"tt-list\">\n            <div class=\"tt-form-divider\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Notifications Settings",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":41},"end":{"line":56,"column":98}}})) != null ? stack1 : "")
    + "</div>\n            <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":24},"end":{"line":57,"column":63}}})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":64},"end":{"line":57,"column":102}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Choose Media",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":104},"end":{"line":57,"column":151}}})) != null ? stack1 : "")
    + "</div>\n\n            <div class=\"tt-switch tt-switch-reverse tt-container-mb-10\">\n              <input id=\"profileFormSwitchMobile\" type=\"checkbox\" name=\"wants_sms\" data-config-edit=\"wants_sms\" />\n              <label for=\"profileFormSwitchMobile\" class=\"js-wants_sms-input\"></label>\n              <span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mobile",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":20},"end":{"line":62,"column":57}}})) != null ? stack1 : "")
    + "</span>\n            </div>\n\n            <div class=\"tt-switch tt-switch-reverse tt-container-mb-10\">\n              <input id=\"profileFormSwitchEmail\" type=\"checkbox\" name=\"wants_email\" data-config-edit=\"wants_email\" />\n              <label for=\"profileFormSwitchEmail\" class=\"js-wants_email-input\"></label>\n              <span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Email",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":20},"end":{"line":68,"column":56}}})) != null ? stack1 : "")
    + "</span>\n            </div>\n\n            <div class=\"tt-switch tt-switch-reverse tt-container-mb-10\">\n              <input id=\"profileFormSwitchPhone\" type=\"checkbox\" name=\"wants_voice\" data-config-edit=\"wants_voice\" />\n              <label for=\"profileFormSwitchPhone\" class=\"js-wants_voice-input\"></label>\n              <span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Phone",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":20},"end":{"line":74,"column":56}}})) != null ? stack1 : "")
    + "</span>\n            </div>\n          </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "            <div class=\"tt-form-divider\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Privacy Dossier Title",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":41},"end":{"line":80,"column":97}}})) != null ? stack1 : "")
    + "</div>\n            <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":24},"end":{"line":81,"column":63}}})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":64},"end":{"line":81,"column":102}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Privacy Dossier Desc",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":104},"end":{"line":81,"column":159}}})) != null ? stack1 : "")
    + "</div>\n\n            <div class=\"tt-list\">\n              <div class=\"tt-input-checkbox-container tt-deprecated-input-checkbox-container tt-mb-list-item-space\" data-nested-page=\"first\">\n                <li class=\"tt-checkbox\">\n                  <label class=\"tt-checkbox__label\">\n                      <input class=\"tt-checkbox__label-input js-privacy_dossier-input\" name=\"\" type=\"checkbox\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPrivacyDossierToggleActive") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":111},"end":{"line":87,"column":161}}})) != null ? stack1 : "")
    + " >\n                  </label>\n                  <div class=\"tt-checkbox__text\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Privacy Dossier Checkbox Text",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":20},"end":{"line":90,"column":84}}})) != null ? stack1 : "")
    + "\n                  </div>\n                </li>\n              </div>\n            </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"personalHealthRecord","==",1,{"name":"checkConfig","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":12},"end":{"line":110,"column":26}}})) != null ? stack1 : "")
    + "          <div class=\"tt-form-divider\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Privacy Remove Appointments Title",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":111,"column":39},"end":{"line":111,"column":107}}})) != null ? stack1 : "")
    + "</div>\n          <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":22},"end":{"line":112,"column":61}}})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":62},"end":{"line":112,"column":100}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Privacy Remove Appointments Description",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":102},"end":{"line":112,"column":176}}})) != null ? stack1 : "")
    + "</div>\n          <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":113,"column":22},"end":{"line":113,"column":61}}})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":113,"column":62},"end":{"line":113,"column":100}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n            <button type=\"button\" class=\"tt-button tt-button--danger\" id=\"hide_history_partial\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disablePartialBtn") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":96},"end":{"line":114,"column":136}}})) != null ? stack1 : "")
    + ">\n              "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Privacy Remove Appointments Button",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":14},"end":{"line":115,"column":83}}})) != null ? stack1 : "")
    + "\n            </button>\n          </div>\n          <div class=\"tt-form-divider\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Privacy Remove Account Title",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":118,"column":39},"end":{"line":118,"column":102}}})) != null ? stack1 : "")
    + "</div>\n          <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":119,"column":22},"end":{"line":119,"column":61}}})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":119,"column":62},"end":{"line":119,"column":100}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Privacy Remove Account Description",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":119,"column":102},"end":{"line":119,"column":171}}})) != null ? stack1 : "")
    + "</div>\n          <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":22},"end":{"line":120,"column":61}}})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":62},"end":{"line":120,"column":100}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n            <button type=\"button\" class=\"tt-button tt-button--danger\" id=\"hide_history_full\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disableFullBtn") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":93},"end":{"line":121,"column":130}}})) != null ? stack1 : "")
    + ">\n              "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Privacy Remove Account Button",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":14},"end":{"line":122,"column":78}}})) != null ? stack1 : "")
    + "\n            </button>\n          </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "            <div class=\"tt-form-divider\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Menu Dossier",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":41},"end":{"line":99,"column":84}}})) != null ? stack1 : "")
    + "</div>\n            <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":24},"end":{"line":100,"column":63}}})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":64},"end":{"line":100,"column":102}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Privacy Dossier",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":104},"end":{"line":100,"column":154}}})) != null ? stack1 : "")
    + "</div>\n            <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":24},"end":{"line":101,"column":63}}})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":64},"end":{"line":101,"column":102}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n              <button type=\"button\" class=\"tt-button tt-button--danger\" id=\"hide_history_dossier\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disableDossierBtn") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":98},"end":{"line":102,"column":138}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDossierActive") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":103,"column":16},"end":{"line":107,"column":23}}})) != null ? stack1 : "")
    + "              </button>\n            </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Mop Privacy Dossier Disable",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":104,"column":18},"end":{"line":104,"column":76}}})) != null ? stack1 : "")
    + "\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Mop Privacy Dossier Deactivated",{"name":"translate","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":18},"end":{"line":106,"column":80}}})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"advanced_options ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":29},"end":{"line":1,"column":67}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n  <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":55}}})) != null ? stack1 : "")
    + "\">\n    <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":16},"end":{"line":3,"column":73}}})) != null ? stack1 : "")
    + "\">\n        <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPrivacySection") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":10},"end":{"line":52,"column":17}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showProfileNotificationSettings") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":8},"end":{"line":77,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPrivacyDossierBiometrics") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":10},"end":{"line":95,"column":17}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"enableOblivion","==",1,{"name":"checkConfig","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":10},"end":{"line":125,"column":26}}})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});