var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"tt-alert tt-customer-alert\">\n      <div>\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"summaryAvailabilityAdditionalInfo") || (depth0 != null ? lookupProperty(depth0,"summaryAvailabilityAdditionalInfo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"summaryAvailabilityAdditionalInfo","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":47}}}) : helper))) != null ? stack1 : "")
    + "\n      </div>\n  </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-label-container\">     \n                    <div class=\"tt-label tt-label--middle appointment_service tt-mb-10\" data-nested-page=\"first\">\n                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"typologyTitle") || (depth0 != null ? lookupProperty(depth0,"typologyTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typologyTitle","hash":{},"data":data,"loc":{"start":{"line":18,"column":24},"end":{"line":18,"column":41}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"activityTitle") || (depth0 != null ? lookupProperty(depth0,"activityTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityTitle","hash":{},"data":data,"loc":{"start":{"line":18,"column":44},"end":{"line":18,"column":61}}}) : helper)))
    + "\n                    </div>\n                </div>\n                <div class=\"tt-availability-summary-info-mobile\" data-nested-page=\"first\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showResource") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(11, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":22,"column":19},"end":{"line":34,"column":26}}})) != null ? stack1 : "")
    + "                    <div class=\"tt-availability-summary-info-mobile__content\">\n                    <div class=\"tt-availability-summary-info-mobile__content-title\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"humanDateLongFormat")||(depth0 && lookupProperty(depth0,"humanDateLongFormat"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"start_date_day_week") : depth0),(depth0 != null ? lookupProperty(depth0,"start_date") : depth0),(depth0 != null ? lookupProperty(depth0,"start_date_month") : depth0),{"name":"humanDateLongFormat","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":20},"end":{"line":37,"column":116}}})) != null ? stack1 : "")
    + "\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"startTime") || (depth0 != null ? lookupProperty(depth0,"startTime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"startTime","hash":{},"data":data,"loc":{"start":{"line":38,"column":20},"end":{"line":38,"column":33}}}) : helper)))
    + "\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showResource") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":20},"end":{"line":42,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"showAreas") : depths[1]),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":20},"end":{"line":45,"column":27}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n\n\n                <div class=\"tt-hide\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"multiple_tmp_id") || (depth0 != null ? lookupProperty(depth0,"multiple_tmp_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"multiple_tmp_id","hash":{},"data":data,"loc":{"start":{"line":50,"column":41},"end":{"line":50,"column":60}}}) : helper)))
    + "_custom_fields_container\">\n                    <div class=\"tt-label\" data-nested-page=\"first\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Additional Info",{"name":"translate","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":67},"end":{"line":51,"column":113}}})) != null ? stack1 : "")
    + "</div>\n                    <div id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"multiple_tmp_id") || (depth0 != null ? lookupProperty(depth0,"multiple_tmp_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"multiple_tmp_id","hash":{},"data":data,"loc":{"start":{"line":52,"column":29},"end":{"line":52,"column":48}}}) : helper)))
    + "_custom_fields\" class=\"tt-container\"></div>\n                </div>\n                <br>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"resource_face_img_link") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":23,"column":11},"end":{"line":27,"column":18}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "												<img class=\"tt-availability-summary-info-mobile__avatar\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"resource_face_img_link") || (depth0 != null ? lookupProperty(depth0,"resource_face_img_link") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"resource_face_img_link","hash":{},"data":data,"loc":{"start":{"line":24,"column":74},"end":{"line":24,"column":100}}}) : helper)))
    + "\" />\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "												<div class=\"tt-availability-summary-info-mobile__avatar--default tt-icon-user-md\"></div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"area_face_img_link") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":29,"column":23},"end":{"line":33,"column":30}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                               <img class=\"tt-availability-summary-info-mobile__avatar\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"area_face_img_link") || (depth0 != null ? lookupProperty(depth0,"area_face_img_link") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"area_face_img_link","hash":{},"data":data,"loc":{"start":{"line":30,"column":93},"end":{"line":30,"column":115}}}) : helper)))
    + "\" />\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "                               <div class=\"tt-availability-summary-info-mobile__avatar--default tt-icon-hospital-alt\"></div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-availability-summary-info-mobile__content-text\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"resourceName") || (depth0 != null ? lookupProperty(depth0,"resourceName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"resourceName","hash":{},"data":data,"loc":{"start":{"line":41,"column":83},"end":{"line":41,"column":99}}}) : helper)))
    + "</div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-availability-summary-info-mobile__content-text\">"
    + ((stack1 = (lookupProperty(helpers,"formattedAreaTitle")||(depth0 && lookupProperty(depth0,"formattedAreaTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"webTitle") : depth0),(depth0 != null ? lookupProperty(depth0,"areaTitle") : depth0),{"name":"formattedAreaTitle","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":83},"end":{"line":44,"column":148}}})) != null ? stack1 : "")
    + "</div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div style=\"display:;\" data-nested-page=\"first\">\n            <div class=\"select-user-methods\">\n\n                <div class=\"tt-label\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"MOP Book For",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":20},"end":{"line":63,"column":63}}})) != null ? stack1 : "")
    + "\n                </div>\n                <div class=\"item\"> <!-- deve scomparire insieme alla input -->\n                <div class=\"tt-input-container\">\n                    <input class=\"form-control tt-input\" id=\"search_user_div\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Search Client Mop",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":91},"end":{"line":67,"column":139}}})) != null ? stack1 : "")
    + "\">\n                </div>\n                    <div class=\"tt-hide typehead-loading\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Searching",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":58},"end":{"line":69,"column":98}}})) != null ? stack1 : "")
    + " ...</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"recentResUser") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":20},"end":{"line":74,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n                <div id=\"patient_data_container\"></div>\n\n                <a href=\"#\" class=\"tt-action register_patient\" data-trigger=\"addPatient:click\">\n                    <span class=\"tt-action__icon tt-icon-user-plus\"></span>\n                    <div class=\"tt-action__content\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Register User",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":52},"end":{"line":80,"column":96}}})) != null ? stack1 : "")
    + "</div>\n                    <span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n                </a>\n\n                <div class=\"tt-hide\">\n                    <button type=\"button\" class=\"tt-button tt-button--secondary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Waiting List",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":81},"end":{"line":85,"column":124}}})) != null ? stack1 : "")
    + "</button>\n                    <button type=\"button\" class=\"tt-button tt-button--primary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Most Recent Patient",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":79},"end":{"line":86,"column":129}}})) != null ? stack1 : "")
    + "</button>\n                </div>\n            </div>\n\n            <div class=\"tt-hide\">\n                <h3 class=\"tt-deprecated-h3\">\n                    <button type=\"button\" class=\"tt-button-close\">\n                        <span aria-hidden=\"true\">&times;</span>\n                        <span class=\"tt-sr-only\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Close",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":49},"end":{"line":94,"column":85}}})) != null ? stack1 : "")
    + "</span>\n                    </button>\n                </h3>\n                <div class=\"choose_client\">\n                    <a href=\"#\" class=\"list-group-item\">Mario Rossi. +39335238229 - m.rossi@mail.it</a>\n                    <a href=\"#\" class=\"list-group-item\">Paolo Bianco, +32738233827376 - p.bianco@hotmail.com</a>\n                    <a href=\"#\" class=\"list-group-item\">Marina Burli, +32362883876 - m.burli@yahoo.it</a>\n                </div>\n            </div>\n\n        </div>\n\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a href=\"javascript:\" class=\"tt-button tt-button--operator tt-button--primary\" id=\"recent-user-btn\" style=\"margin-top:15px;\">\n                            "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Most Recent Patient",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":28},"end":{"line":72,"column":78}}})) != null ? stack1 : "")
    + "\n                        </a>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n        <div class=\"tt-label\" data-nested-page=\"first\">\n            "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"MOP Book For",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":12},"end":{"line":109,"column":55}}})) != null ? stack1 : "")
    + "\n        </div>\n\n        <div class=\"list-group choose_client\" data-nested-page=\"first\">\n            <div class=\"tt-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"reservationUsers") : depth0),{"name":"each","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":16},"end":{"line":128,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"allowFamilies") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":131,"column":12},"end":{"line":141,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-input-radio-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":53},"end":{"line":115,"column":87}}})) != null ? stack1 : "")
    + "\" data-trigger=\"user:selectByPatient\" data-trigger-param=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n\n                    <label class=\"tt-input-radio js-no-required-validation\">\n                        <input class=\"tt-input-radio__input\" type=\"radio\" name=\"group\" value=\"\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":118,"column":96},"end":{"line":118,"column":141}}})) != null ? stack1 : "")
    + ">\n                        <div class=\"tt-input-radio__content\">\n                            <div class=\"tt-input-radio__content-text\" data-trigger=\"user:selectByPatient\" data-trigger-param=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n                                "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n                            </div>\n                            <i class=\"tt-input-radio__content-icon tt-icon-check\"></i>\n                        </div>\n                    </label>\n                </div>\n\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "active";
},"27":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotOneTimeUser") || (depth0 != null ? lookupProperty(depth0,"isNotOneTimeUser") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isNotOneTimeUser","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":14},"end":{"line":140,"column":35}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isNotOneTimeUser")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-container\">\n                    <a href=\"javascript:\" class=\"tt-action\" data-trigger=\"addParent:click\">\n                        <span class=\"tt-action__icon tt-icon-user-plus\"></span>\n                        <div class=\"tt-action__content\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Add Relatives",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":136,"column":56},"end":{"line":136,"column":100}}})) != null ? stack1 : "")
    + "</div>\n                        <span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n                    </a>\n                </div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showContactFieldForm") : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":0},"end":{"line":163,"column":7}}})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n  <div class=\"tt-label js-summary-communication-container\" data-nested-page=\"first\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Resume Specitfy Contact",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":84},"end":{"line":149,"column":142}}})) != null ? stack1 : "")
    + "</div>\n  <div class=\"tt-container\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationPhone") : depth0),"!=",0,{"name":"ifCond","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":151,"column":4},"end":{"line":155,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationEmail") : depth0),"!=",0,{"name":"ifCond","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":156,"column":4},"end":{"line":160,"column":15}}})) != null ? stack1 : "")
    + "  </div>\n\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"tt-input-container tt-input-container--link tt-m-input "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationPhone") : depth0),"==",2,{"name":"ifCond","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":71},"end":{"line":152,"column":147}}})) != null ? stack1 : "")
    + "\">\n      <input class=\"tt-input js-summary-communication-phone\" name=\"communication_phone\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Specify Phone Placeholder",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":101},"end":{"line":153,"column":161}}})) != null ? stack1 : "")
    + "\" value="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"phone") || (depth0 != null ? lookupProperty(depth0,"phone") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"phone","hash":{},"data":data,"loc":{"start":{"line":153,"column":169},"end":{"line":153,"column":178}}}) : helper)))
    + ">\n    </div>\n";
},"35":function(container,depth0,helpers,partials,data) {
    return "tt-input-container--required";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"tt-input-container tt-input-container--link tt-m-input "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"communicationEmail") : depth0),"==",2,{"name":"ifCond","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":157,"column":71},"end":{"line":157,"column":147}}})) != null ? stack1 : "")
    + "\">\n      <input class=\"tt-input js-summary-communication-email\" name=\"communication_email\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Specify Email Placeholder",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":101},"end":{"line":158,"column":161}}})) != null ? stack1 : "")
    + "\" value="
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":158,"column":169},"end":{"line":158,"column":178}}}) : helper)))
    + ">\n    </div>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"tt-input-checkbox-container tt-deprecated-input-checkbox-container\" data-nested-page=\"first\">\n			<li class=\"tt-checkbox\">\n				<label class=\"tt-checkbox__label\">\n					<input class=\"tt-checkbox__label-input\" type=\"checkbox\" id=\"reservation_privacy\" autocomplete=\"off\" name=\"privacy\">\n				</label>\n				<div class=\"tt-checkbox__text\" id=\"privacy_url\">\n					"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Privacy Member","conf:title",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":175,"column":5},"end":{"line":175,"column":63}}})) != null ? stack1 : "")
    + "\n				</div>\n			</li>\n		</div>\n\n";
},"41":function(container,depth0,helpers,partials,data) {
    return "hide";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"summaryAvailabilityAdditionalInfo") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":7,"column":7}}})) != null ? stack1 : "")
    + "\n<form>\n    <div class=\"list summary_container\">\n        <div class=\"appointment_block\">\n            <div class=\"tt-label-container\">\n                <div class=\"tt-label\" data-nested-page=\"first\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"App Your Search",{"name":"translate","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":63},"end":{"line":13,"column":109}}})) != null ? stack1 : "")
    + "</div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"availabilities") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":55,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isAdminLoggedIn") || (depth0 != null ? lookupProperty(depth0,"isAdminLoggedIn") : depth0)) != null ? helper : alias2),(options={"name":"isAdminLoggedIn","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.program(23, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":58,"column":4},"end":{"line":144,"column":20}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isAdminLoggedIn")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotAdminLoggedIn") || (depth0 != null ? lookupProperty(depth0,"isNotAdminLoggedIn") : depth0)) != null ? helper : alias2),(options={"name":"isNotAdminLoggedIn","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":146,"column":0},"end":{"line":164,"column":23}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNotAdminLoggedIn")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n  <!-- Tolta gestione prescription -->\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPortalInstancePrivacy") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":168,"column":2},"end":{"line":180,"column":9}}})) != null ? stack1 : "")
    + "\n        <div class=\"tt-container\" data-nested-page=\"first\">\n    <button type=\"button\" class=\"tt-button js-book-btn submit tt-button--primary tt-customer-book ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isAdminLoggedIn") || (depth0 != null ? lookupProperty(depth0,"isAdminLoggedIn") : depth0)) != null ? helper : alias2),(options={"name":"isAdminLoggedIn","hash":{},"fn":container.program(41, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":183,"column":98},"end":{"line":183,"column":142}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isAdminLoggedIn")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\" data-submit-origin=\"0\">\n      "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Book Button",{"name":"translate","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":184,"column":6},"end":{"line":184,"column":52}}})) != null ? stack1 : "")
    + "\n    </button>\n   <!-- <a class=\"back_to_availabilities\" href=\"#\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Back",{"name":"translate","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":186,"column":51},"end":{"line":186,"column":86}}})) != null ? stack1 : "")
    + "</a> -->\n  </div>\n    </div>\n</form>\n";
},"useData":true,"useDepths":true});