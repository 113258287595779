import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as style from "./style.js";

const TEXTAREA_MAX_LENGTH = 1000; // Sul BE c'è un limite di salvataggio di 1000 caratteri per le textArea

export const TextArea = forwardRef(({ placeholder = null, value = null, onChange = null, disabled = false }, ref) => {

  const [val, setVal] = useState(value || "");

  useEffect(() => {
    setVal(value || '');
  }, [value]);

  const _onChange = e => {
    const newValue = e.target.value

    if(newValue.length >= TEXTAREA_MAX_LENGTH) return;

    setVal(newValue);
    onChange && onChange(newValue);
  }

  return <textarea ref={ref} css={style.textarea} placeholder={placeholder} disabled={disabled} value={val} onChange={_onChange} maxlength={TEXTAREA_MAX_LENGTH}/>;
});

TextArea.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};
