import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

export const ForwardRef = forwardRef(({ children = null }, ref) => {
  return <div ref={ref}>{children}</div>;
});

ForwardRef.propTypes = {
  children: PropTypes.node
};
