import React from 'react';
import PropTypes from 'prop-types';

import { Qr } from './Qr';
import { Barcode } from './Barcode';
import { Numeric } from './Numeric';
import { Label } from "ui/Label";
import { Box } from "ui/Box";

import { TTPolyglot } from "@frontend/tt-polyglot";

export const Checkin = (
  {
    code,
    fontSize = null,
    imgUrl,
    message = null,
    type,
  }) => {

  const renderCheckin = () => {
    switch (type) {
      case 0:
        return <Qr imgUrl={imgUrl} code={code} message={message} fontSize={fontSize} />;

      case 1:
        return <Barcode imgUrl={imgUrl} code={code} message={message} fontSize={fontSize} />;

      case 2:
        return <Numeric code={code} message={message} fontSize={fontSize} />;

      default:
        return null;
    }
  }

  return (
    <>
      {renderCheckin()}
    </>
  );



};


Checkin.propTypes = {
  code: PropTypes.string,
  fontSize: PropTypes.string,
  imgUrl: PropTypes.string,
  message: PropTypes.string,
  // CHECKIN_QR: 0,
  // CHECKIN_BARCODE: 1,
  // CHECKIN_NUMERIC: 2,
  type: PropTypes.oneOf([0, 1, 2]).isRequired,
};