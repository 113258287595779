import { useTheme } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as style from "./style";

/**
 * Primary UI component for user interaction
 */
export const Popover = ({ open = false, anchorEl = null, children = null, sameWidthOfAnchor = false, maxHeight = null }) => {

  if (!open) return null;

  if (!anchorEl) return null;

  if (!children) return null;


  const theme = useTheme();

  const [left, setLeft] = useState("");
  const [top, setTop] = useState("");
  const [width, setWidth] = useState(null);

  useEffect(() => {

    const { offsetLeft, offsetTop, offsetHeight, offsetWidth } = anchorEl;

    // Calcolo top e left perchè i settings hanno position absolute e 
    // devono essere posizionati sotto l'input
    const left = `${offsetLeft}px`;

    // diamo ulteriori 5px di distanza sopra al popover
    const top = `${offsetTop + offsetHeight + 5}px`; 

    setLeft(left);
    setTop(top);
    sameWidthOfAnchor && setWidth(offsetWidth);

  }, []);



  return (
    <div css={style.base(theme)({ left, top, maxHeight })}>
      <div css={style.popover(theme)({width})}>
        {children}
      </div>
    </div>
  );

};

Popover.propTypes = {
  children: PropTypes.node,
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  sameWidthOfAnchor: PropTypes.bool,
  maxHeight: PropTypes.string
};