import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";
import { useTheme } from '@emotion/react';

import {
  APPOINTMENT_STATUS_TAG_PAYMENT_PENDING,
  APPOINTMENT_STATUS_TAG_PAYMENT_REFUNDED,
  APPOINTMENT_STATUS_TAG_PAYMENT_REFUND_SUBMITTED,
  APPOINTMENT_STATUS_TAG_PAYMENT_SUBMITTED,
  APPOINTMENT_STATUS_TAG_PAID,
  APPOINTMENT_STATUS_TAG_PAID_MANUALLY,
  APPOINTMENT_STATUS_TAG_NOT_PAID,
  APPOINTMENT_STATUS_TAG_PENDING_APPROVAL,
  APPOINTMENT_STATUS_TAG_NO_SHOW,
  APPOINTMENT_STATUS_TAG_CANCELLED,
  APPOINTMENT_STATUS_TAG_CHECKED_IN,
  APPOINTMENT_STATUS_TAG_PAID_CHECKED_IN,
  APPOINTMENT_STATUS_TAG_PAID_MANUALLY_CHECKED_IN,
  APPOINTMENT_STATUS_TAG_CONFIRMED,
  APPOINTMENT_STATUS_TAG_CONFIRMED_BY_PATIENT,
  APPOINTMENT_TAG_REMOTE
} from 'models/_reservation';

import { TTPolyglot } from "@frontend/tt-polyglot";

/**
 * Primary UI component for user interaction
 */
export const AppointmentStatusTag = ({ type = "" }) => {

  const theme = useTheme();

  let color = "";
  let backgroundColor = "";
  let label = "";
  
  switch (type) {
    case APPOINTMENT_STATUS_TAG_PAYMENT_PENDING: {
      color = "warning";
      backgroundColor = "warning_light";
      label = TTPolyglot.t("Payment pending");
      break;
    }

    case APPOINTMENT_STATUS_TAG_PENDING_APPROVAL: {
      color = "warning";
      backgroundColor = "warning_light";
      label = TTPolyglot.t("Pending Approval");
      break;
    }

    case APPOINTMENT_STATUS_TAG_PAYMENT_REFUNDED: {
      color = "text_light";
      backgroundColor = "gainsboro";
      label = TTPolyglot.t("Payment refunded");
      break;
    }

    case APPOINTMENT_STATUS_TAG_NO_SHOW: {
      color = "text_light";
      backgroundColor = "gainsboro";
      label = TTPolyglot.t("No Show");
      break;
    }

    case APPOINTMENT_STATUS_TAG_PAYMENT_REFUND_SUBMITTED: {
      color = "text_light";
      backgroundColor = "gainsboro";
      label = TTPolyglot.t("Payment refund submitted");
      break;
    }

    case APPOINTMENT_STATUS_TAG_NOT_PAID: {
      color = "danger";
      backgroundColor = "danger_light";
      label = TTPolyglot.t("Not paid");
      break;
    }

    case APPOINTMENT_STATUS_TAG_CANCELLED: {
      color = "danger";
      backgroundColor = "danger_light";
      label = TTPolyglot.t("Cancelled");
      break;
    }

    case APPOINTMENT_STATUS_TAG_PAYMENT_SUBMITTED: {
      color = "warning";
      backgroundColor = "warning_light";
      label = TTPolyglot.t("Payment Pending Confirmation");
      break;
    }

    case APPOINTMENT_STATUS_TAG_PAID: {
      color = "success";
      backgroundColor = "success_light";
      label = TTPolyglot.t("Paid");
      break;
    }

    case APPOINTMENT_STATUS_TAG_PAID_MANUALLY: {
      color = "success";
      backgroundColor = "success_light";
      label = TTPolyglot.t("Mop Manual Payment Status");
      break;
    }

    case APPOINTMENT_STATUS_TAG_CHECKED_IN: {
      color = "success";
      backgroundColor = "success_light";
      label = TTPolyglot.t("Checkin Status Checkedin");
      break;
    }

    case APPOINTMENT_STATUS_TAG_PAID_CHECKED_IN: {
      color = "success";
      backgroundColor = "success_light";
      label = `${TTPolyglot.t("Paid")} - ${TTPolyglot.t("Checkin Status Checkedin")}`;
      break;
    }

    case APPOINTMENT_STATUS_TAG_PAID_MANUALLY_CHECKED_IN: {
      color = "success";
      backgroundColor = "success_light";
      label = `${TTPolyglot.t("Mop Manual Payment Status")} - ${TTPolyglot.t("Checkin Status Checkedin")}`;
      break;
    }

    case APPOINTMENT_STATUS_TAG_CONFIRMED_BY_PATIENT: {
      color = "success";
      backgroundColor = "success_light";
      label = TTPolyglot.t("MOP Confirmed by patient");
      break;
    }
    
    case APPOINTMENT_STATUS_TAG_CONFIRMED: {
      color = "success";
      backgroundColor = "success_light";
      label = TTPolyglot.t("Confirmed");
      break;
    }

    case APPOINTMENT_TAG_REMOTE: {
      color = "medium_purple";
      backgroundColor = "anti_flash_white";
      label = TTPolyglot.t("Online");
      break;
    }

    default:
      break;
  }


  return (
    <div css={style.base(theme)({ color, backgroundColor })} >
      <p>{label}</p>
    </div>
  );
};

AppointmentStatusTag.propTypes = {
  type: PropTypes.oneOf([
    APPOINTMENT_STATUS_TAG_PAYMENT_PENDING,
    APPOINTMENT_STATUS_TAG_PAYMENT_REFUNDED,
    APPOINTMENT_STATUS_TAG_PAYMENT_REFUND_SUBMITTED,
    APPOINTMENT_STATUS_TAG_PAYMENT_SUBMITTED,
    APPOINTMENT_STATUS_TAG_PAID,
    APPOINTMENT_STATUS_TAG_NOT_PAID,
    APPOINTMENT_STATUS_TAG_PENDING_APPROVAL,
    APPOINTMENT_STATUS_TAG_NO_SHOW,
    APPOINTMENT_STATUS_TAG_CANCELLED,
    APPOINTMENT_STATUS_TAG_CHECKED_IN,
    APPOINTMENT_STATUS_TAG_CONFIRMED,
    APPOINTMENT_STATUS_TAG_CONFIRMED_BY_PATIENT,
    APPOINTMENT_TAG_REMOTE
  ]).isRequired
};
