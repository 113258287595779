import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";
import { useTheme } from '@emotion/react';

export const Highlight = ({ toHighlight = "", highlightText = "", size = null, weight = null, color = null }) => {

  const theme = useTheme();

  const printHighlightedText = (noAccentArray, accentText, regex) => {
    // Mi permette di andare a evidenziare il testo con gli accenti, facendo però match con il testo senza accenti

    const noAccentArrayFiltered = noAccentArray.filter(part => part && part !== "");

    return (
      noAccentArrayFiltered.map(
        (part, i) => {

          // Scorro l'array non accentato al contrario per calcolare nelle posizioni precedenti dell'array quante lettere in tutto ci sono
          let count = 0;
          for (let indexRev = i - 1; indexRev >= 0; indexRev -= 1) {
            count += noAccentArrayFiltered[indexRev].length;
          }

          // Nel testo accentato prendo solo le lettere dalla posizione trovata più il numero di lettere nella posizione corrente
          // In questo modo ottengo le lettere che sto considerando ora, ma nella versione originale con accenti
          const toPrintText = accentText.substr(count, part.length);

          // Se le lettere senza accenti passano il test allora vuol dire che quelle con accenti devono essere evidenziate, 
          // altrimenti vengono stampate normali
          return (
            regex.test(part) === true ?
              <span key={i} css={style.match}>{toPrintText}</span> :
              <span key={i} css={style.standard(theme)({color})}>{toPrintText}</span>
          );
        }
      )
    );
  };

  const Highlighted = (text = '', highlight = '') => {
    if (!highlight.trim()) {
      return <span css={style.standard(theme)({color})}>{text}</span>;
    }

    // Prima di passare la highlight alla RegExp, devo aggiungere gli escape char a tutti i caratteri che hanno
    // un significato dentro le espessioni regolari (come i "+")
    const highlightEscaped = highlight.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    const regex = new RegExp(`(${highlightEscaped})`, 'gi');
    const parts = text.split(regex);
    return (
      <>
        {printHighlightedText(parts, text, regex)}
      </>
    );
  };

  return (
    <div css={style.base(theme)({size, weight})}>
      {Highlighted(toHighlight, highlightText)}
    </div>
  );
};

Highlight.propTypes = {
  toHighlight: PropTypes.string,
  highlightText: PropTypes.string,
  size: PropTypes.string,
  weight: PropTypes.string,
  color: PropTypes.string
};