var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"mySmModalLabel\" id=\"paymentConditionModal\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\" id=\"tt-js-scrollable-modal\">\n      <div class=\"modal-body\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"textPaymentTerm") || (depth0 != null ? lookupProperty(depth0,"textPaymentTerm") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"textPaymentTerm","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\n      </div>\n      <div class=\"modal-footer tt-flexend-container tt-dialog-button\">\n        <button type=\"button\" class=\"tt-button tt-button--primary\" data-dismiss=\"modal\">\n          "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Close",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":10},"end":{"line":9,"column":46}}})) != null ? stack1 : "")
    + "\n        </button>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});