import MOP from 'app';
import { TTPolyglot } from "@frontend/tt-polyglot";

export const createMenuItem = (config, page, route, label, icon = "", onClick = null, e2eid = "") => {
  const item = {};
  item.config = config;
  item.page = page;
  item.route = route;
  item.label = label;
  item.icon = icon;
  item.onClick = onClick;
  item.e2eid = e2eid;

  return item;
};

const getBottomBarConfig = () => {

  const bottomBarConfig = [...MOP.config.getInstanceConfig('mopPatientBottomBar')];
  // è stato messo questo slice perchè l'ultimo elemento è il fullmenu che non è editabile ed è già inserito in maniera statica nella bottom bar
  bottomBarConfig.splice(-1, 1);
 
  const findHome = bottomBarConfig.find(item => item.page === 'home');
  const findHomeIndex = bottomBarConfig.findIndex(item => item.page === 'home');

  // forceHomeBottomBar serve per forzare in mobile la visibilità della home nei vari menu
  if (findHome !== undefined && MOP.config.isMobile() && !MOP.config.isMobileApp() && MOP.Utilities.empty(MOP.querystring['forceHomeBottomBar'])) {
    // Se siamo in mobile e NON siamo in app allora non facciamo vedere la home e la sostituiamo
    // con la prima pagina attiva SOLO se search non è già all'interno della bottom bar
    const findSearch = bottomBarConfig.find(item => item.page === 'search');
    if (findSearch === undefined) {
      // Search non esiste allora mettiamo la prima pagina attiva che di solito è proprio il cerca, altrimenti se il cerca è spento ci andrà
      // cmq la prima pagina disponibile e però al momento si prenderò l'icona che era stata scelta per la home, se non va bene faremo un mapping
      // anche sulle icone

      const { page } = MOP.getFirstActivePage();

      for (let i = 0; i < bottomBarConfig.length; i += 1) {
        if (i === findHomeIndex) {
          if (MOP.Utilities.empty(bottomBarConfig.findIndex(item => item.page === page))) {
            bottomBarConfig[i].page = page;
          } else {
            bottomBarConfig.splice(findHomeIndex, 1);
          }
        }
      }
    } else {
      // Se findSearch esiste già andiamo solo a rimuovere la home
      bottomBarConfig.splice(findHomeIndex, 1);
    }
  }

  return bottomBarConfig;
};


const createRightMenuItems = () => {
  if (MOP.isAdminLoggedIn() && MOP.config.isInternalMop()) return null;

  const rightMenu = {};

  if(MOP.isLoggedIn() && MOP.getCurrentRoute() === 'login/otp') {
    rightMenu.profile = createMenuItem('logout', null, null, MOP.TM.getMenuPageTitle('logout', 'Log Out'), null, () => {
      MOP.localStorage.unset('otpLoginTimestamp');
      logout();
    }, "logout-button");
  } else if (MOP.isLoggedIn() && !MOP.isOneTimeUser() && !MOP.isSamlUser()) {
    const profileDropdown = [];
    if (MOP.TM.isMenuPageVisibileForUserRole('profile')) {
      profileDropdown.push(
        createMenuItem('profile', 'profile', null, MOP.TM.getMenuPageTitle('profile', 'Change My Profile Information/Password'), null, null, "goto-profile")
      );
      profileDropdown.push(
        createMenuItem('profile', 'notifications', null, TTPolyglot.t('Messages'))
      );
    }
    if (!MOP.isAdminLoggedIn() && MOP.config.allowFamilies() && !MOP.Utilities.empty(MOP.getLoggedUserData().parents)) {
      profileDropdown.push(
        createMenuItem('profile', 'profile', 'profile/family/list', TTPolyglot.t('Family Members'))
      );
    }

    // Sezione nascosta per Adeslas su richiesta FD https://tuotempo.freshdesk.com/a/tickets/86609    
    // A seguito degli sviluppi degli utenti normali su adeslas aggiungiamo queste logiche:
    // - i vecchi utenti adeslas (assicurati + ot users) continuano a non poter vedere la sezione
    // - i nuovi utenti adeslas (registrati normalmente) possono vedere la sezione
    if (
      !MOP.isAdminLoggedIn() && 
      (
        (MOP.getDbName() !== 'tt_portal_adeslas' && MOP.getDbName() !== 'tt_portal_adeslas_test') ||
        (MOP.getDbName() === 'tt_portal_adeslas_test' && !MOP.isAdeslasUser())
      )
    ) {
      profileDropdown.push(
        createMenuItem('profile', 'profile', 'profile/advanced_options', TTPolyglot.t('Mop Profile Privacy'), null, null, "")
      );
    }


    if (MOP.TM.isMenuPageVisibileForUserRole('logout')) {
      profileDropdown.push(
        createMenuItem('logout', null, null, MOP.TM.getMenuPageTitle('logout', 'Log Out'), null, () => logout(), "logout-button")
      );
    }

    rightMenu.profile = profileDropdown;
  } else if (MOP.isLoggedIn() && MOP.isOneTimeUser()) {
    rightMenu.profile = createMenuItem(
      'logout', 
      null, 
      null, 
      TTPolyglot.t('Onetime Kill Session'),
      '',
      () => {
        MOP.killOneTimeSession();
      }
    );
  } else if (MOP.isLoggedIn() && MOP.isSamlUser()) {
    rightMenu.profile = createMenuItem('logout', null, null, MOP.TM.getMenuPageTitle('logout', 'Log Out'), null, () => logout(), "logout-sso-button");
  } else if (parseInt(MOP.config.getInstanceConfig('disableLogin'), 10) === 0) {
    rightMenu.profile = createMenuItem('login', 'login', null, TTPolyglot.t('Start Session'), null, null, "goto-login");
  } 

  if (MOP.TM.isMenuPageVisibileForUserRole('support')) {
    rightMenu.support = createMenuItem('support', 'contactus', null, null, 'tt-icon-question-circle');
  }

  rightMenu.languages = !parseInt(MOP.config.getInstanceConfig("disableMopLanguageChoice"), 10) ? MOP.config.get('langs') : null;

  if (checkAdditionalMenu()) {
    rightMenu.additionalMenu = getAdditionalMenu();
  }

  return rightMenu;

};

const checkBottomBarConfig = config => {
  const bottomBarConfig = getBottomBarConfig();

  return bottomBarConfig.find(item => parseInt(item.enabled, 10) === 1 && item.page === config);
};

const getBottomBarIcon = config => {
  const bottomBarConfig = getBottomBarConfig();

  let tmp = '';
  bottomBarConfig.map(item => {
    if (item.page === config) {
      tmp = item.icon;
    }
  });
  return tmp;
};

const pushMenuItem = menu => {
  const bottomBar = [];
  const sideMenu = [];

  menu.map(item => {
    const { config, page, route, label, onClick } = item;

    if (!checkBottomBarConfig(config)) {
      sideMenu.push(item);
    } else {
      bottomBar.push(
        createMenuItem(config, page, route, label, getBottomBarIcon(config), onClick, `goto-${config}`)
      );
    }
  });

  return { bottomBar: sortBottomBar(bottomBar), sideMenu: sideMenu };
};

const sortBottomBar = menu => {
  const bottomBarConfig = getBottomBarConfig();

  const result = [];
  Object.values(bottomBarConfig).map(config => {
    menu.map(item => {
      if (item.config === config.page) {
        result.push(item);
      }
    });
  });
  return result;
};

const checkAdditionalMenu = () => {
  let count = 0;

  if (MOP.config.isDesktop() || MOP.config.isIpad()) {
    if (MOP.TM.isMenuPageVisibileForUserRole('talkToDoctor')) count += 1;
    if (MOP.TM.isMenuPageVisibileForUserRole('blog')) count += 1;
    if (MOP.TM.isMenuPageVisibileForUserRole('price')) count += 1;
    if (MOP.TM.isMenuPageVisibileForUserRole('community')) count += 1;

  }
  return count > 1;

};

const getAdditionalMenu = () => {
  const additionalMenu = [];

  if (MOP.TM.isMenuPageVisibileForUserRole('talkToDoctor')) {
    additionalMenu.push(createMenuItem(
      'talkToDoctor', "talk_doctor", null, MOP.TM.getMenuPageTitle('talkToDoctor', 'App Talk Doctor'),
      "tt-icon-user-md-chat"
    ));
  }

  if (MOP.TM.isMenuPageVisibileForUserRole('blog') && MOP.TM.isMenuPageKeySet('blog', 'url')) {
    if (!MOP.Utilities.empty(MOP.TM.getMenuPageCustomValue("blog", "blogOpenExternal"))) {
      additionalMenu.push(createMenuItem(
        'blog', "blog", null,
        MOP.TM.getMenuPageTitle('blog', 'App Menu Blog'), "tt-icon-money-bill-wave", window.open(MOP.TM.getMenuPageUrl("blog"), '_system')
      ));
    } else {
      additionalMenu.push(createMenuItem(
        'blog', "blog", null,
        MOP.TM.getMenuPageTitle('blog', 'App Menu Blog'), "tt-icon-money-bill-wave"
      ));
    }
  }

  if (MOP.TM.isMenuPageVisibileForUserRole('price') && MOP.TM.isMenuPageKeySet('price', 'url')) {
    additionalMenu.push(createMenuItem(
      "prices", "prices", null,
      MOP.TM.getMenuPageTitle('price', 'App Menu Prices'), "tt-icon-money-bill-wave"
    ));
  }

  if (MOP.TM.isMenuPageVisibileForUserRole('community') && MOP.TM.isMenuPageKeySet('community', 'url')) {
    additionalMenu.push(createMenuItem(
      "community", "community", null,
      MOP.TM.getMenuPageTitle('community', 'App Menu Community'), "tt-icon-users"
    ));
  }

  return additionalMenu;

};

export const logout = () => {
  MOP.setLastPage('reservations', null);
  MOP.LM.doLogout();
};

const createMenu = () => {
  const menu = [];

  // Home
  if (MOP.TM.isMenuPageVisibileForUserRole('home') && (MOP.Utilities.isMobileApp() || !MOP.Utilities.empty(MOP.querystring['forceHomeBottomBar']))) {
    menu.push(createMenuItem(
      "home", "home", null, MOP.TM.getMenuPageTitle('home', 'App Menu Home'), 'tt-icon-home'
    ));
  }

  // Search
  if (MOP.TM.isMenuPageVisibileForUserRole('search')) {
    menu.push(createMenuItem(
      'search', "search", null, MOP.TM.getMenuPageTitle('search', 'Make a Reservation'), 'tt-icon-calendar', null, "goto-search"
    ));
  }

  // Reservations
  if (MOP.TM.isMenuPageVisibileForUserRole('myReservations') &&
    !MOP.isKioskMop() &&
    !MOP.isOneTimeUser()) {
    if (MOP.isAdminLoggedIn() && !MOP.Utilities.empty(MOP.getLoggedUserDataField("is_allowed_to_access_my_reservations_page"))) {
      menu.push(createMenuItem(
        'myReservations', "agenda", null,
        MOP.TM.getMenuPageTitle('myReservations', 'Appointments'), 'tt-icon-list', null,
        "goto-myReservations"
      ));
    } else {
      menu.push(createMenuItem(
        'myReservations', "reservations", null, MOP.TM.getMenuPageTitle('myReservations', 'Appointments'), 'tt-icon-list tt-customer-icon-reservations', null, "goto-myReservations"
      ));
    }
  }

  // Patients
  if (MOP.TM.isMenuPageVisibileForUserRole('patients')) {
    menu.push(createMenuItem(
      "patients", "patients", null, TTPolyglot.t('Menu patients'), 'tt-icon-list'
    ));
  }

  // Disdici
  if (MOP.TM.isMenuPageVisibileForUserRole('cancelReservations')) {
    menu.push(createMenuItem(
      'cancelReservations', "reservations", 'cancel',
      MOP.TM.getMenuPageTitle('cancelReservations', 'Menu cancelReservations'), 'tt-icon-trash-alt tt-customer-icon-cancel-reservations', null, "goto-cancel"
    ));
  }

  // Dossier/Referti
  if (!MOP.Utilities.empty(MOP.config.getInstanceConfig('personalHealthRecord'))) {
    if (MOP.TM.isMenuPageVisibileForUserRole('dossier')) {
      menu.push(createMenuItem(
        'dossier', "dossier", null,
        MOP.TM.getMenuPageTitle('dossier', 'Dossier Page Dossier'), 'tt-icon-folder-open', null, "goto-dossier"
      ));
    }
  } else if (MOP.TM.isMenuPageVisibileForUserRole('downloads') &&
    !MOP.Utilities.empty(MOP.config.getInstanceConfig('defaultDownloadsPath'))) {

    menu.push(createMenuItem(
      'downloads', "downloads", null,
      MOP.TM.getMenuPageTitle('downloads', 'Medical Results'), 'tt-icon-arrow-circle-down', () => openDownloads(), null, "goto-downloads"
    ));
  }

  if (MOP.TM.isMenuPageVisibileForUserRole('talkToDoctor') && !checkAdditionalMenu()) {
    menu.push(createMenuItem(
      'talkToDoctor', "talk_doctor", null, MOP.TM.getMenuPageTitle('talkToDoctor', 'App Talk Doctor'),
      "tt-icon-user-md-chat"
    ));
  }

  if (MOP.TM.isMenuPageVisibileForUserRole('blog') && MOP.TM.isMenuPageKeySet('blog', 'url')) {
    if (MOP.config.isMobileApp()) {
      menu.push(createMenuItem(
        'blog', "blog", null,
        MOP.TM.getMenuPageTitle('blog', 'App Menu Blog'), "tt-icon-comments-alt tt-customer-icon-blog", () => openCustomUrl('blog')
      ));
    } else if (!checkAdditionalMenu()) {
      if (!MOP.Utilities.empty(MOP.TM.getMenuPageCustomValue("blog", "blogOpenExternal"))) {
        menu.push(createMenuItem(
          'blog', "blog", null,
          MOP.TM.getMenuPageTitle('blog', 'App Menu Blog'), "tt-icon-comments-alt tt-customer-icon-blog", window.open(MOP.TM.getMenuPageUrl("blog"), '_system')
        ));
      } else {
        menu.push(createMenuItem(
          'blog', "blog", null,
          MOP.TM.getMenuPageTitle('blog', 'App Menu Blog'), "tt-icon-comments-alt tt-customer-icon-blog"
        ));
      }
    }
  }


  if (MOP.TM.isMenuPageVisibileForUserRole('price') && MOP.TM.isMenuPageKeySet('price', 'url')) {
    if (MOP.config.isMobileApp()) {
      menu.push(createMenuItem(
        "prices", "prices", null,
        MOP.TM.getMenuPageTitle('price', 'App Menu Prices'), "tt-icon-money-bill-wave tt-customer-icon-prices", () => openCustomUrl('price')
      ));
    } else if (!checkAdditionalMenu()) {
      menu.push(createMenuItem(
        "prices", "prices", null,
        MOP.TM.getMenuPageTitle('price', 'App Menu Prices'), "tt-icon-money-bill-wave tt-customer-icon-prices"
      ));
    }
  }

  if (MOP.TM.isMenuPageVisibileForUserRole('community') && MOP.TM.isMenuPageKeySet('community', 'url')) {
    if (MOP.config.isMobileApp()) {
      menu.push(createMenuItem(
        "community", "community", null,
        MOP.TM.getMenuPageTitle('community', 'App Menu Community'), "tt-icon-users tt-customer-icon-community", () => openCustomUrl('community')
      ));
    } else if (!checkAdditionalMenu()) {
      menu.push(createMenuItem(
        "community", "community", null,
        MOP.TM.getMenuPageTitle('community', 'App Menu Community'), "tt-icon-users tt-customer-icon-community"
      ));
    }
  }

  if (MOP.TM.isMenuPageVisibileForUserRole('findDoctor') && MOP.config.isSearchWidgetEnabled('resources')) {
    menu.push(createMenuItem(
      'findDoctor', 'find_doctor', null, MOP.TM.getMenuPageTitle('findDoctor', 'Find Doctors'), 'tt-icon-stethoscope'
    ));
  }

  if (MOP.TM.isMenuPageVisibileForUserRole('findLocation') && MOP.config.isSearchWidgetEnabled('areas')) {
    menu.push(createMenuItem(
      'findLocation', 'find_location', null, MOP.TM.getMenuPageTitle('findLocation', 'Find Locations'), 'tt-icon-map tt-customer-icon-findlocation'
    ));
  }

  // solo mobile
  if (MOP.config.isMobile()) {
    if (MOP.TM.isMenuPageVisibileForUserRole('info')) {
      menu.push(createMenuItem(
        'info', 'contactus', 'custom', MOP.TM.getMenuPageTitle('info', 'Menu info'), 'tt-icon-phone tt-customer-icon-contactus'
      ));
    }

    if (MOP.TM.isMenuPageVisibileForUserRole('profile')) {
      if (!MOP.isOneTimeUser()) {
        menu.push(createMenuItem(
          'profile', 'profile', null, MOP.TM.getMenuPageTitle('profile', 'Change My Profile Information/Password'), 'tt-icon-user tt-customer-icon-profile', null, "goto-profile"
        ));
      }
    } else {
      if (!MOP.isLoggedIn()) {
        menu.push(createMenuItem(
          'login', 'login', null, 'login'
        ));
      } else if (!MOP.isOneTimeUser()) {
        menu.push(createMenuItem(
          'profile', 'profile', 'profile/advanced_options', TTPolyglot.t('Mop Profile Privacy'), 'tt-icon-lock'
        ));
      }
    }

    if (MOP.TM.isMenuPageVisibileForUserRole('support')) {
      menu.push(createMenuItem(
        'support', 'contactus', 'support', MOP.TM.getMenuPageTitle('support', 'Support'), 'tt-icon-envelope tt-customer-icon-support'
      ));
    }

    if (!MOP.Utilities.empty(window.MOP_globals.customerConfig) && !MOP.Utilities.empty(window.MOP_globals.customerConfig.showSelectInstance)) {
      menu.push(createMenuItem(
        null, 'find_instances', null, TTPolyglot.t('Change Location'), 'tt-icon-map-marker-alt'
      ));
    }

    if (parseInt(MOP.config.getInstanceConfig('disableMopLanguageChoice'), 10) === 0) {
      menu.push(createMenuItem(
        'languageChoice', null, null, TTPolyglot.t('MOP Select Language Menu Item'), 'tt-icon-globe-light tt-customer-icon-language', null, "side-menu-language"
      ));
    }

    if (MOP.TM.isMenuPageVisibileForUserRole('logout') && MOP.isLoggedIn() && !MOP.isOneTimeUser()) {
      menu.push(createMenuItem(
        'logout', null, null, MOP.TM.getMenuPageTitle('logout', 'Log Out'), 'tt-icon-sign-out', () => logout(), "logout-button"
      ));
    } else if (MOP.isLoggedIn() && MOP.isOneTimeUser()) {
      menu.push(createMenuItem(
        'logout', 
        null, 
        null, 
        TTPolyglot.t('Onetime Kill Session'),
        'tt-icon-sign-out',
        () => {
          MOP.killOneTimeSession();
        },
        "logout-otu-button"
      ));
    }
  }

  return menu;

};

export const itemClicked = item => {
  const { page, route, onClick } = item;

  if (onClick) return onClick();

  MOP.changePage(page, route);

};

export const getHeaderMenu = () => {
  return { menu: createMenu(), rightMenu: createRightMenuItems() };
};

export const getMobileMenu = () => {
  const menu = createMenu();
  return pushMenuItem(menu);
};

export const isHeaderDisabled = ({ pwd_expired, validated }) => {  
  return (MOP.isLoggedIn() && !MOP.Utilities.empty(pwd_expired)) ||
    (MOP.isValidationEnabled() && MOP.Utilities.empty(validated));
};

export const getLangs = () => MOP.config.get('langs');

export const getCurrentLang = () => MOP.config.get('lang');

const openDownloads = () => {
  if (!MOP.Utilities.empty(MOP.TM.getMenuPageUrl("downloads"))) {
    MOP.DM.openURLNewTab('downloads', MOP.Utilities.isMobileApp());
  } else {
    MOP.changePage('downloads');
  }
};

const openCustomUrl = item => {
  if (MOP.Utilities.isMobileApp()) {
    const ref = cordova.InAppBrowser.open(MOP.TM.getMenuPageUrl(item), '_blank', 'location=no');
  }
};

export const hideHeader = () => (MOP.config.isLoginFirst() && !MOP.isLoggedIn()) || MOP.config.isOtuFirst(MOP) || MOP.config.isOtuPost(MOP);


export const certificationWidgetEnabled = () => {

  const { 
    desktop: { 
      enabled: enabledDesktop
    }, 
    mobile: { 
      enabled: enabledMobile 
    }, 
    app: { 
      enabled: enabledApp
    }
  } = MOP.config.getInstanceConfig('mopShowDocplannerCertificationWidget');


  if(MOP.Utilities.isMobileApp()) {
    return !!parseInt(enabledApp);
  }

  if(!MOP.Utilities.isMobileApp() && MOP.config.isMobile()) {
    return !!parseInt(enabledMobile);
  }

  return !!parseInt(enabledDesktop);

};