var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"tt-text-bold tt-mb-10\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Wait Swap Reservation Title",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":47},"end":{"line":4,"column":105}}})) != null ? stack1 : "")
    + "</div>\n            <div class=\"modal-body\">\n                <div>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"replace_reservation_text") || (depth0 != null ? lookupProperty(depth0,"replace_reservation_text") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"replace_reservation_text","hash":{},"data":data,"loc":{"start":{"line":6,"column":21},"end":{"line":6,"column":49}}}) : helper)))
    + "</div>\n            </div>\n            <div class=\"modal-footer tt-flexend-container tt-dialog-button\">\n               \n                    <button type=\"button\" id=\"replaceReservation\" class=\"tt-button tt-button--primary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Wait Swap Reservation Action",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":103},"end":{"line":10,"column":162}}})) != null ? stack1 : "")
    + "</button>\n               \n               \n                    <a class=\"tt-button tt-button--secondary dismiss\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Clear",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":70},"end":{"line":13,"column":106}}})) != null ? stack1 : "")
    + "</a>\n               \n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});