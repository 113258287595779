import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import * as style from "./style.js";
import { useTheme } from '@emotion/react';
import { Icon } from '../Icon';
import { Label } from '../Label';
import { Dialog } from '../Dialog';
import { Checkbox } from '../Checkbox/index.jsx';

export const Privacy = ({ onCheck = null, text = null, placeholder = null, error = null, dialogContent = null, onClick = null, type = "dialog" }) => {
  const theme = useTheme();

  const [checked, setChecked] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const privacyRef = useRef(null);


  const handleClick = () => {
    const newCheck = !checked;
    setChecked(newCheck);
    onCheck(newCheck);
  }

  const handlePlaceholderClick = () => {

    if(type === "link") {
      return onClick()
    }

    return setDialogOpen(true);

  }

  
  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        closeButton={true}
        outClick={true}
        anchorEl={privacyRef?.current}
      >
        <Dialog.Content>
          <div dangerouslySetInnerHTML={{__html: dialogContent}}>
          </div>
        </Dialog.Content>
      </Dialog>


      <div css={style.base}>
        <div css={style.privacyContainer(theme)({ error: !!error })} ref={privacyRef}>
          <Checkbox checked={checked} onClick={handleClick}>
            <Label>
              {text}
            </Label>
            <Label onClick={handlePlaceholderClick}>
              {placeholder}
            </Label>
          </Checkbox>
        </div>
        {!!error && <Label color={"danger"}>{error}</Label>}
      </div>

    </>

  );
};

Privacy.propTypes = {
  onCheck: PropTypes.func,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  type: PropTypes.oneOf(["link", "dialog"]),
  onClick: PropTypes.func,
  dialogContent: PropTypes.string
};