var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " style=\"display: none;\" ";
},"3":function(container,depth0,helpers,partials,data) {
    return "tt-columns";
},"5":function(container,depth0,helpers,partials,data) {
    return " tt-container ";
},"7":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-column is-6 is-offset-3\"";
},"9":function(container,depth0,helpers,partials,data) {
    return "style=\"height: 50px;\"";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"currentPage") : depth0),"!=","find_instances",{"name":"ifCond","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":30,"column":17}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFirstAvailabilities") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":10},"end":{"line":11,"column":17}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"whiteMop") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":10},"end":{"line":19,"column":17}}})) != null ? stack1 : "")
    + "    <img height=\"1\" border=\"0\" class=\"mop_width\" src=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"getImgPath") || (depth0 != null ? lookupProperty(depth0,"getImgPath") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"getImgPath","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":54},"end":{"line":20,"column":84}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"getImgPath")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "/spacer.png\" alt=\"\" />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_footer_logo") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":21,"column":14},"end":{"line":29,"column":21}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"awaiting_time\">\n                  <a class=\"tt-link\" href=\"javascript:\" id=\"mop_waiting_time_div\" >"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"MopAwaitingTime") || (depth0 != null ? lookupProperty(depth0,"MopAwaitingTime") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"MopAwaitingTime","hash":{},"data":data,"loc":{"start":{"line":9,"column":83},"end":{"line":9,"column":102}}}) : helper)))
    + "</a>\n              </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"tt-footer-layout-link\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"gipoFooter") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":18},"end":{"line":16,"column":25}}})) != null ? stack1 : "")
    + "                  <a target=\"_blank\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"footerURL") || (depth0 != null ? lookupProperty(depth0,"footerURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"footerURL","hash":{},"data":data,"loc":{"start":{"line":17,"column":43},"end":{"line":17,"column":56}}}) : helper)))
    + "\" class=\"tt_logo\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"footerMessage") || (depth0 != null ? lookupProperty(depth0,"footerMessage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"footerMessage","hash":{},"data":data,"loc":{"start":{"line":17,"column":74},"end":{"line":17,"column":91}}}) : helper)))
    + "</a>\n              </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "                      <a target=\"_blank\" href=\"http://www.gipo.it\" class=\"link\">GipoWEB</a> &nbsp;|&nbsp;\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "";
},"20":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-footer-layout__logo\">\n                  <img src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"show_footer_logo") || (depth0 != null ? lookupProperty(depth0,"show_footer_logo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"show_footer_logo","hash":{},"data":data,"loc":{"start":{"line":23,"column":28},"end":{"line":23,"column":48}}}) : helper)))
    + "\" border=\"0\" alt=\"\" />\n                </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-footer-layout__text\">\n                  "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"show_footer_html") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":18},"end":{"line":27,"column":69}}})) != null ? stack1 : "")
    + "\n                </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"show_footer_html") || (depth0 != null ? lookupProperty(depth0,"show_footer_html") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"show_footer_html","hash":{},"data":data,"loc":{"start":{"line":27,"column":42},"end":{"line":27,"column":62}}}) : helper)));
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div style=\"position: fixed; left: 0; right: 0; bottom: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"btnReturnPageBottomStyle") || (depth0 != null ? lookupProperty(depth0,"btnReturnPageBottomStyle") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"btnReturnPageBottomStyle","hash":{},"data":data,"loc":{"start":{"line":34,"column":62},"end":{"line":34,"column":90}}}) : helper)))
    + "; height: 0; z-index: 1005;\">\n        <div style=\"position: relative;\">\n          <div style=\"-webkit-box-sizing: border-box; box-sizing: border-box; position: absolute; width: 100%; bottom: 0;\">\n            <div style=\"text-align: center!important; margin-bottom: 10px!important;\">\n              <button class=\"tt-button tt-button--operator tt-button--primary\" id=\"iFrameReturnPageBtn\"\n                style=\"height: 33px; line-height: 31px; font-size: 16px; padding: 0 14px;\">\n                      "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Back To Website",{"name":"translate","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":22},"end":{"line":40,"column":72}}})) != null ? stack1 : "")
    + "\n              </button>\n            </div>\n          </div>\n        </div>\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"tt-footer-layout\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"currentPage") : depth0),"==","home",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":30},"end":{"line":1,"column":100}}})) != null ? stack1 : "")
    + ">\n  <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":55}}})) != null ? stack1 : "");
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":55},"end":{"line":2,"column":95}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n    <div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":9},"end":{"line":3,"column":74}}})) != null ? stack1 : "")
    + ">\n      <div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mopIframeReturnPage") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":11},"end":{"line":4,"column":66}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"currentPage") : depth0),"!=","home",{"name":"ifCond","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":31,"column":17}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mopIframeReturnPage") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":6},"end":{"line":46,"column":13}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});