var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-columns\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-column is-8 is-offset-2\"";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-input-container tt-input-container--required\">\n            <input class=\"tt-input tt-dynamic-text\" data-config-validator=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldDataConfigValidator") || (depth0 != null ? lookupProperty(depth0,"fieldDataConfigValidator") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldDataConfigValidator","hash":{},"data":data,"loc":{"start":{"line":9,"column":75},"end":{"line":9,"column":103}}}) : helper)))
    + "\" autocomplete=\"off\" type=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldType") || (depth0 != null ? lookupProperty(depth0,"fieldType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldType","hash":{},"data":data,"loc":{"start":{"line":9,"column":130},"end":{"line":9,"column":143}}}) : helper)))
    + "\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldName") || (depth0 != null ? lookupProperty(depth0,"fieldName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldName","hash":{},"data":data,"loc":{"start":{"line":9,"column":151},"end":{"line":9,"column":164}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldId") || (depth0 != null ? lookupProperty(depth0,"fieldId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldId","hash":{},"data":data,"loc":{"start":{"line":9,"column":170},"end":{"line":9,"column":181}}}) : helper)))
    + "\" value=\"\" data-validation=\"true\" placeholder=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldPlaceholder") || (depth0 != null ? lookupProperty(depth0,"fieldPlaceholder") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldPlaceholder","hash":{},"data":data,"loc":{"start":{"line":9,"column":228},"end":{"line":9,"column":248}}}) : helper)))
    + "\"\n                    data-validation-error=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fieldDataValidationError") || (depth0 != null ? lookupProperty(depth0,"fieldDataValidationError") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fieldDataValidationError","hash":{},"data":data,"loc":{"start":{"line":10,"column":43},"end":{"line":10,"column":71}}}) : helper)))
    + "\">\n        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "";
},"9":function(container,depth0,helpers,partials,data) {
    return "checked";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"privacyLabelPost") || (depth0 != null ? lookupProperty(depth0,"privacyLabelPost") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"privacyLabelPost","hash":{},"data":data,"loc":{"start":{"line":27,"column":56},"end":{"line":27,"column":76}}}) : helper)))
    + "</span>";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"mopPrivacyPromotion","==",1,{"name":"checkConfig","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":9},"end":{"line":45,"column":21}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"tt-input-checkbox-container tt-deprecated-input-checkbox-container\">\n						<li class=\"tt-checkbox\">\n							<label class=\"tt-checkbox__label\">\n								<input class=\"tt-checkbox__label-input\" type=\"checkbox\" name=\"privacy_promotions\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"privacy_promotions") : depth0),"==","1",{"name":"ifCond","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":90},"end":{"line":37,"column":147}}})) != null ? stack1 : "")
    + ">\n							</label>\n							<div class=\"tt-checkbox__text\">\n								"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"MOP Privacy2 Checkbox",{"name":"translate","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":8},"end":{"line":40,"column":60}}})) != null ? stack1 : "")
    + "\n							</div>\n						</li>\n					</div>\n\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"mopPrivacySurvey","==",1,{"name":"checkConfig","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":6},"end":{"line":60,"column":22}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<div class=\"tt-input-checkbox-container tt-deprecated-input-checkbox-container\">\n							<li class=\"tt-checkbox\">\n								<label class=\"tt-checkbox__label\">\n									<input class=\"tt-checkbox__label-input\" type=\"checkbox\" name=\"privacy_review\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"privacy_review") : depth0),"==","1",{"name":"ifCond","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":87},"end":{"line":53,"column":140}}})) != null ? stack1 : "")
    + ">\n								</label>\n								<div class=\"tt-checkbox__text\">\n									"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Privacy Survey Checkbox",{"name":"translate","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":9},"end":{"line":56,"column":67}}})) != null ? stack1 : "")
    + "\n								</div>\n							</li>\n						</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form role=\"form\" "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":18},"end":{"line":1,"column":67}}})) != null ? stack1 : "")
    + " onsubmit=\"return false;\">\n    <div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":9},"end":{"line":2,"column":74}}})) != null ? stack1 : "")
    + ">\n        <div class=\"tt-container\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"invitationText") || (depth0 != null ? lookupProperty(depth0,"invitationText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"invitationText","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":30}}}) : helper)))
    + "\n        </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"isResource") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":12,"column":20}}})) != null ? stack1 : "")
    + "        <div id=\"password_confirm_box\"></div>\n\n        <div class=\"tt-form-divider\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Privacy",{"name":"translate","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":37},"end":{"line":15,"column":75}}})) != null ? stack1 : "")
    + "</div>   \n				\n				<div class=\"tt-container-space-75\">\n					<div class=\"tt-input-checkbox-container tt-deprecated-input-checkbox-container\">\n						<li class=\"tt-checkbox\">\n							<label class=\"tt-checkbox__label\">\n											<input class=\"tt-checkbox__label-input\" type=\"checkbox\" name=\"privacy\" data-validation-error=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Privacy NO",{"name":"translate","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":105},"end":{"line":21,"column":146}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"privacy") : depth0),"==","1",{"name":"ifCond","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":148},"end":{"line":21,"column":194}}})) != null ? stack1 : "")
    + ">\n							</label>\n              <div class=\"tt-checkbox__text \">\n                <div>\n                  <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"privacyLabelPre") || (depth0 != null ? lookupProperty(depth0,"privacyLabelPre") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"privacyLabelPre","hash":{},"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":25,"column":43}}}) : helper)))
    + "</span>\n                  <span class=\"tt-text-link-action tt-pointer js-privacy-policy\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Open Privacy Dialog",{"name":"translate","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":81},"end":{"line":26,"column":135}}})) != null ? stack1 : "")
    + "</span>\n                  "
    + ((stack1 = (lookupProperty(helpers,"isNotEmpty")||(depth0 && lookupProperty(depth0,"isNotEmpty"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"privacyLabelPost") : depth0),{"name":"isNotEmpty","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":18},"end":{"line":27,"column":98}}})) != null ? stack1 : "")
    + "\n                </div>\n              </div>\n						</li>\n        	</div>\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"privacy_promotions") : depth0),"!=","1",{"name":"ifCond","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":5},"end":{"line":46,"column":16}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"privacy_review") : depth0),"!=","1",{"name":"ifCond","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":5},"end":{"line":61,"column":17}}})) != null ? stack1 : "")
    + "				</div>\n        \n        <div class=\"tt-container\">\n            <button type=\"button\" class=\"tt-button tt-button--primary\" id=\"profile_submitButton\">\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Save New Password",{"name":"translate","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":16},"end":{"line":66,"column":64}}})) != null ? stack1 : "")
    + "\n            </button>\n        </div>\n    </div>\n</form>\n";
},"useData":true});