var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"tt-container tt-text-center\">\n    <img src=\"https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png\" alt=\"PayPal Logo\">\n    <br> "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"user_payment") : depth0)) != null ? lookupProperty(stack1,"account") : stack1), depth0))
    + "\n  </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "tt-columns";
},"5":function(container,depth0,helpers,partials,data) {
    return "tt-container";
},"7":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-column is-8 is-offset-2\"";
},"9":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div id=\"card_container\" style=\"padding-top: 50px;\">\n\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"user_payment") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==","paypal",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":8,"column":13}}})) != null ? stack1 : "")
    + "\n\n    <form id=\"payment-form-hidden\" style=\"display: none;\">\n      <input type=\"text\" name=\"number\">\n      <input type=\"text\" name=\"name\"/>\n      <input type=\"text\" name=\"expiry\"/>\n      <input type=\"text\" name=\"cvc\"/>\n  </form>\n\n  <div class=\"tt-container\">\n    <div id=\"card-wrapper\" style=\"display: block; margin: 0 auto; margin-bottom: 50px;\">\n\n    </div>\n  </div>\n</div>\n<div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":29,"column":53}}})) != null ? stack1 : "");
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":53},"end":{"line":29,"column":91}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n  <div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":7},"end":{"line":30,"column":72}}})) != null ? stack1 : "")
    + ">\n    <button type=\"button\" class=\"tt-button tt-button--primary tt-margin-bottom\" id=\"editCard\">\n      "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Payment Method Edit",{"name":"translate","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":6},"end":{"line":32,"column":60}}})) != null ? stack1 : "")
    + "\n    </button>\n    <button type=\"button\" class=\"tt-button tt-button--danger\" id=\"deleteCard\">\n      "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Payment Method Remove",{"name":"translate","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":6},"end":{"line":35,"column":62}}})) != null ? stack1 : "")
    + "\n    </button>\n  </div>\n</div>";
},"useData":true});