var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-container\"";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <i class=\"tt-payment-response-status__icon tt-text-success tt-icon-check-circle\"></i>\n      <h3 class=\"tt-payment-response-status__title\">\n        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"MOP Payment Request Success",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":66}}})) != null ? stack1 : "")
    + "\n      </h3>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <i class=\"tt-payment-response-status__icon tt-text-warning tt-icon-exclamation-circle\"></i>\n      <h3 class=\"tt-payment-response-status__title\">\n        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"MOP Payment Request Pending",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":66}}})) != null ? stack1 : "")
    + "\n      </h3>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, alias5=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":5},"end":{"line":1,"column":51}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n  <div class=\"tt-payment-response\">\n    <div class=\"tt-payment-response-status\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"verified") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":14,"column":11}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"tt-payment-response-card\">\n      <div class=\"tt-payment-response-card-detail\">\n        <div class=\"tt-payment-response-card-detail-item\">\n          <i class=\"tt-payment-response-card-detail-item__icon tt-icon-file-invoice-dollar\"></i>\n          <div>"
    + alias5(((helper = (helper = lookupProperty(helpers,"item") || (depth0 != null ? lookupProperty(depth0,"item") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"item","hash":{},"data":data,"loc":{"start":{"line":21,"column":15},"end":{"line":21,"column":23}}}) : helper)))
    + "</div>\n        </div>\n        <div class=\"tt-payment-response-card-detail-item\">\n          <i class=\"tt-payment-response-card-detail-item__icon tt-icon-calendar-alt\"></i>\n          <div>"
    + ((stack1 = (lookupProperty(helpers,"getLongDateFromTimestamp")||(depth0 && lookupProperty(depth0,"getLongDateFromTimestamp"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"created") : depth0),{"name":"getLongDateFromTimestamp","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":15},"end":{"line":25,"column":81}}})) != null ? stack1 : "")
    + "<span> • </span>"
    + alias5(((helper = (helper = lookupProperty(helpers,"payment_hour") || (depth0 != null ? lookupProperty(depth0,"payment_hour") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"payment_hour","hash":{},"data":data,"loc":{"start":{"line":25,"column":97},"end":{"line":25,"column":113}}}) : helper)))
    + "</div>\n        </div>\n      </div>\n\n      <div class=\"tt-payment-response-card-price\">\n        <div class=\"tt-payment-response-card-price-item\">\n          <div class=\"tt-payment-response-card-price-item__title is-total\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Total",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":75},"end":{"line":31,"column":111}}})) != null ? stack1 : "")
    + "</div>\n          <div>\n            <h3>"
    + alias5(((helper = (helper = lookupProperty(helpers,"price") || (depth0 != null ? lookupProperty(depth0,"price") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"price","hash":{},"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":33,"column":25}}}) : helper)))
    + " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"currencySymbol") || (depth0 != null ? lookupProperty(depth0,"currencySymbol") : depth0)) != null ? helper : alias2),(options={"name":"currencySymbol","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":26},"end":{"line":33,"column":64}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"currencySymbol")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</h3>\n          </div>\n        </div>\n        <div class=\"tt-payment-response-card-price-item\">\n          <div class=\"tt-payment-response-card-price-item__title\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Transaction",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":66},"end":{"line":37,"column":108}}})) != null ? stack1 : "")
    + "</div>\n          <div>\n            <div>"
    + alias5(((helper = (helper = lookupProperty(helpers,"paymentid") || (depth0 != null ? lookupProperty(depth0,"paymentid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"paymentid","hash":{},"data":data,"loc":{"start":{"line":39,"column":17},"end":{"line":39,"column":30}}}) : helper)))
    + "</div>  \n            <div>"
    + alias5(((helper = (helper = lookupProperty(helpers,"payment_date") || (depth0 != null ? lookupProperty(depth0,"payment_date") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"payment_date","hash":{},"data":data,"loc":{"start":{"line":40,"column":17},"end":{"line":40,"column":33}}}) : helper)))
    + "</div>  \n          </div>\n        </div>\n      </div>\n    </div>  \n  </div>\n</div>";
},"useData":true});