var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Price",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":22},"end":{"line":1,"column":58}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"tt-label tt-label--highlight tt-text-center\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"currencySymbol") || (depth0 != null ? lookupProperty(depth0,"currencySymbol") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currencySymbol","hash":{},"data":data,"loc":{"start":{"line":2,"column":57},"end":{"line":2,"column":75}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"amount") || (depth0 != null ? lookupProperty(depth0,"amount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":2,"column":75},"end":{"line":2,"column":85}}}) : helper)))
    + "</div>\n<div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Request",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":22},"end":{"line":3,"column":60}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"tt-label tt-label--middle\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"item_name") || (depth0 != null ? lookupProperty(depth0,"item_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"item_name","hash":{},"data":data,"loc":{"start":{"line":4,"column":39},"end":{"line":4,"column":52}}}) : helper)))
    + "</div>\n<div class=\"tt-label tt-label--middle\">"
    + ((stack1 = (lookupProperty(helpers,"getLongDateFromTimestamp")||(depth0 && lookupProperty(depth0,"getLongDateFromTimestamp"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"created") : depth0),{"name":"getLongDateFromTimestamp","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":39},"end":{"line":5,"column":105}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});