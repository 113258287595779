var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "tt-container";
},"3":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-container\"";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":16},"end":{"line":60,"column":57}}})) != null ? stack1 : "")
    + "\">\n      <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":18},"end":{"line":61,"column":75}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"payment-condition\" id=\"paymentConditionContainer\">\n\n          <div id=\"common_condition_checkbox_container\" class=\"tt-mb-20\">\n          </div>\n        </div>\n        </div>\n      </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "tt-columns";
},"8":function(container,depth0,helpers,partials,data) {
    return "tt-column is-8 is-offset-2";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-column\">\n                  <a href=\"javascript:\" class=\"tt-button tt-button--secondary\" mopSelector=\"clearPayment\" data-default-page=\"reservations\">\n                      "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Clear",{"name":"translate","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":22},"end":{"line":79,"column":58}}})) != null ? stack1 : "")
    + "\n                  </a>\n                </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, alias5=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div id=\"billing_information_regions\"></div>\n\n<div data-nested-page=\"first\">\n  <div id=\"payment_summary_container\" class=\"payment-resume tt-padding-top-30 tt-mb-30 ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":87},"end":{"line":4,"column":125}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n  </div>\n<div id=\"billing_information_container\" ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":40},"end":{"line":6,"column":86}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "></div>\n  <div id=\"payment_container\" ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":30},"end":{"line":7,"column":76}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n\n\n    <iframe class=\"tt-hide\" id=\"paypal_hss_iframe\" name=\"paypal_hss_iframe\" style=\"width:100%;\" height=\"580px\" frameborder=\"0\"></iframe>\n\n    <form class=\"tt-hide\" target=\"paypal_hss_iframe\" id=\"paypalProForm\" name=\"paypalProForm\" method=\"post\" action=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"endpoint") || (depth0 != null ? lookupProperty(depth0,"endpoint") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"endpoint","hash":{},"data":data,"loc":{"start":{"line":12,"column":115},"end":{"line":12,"column":127}}}) : helper)))
    + "\">\n\n      <input type=\"hidden\" name=\"cmd\" value=\"_hosted-payment\" />\n      <input type=\"hidden\" name=\"template\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"template") || (depth0 != null ? lookupProperty(depth0,"template") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"template","hash":{},"data":data,"loc":{"start":{"line":15,"column":50},"end":{"line":15,"column":62}}}) : helper)))
    + "\" />\n      <input type=\"hidden\" name=\"paymentaction\" value=\"sale\" />\n      <input type=\"hidden\" name=\"subtotal\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"subtotal") || (depth0 != null ? lookupProperty(depth0,"subtotal") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subtotal","hash":{},"data":data,"loc":{"start":{"line":17,"column":50},"end":{"line":17,"column":62}}}) : helper)))
    + "\" />\n\n      <input type=\"hidden\" name=\"currency_code\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"currency_code") || (depth0 != null ? lookupProperty(depth0,"currency_code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currency_code","hash":{},"data":data,"loc":{"start":{"line":19,"column":55},"end":{"line":19,"column":72}}}) : helper)))
    + "\" />\n      <input type=\"hidden\" name=\"business\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"business") || (depth0 != null ? lookupProperty(depth0,"business") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"business","hash":{},"data":data,"loc":{"start":{"line":20,"column":50},"end":{"line":20,"column":62}}}) : helper)))
    + "\" />\n\n      <input type=\"hidden\" name=\"charset\" value=\"utf-8\" />\n\n      <input type=\"hidden\" name=\"no_note\" value=\"1\" />\n      <input type=\"hidden\" name=\"lc\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"lc") || (depth0 != null ? lookupProperty(depth0,"lc") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lc","hash":{},"data":data,"loc":{"start":{"line":25,"column":44},"end":{"line":25,"column":50}}}) : helper)))
    + "\" />\n      <input type=\"hidden\" name=\"no_shipping\" value=\"1\" />\n      <!-- ? -->\n      <input type=\"hidden\" name=\"address_override\" value=\"1\" />\n      <input type=\"hidden\" name=\"cbt\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"cbt") || (depth0 != null ? lookupProperty(depth0,"cbt") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cbt","hash":{},"data":data,"loc":{"start":{"line":29,"column":45},"end":{"line":29,"column":52}}}) : helper)))
    + "\" />\n      <input type=\"hidden\" name=\"notify_url\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"notify_url") || (depth0 != null ? lookupProperty(depth0,"notify_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"notify_url","hash":{},"data":data,"loc":{"start":{"line":30,"column":52},"end":{"line":30,"column":66}}}) : helper)))
    + "\" />\n\n      <!-- <input type=\"hidden\" name=\"tax\" value=\"\"/> -->\n\n      <input type=\"hidden\" name=\"showShippingAddress\" value=\"false\">\n\n      <!-- <input type=\"hidden\" id=\"paypal_<?=PAYPAL_PRO?>_item_number\" name=\"item_number\" value=\"\"/> -->\n      <input type=\"hidden\" name=\"item_name\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"item_name") || (depth0 != null ? lookupProperty(depth0,"item_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"item_name","hash":{},"data":data,"loc":{"start":{"line":37,"column":51},"end":{"line":37,"column":64}}}) : helper)))
    + "\" />\n      <input type=\"hidden\" name=\"invoice\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"invoice") || (depth0 != null ? lookupProperty(depth0,"invoice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"invoice","hash":{},"data":data,"loc":{"start":{"line":38,"column":49},"end":{"line":38,"column":60}}}) : helper)))
    + "\" />\n\n      <input type=\"hidden\" name=\"custom\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"custom") || (depth0 != null ? lookupProperty(depth0,"custom") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom","hash":{},"data":data,"loc":{"start":{"line":40,"column":48},"end":{"line":40,"column":58}}}) : helper)))
    + "\" />\n      <input type=\"hidden\" name=\"return\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"return") || (depth0 != null ? lookupProperty(depth0,"return") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"return","hash":{},"data":data,"loc":{"start":{"line":41,"column":48},"end":{"line":41,"column":58}}}) : helper)))
    + "\" />\n      <input type=\"hidden\" name=\"cancel_return\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"cancel_return") || (depth0 != null ? lookupProperty(depth0,"cancel_return") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cancel_return","hash":{},"data":data,"loc":{"start":{"line":42,"column":55},"end":{"line":42,"column":72}}}) : helper)))
    + "\" />\n\n      <input type=\"hidden\" name=\"first_name\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"first_name") || (depth0 != null ? lookupProperty(depth0,"first_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"first_name","hash":{},"data":data,"loc":{"start":{"line":44,"column":52},"end":{"line":44,"column":66}}}) : helper)))
    + "\" />\n      <input type=\"hidden\" name=\"last_name\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"last_name") || (depth0 != null ? lookupProperty(depth0,"last_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"last_name","hash":{},"data":data,"loc":{"start":{"line":45,"column":51},"end":{"line":45,"column":64}}}) : helper)))
    + "\" />\n      <input type=\"hidden\" name=\"email\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":46,"column":47},"end":{"line":46,"column":56}}}) : helper)))
    + "\" />\n\n      <input type=\"hidden\" name=\"address1\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"address1") || (depth0 != null ? lookupProperty(depth0,"address1") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"address1","hash":{},"data":data,"loc":{"start":{"line":48,"column":50},"end":{"line":48,"column":62}}}) : helper)))
    + "\" />\n      <input type=\"hidden\" name=\"zip\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"zip") || (depth0 != null ? lookupProperty(depth0,"zip") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"zip","hash":{},"data":data,"loc":{"start":{"line":49,"column":45},"end":{"line":49,"column":52}}}) : helper)))
    + "\" />\n      <input type=\"hidden\" name=\"city\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"city") || (depth0 != null ? lookupProperty(depth0,"city") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"city","hash":{},"data":data,"loc":{"start":{"line":50,"column":46},"end":{"line":50,"column":54}}}) : helper)))
    + "\" />\n      <input type=\"hidden\" name=\"state\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"state") || (depth0 != null ? lookupProperty(depth0,"state") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"state","hash":{},"data":data,"loc":{"start":{"line":51,"column":47},"end":{"line":51,"column":56}}}) : helper)))
    + "\" />\n      <input type=\"hidden\" name=\"country\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"country") || (depth0 != null ? lookupProperty(depth0,"country") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"country","hash":{},"data":data,"loc":{"start":{"line":52,"column":49},"end":{"line":52,"column":60}}}) : helper)))
    + "\" />\n\n      <input type=\"hidden\" name=\"night_phone_a\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"night_phone_a") || (depth0 != null ? lookupProperty(depth0,"night_phone_a") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"night_phone_a","hash":{},"data":data,"loc":{"start":{"line":54,"column":55},"end":{"line":54,"column":72}}}) : helper)))
    + "\" />\n      <input type=\"hidden\" name=\"night_phone_b\" value=\"\" />\n      <input type=\"hidden\" name=\"night_phone_c\" value=\"\" />\n    </form>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"enablePaymentTerm") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":4},"end":{"line":69,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":16},"end":{"line":71,"column":57}}})) != null ? stack1 : "")
    + "\">\n      <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":18},"end":{"line":72,"column":75}}})) != null ? stack1 : "")
    + "\">\n\n          <div ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":15},"end":{"line":74,"column":61}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n            <div class=\"tt-columns tt-flex\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"payment_required") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":14},"end":{"line":82,"column":26}}})) != null ? stack1 : "")
    + "              <div class=\"tt-column\">\n                <button data-e2eid=\"payment-proceed\" type=\"button\" id=\"paypal_pro-submit-payment-button\" mopSelector=\"submitPaymentButton\" class=\"tt-button tt-button--primary\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Proceed",{"name":"translate","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":20},"end":{"line":85,"column":58}}})) != null ? stack1 : "")
    + "\n                </button>\n              </div>\n            </div>\n          </div>\n\n        </div>\n      </div>\n  </div>\n\n\n<div id=\"dialog_payment_condition_container\">\n</div>\n</div>";
},"useData":true});