import Backbone from 'backbone-shim';
import Marionette from 'backbone.marionette';
import AlertRegion from 'regions/alert';
import BannerRegion from 'regions/banner';
import PermanentAlert from 'regions/permanentAlert';
import DialogRegion from 'regions/dialog';
import Config from 'config';
import MopStorage from 'storage';
import LocalStorage from 'local_storage';
import SessionStorage from 'session_storage';
import templateModule from 'common/template_module';
import mobileappModule from 'common/mobileapp_module';
import backgroundModule from 'common/background_module';
import commonBehavoir from 'common-behavior';
import hotDogBehavior from 'hotdog-behavior';
import nestedPageBehavior from 'nested_page_behavior';
import Cookies from 'js-cookie';
import Utilities from 'common/utilities';
import backbone_fetch_cache from 'fetch-cache';
import Index from 'index';
import {retry} from 'common-lib/promises';
import { resetStorageDossierAuth } from 'common-mop/dossier';
import { mopLocation } from 'api/events';
import { MOP_LAUNCHER_ORIGIN_LEAD_MANAGEMENT, MOP_LAUNCHER_AXA_TEMPORARY } from 'common-lib/constants';
import { TTPolyglot } from "@frontend/tt-polyglot";
import { querystringToObject, isProduction } from 'common-lib/url';
import { insertNodeFromString } from 'common-lib/dom';

import { initializeMonitoring, sendUserEvent } from 'monitoring';
import { ERROR_LOGIN, LOGIN, LOGIN_DURING_RESERVATION, LOGIN_FROM_RESET_PASSWORD } from 'monitoring-costants';

import { getZipCodePlaceholderFromPrefix, validateZipCode } from 'mop-utils/countries';
import detectHeadlessBrowser from "@frontend/bot-detector";

import 'common-lib/polyfill';

    var MOP = new Marionette.Application();

    // Setting iniziale dei behavoirs
    window.Behaviors = {};
    Marionette.Behaviors.behaviorsLookup = function() {
        return window.Behaviors;
    };


    window.Behaviors.CommonBehavior = commonBehavoir(MOP);
    window.Behaviors.HotDogBehavior = hotDogBehavior(MOP);
    window.Behaviors.NestedPageBehavior = nestedPageBehavior(MOP);

    MOP.templateModule = templateModule(MOP);
    MOP.TM = MOP.templateModule;
    MOP.MAM = mobileappModule(MOP);
    MOP.BCKGM = backgroundModule(MOP);
    MOP.Utilities = Utilities;

    MOP.SessionStorage = SessionStorage;

    // Stato globale dell'applicazione.
    MOP.state = {
        loggedUser: undefined
    };

	/****************************************************************************************************
	 * Events
	 * ------
	 */ 
	MOP.on('start', function (options) {
        if (Backbone.history){
          if(!MOP.isMaintenanceMode){
            require("head_app");
            require("footer_app");
            require("header_app");
            require("views");

            if(window.location.hash.includes("maintenance")) window.location.hash = "";
          }

          // se siamo nel mop embedded e nell'url c'è il parametro avoidFirstScrollingTop=1 non vogliamo fare lo scrollTop
          // al primo caricamento della pagina
          MOP.avoidFirstScrollingTop = {
            enabled: MOP.config.isInIFrame() && !MOP.config.isOldLauncher(MOP) && !MOP.config.isNewLauncher(MOP) && !MOP.Utilities.empty(MOP.querystring['avoidFirstScrollingTop']),
            moduleLoaded: null
          }

            Index.startRouter(MOP);
            MOP.Utilities.log(MOP, 'starting MOP');

            if(MOP.config.isNewLauncher()){
              MOP.sendMessageToParentFrame({fn: "mopLauncher-hideLoader"}) 
            }
            // Avvio il modulo di Background => start_new_mop - recache
            //-----------------------------------------------------------------------------------
            //NB => non lo faccio + nel main perchè la start_new_mop richiamata in background
            //potrebbe modificare il MOP.state.loggedUser a cui sono stati attaccati dei listener
            //dal modulo header che viene incluso solo allo start dell'application
            MOP.BCKGM.start();

            MOP.showCloseButtonLauncherEmbedded();
        }
    });

    /****************************************************************************************************
     * Regions
     * -------
     */
    MOP.addRegions({
        bodyRegion: '#body-region',
        mobileMenuRegion: '#mobile-menu-region',
        headerRegion:   '#header-region',
        mainRegion: '#main-region',
        footerRegion: '#footer-region',
        loadingRegion: '#loading-region',
        alertRegion: {
            selector: "#alert-region",
            regionClass: AlertRegion
        },
        permanentAlert: {
          selector: "#permanentAlert-region",
          regionClass: PermanentAlert
        },
        bannerRegion: {
            selector: "#banner-region",
            regionClass: BannerRegion
        },
        dialogRegion: {
            selector: "#dialog-region",
            regionClass: DialogRegion
        }
    });

    /****************************************************************************************************
     * Properties
     * ----------
     */
    MOP.config			= null;
    MOP.constants		= {};
    MOP.environment 	= null;
    MOP.querystring 	= {};
    MOP.queryStringSchemaUrl 	= {};
    MOP.localStorage	= null;
    MOP.permanentLocalStorage	= null;

    /****************************************************************************************************
     * Methods
     * -------
     */

    MOP.startNewMop = function startNewMop (params, options, login_data) {
        var internalDeferred = new $.Deferred();
        var externalDeferred = new $.Deferred();
        var member_name_key = 'member_name';
        var member_code_key = 'member_code';
        MOP.isMaintenanceMode = false;
        
        if (window.MOP_globals.e2e === true) {
          MOP.config.set({dbName: window.dbNameTestE2E});
          MOP.config.set({hostname: window.hostnameTestE2E});
          MOP.config.fetch(MOP, {
            ajax: {
                data: params,
                options: options
            }
          }).done(internalDeferred.resolve);
        } else if (
          !MOP.Utilities.empty(window?.MOP_globals?.bootstrapData) && 
          window.MOP_globals.bootstrapData.result === 'OK' && 
          !MOP.Utilities.isMobileApp()
          ) {
          // TODO: GoodByePHP - TO REMOVE AFTER GLOBAL DEPLOY
          // Vecchio codice quando lo sviluppo GoodbyePHP verrà deployato per tutti i clienti, 
          // questo if diventerà inutile e andrà tolto  
          MOP.config.set(window.MOP_globals.bootstrapData['return']);
          internalDeferred.resolve(MOP.config);

        } else if (
          !MOP.Utilities.empty(window?.MOP_globals?.bootstrapData) && 
          window.MOP_globals.bootstrapData.result != 'OK' && 
          window.MOP_globals.bootstrapData.exception == 'TUOTEMPO_SERVICE_NOT_ALLOWED' && 
          !MOP.Utilities.isMobileApp()
          ){
          // TODO: GoodByePHP - TO REMOVE AFTER GLOBAL DEPLOY
          // Vecchio codice quando lo sviluppo GoodbyePHP verrà deployato per tutti i clienti, 
          // questo if diventerà inutile e andrà tolto  
          
          //cambiamo hash a mano perchè altrimenti con changePage() non funzionerebbe, poichè il Router non è ancora startato
          MOP.config.set(window.MOP_globals.bootstrapData['return']);
          internalDeferred.resolve(MOP.config);
          window.location.hash = 'maintenance';
          MOP.isMaintenanceMode = true;
        } else {
            params = params || {};
            options = options || {};

            params.isMobile = (MOP.config.isMobile() ? 1 : 0);

            if( MOP.config.has(member_name_key) ){
                params[member_name_key] = MOP.config.get(member_name_key);
            }
            if( MOP.config.has(member_code_key) ){
                params[member_code_key] = MOP.config.get(member_code_key);
            }

            if( MOP.config.has('css') ){
                params['css'] = MOP.config.get('css');
            }

            if( MOP.config.has('areaid') ){
                params['areaid'] = MOP.config.get('areaid');
            }

            if (MOP.Utilities.isMobileApp()) {
                _.extend(params, MOP.MAM.getDeviceDatas(['model', 'platform', 'uuid', 'version', 'token']));
            }
 
            // Questo controllo viene utilizzato quando siamo in MobileApp perchè le app vengono buildate
            // con una lingua in query string e questo controllo qui sotto forza la lingua del mop con quella
            // del dispositivo prima che venga fatta la fetch dei config. Su Desktop e su WebApp invece questo
            // non serve perchè è già il PHP che ritorna la lingua del browser
            if (MOP.Utilities.isMobileApp() && !MOP.isLoggedIn() && MOP.Utilities.empty(MOP.querystring['lang'])) {
              const lang = MOP.Utilities.getDeviceLanguage(MOP);
              if (!MOP.Utilities.empty(lang.deviceLang)) {
                  MOP.config.set('lang',lang.deviceLang);
              }
            }

            // Controlliamo se il dispositivo è rooted, se si, non chiamiamo la startNewMop.
            // Lasciamo fuori solo il caso in cui siamo su desktop con forceMobileApp=1, in questo caso
            // non vogliamo controllare nulla, altrimenti ci sarebbe errore perchè il plugin non esiste
            const rootedDeviceDeferred = new $.Deferred();
            if(MOP.Utilities.isMobileApp(true)) {
              IRoot.isRooted(
                data => {
                  if (data && data == 1) {
                    alert("You have a rooted device. You cannot use the application on a rooted device");
                    return;
                  }

                  rootedDeviceDeferred.resolve();
                },
                error => {
                  alert("Errore nel plugin IRoot", error);
                }
              );
            } else {
              rootedDeviceDeferred.resolve();
            }

            rootedDeviceDeferred.then(() => {
              MOP.config.fetch(MOP, {
                ajax: {
                    data: params,
                    options: options
                }
              }).done(resp => {

                if(MOP.Utilities.isMobileApp()) {
                  const { enabled } = MOP.config.getInstanceConfig('patientApp');

                  if(parseInt(enabled) !== 1) {
                    alert("The Application is Disabled");
                    return;
                  }
                } else {
                  
                  /**
                  * Inserisco i customBody e customHeader nel dom
                  */
                  const customBody = MOP.config.getInstanceConfig("mopCustomBody");
                  if(!MOP.Utilities.empty(customBody)) {
                    insertNodeFromString(customBody, "body", false);
                  }

                  const customHeader = MOP.config.getInstanceConfig("mopCustomHeader");
                  if(!MOP.Utilities.empty(customHeader)) {
                    insertNodeFromString(customHeader, "head", true);
                  }
                  // se ho il css nel ritorno della start_new_mop allora lo inietto nell'head dell'html
                  // Evito di farlo lato APP perchè in quel caso il css è messo dentro dalla build del mop
                  const css = resp.get("css");
                  if(!MOP.Utilities.empty(css)) {
                    for (const [key, value] of Object.entries(css)) {
                      // Per ogni entry mi creo l'elemento link e lo appendo nell'head
                      const style = document.createElement('link');
                      style.setAttribute('rel', 'stylesheet');
                      style.setAttribute('type', 'text/css');
                      style.setAttribute('href', value.href);

                      // Il media serve in particolare per il css print_mop, nel suo caso riceviamo media=print e dobbiamo impostarlo
                      if(!MOP.Utilities.empty(value.media)) style.setAttribute("media", value.media);
                
                      document.getElementsByTagName('head')[0].appendChild(style);
                    }
                  }
                }

                internalDeferred.resolve(resp);
              });
            });


        }

        // Punto di sincronizzazione comune tra il flusso sincrono e asincrono,
        // qui gestisco tutte le azioni che devono essere eseguite nel momento
        // in cui ho recuperato i bootstrap data che hanno valorizzato il config
        internalDeferred.then(function (config) {
        	if( config ){

            const initPOTTDeferred = new $.Deferred();

            const { totp: { enabled: POTTEnabled, algorithmParams } } = MOP.config.getInstanceConfig('apiPreventScraping');
            
            if(!MOP.Utilities.empty(POTTEnabled)) {

              const initPott = () => {
                // Carichiamo nel body lo script di POTT
                const pottScript = document.createElement("script");
                pottScript.setAttribute("type", "text/javascript");
                pottScript.setAttribute("src", `${MOP.config.getStaticPath()}js/lib/@frontend-pott/dist/umd/pott.umd.js`);
    
                pottScript.onload = resp => {
                  window.POTT.init({
                    key: algorithmParams.key,
                    period: parseInt(algorithmParams.period),
                    digits: parseInt(algorithmParams.digits),
                    algorithm: algorithmParams.algorithm,
                  });

                  initPOTTDeferred.resolve(config);
                }
    
                document.body.appendChild(pottScript);
              }

               if(isProduction()) {
                // Faccio detect dei browser headless solo se sono su produzione
                detectHeadlessBrowser().then(isBot => {
                  // Se la detect torna true allora il browser è headless, continuo con lo start solo se il browser NON è headless
                  if(!isBot) {
                    initPott();
                  } else {
                    console.error('headless browser blocked');
                  }
                });
              } else {
                initPott();
              }
              
            } else {
              // se POTT disabilitato non vogliamo caricare lo script, risolvo
              initPOTTDeferred.resolve(config);
            }

            initPOTTDeferred.then(config => {
              var sessionid = config.get('sessionid');
              var logged_user_data = config.get('logged_user');
    
              initializeMonitoring();
    
              if( !MOP.Utilities.empty(sessionid) && !MOP.Utilities.empty(logged_user_data) ){
    
                  if( MOP.getSessionid() == sessionid ){
                    MOP.state.loggedUser.set(Object.assign({}, logged_user_data, { sessionid: sessionid }));
                    MOP.startCountdownKillSession();
                  }else{
                    MOP.setSession(sessionid, logged_user_data);
                  }
    
                }else if( MOP.isLoggedIn() ){
                  MOP.resetSession();
                }
    
                /* -------------------------------------------------------------------
                 * NB => il logged_user e il sessionid fetchati dalla start_new_mop
                 *       e caricati nel MOP.config vengono ora consumati per impostare
                 *       il MOP.state.loggedUser che deve rimanere l'unico punto dove
                 *       leggere queste informazioni, quindi per sicurezza andiamo ad
                 *       unsettare quelli presenti nel MOP.config
                 * -------------------------------------------------------------------
                 */
                config.unset('logged_user');
                config.unset('sessionid');
    
              //Gestione LOGIN Automatico in presenza di login_data oppure in presenza di doLoginForSchemaUrl
                  //---------------------------------------------------
              if(!MOP.isLoggedIn() && (login_data || !MOP.Utilities.empty(MOP.queryStringSchemaUrl["doLoginForSchemaUrl"]))) {
                var automatic_login = true;
                var hidden_login = true;
    
                //Se è presente doLoginForSchemaUrl allora riempiamo il login data con il sessionid|ots_token
                if (!MOP.Utilities.empty(MOP.queryStringSchemaUrl["doLoginForSchemaUrl"])) {
                  login_data = {};
    
                  if (!MOP.Utilities.empty(MOP.queryStringSchemaUrl["ots_token"])) {
                    login_data["ots_token"] = MOP.queryStringSchemaUrl["ots_token"];
                  } else if (!MOP.Utilities.empty(MOP.queryStringSchemaUrl["sessionid"])) {
                    login_data["sessionid"] = MOP.queryStringSchemaUrl["sessionid"];
                  }
                  
                  hidden_login = MOP.queryStringSchemaUrl["hidden_login"];
                  automatic_login = MOP.queryStringSchemaUrl["automatic_login"];
    
                  delete MOP.queryStringSchemaUrl["doLoginForSchemaUrl"];
                }
    
    
                       MOP.LM.doLogin( login_data,
                              null,
                              null,
                              automatic_login,
                              hidden_login
                       ).done(externalDeferred.resolve(config));
    
              } else if (!MOP.Utilities.empty(MOP.querystring['token']) && !MOP.Utilities.empty(MOP.querystring['auth_mode']) && MOP.querystring['auth_mode'] === MOP.constants.AUTH_MODE_TRD111) {
     
                  fetch(`${MOP.config.getProtocol()}//${MOP.config.get('hostname')}/token.php?service=user&fn=loginUserWithAuthMode&token=${MOP.querystring['token']}&dbName=${MOP.getDbName()}&token_dbName=${MOP.querystring['token_dbName']}&auth_mode=${MOP.constants.AUTH_MODE_TRD111}&is_bundle=${MOP.querystring['is_bundle'] || 0}`)
                  .then(response => response.json())
                  .then(data => {
                    if (data.result !== 'OK') {
                      MOP.TM.unblockLoading();
                      var alert = {type: "danger", msg: TTPolyglot.t("Something Went Wrong")};
                      MOP.execute("MOP:alert", alert);
                    } else {
                      const user = data.return;
                      user.auth_mode = data.auth_mode;
                      user.validated = MOP.Utilities.getTimestamp();
                      MOP.setSession(data.sessionid, user);
                      externalDeferred.resolve(config);
                    }
    
                  });
     
              } else if (!MOP.Utilities.empty(MOP.querystring['token']) && !MOP.Utilities.empty(MOP.querystring['auth_mode']) && MOP.querystring['auth_mode'] === MOP.constants.AUTH_MODE_FROM_PAYMENT) {
                let url = `${MOP.config.getProtocol()}//${MOP.config.get('hostname')}/token.php?service=user&fn=loginUserWithAuthMode&token=${MOP.querystring['token']}&dbName=${MOP.getDbName()}&token_dbName=${MOP.querystring['token_dbName']}&auth_mode=${MOP.constants.AUTH_MODE_FROM_PAYMENT}`;
    
                // Per il pagamento del documento aggiungiamo un parametro
                if (!MOP.Utilities.empty(MOP.querystring['pay_document'])) {
                  url += '&pay_document=1'
                }
    
                // Per il pagamento della richiesta aggiungiamo un parametro
                if (!MOP.Utilities.empty(MOP.querystring['pay_payment_request'])) {
                  url += '&pay_payment_request=1'
                }
    
                fetch(url)
                .then(response => response.json())
                .then(data => {
                  if (data.result !== 'OK') {
                    MOP.TM.unblockLoading();
                      var alert = {type: "danger", msg: TTPolyglot.t("Something Went Wrong")};
                      MOP.execute("MOP:alert", alert);
                  } else {
                    const user = data.return;
                    user.auth_mode = data.auth_mode;
                    user.validated = MOP.Utilities.getTimestamp();
                    MOP.setSession(data.sessionid, user);
                    externalDeferred.resolve(config);
                  }
    
                });
    
            } else {
                externalDeferred.resolve(config);
              }

            });
    		}
        });

        return externalDeferred;
    };

    /**
     * Funzione di inizializzazione del MOP.
     * @param {Object} options
     * @param {Object} options.querystring
     * @param {String} options.environment
     * @return {Object} fetching_config - config
     */
    MOP.init = function init(options) {
        var fetching_config = new $.Deferred();
        var login_data;

        MOP.querystring = options.querystring;
        MOP.environment = options.environment;

        MOP.config = new Config(MOP.querystring);
        MOP.constants = MOP.config.constants;

        MOP.state.networkReachability = navigator.onLine;

        //Se in query string è presente l'oauthSession allora lo mettiamo nelal rispettiva variabile per andare a leggerlo successivamente
        if (!MOP.Utilities.empty(MOP.querystring['oauthSession'])) {
          MOP.queryStringSchemaUrl["doLoginForSchemaUrl"] = true;
          MOP.queryStringSchemaUrl["sessionid"] = MOP.querystring['oauthSession'];
          MOP.queryStringSchemaUrl["automatic_login"] = true;
          MOP.queryStringSchemaUrl["hidden_login"] = false;
        }

        // Storage che non contiene riferimento alla sessione e
        // che quindi "sopravvive" anche ai login/logout.
        // la memoria nella quale viene salvato è la LOCALSTORAGE.
        MOP.permanentLocalStorage = new MopStorage({
            id: MOP.generateApplicationPermanentId(),
            type: MOP.constants.LOCAL_STORAGE,
            backboneKey: Backbone.fetchCache.getLocalStorageKey()
        });

        // Il fetching della permanent deve essere fatto come prima cosa
        // per poter recuperare un eventuale sessionid o per recuperare
        // i LoginData in caso di app nativa prima della startNewMop
        $.when(
        		MOP.permanentLocalStorage.fetch(),
        		LocalStorage.getItem('selectedInstance')
    		)
            .done(function(v1, v2) {

            	var sessionid;

            	var sessionid_in_querystring = MOP.querystring.sessionid;

            	var sessionid_in_permanent_storage = (MOP.permanentLocalStorage.has('sessionid') ? MOP.permanentLocalStorage.get('sessionid') : null);

            	var login_data_in_permanent_storage = MOP.getLoginData();

                var entities_user = require('entities/user');
                	/*
                	 * ISTANZIAMO il LOGGED USER
                	 * -------------------------
                	 */
                    MOP.state.loggedUser = MOP.request('user:entity:new');
                    
                    // In header esiste già un listener sul change del loggedUser che viene principalmente usato per nascondere
                    // visualizzare elementi in interfaccia, qui invece mettiamo un nuovo listener per far partire logiche non
                    // di visualizzazione come Sentry
                    MOP.state.loggedUser.on('change', function (loggedUser) {
                      /*
                      Impostiamo l'user context per Sentry
                      */
                      if (loggedUser && !MOP.Utilities.empty(loggedUser.get("id"))) {  
                        if (typeof Sentry !== "undefined") {
                          Sentry.configureScope(function (scope) {
                            scope.setUser({
                              "email": loggedUser.get("email"), 
                              "id": loggedUser.id,
                              "fname": loggedUser.get("fname"),
                              "lname": loggedUser.get("lname"),
                            });
                          });
                        }  
                      }
                      
                    });
                    
                    // GESTIONE SESSIONID ////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////////////////////
                    //Forziamo un sessionid:
                    //- se presente in querystring
                    //- o se viene recuperato nel sessionid della permanentLocalStorage
                    //- o se viene recuperato nei login_data della permanentLocalStorage
                    if( !MOP.Utilities.empty(sessionid_in_querystring) ){
                    	sessionid = sessionid_in_querystring;

                    }else if( !MOP.Utilities.empty(sessionid_in_permanent_storage) ) {
                    	sessionid = sessionid_in_permanent_storage;

                	}else if( login_data_in_permanent_storage && !MOP.Utilities.empty(login_data_in_permanent_storage.sessionid) ) {
                    	sessionid = login_data_in_permanent_storage.sessionid;
                    }

                    if( !MOP.Utilities.empty(sessionid) ){
                    	MOP.state.loggedUser.set('sessionid', sessionid);
                    }
                    /////////////////////////////////////////////////////////////////////////////////

                    // NB, nonostante il nome la memoria nella quale viene
                    // salvato è la SESSIONSTORAGE.
                    MOP.localStorage = new MopStorage({
                        id: MOP.generateApplicationId(),
                        type: MOP.constants.SESSION_STORAGE,
                        backboneKey: Backbone.fetchCache.getLocalStorageKey()
                    });

                	   //Gestione redirect automatico in caso find_instances
                     var findInstanceDeferred = $.Deferred();

                    var selectedInstance = JSON.parse(v2);
                    if (selectedInstance && selectedInstance.instance != MOP.getDbName()) {

                        const Instances = require("data/instances.json");

                        if (Instances) {

                        var checkSelectedInstance = false;

                        for (var i = 0; i < Instances.length; i++) {
                          if (selectedInstance.instance == Instances[i].instance) {
                            checkSelectedInstance = true;
                            break;
                          }
                        }

                        if (checkSelectedInstance === true) {
                          //Qui non risolviamo il deferred perchè cambiamo pagina
                          return MOP.goToSelectedInstance(selectedInstance);
                        } else {
                          LocalStorage.removeItem('selectedInstance');
                          findInstanceDeferred.resolve();
                        }

                      } else {
                        LocalStorage.removeItem('selectedInstance');
                        findInstanceDeferred.resolve();
                      }
                    } else {
                      findInstanceDeferred.resolve();
                    }

                    var startNewMop_params;
                    var startNewMop_options;
                    var startNewMop_login_data;

                    //Gestione LOGIN Automatico => autologin per applicazione mobile
                    if (MOP.Utilities.isMobileApp() && login_data_in_permanent_storage) {
                    	startNewMop_login_data = login_data_in_permanent_storage;
                    //Gestione LOGIN Automatico => autologin da querystring
                    }else if ( MOP.querystring['doLogin'] == 'true' ) {
                    	startNewMop_login_data = {user: MOP.querystring['username'], password: MOP.querystring['password']};
                    }

                    $.when( findInstanceDeferred, MOP.localStorage.fetch() )
                    	.done(function () {

                        // Qui andiamo a creare un SessionToken che passeremo alla libreria se non esiste già
                        if (!MOP.localStorage.has("GoogleMapAPISessionToken")) {
                          MOP.localStorage.set("GoogleMapAPISessionToken", MOP.Utilities.makeRandomID(15));
                        }

                    		$.when(MOP.startNewMop(startNewMop_params, startNewMop_options, startNewMop_login_data))
                        .done(function () {

                            fetching_config.resolve();
                        });

                        // Listener totali dell'applicazione
                        new MutationObserver(function() {
                          var timeoutID;

                          const resize = function () {
                              window.clearInterval(timeoutID);
                              timeoutID = window.setTimeout(function () {
                                if ((!MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage')) && !MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage')['search'])) || 
                                !MOP.Utilities.empty(MOP.querystring['enableNewSearch'])) {
                                  MOP.templateModule.resizeThisFrame();
                                } else {
                                  MOP.templateModule.oldResizeThisFrame();
                                }
                              }, 100);
                          };

                          resize();
                        }).observe(document, {childList: true, subtree: true});

                        // enableBetaMopPage['search'] questo listener andrà eliminato
                        $('body').on('hotdog-change', function () {
                          var timeoutID;

                          return function (data, sentArguments) {
                              window.clearInterval(timeoutID);

                              // se effettuo il resize in conseguenza all'apertura di un hotdog disabilito
                              // ogni resize che avviene fintanto che l'hotdog non viene chiuso, in questo modo
                              // evito che l'altezza aggiunta non venga cancellata
                              timeoutID = window.setTimeout(MOP.templateModule.oldResizeThisFrame(
                                  (sentArguments.action === 'closed' ? -1 : 1) * sentArguments.height,
                                  sentArguments.action === 'opened',
                                  sentArguments.action === 'closed'
                              ), 100);
                          };
                      }());

                        // Gestione notifica VCODE
                        // [TODO] mattia, metti dentro done localStorage
                    })
                    .fail(function () {
                        MOP.Utilities.log(MOP, 'fetching_config failed');
                    });
            });

        return fetching_config;
    };

    /**
     * Ritorna il dbName corrente
     * @return {String} - dbName
     */
    MOP.getDbName = function(){
        return this.config.get('dbName');
    };

    MOP.getDBNameById = function(id){
      return MOP.config.isPortalInstance() ? id.split('@')[1] : MOP.getDbName();
    }

    MOP.resetSession = function(){
        // Ripasso dalla setSession con parametri nulli per resettare
        // i dati dell'utente loggato e la sessione in localStorage.
        MOP.setSession(null, null);
        MOP.resetLoginData();

        // MOP.resetSessionCookies();
        // this.resetBackboneCache();
        MOP.cleanLocalStorage();
        MOP.cleanQuerystringDisposables();
        MOP.vent.trigger('session:reset');
    };

    MOP.setSession = function( sessionid, logged_user_data, login_data ){
      
        if (!sessionid && !logged_user_data) {
            MOP.state.loggedUser.clear();
        } else {
            MOP.state.loggedUser.set(Object.assign({}, logged_user_data, { sessionid: sessionid }));
        }

    	var manage_sessionid_in_local_storage = function(id){
    		if (!sessionid) {
    			MOP.permanentLocalStorage.unset('sessionid');
    		}else{
    			MOP.permanentLocalStorage.set('sessionid', id);
    		}
    	};

    	var reg_exp = "^" + this.generateApplicationId(true);

        $.when( manage_sessionid_in_local_storage(sessionid), LocalStorage.removeItemByRegExp(reg_exp) )
            .done(function(){
                MOP.localStorage = MOP.localStorage.clone();
                MOP.localStorage.set('id', MOP.generateApplicationId());

                //NB => Invalidiamo anche la BackboneCache
                // MOP.resetBackboneCache();
                if( login_data ){
                    MOP.setLoginData( login_data );
                }
            });

        if (sessionid) {
            MOP.vent.trigger('session:set');
        }
    };

    /*
    * * *
    * * * Gestione degli elementi session-dependent (vengono etichettati come disposables, cioè 'da eliminare').
    * * * Questi elementi vengono memorizzati usando liste di chiavi presenti in localstorage e gestiti mediante due metodi (set e clean)
    * * *
    * * * LISTE:
    * * * localStorageDisposableList
    * * * querystringDisposableList
    * * *
    */
    /*
    * Setta a 'disposable' un elemento presente in localStorage con chiave key
    *
    * @param {String} key
    */
    MOP.setLocalStorageDisposable = function(key) {
        if(!MOP.localStorage.has('localStorageDisposableList')) {
            MOP.localStorage.set('localStorageDisposableList', []);
        }
        if(MOP.localStorage.get('localStorageDisposableList').indexOf(key) == -1){
            var list = MOP.localStorage.get('localStorageDisposableList');
            list.push(key);

            MOP.localStorage.set('localStorageDisposableList', list);
        }
    };
    /*
    * 
    */
    MOP.cleanLocalStorage = function() {

      // Rimuove gli elementi 'disposable' presenti nella localStorage
      MOP.localStorage.attributes = _.omit(MOP.localStorage.attributes, MOP.localStorage.get('localStorageDisposableList'));
      MOP.localStorage.unset('localStorageDisposableList');

      // Resetto il timestamp e la scelta sulle biometriche dell'otp nella localstorage
      resetStorageDossierAuth();

    };
    /*
    * Setta a 'disposable' un valore della quesrystring con chiave key
    *
    * @param {String} key
    */
    MOP.setQuerystringDisposable = function(key) {
        if(!MOP.localStorage.has('querystringDisposableList')) {
            MOP.localStorage.set('querystringDisposableList', []);
        }

        if(MOP.localStorage.get('querystringDisposableList').indexOf(key) == -1){
            var list = MOP.localStorage.get('querystringDisposableList');
            list.push(key);

            MOP.localStorage.set('querystringDisposableList', list);
        }
    };
    /*
    * Rimuove gli elementi 'disposable' presenti in querystring
    */
    MOP.cleanQuerystringDisposables = function () {
        MOP.localStorage.unset('querystringDisposableList');
    };

    /**
     * Ritorna i dati di login dell'utente, utilizzati solo nel caso di mobile app.
     * @return {Object}
     */
    MOP.getLoginData = function(){
        if( MOP.Utilities.isMobileApp() ){
            return (MOP.permanentLocalStorage && MOP.permanentLocalStorage.has('login_data') ? MOP.permanentLocalStorage.get('login_data') : null);
        }
        return null;
    };

    /**
     * Salva i dati di login dell'utente nella permanentLocalStorage,
     * utilizzati solo nel caso di mobile app.
     *
     * @param {Object}
     * @return {Boolean} - se l'azione è stata eseguita o meno
     */
    MOP.setLoginData = function( login_data ){
        if( MOP.Utilities.isMobileApp()){
            MOP.permanentLocalStorage.set('login_data', login_data);
            return true;
        }
        return false;
    };

    /**
     * Pulisce tutti i cookies relativi alla sessione corrente.
     */
    MOP.resetSessionCookies = function() {
        if (MOP.Utilities.isMobileApp()) {
            window.cookies.clear();
        } else {
            Cookies.remove('sessionid', { path: '/' });
            Cookies.remove('sessionName', { path: '/' });
            Cookies.remove('sessionProfile', { path: '/' });
            Cookies.remove('sessionUserId', { path: '/' });
            Cookies.remove('PHPSESSID', { path: '/' });
        }
    };

    MOP.resetLoginData = function(){
        if( MOP.Utilities.isMobileApp() ){
            MOP.permanentLocalStorage.unset('login_data');
            //window.docCookies.removeItem('dossierSubSession');
            MOP.permanentLocalStorage.unset('dossierSubSession');
            return true;
        }
        return false;
    };

    MOP.resetBackboneCache = function(){
        var backbone_cache_storage = new MopStorage({id: Backbone.fetchCache.getLocalStorageKey(), type: MOP.constants.LOCAL_STORAGE});
        backbone_cache_storage.destroy();
        return true;
    };

    /**
     * Manual invalidation of the fn in the backbone.fetchcache
     *
     * @param {String} fn
     * @returns {boolean}
     */
    MOP.clearBackboneCacheItemsByJsonFn = function( fn ){
        for(var key in  Backbone.fetchCache._cache) {
            if( key.search(`/${fn}`) > -1 ){
                Backbone.fetchCache.clearItem(key);
            }
        }
        return true;
    };

    MOP.getSessionid = function(){
    	if( MOP.isLoggedIn() ){
    		return MOP.state.loggedUser.get('sessionid');
    	}
    	return undefined;
    };

    /**
     * Ritorna true se l'utente è affidabile, false se non lo è
     * @return {Boolean}
     */
    MOP.isUserTrusted = function(){
      // Per il onetime user non valutiamo la trustability
      if (MOP.isOneTimeUser()) return true;
      
      return (Number(MOP.getLoggedUserData().trustability) >= Number(MOP.config.getInstanceConfig('trustabilityMinThreshold')) ? true : false);
    };

    /**
     * Ritorna i dati relativi all'utente come Object.
     * @return {Object|false}
     */
    MOP.getLoggedUserData = function(){
        return MOP.state.loggedUser && MOP.state.loggedUser.isLoggedIn() && MOP.state.loggedUser.toJSON();
    };

    /**
     * Ritorna un istanza del modello User inizializzato
     * con i dati dell'utente corrente.
     * @return {Object} - Entities.User
     */
    MOP.getLoggedUser = function () {
        return MOP.state.loggedUser;
    };

    /**
     * Aggiorna i dati dell'utente attualmente loggato con quelli passati.
     * @param  {Object} data
     * @return {Boolean} - se l'azione è stata eseguita o meno
     */
    MOP.updateLoggedUserData = function (data) {
      return MOP.state.loggedUser && MOP.state.loggedUser.set(data);
    };

    MOP.updateLoggedUserDataParents = function (userid, data) {
        return MOP.state.loggedUser && MOP.state.loggedUser.updateLoggedUserDataParents(userid, data);
    };

    /**
     * Restituisce il valore associato alla proprietà dell'utente.
     * @param  {String} field
     * @return {String|Object|Number|Null}
     */
    MOP.getLoggedUserDataField = function (field) {
        return MOP.state.loggedUser && MOP.state.loggedUser.get(field);
    };

    MOP.updateLoggedUserDataField = function (field) {
      return MOP.state.loggedUser && MOP.state.loggedUser.set(field);
    };

    MOP.getPrivacyHistoryPrivacyModified = function () {
        return MOP.state.loggedUser.getPrivacyHistoryPrivacyModified();
    };

    MOP.setPrivacyHistoryPrivacyModified = function (modified) {
      return MOP.state.loggedUser.setPrivacyHistoryPrivacyModified(modified);
    };

    MOP.isLoggedIn = function () {
        return MOP.state.loggedUser && MOP.state.loggedUser.isLoggedIn();
    };

    MOP.getLoggedUserId = function () {
        return MOP.state.loggedUser && MOP.state.loggedUser.getLoggedUserId();
    };

    MOP.isFromTRD111 = function () {
        return (MOP.state.loggedUser && MOP.state.loggedUser.isFromTRD111());
    };

    MOP.isFromAuthModePayment = function () {
      return (MOP.state.loggedUser && MOP.state.loggedUser.isFromAuthModePayment());
    }

    MOP.isAuthModeEnabled = function () {
        return MOP.state.loggedUser && MOP.state.loggedUser.isAuthModeEnabled();
    };

    MOP.getLoggedUserPosition = function () {
        return MOP.state.loggedUser && MOP.state.loggedUser.getLoggedUserPosition();
    };

    MOP.getLoggedUserProfile = function () {
        return MOP.state.loggedUser && MOP.state.loggedUser.getLoggedUserProfile();
    };

    MOP.isResourceLoggedIn = function () {
        return MOP.state.loggedUser && MOP.state.loggedUser.isResourceLoggedIn();
    };

    MOP.isBaseResourceLoggedIn = function () {
        return MOP.state.loggedUser && MOP.state.loggedUser.isBaseResourceLoggedIn();
    };

    MOP.isAdvancedResourceLoggedIn = function () {
        return MOP.state.loggedUser && MOP.state.loggedUser.isAdvancedResourceLoggedIn();
    };

    MOP.isOperatorNotResourceLoggedIn = function () {
        return MOP.state.loggedUser && MOP.state.loggedUser.isOperatorNotResourceLoggedIn();
    };

    MOP.isOperatorOrAdvancedResource = function () {
      return MOP.isOperatorNotResourceLoggedIn() || MOP.isAdvancedResourceLoggedIn();
  };

    MOP.isAdminLoggedIn = function(){
        var profile = this.getLoggedUserProfile();
        if( (profile !== null) && (_.indexOf([MOP.constants.ADMIN, MOP.constants.SUPER_ADMIN, MOP.constants.VIEWER, MOP.constants.OPERATOR], parseInt(profile)) != -1) ){
            return true;
        }else{
            return false;
        }
    };

    MOP.isOneTimeUser = function(){
        return MOP.state.loggedUser && MOP.state.loggedUser.isOneTimeUser();
    };

    MOP.isSSOUser = function(){
      return MOP.state.loggedUser && MOP.state.loggedUser.isSSOUser();
    };

    MOP.isSamlUser = function(){
      return MOP.state.loggedUser && MOP.state.loggedUser.isSamlUser();
    };

    MOP.isAdeslasUser = function(){
      return MOP.state.loggedUser && MOP.state.loggedUser.isAdeslasUser();
    };

    MOP.isSpidUser = function(){
      return MOP.state.loggedUser && MOP.state.loggedUser.isSpidUser();
    };

    /** killOneTimeSession
     * * Questa funzione viene chiamata da tutti i flussi che vogliono il onetime user
     * * se viene chiamata dalla countdown per ora impostato a OTU_SESSION_DURATION allora mostra anche
     * * un messaggio al cambio della pagina
     * 
     * @param {Boolean} countdownExpired 
     *  se true mostro un alert dopo aver fatto il redirect
     *  se false disattivo il timer dell'OTU e non mostro alcun alert
     * @param {AlertType} customAlert 
     *  se viene passato, mostro questo alert custom dopo aver fatto redirect
     */
    MOP.killOneTimeSession = function(countdownExpired = false, customAlert = null){

      // Andiamo a ripulire il countdown perchè la chiamata è stata fatta non dal countdown
      // e per evitare che venga chiamato due volte lo puliamo
      if (countdownExpired === false) {
        clearTimeout(MOP.OneTimeUserCountdownKillSession);
      }
      MOP.OneTimeUserCountdownKillSession = null;
      MOP.localStorage.unset('OneTimeUserCountdownKillSession');

      MOP.LM.doLogout(true)
      .then(() => {

        let alert = { type: 'warning', msg:TTPolyglot.t('MOP OTU Countdown Session Expired') };
        if (countdownExpired === false) alert = null;

        if (MOP.getDbName() === 'tt_portal_adeslas' || MOP.getDbName() === 'tt_portal_adeslas_test') {
          return MOP.changePage('login', null, null, null, alert || customAlert);
        }
  
        if (MOP.config.isOtuPost(MOP)) {
          return MOP.changePage("search");
        }
        return MOP.changePage("profile", "profile/ot_registration", null, null, alert || customAlert);
      })
      };
    
      // Questa funzione viene chiamata quando l'utente viene settato se è un OTU, se lo è allora
      // parte un timeout di OTU_SESSION_DURATION che poi slogga l'utente.
      // Viene salvato il session storage il timestamp di quando è partita la richiesta, così che
      // se viene aggiornata la pagina allora viene recuperato il timestamp e fatta la differenza per
      // riprendere il timer da dove era stato fermato, ovviamente se è scaduto lo slogga in quanto viene
      // eseguito subito.
    MOP.startCountdownKillSession = () => {
      if (MOP.Utilities.empty(MOP.OneTimeUserCountdownKillSession) && MOP.isOneTimeUser()) {
        // Controlliamo se era stato fatto partire un timer
        let duration = MOP.constants.OTU_SESSION_DURATION;
        let backupCountdown = MOP.localStorage.get('OneTimeUserCountdownKillSession') || 0;

        if (backupCountdown !== 0) {
          backupCountdown = MOP.Utilities.getTimestamp() - backupCountdown;
          if (backupCountdown >= duration) {
            duration = 0;
          } else {
            duration = (MOP.constants.OTU_SESSION_DURATION - backupCountdown) * 1000;
          }
        } else {
          duration = duration * 1000;
          // Questo lo salviamo solo in questa condizione perchè se è 0 sarà eseguito subito e quindi verrà sloggato
          MOP.localStorage.set('OneTimeUserCountdownKillSession', MOP.Utilities.getTimestamp());
        }
        
        // Viene salvato l'id del timeout che poi ci servirà per annullarlo se serve
        MOP.OneTimeUserCountdownKillSession = setTimeout(() => {
          MOP.killOneTimeSession(true);
        }, duration);
      }
    }



    MOP.isViewerLoggedIn = function(){
        var profile = this.getLoggedUserProfile();
        if( (profile !== null) && (_.indexOf([MOP.constants.VIEWER], parseInt(profile)) != -1) ){
            return true;
        }else{
            return false;
        }
    };

    MOP.isLoggedUserValidated = function isLoggedUserValidated() {

      // Se un utente proviene da login con SAML, dobbiamo sempre saltare la validazione
      if(MOP.isSamlUser()) {
        return true
      }

      return !MOP.Utilities.empty(MOP.getLoggedUserDataField('validated'));
    };

    MOP.isValidationEnabled = function isValidationEnabled () {

      if (MOP.isLoggedIn()) {
        if (!MOP.isAdminLoggedIn() && parseInt(MOP.config.getInstanceConfig('validation_required'), 10) >= 0) {
          return true;
          /*
           1. Se backofficeUsersVcodeEnabled == 0 allora non c'è validazione
           2. Se backofficeUsersVcodeEnabled è pieno allora sicuramente è un timestamp quindi:
            a. Se il validated dell'utente è < o nullo di backofficeUsersVcodeEnabled allora è da validare
            b. Se il validated dell'utente è > backofficeUsersVcodeEnabled allora Non è da validare
          
            Quindi la validazione entra in gioco solo 2.2a
          */
        } else if (MOP.isAdminLoggedIn() && !MOP.Utilities.empty(MOP.config.getInstanceConfig('backofficeUsersVcodeEnabled'))) {
          if (MOP.Utilities.empty(MOP.getLoggedUserDataField('validated')) || MOP.getLoggedUserDataField('validated') < MOP.config.getInstanceConfig('backofficeUsersVcodeEnabled')) {
            return true;
          }
        }
      }

      return false;
    };

    MOP.sendVcode = function sendVcode (userid) {

      var ajaxRestParams = {
        searchPath: "users/"+userid+"/_vcode",
        ajax: {
          options: {
            method: 'PUT'
          }
        },
        loading: {
            enabled: true
        }
      };
      
        var requesting = MOP.ajaxRest(ajaxRestParams);

        $.when(requesting)
        .done(function (resp) {
          // Non vogliamo gestire nessun tipo di redirect perchè ci serve solo per mandare il vcode
        });

    }

    // Qui andremo a forzare il non paziente a una pagina specifica dopo il Login
    MOP.notUserLandingPageAfterLogin = function notUserLandingPageAfterLogin(forced_lang) {
      
      // Se siamo nel flusso della prenotazione e stiamo andando al summary non facciamo redirect ma continuiamo la navigazione
      if (MOP.localStorage.has('anchorPage') && MOP.localStorage.get('anchorPage') == 'availabilities' && MOP.localStorage.has('anchorPageRoute') && MOP.localStorage.get('anchorPageRoute').indexOf('summary') != -1) {
        return MOP.goToAnchorPage(null, 'search', forced_lang);
      } else if (MOP.localStorage.has('anchorPage') && MOP.localStorage.get('anchorPage') == 'agenda' && MOP.isResourceLoggedIn()) {
        return MOP.goToAnchorPage(null, 'agenda', forced_lang);
      }
    
      if (MOP.isResourceLoggedIn()) {
        return MOP.changePage("agenda", null, null, null, null, forced_lang)
      } else {
        return MOP.changePage("patients", "query/&", null, null, null, forced_lang)
      }

    }

MOP.getMainLoggedUserData = function getMainLoggedUserData () {

      const loggedUser = MOP.getLoggedUserData();
      const { 
        fname,
        lname,
        lname_2,
        phone,
        idnumber,
        birthday, 
        email,
        gender,
        // A seguito del ticket https://tuotempo.freshdesk.com/a/tickets/102736 
        // aggiungiamo anche le chiavi sull'indirizzo dell'utente tra i dati tornati
        address,
        street_number,
        cp,
        neighborhood,
        city,
        province,
        region,
        country
    } = loggedUser;

      const result = {
        "user_name": `${fname} ${lname}`,
        "fname": fname,
        "lname": lname,
        "phone": phone,
        "idnumber" : idnumber,
        "birthday": birthday,
        "email": email,
        "gender": gender,
        "address": address,
        "cp": cp,
        "neighborhood": neighborhood,
        "city": city,
        "province": province,
        "region": region,
        "country": country

      }

      if(!MOP.Utilities.empty(MOP.config.getInstanceConfig('showStreetNumberInAddress'))) {
        // inviamo lo street number solo se il relativo config è attivo
        result["street_number"] = street_number;
      }

      if (!MOP.Utilities.empty(loggedUser.lname_2))
        result["lname_2"] = loggedUser.lname_2;

      return result;
    };

    MOP.generateApplicationId = function( without_sessionid ){
        return "MOP." + this.getDbName() + (without_sessionid ? "" : "." + this.getSessionid());
    };

    /**
     * Ritorna l'id della permanent local storage
     * @returns {string}
     */
    MOP.generateApplicationPermanentId = function generateApplicationPermanentId() {
        return 'MOP.permanent.' + this.getDbName();
    };

    MOP.log = Utilities.log;

    // Ritorna la pagina dell'applicazione startata
    MOP.getCurrentPage = function(){
        return window.MOP_globals.page;
    };

    // Ritorna la pagina recuperandola dalla rotta, non è detto che la pagina sia già startata
    MOP.getPageFromRoute = function(){
        var _route = MOP.getCurrentRoute();
        if (!MOP.Utilities.empty(_route)) {
            return _route.indexOf('/') != -1 ? _route.substr(0, _route.indexOf('/')) : _route; 
    
        }

        return '';
    };

    MOP.navigate = function(route, options, silent){
        //MOP.execute("MOP:clearAlert");	//FIXME[GIO]
        // Nei cambi rotta voglio forzare lo scrolling vero il top della pagina
        if (silent == null) MOP.TM.scrollTop();

        options || (options = {});
        
        if(MOP.config.isNewLauncher()){
          MOP.sendMessageToParentFrame({fn: "mopLauncher-updateRoute", route})
        }
        // La regexp applicata alla rotta fa si che questa non contenga
        // slash ripetute che potrebbero non fare scattare i router corretti.
        Backbone.history.navigate(route.replace(/([^:]\/)\/+/g, '$1'), options);
    };

    MOP.forceNavigate = function(route, silent){
        //MOP.execute("MOP:clearAlert");	//FIXME[GIO]
        // Nei cambi rotta voglio forzare lo scrolling vero il top della pagina
        if (silent == null) window.scrollTo(0, 0);

        // La regexp applicata alla rotta fa si che questa non contenga
        // slash ripetute che potrebbero non fare scattare i router corretti.
        Backbone.history.fragment = null;
        Backbone.history.navigate(route.replace(/([^:]\/)\/+/g, '$1'), true);
    };

    MOP.navigateBack = function(options){
        //MOP.execute("MOP:clearAlert");	//FIXME[GIO]
        options || (options = {});
        window.history.back();
        window.MOP_regionDirectionReverse = true;
    };

    MOP.getCurrentRoute = function() {
      if (Backbone.History.started) {
        return Backbone.history.fragment;
      } else {
        return window.location.hash ? window.location.hash.replace("#","") : undefined;
      }

    };

	MOP.startSubApp = function (appName, args, forceStopCurrentApp) {
		var currentApp = appName ? MOP.module(appName) : null;

        if (MOP.currentApp == currentApp && !forceStopCurrentApp) { return; }

        if (MOP.currentApp) {
            MOP.currentApp.stop();
            MOP.trigger('subapp:stop', MOP.currentApp.page);
        }

        MOP.currentApp = currentApp;

        if (currentApp) {
            currentApp.start(args);
            MOP.trigger('subapp:start', MOP.currentApp.page);
            if (MOP.Utilities.isMobileApp(true)) {
              if (!MOP.Utilities.empty(window.ga) && typeof window.ga === Object) {
                //Plugin Cordova Google Analytics per monitorare ogni pagina
                if (!MOP.Utilities.empty(window.MOP_globals.customerConfig.googleAnalytics)) {
                  window.ga.trackView(MOP.currentApp.page);
                }
              }

            }
        }
    };
    MOP.getParentLocation = function () {
        return MOP.querystring['parent_location'];
    };

    //------------------------------------------------------------------------------------------------
    MOP.getSearchDynamicUsersAjaxObject = function(ajax_customizations, pre_process_callback) {
      
      // Per il momento è stata adattata l'ajax per farla rispecchiare alla nostra, ma questo plugin andrà tolto perchè utilizza bootstrap e
      // e non utilizza l anostra funzione ajax, quindi andrà rifatto
      var url = MOP.Utilities.ApiV3Module.getRestApi_AjaxUrl(MOP, { searchPath: "users"});
      var params = MOP.config.getAjaxDefaultParams(MOP, true);


      var ajax_customizations = ajax_customizations || {};
      var ajax = {
        url: url,
        timeout: 500,
        displayField: "text",
        valueField: "id",
        triggerLength: 3,
        method: "get",
        headers: MOP.getSessionid() ? {"Authorization": "Bearer "+MOP.getSessionid()} : {},
        loadingClass: "loading-circle",
        preDispatch: function(query) {
          MOP.TM.blockTypeheadLoading();

          params.profile = MOP.constants.EXTERNAL_USER;
          params.textToSearch = query;

          if (MOP.isAdminLoggedIn() && MOP.getCurrentPage() == 'patients') {

            if (!MOP.localStorage.has("mop-admin-show-only-future-toggle") || 
                (
                  MOP.localStorage.has("mop-admin-show-only-future-toggle") && 
                  !MOP.Utilities.empty(MOP.localStorage.get("mop-admin-show-only-future-toggle"))
                )
              ) {
              params.with_future_reservations = 1;
              params.with_reservations = 0;
            } else {
              params.with_reservations = 0;
              params.with_future_reservations = 0;
            }
          }
          return params;
        },
        preProcess: function(resp) {
          MOP.TM.unblockTypeheadLoading();

          var data = MOP.checkAjaxResponse(resp);
          if (pre_process_callback) {
            pre_process_callback(data);
          }
          if (data['result'] == 'ERROR') {
            var users_data_to_return = [];
            users_data_to_return.push({
              id: 'client',
              text: data['msg']
            });
            return users_data_to_return;
          } else if (data['result'] == 'OK') {
            
            var users_data_to_return = [];

            var usersDynamicSearchFields = MOP.config.getInstanceConfig('usersDynamicSearchFields').split(",");
            
            if (data['return']['results'] && data['return']['results'].length > 0) {
              _.each(data['return']['results'], function(element, index, list) {
                
                var str = [];
                let nameStr = '';
                let socialNameStr = '';

                // search box, iterate on all the splitted substring of the config
                _.each(usersDynamicSearchFields, function(field) {

                  if(field === 'fname') {
                      if (!MOP.Utilities.empty(element[field])) nameStr = element[field];
                  } else if (field === 'social_name'){
                      if (!MOP.Utilities.empty(element[field])) socialNameStr = element[field];
                  } else if (field !== 'memberid') {
                    if (!MOP.Utilities.empty(element[field])) str.push(element[field]);
                  }

                })

                const separator = MOP.Utilities.empty(socialNameStr) ? ' ' : '/';

                var user_data_to_return = {
                  id: element['memberid'],
                  text: `${nameStr}${separator}${socialNameStr} ${str.join(" ")}`
                };
                users_data_to_return.push(user_data_to_return);
              });
            } else {
              users_data_to_return.push({
                id: 'client',
                text: TTPolyglot.t('No results')
              });
            }

            return users_data_to_return;
          }
          return false;
        }
      };
      $.extend(ajax, ajax_customizations);

      return ajax;
    };

    MOP.getSearchDynamicCitiesAjaxObject = async function( ajax_customizations = {}, pre_process_callback ){
      // Per il momento è stata adattata l'ajax per farla rispecchiare alla nostra, ma questo plugin andrà tolto perchè utilizza bootstrap e
      // e non utilizza l anostra funzione ajax, quindi andrà rifatto
        var url = MOP.Utilities.ApiV3Module.getRestApi_AjaxUrl(MOP, { searchPath: "cities"});
        var params = MOP.config.getAjaxDefaultParams(MOP, true);

        const apiPreventScraping = MOP.config.getInstanceConfig('apiPreventScraping');

        const getResult = (token) => {
          const headers = MOP.getSessionid() ? {"Authorization": "Bearer "+MOP.getSessionid()} : {};

          if (token) {
            headers['X-TuoTempo-Authorization'] = token;
          }

          var ajax = {
              url: 			url,
              timeout: 		500,
              displayField: 	"text",
              valueField: 	"id",
              triggerLength: 	3,
              method: 		"get",
              headers,
              loadingClass:   "loading-circle",
              preDispatch: function (query) {
                  MOP.TM.blockTypeheadLoading();

                  params.textToSearch = query;
                  params.country = MOP.config.getInstanceConfig('country');
                  params.dbName = MOP.getDbName();
                  return params;
              },
              preProcess: function (resp) {
                  MOP.TM.unblockTypeheadLoading();

                  var data = MOP.checkAjaxResponse(resp),
                      suggestion = [];

                  if( pre_process_callback ){
                      pre_process_callback(data);
                  }
                  
                  if (data['result'] == 'OK') {
                      _.reduce(data['return']['results'], function(acc, item) {
                          acc.push({
                              id: item.code,
                              text: item.description
                          });
                          return acc;
                      }, suggestion);
                  }
                  return suggestion;
              }
          };

          _.extend(ajax, ajax_customizations);

          return ajax;
        }

        if (!MOP.Utilities.empty(parseInt(apiPreventScraping?.totp?.enabled)) && !MOP.Utilities.empty(window.POTT)) {
          return window.POTT.generateToken().then((token) => {
            return getResult(token);
          });
        } else {
          return getResult();
        }
    };
	//------------------------------------------------------------------------------------------------
    /**
     * @var string fn
     * @var object more_params
     * @var object more_options
     * @var bool   loading
     * @var object loading_options
     *
     * @return promise
     */
    MOP.ajax = function (params) {
        params = params || {};
        params.ajax = params.ajax || {};
        params.ajax.options = params.ajax.options || {};

        var defaultAjaxOptionsParams = {
            options: {
                type:       'GET',
                dataType:   'json'
            }
        };
        params = _.extend({}, defaultAjaxOptionsParams, params);

        var deferred = new $.Deferred();
        MOP.Utilities.ajax(MOP, params)
            .done(function (resp) {
                try {
                    deferred.resolve(MOP.checkAjaxResponse(resp));
                } catch (e) {
                    if (params.preventThrowException) {
                        deferred.reject();
                    }

                    throw e;
                }
            })
            .fail(function(resp){
              if(resp === MOP.constants.AJAX_OFFLINE_REJECT) return
              deferred.reject(resp)
            });

        return deferred;
    };

    MOP.ajaxRest = function (params, insertAuthBearer) {
        var deferred = $.Deferred();

        MOP.Utilities.ajax(MOP, params, undefined, insertAuthBearer)
            .done(function (resp) {
                try {
                    if (!params.bypassCheckResponse) {
                        MOP.checkAjaxResponse(resp);
                    }
                    deferred.resolve(resp);
                } catch (e) {
                    MOP.Utilities.log(MOP, e);
                    
                    if (params.preventThrowException) {
                        deferred.reject();
                    }

                    throw e;
                }
            })
            .fail(function (resp) {
              if(resp === MOP.constants.AJAX_OFFLINE_REJECT) return
              try {
                  if (!params.bypassCheckResponse) {
                      MOP.checkAjaxResponse(resp);
                  }
                  deferred.resolve(resp);
              } catch (e) {
                  MOP.Utilities.log(MOP, e);
                  
                  if (params.preventThrowException) {
                      deferred.reject();
                  }

                  throw e;
              }
            });


        return deferred;
    };

	MOP.checkAjaxResponse = function( data ){
    
    MOP.Utilities.log(MOP, "Ajax result: " + data['result']);
    
		if( data['result'] == 'EXCEPTION' || data['exception'] == "TUOTEMPO_NOT_LOGGED_IN" || data['exception'] == "HTTP_METHOD_NOT_IMPLEMENTED"){

			if( data['exception'] == 'TUOTEMPO_EMPTY_RESPONSE' ){				// - Empty Response
        var alert = {type: "danger", msg: data['msg']};
        MOP.execute("MOP:alert", alert);
			}else if( data['exception'] == 'TUOTEMPO_SERVICE_UNAVAILABLE' ){	// - MANTAINANCE o FATAL ERRORS
				var alert = {type: "danger", msg: data['msg']};
				MOP.execute("MOP:alert", alert);
      }else if( data['exception'] == 'TUOTEMPO_SERVICE_NOT_ALLOWED' ){	// - UsersReserve
        //cambiamo hash a mano perchè altrimenti con changePage() non funzionerebbe, poichè il Router non è ancora startato
        window.location.hash = 'maintenance';
        MOP.isMaintenanceMode = true;
        return;
			}else if( data['exception'] == 'TUOTEMPO_LOGIN_FIRST' ){  			// - LoginFirst
				this.resetSession();
				this.changePage('login', null, this.getCurrentPage(), this.getCurrentRoute());
			}else if ( data['exception'] == 'TUOTEMPO_NOT_LOGGED_IN' || data['exception'] == "HTTP_METHOD_NOT_IMPLEMENTED"){			// - Not Logged In
				this.resetSession();
				this.changePage('login', null, this.getCurrentPage(), this.getCurrentRoute());
			} else if ( data['exception'] == 'TUOTEMPO_MOBILE_APP_NOT_UPDATED' ) {			// - App Not Updated

            var _views = require("views");
          var link = '';
          if (MOP.Utilities.isAppleDevice()) {
            link = !MOP.Utilities.empty(data['appleAppLink']) ? data['appleAppLink'] : MOP.config.constants.APP_STORE_URL;
          } else if (MOP.Utilities.isAndroidDevice()) {
            link = !MOP.Utilities.empty(data['androidAppLink']) ? data['androidAppLink'] : MOP.config.constants.GOOGLE_PLAY_STORE_URL;
          }

          var btn_msg = !MOP.Utilities.empty(data['buttonMsg']) ? data['buttonMsg'] : "Mobile App Do Upgrade";

          var dialog_content = {link: link, msg: data['msg'], btn_msg: btn_msg, external: true};
          MOP.execute('MOP:dialog:show', MOP.Common.Views.view_functions.loadingAppDialog(dialog_content));

      } else if (
                $.inArray(
                    data['exception'],
                    [
                     'PROVIDER_EXCEPTION',
                     'PROVIDER_COMMUNICATION_EXCEPTION',
                     'PROVIDER_UNHEALTHY',
                     'MEMBERS_EXCEPTION',
                     'MEMBERS_PROVIDER_EXCEPTION',
                     'INTEGRATION_REQUEST_WITHOUT_REQUIRED_PARAMS',
                     'INTEGRATION_RESPONSE_WITHOUT_REQUIRED_PARAMS',
                     'INTEGRATION_RESPONSE_KO_RESULT',
                     'INTEGRATION_RESPONSE_MALFORMED_RESULT',
                     'INTEGRATION_INVALID_API_REQUEST',
                     'INTEGRATION_INVALID_API_RESPONSE',
                     'INTEGRATION_INVALID_INFORMATION',
                     'INTEGRATION_INVALID_SET_CLIENT',
                     'INTEGRATION_FUNCTION_NOT_IMPLEMENTED',
                     'INTEGRATION_INIT_CLIENT_ERROR',
                     'INTEGRATION_TIMEOUT',
                     'INTEGRATION_INTERFACE_NOT_IMPLEMENTED',
                     'INTEGRATION_RESERVATION_NOT_PRESENT',
                     'PROVIDER_ERROR'
                     ]) > -1
                ){																// - Provider/Members exceptions
                if( MOP.Utilities.weAreOnDevelopment(MOP) ){
                    var alert = {type: "danger", msg: data['exception'] + ": " + data['msg'], showChangeSearch: true};
                }else{
                    var alert = {type: "danger", msg: TTPolyglot.t('Mop not allowed'), showChangeSearch: true};
                }
                MOP.execute("MOP:alert", alert);
            }else {																// - Exception in json
				if( MOP.Utilities.weAreOnDevelopment(MOP) ){
					var alert = {type: "danger", msg: data['exception'] + ": " + data['msg']};
				}else{
					var alert = {type: "danger", msg: data['msg']};
				}
				MOP.execute("MOP:alert", alert);
			}

            // Nel caso di exception nascondiamo sempre il loading per
            // non dare all'utente l'effetto di "azione in corso".
            MOP.TM.updateVisibility({hide: [$('#loading-region')]});
            MOP.TM.unblockLoading(true);
            MOP.TM.unblockLoading(false);

			//Lanciamo una Exception bloccando il processo
      
      //OLD
			//throw (data['exception'] + ": " + data['msg']);

      //NEW
      //Oggetto err.response.data per gestire eccezione nel JSON
      throw({ response: { data: `${data['exception']}: ${data['msg']}` }});
		}else{

			return data;
		}
	};  
	//------------------------------------------------------------------------------------------------
    /**
     *
     * @param {Object} page_route
     * @param {String} page_rote.page
     * @param {String} page_rote.route
     * @param {Object} [forced_param={}]
     * @param {Array}  [excluded_params=[]]
     * @returns {string}
     */
    MOP.getMopPageUrl = function( page_route, forced_param, excluded_params, goToExternalPage, omitCurrentQueryString ){
        var extension = '';
        var server_addr = '';
        var page  = page_route.page;
        var route = page_route.route;

        forced_param = forced_param || {};
        excluded_params = excluded_params || [];

		if (MOP.Utilities.isMobileApp(true) && MOP.Utilities.empty(goToExternalPage)) {
			extension = 'html';
            server_addr = '';
		} else {
			var protocol = MOP.config.isSecureConnection() || MOP.Utilities.isMobileApp() ? 'https://' : 'http://';
			extension = 'php';
      if (MOP.Utilities.empty(window.MOP_globals.e2e)) {
        server_addr = protocol + this.config.get('hostname') + '/mop/';
      } else {
        server_addr = window.location.origin + '/mop/';
      }
		}

		var url = server_addr + 'index.' + extension + '?';
    var clonedQuerystring = _.clone(querystringToObject(window.location.search.substr(1)));

    ///// SESSIONID /////////////////////////////////////////////////////////////////////////////////////////
		//(NB) Se siamo loggati aggiungiamo il sessionid ai parametri della querystring ma
    //     solo se dall'app nativa vogliamo andare sul browser senza perdere la sessione
    //(NB) Manteniamo il sessionid anche in una connessione non sicura perchè il cookie di sessione
    //    viene mantenuto nel dominio sicuro e quindi non viene riletto corettamente dal PHP della pagina successiva
    //    Se siamo loggati aggiungiamo il sessionid ai parametri della querystring se non siamo in app nativa
    //    e se i cookie non sono abilitati. Abbiamo introdotto questo fix per supportare il login ai browser che non hanno
    //    i cookie attivi, e soprattuto per Safari che di default non scrive i cookie di un iFrame se il sito dell'iFrame non è già
    //    stato navigato singolarmente.
		if (MOP.isLoggedIn() && ((MOP.Utilities.isMobileApp(true) && !MOP.Utilities.empty(goToExternalPage))
                          || (!MOP.Utilities.isMobileApp(true) && !MOP.config.isSecureConnection())
                          || (!MOP.Utilities.isMobileApp(true) && !MOP.config.areCookiesEnabled()))) {

      forced_param.sessionid = encodeURIComponent(this.getSessionid());
      //(NB) Altrimenti NON vogliamo più trasportare il sessionid in querystring al cambio pagina
      //vedi Remediation => http://redmine.tuotempo.net/issues/10607
    } else {
      clonedQuerystring = _.omit(clonedQuerystring, 'sessionid');
    }
		/////////////////////////////////////////////////////////////////////////////////////////////////////////

        // Per poter tornare alla app nativa dalla pagina di pagamento
        if (MOP.Utilities.isMobileApp() && page == 'payment') {
            forced_param.mobileapp = encodeURIComponent(MOP.Utilities.getPublicURL(MOP));
        }

        // Andiamo a svuotare la query string corrente se vogliamo ometterla, per adesso usiamo questa modalità solo nei cambi di istanza nell'app
        if (!MOP.Utilities.empty(omitCurrentQueryString)) {
          clonedQuerystring = {};
        }

        _.extend(clonedQuerystring, forced_param);
        clonedQuerystring = _.omit(clonedQuerystring, excluded_params);
        url += $.param(clonedQuerystring) + (route ? '#' + route : '');

		return url;
	};

  MOP.createPageUrlInIframe = function (page, route) {
    
    if (!MOP.Utilities.empty(page) && !MOP.Utilities.empty(MOP.getParentLocation())) {

      var url = MOP.getParentLocation();
      url = MOP.Utilities.updateQuerystringUrlParameter(url, "forcePage", page);
      url = MOP.Utilities.updateQuerystringUrlParameter(url, "forceRoute", route);
      return url;
    }

    return '';
  }

	MOP.goToLocationDetails = function( areaid, fromPage = null ){
    let route = `area/${areaid}`;
    if(!MOP.Utilities.empty(fromPage)) route += `/fromPage/${fromPage}`
		MOP.changePage('find_location', route);
	};

	MOP.isSearchPage = function( page ){
		return ( page.indexOf('search') !== -1 );
	};

    /**
     * Redirige all'istanza selezionata.
     * @param {String} selectedInstanceData
     * @param {Boolean} storeIt
     */
    MOP.goToSelectedInstance = function goToSelectedInstance(selectedInstanceData, storeIt) {
        if (storeIt) {
            window.localStorage.setItem('selectedInstance', JSON.stringify(selectedInstanceData));
        }

        if (MOP.Utilities.empty(selectedInstanceData.params)) {
            selectedInstanceData.params = {
                "dbName": selectedInstanceData.instance
            }
        }

        //Se siamo in un'app con il selettore di istanza allora se è presente doLoginForSchemaUrl andiamo a metterlo tra i parametri del changePage
        var forcedParams = selectedInstanceData.params;
        if (!MOP.Utilities.empty(MOP.queryStringSchemaUrl["doLoginForSchemaUrl"])) {
            forcedParams.oauthSession = MOP.queryStringSchemaUrl["sessionid"];
        }

        MOP.changePage(selectedInstanceData.defaultPage || 'search', null, null, null, null, null, forcedParams, null, null, true);
    };
    
    MOP.checkLoginFirstWhiteListPages = function (_page, _route) {

      var loginFirstWhiteListPages = [
        {page: "login", route: "", useIndexOf: false}, 
        {page: "login", route: "login", useIndexOf: false}, 
        {page: "login", route: "login/forced", useIndexOf: false},
        {page: "login", route: "login/forgot", useIndexOf: false}, 
        {page: "profile", route: "profile/register", useIndexOf: false}, 
        {page: "profile", route: "profile/ot_registration", useIndexOf: false}, 
        {page: "contactus", route: "contactus", useIndexOf: false}, 
        {page: "home", route: "home"}, 
        {page: "home", route: "home/first_access", useIndexOf: false},
        {page: "dossier", route: "dossier/sharedDocuments", useIndexOf: true},
        {page: "reservations", route: "print", useIndexOf: true}, //Questa a causa del OneTimeUser che ci arriva sloggato deve essere nella white list
        {page: "maintenance", route: "maintenance", useIndexOf: false}, 
      ];
      
      var check = false;
      
      for (var i = 0; i < loginFirstWhiteListPages.length; i++) {
        
        if (loginFirstWhiteListPages[i].page == _page) {  
          if (_route ) {
            if (loginFirstWhiteListPages[i].useIndexOf) {
              if (_route.indexOf(loginFirstWhiteListPages[i].route) != -1) {
                check = true;
                break
              }
            } else {
              if (loginFirstWhiteListPages[i].route == _route) {
                check = true;
                break
              }
            }
          } else {
            check = true;
            break
          }
        }
      }
      
      return check;
      
    }
    
    MOP.getPageConfigMapping = function () {

        // Questo oggetto è un mapping tra il nome della pagina e il config collegato, alcune pagine hanno
        // più sottopagine e quindi avremo sotto oggetti da controllare
        var pageToConfigPageMapping = {
            "search": [
                {
                    pageConfig: "search",
                    route: ""
                }
            ],
            "reservations": [
                {
                    pageConfig: "myReservations",
                    route: "reservations"
                },
                {
                    pageConfig: "cancelReservations",
                    route: "reservations/cancel"
                },
            ],
            "patients": [
                {
                  pageConfig: "patients",
                  route:"patients"
                }
            ],
            "dossier": [
                {
                    pageConfig: "dossier",
                    route: ""
                }
            ],
            "downloads": [
                {
                    pageConfig: "downloads",
                    route: ""
                }
            ],
            "find_doctor": [
                {
                    pageConfig: "findDoctor",
                    route: "find_doctor/*"
                },
                {
                    pageConfig: "talkToDoctor",
                    route: "find_doctor/preferred"
                }
            ],
            "find_location": [
                {
                    pageConfig: "findLocation",
                    route: ""
                }
            ],
            "home": [
                {
                    pageConfig: "home",
                    route: "",
                }
            ],
            "prices": [
                {
                    pageConfig: "price",
                    route: ""
                }
            ],
            "blog": [
                {
                    pageConfig: "blog",
                    route: ""
                }
            ],
            "community": [
                {
                    pageConfig: "community",
                    route: ""
                }
            ],
            "profile": [
                {
                    pageConfig: "profile",
                    route: ""
                }
            ],
            "contactus": [
                {
                    pageConfig: "support",
                    route: "contactus/support"
                },
                {
                    pageConfig: "info",
                    route: "contactus/custom"
                }
            ]
        }

        return pageToConfigPageMapping;
    }

    MOP.getFirstActivePage = function () {

        var pageToConfigPageMapping = MOP.getPageConfigMapping();
        var returnPage = {page: "", route: ""};

        var pages = Object.keys(pageToConfigPageMapping);

        //Iterare e cercare la prima pagina valida da restituire
        for (var i = 0; i < pages.length; i++) {

            var page = pageToConfigPageMapping[pages[i]];

            if (page.length == 1) {

                var pageConfig = page[0]["pageConfig"];

                if (MOP.TM.isMenuPageVisibileForUserRole(pageConfig)) {
                    returnPage.page = pages[i];
                    returnPage.route = page[0].route;
                    break;
                }
                
            } else {

                for (var j = 0; j < page.length; j++) {

                    var pageConfig = page[j]["pageConfig"];

                    if (MOP.TM.isMenuPageVisibileForUserRole(pageConfig)) {
                        returnPage.page = pages[i];
                        returnPage.route = page[j].route;
                        break;
                    }
                }

                if (returnPage.page != "") break;
            }
        };
        

        //Se non hai pagine attive vado a 404 DA FARE
        if (MOP.Utilities.empty(returnPage.page)) {
            returnPage.page = '404';
        }

        return returnPage;
    }

        /*
            Questa funzione permette di sapere se è possibile raggiungere la pagina andando a controllare se il config è attivo
            per quell'utente, se non è così ritorna la prima pagina di defualt con un ordine di priorità definito da noi che è 
            rappresentato dall'ordine delle chiavi dell'oggetto pageToConfigPageMapping, con priorità decrescente (il primo è il più prioritario)
        */
        MOP.canNavigateToPage = function (page, route) {
            /*
                Queste rotte sono sempre ammesse, se voglio raggiungere una di queste salto tutto e proseguo
            */
            var whitelistpass = false;

            var menuConfigWhiteListRoute = [
                "profile/expired",
                "profile/expired/otp",
                "profile/admin/profile"
            ];

            if (route) {
              menuConfigWhiteListRoute.forEach(function (whitelist_route) {
                  if (route.indexOf(whitelist_route) != -1) whitelistpass = true;
              });
            }

            if (whitelistpass) return true;


            /*
                Controllo se ci sono dei config disabilitati che mi impediscono di raggiungere una pagina
            */
            var pass = true;

            var pageToConfigPageMapping = MOP.getPageConfigMapping();

            if (pageToConfigPageMapping[page]) {

                var main = pageToConfigPageMapping[page];

                var pageConfig = "";
                if (main.length == 1) {
                    pageConfig = pageToConfigPageMapping[page][0]["pageConfig"]
                } else {

                    var found = true;
                    //Faccio prima un primo giro per fare match 100% uguale
                    for (var i = 0; i < pageToConfigPageMapping[page].length; i++) {
                        //Se la rotta non è vuota allora faccio match
                        if (pageToConfigPageMapping[page][i]["route"].indexOf(route) != -1) {
                            pageConfig = pageToConfigPageMapping[page][i]["pageConfig"];
                            found = true;
                            break;
                        }
                    }

                    // Se non ho fatto ancora match guardo se in una rotto c'è * se c'è facciamo fallback su quella
                    if (!found) {
                        for (var i = 0; i < pageToConfigPageMapping[page].length; i++) {
                            //Se la rotta è vuota allora faccio match
                            if (pageToConfigPageMapping[page][i]["route"].indexOf("*") != -1) {
                                pageConfig = pageToConfigPageMapping[page][i]["pageConfig"];
                                break;
                            }
                        }
                    }
                }

                if (!MOP.Utilities.empty(pageConfig) && !MOP.TM.isMenuPageVisibileForUserRole(pageConfig)) {
                    // Non posso raggiungere la pagina
                    pass = false;
                }
            }

            return pass;
        }
    
      MOP.getProfileFieldToUpdate = function getProfileFieldToUpdate (userToCheck) {

        var fields = [];
  
        var mappingConfigs =  {
          "basic": {
            "mopName": "fname",
            "mopSocialName": "social_name",
            "mopLastName": "lname",
            "mopLastName2": "lname_2",
            "mopMobile": "phone",
            "mopEmail": "email",
            "mopLandline": "hometel",
            "mopOtherphone": "otherphone",
            "mopSkypeid": "skypeid",
            "mopBirthday": "birthday",
            "mopBirthplace": "birthplace",
            "mopGender": "gender",
            "mopSocialGender": "social_gender",
            "mopIdnumber": "idnumber",
            "mopAddress": [
              "address",
              "street_number",
              "cp",
              "city",
              "province",
              "country",
              "region",
              "neighborhood"
            ],
            "mopPayment_method": "payment_method",
            "mopSubscription": "subscription",
            "mopPrivacyPromotion": "privacy_promotions",
            "mopPrivacySurvey": "privacy_review",
            "mopPrivacyCaregiver": "privacy_caregiver"
          },
          "custom": {
            "custom_0": "custom_0",
            "custom_1": "custom_1",
            "custom_2": "custom_2",
            "custom_3": "custom_3",
            "custom_4": "custom_4",
          }
        }
  
        var userData = MOP.getLoggedUserData();
        
        if (!MOP.Utilities.empty(userToCheck)) {
          userData = userToCheck;
        }

        var isLoggedUser = MOP.getLoggedUserId() == userData.id ? true : false;

        var userInfoPolicy = MOP.config.getInstanceConfig('userInfoPolicy');
  
        var dataConfigs = Object.keys(mappingConfigs.basic);
  
        for (var i = 0; i < dataConfigs.length; i += 1) {
          var infoPolicy = parseInt(userInfoPolicy[dataConfigs[i]], 10) ||
          MOP.config.getInstanceConfig('userInfoPolicy' + MOP.TM.ucOnlyFirst(dataConfigs[i])) ||
          MOP.config.getInstanceConfig(dataConfigs[i]);
  
          if (infoPolicy == MOP.constants.FIELD_COMPULSORY) {
            var fieldName = mappingConfigs.basic[dataConfigs[i]];
            if (typeof fieldName === 'string') {

              /*
                Nel caso di campi come idnumber verificare che la combinazione in possesso del paziente 
                sia coerente con le configurazioni. ES: il paziente ha salvato la carta d'identità e il 
                centro decide che da oggi accetterà SOLO il codice fiscale. In questo caso dobbiamo considerare 
                l'idnumber del paziente come non valido e trattarlo come se fosse vuoto e quindi presentare 
                la pagina e il campo.
              */
              // Se è l'idNumber e non è vuoto allora facciamo come detto qui sopra
              if (dataConfigs[i] === 'mopIdnumber') {
                if (!MOP.Utilities.realEmpty(userData[fieldName])) {
                  var idtypes = Object.keys(MOP.config.getInstanceConfig('idtypes'));
                  if (!idtypes.includes(userData.idtype)) {
                    // Se è il familiare devo controllare che il config mopRelativeFollowsParentIdnumberCompulsoriness
                    // sia 0, perchè se è 0 l'id number per i familiare è sempre opzionale
                    if (isLoggedUser === false && MOP.Utilities.empty(MOP.config.getInstanceConfig('mopRelativeFollowsParentIdnumberCompulsoriness'))) {
                    } else {
                      fields.push(dataConfigs[i])
                    }
                  }
                } else {
                  if (isLoggedUser === false && MOP.Utilities.empty(MOP.config.getInstanceConfig('mopRelativeFollowsParentIdnumberCompulsoriness'))) {
                  } else {
                    fields.push(dataConfigs[i])
                  }
                }
              } else {
                if (MOP.Utilities.realEmpty(userData[fieldName])) {
                  fields.push(dataConfigs[i])
                }
              }
            } else {
              // Gestire il tipo array

              var fieldsName = mappingConfigs.basic[dataConfigs[i]];
              for (var j = 0; j < fieldsName.length; j += 1) {
                if (dataConfigs[i] == 'mopAddress') {
                  // Per il brasile non mostriamo il campo province
                  if (fieldName[j] === 'province' && MOP.config.getInstanceConfig('defaultPrefix') == '+55') continue;
                  
                  if (fieldName[j] === 'street_number' && !MOP.Utilities.empty(MOP.config.getInstanceConfig('showStreetNumberInAddress'))) {
                    if (MOP.Utilities.realEmpty(userData[fieldsName[j]])) {
                      fields.push(dataConfigs[i]);
                      break;
                    }
                  } else if (fieldName[j] === 'region' && MOP.config.getInstanceConfig('defaultPrefix') == '+55') {
                    if (MOP.Utilities.realEmpty(userData[fieldsName[j]])) {
                      fields.push(dataConfigs[i]);
                      break;
                    }
                  } else if (fieldName[j] === 'neighborhood' && MOP.config.getInstanceConfig('defaultPrefix') == '+55') {
                    if (MOP.Utilities.realEmpty(userData[fieldsName[j]])) {
                      fields.push(dataConfigs[i]);
                      break;
                    }
                  } else if(fieldName[j] === 'cp' && !MOP.Utilities.empty(MOP.config.getInstanceConfig('mopValidateZipCode'))) {
                    // Se stiamo considerando lo zip code, ed abbiamo il config di validazione attivo, dobbiamo fare la validazione di questo.
                    // E se lo zipcode è errato, facciamo redirect sulla pagina di update profilo

                    const prefix = MOP.config.getInstanceConfig('defaultPrefix');
                    const countryPlaceholder = getZipCodePlaceholderFromPrefix(prefix);

                    const fieldVal = userData[fieldsName[j]];

                    if(MOP.Utilities.realEmpty(fieldVal)) {
                      // Lo zip code è vuoto, torniamo alla pagina di completamento profilo
                      var alert = {type: "danger", msg: TTPolyglot.t("Cp is required")};
                      MOP.execute("MOP:alert", alert);

                      fields.push(dataConfigs[i]);
                      break;
                    }

                    const isValid = validateZipCode(prefix, fieldVal); 
                    
                    if(!isValid) {
                      // Lo zip code è pieno ma invalido, torniamo alla pagina di completamento profilo
                      var alert = {type: "danger", msg: TTPolyglot.t("MOP Invalid Zip Code Format", { 0: countryPlaceholder })};
                      MOP.execute("MOP:alert", alert);

                      fields.push(dataConfigs[i]);
                      break;
                    }

                  } else if (fieldName[j] !== 'neighborhood' && fieldName[j] !== 'region' && fieldName[j] !== 'street_number' && fieldName[j] !== 'cp') {
                    if (MOP.Utilities.realEmpty(userData[fieldsName[j]])) {
                      fields.push(dataConfigs[i]);
                      break;
                    }
                  } 
                } else {
                  if (MOP.Utilities.realEmpty(userData[fieldsName[j]])) {
                    fields.push(dataConfigs[i]);
                    break;
                  }
                }
              }
            }
          }
        }

        var customsLoginConfigurations = MOP.config.getInstanceConfig('customsLoginConfigurations');
        var customConfigs = Object.keys(mappingConfigs.custom);
        for (var i = 0; i < customConfigs.length; i += 1) {
          var el = customsLoginConfigurations[customConfigs[i]];

          if (el.policy == MOP.constants.FIELD_COMPULSORY) {

            const isEnabled = parseInt(el.enabled) === 1;
            const custom_val = userData[customConfigs[i]];
                          

            // Se stiamo considerando un campo custom di tipo privacy abbiamo una gestione diversa per capire se mostrarlo nel completamento profilo
            if(el.properties.type === "6") {

              // Per i familiari, a prescindere non deve essere mostrato il campo
              if(isLoggedUser === false) continue;

              // Per i campi custom di tipo privacy facciamo una gestione diversa, perchè questi possono avere tre diversi valori
              // "0": Consenso espresso, Policy Negata
              // "1": Consenso espresso, policy accettata
              // "2": Consenso non espresso (esempio: quando un operatore registra un paziente)
              // Quindi noi dobbiamo gestire il caso in cui il campo è "0" ed anche il caso in cui è "2", 
              // perchè in entrambi i casi dobbiamo forzare l'aggiornamento del profilo
              

              if(isEnabled && (parseInt(custom_val) === 0 || parseInt(custom_val) === 2 || !custom_val)) {
                fields.push(customConfigs[i])
              }

            } else if (MOP.Utilities.realEmpty(custom_val) && isEnabled) {
              fields.push(customConfigs[i])
            }
          }
        }

        // Se privacy_review o privacy_promotions hanno valore 2 vuol dire che non è stato acquisito e il paziente deve inserirlo
        // ma solo se è l'utente principale
        if (isLoggedUser === true) {
          if (!MOP.Utilities.empty(MOP.config.getInstanceConfig('mopPrivacySurvey')) && parseInt(userData["privacy_review"]) == 2) {
            fields.push('mopPrivacySurvey');
          }
  
          if (!MOP.Utilities.empty(MOP.config.getInstanceConfig('mopPrivacyPromotion')) && parseInt(userData["privacy_promotions"]) == 2) {
            fields.push('mopPrivacyPromotion');
          }
        }


        // Se non è l'utente loggato (familiare), il config showCaregiverPrivacyPolicy è attivo e l'utente non ha checkato la privacy caregiver, allora devo validare questo campo
        if (isLoggedUser === false && !MOP.Utilities.empty(MOP.config.getInstanceConfig('showCaregiverPrivacyPolicy')) && MOP.Utilities.empty(userData["privacy_caregiver"])) {
          fields.push('mopPrivacyCaregiver');
        }

        //console.log(fields);
        
        return fields;
      }
 
      MOP.checkIfProfileIsComplete = function checkIfProfileIsComplete(route) {

        if (MOP.isLoggedIn() &&
            !MOP.isAdminLoggedIn() && 
          !MOP.Utilities.empty(MOP.config.getInstanceConfig('forceUserDataUpdateIfNewCompulsoryFieldsOrPrivacyNotGiven')) &&
          !MOP.isAuthModeEnabled() &&
          !MOP.isOneTimeUser()) {

          if (MOP.getProfileFieldToUpdate().length !== 0) {

            var check = false;
            
            if (route && route.indexOf("profile/complete") !== -1) {
              check = true;
            } else if (route && route.indexOf("profile/expired") !== -1) {
              check = true;
            } else if (route && route.indexOf("profile/expired/otp") !== -1) {
              check = true;
            } else if (route && route.indexOf("profile/invitation") !== -1) {
              check = true;
            } else if (route && route.indexOf("profile/renew-password") !== -1) {
              check = true;
            } else if (route && route.indexOf("profile/update_privacy") !== -1) {
              check = true;
            } else if (route && route.indexOf("login/otp") !== -1) {
              check = true;
            }else if (route && route.indexOf("login/vcode") !== -1) {
              check = true;
            // Qui non ho messo anche route.indexOf("/summary") perchè viene fatto un cambio pagina nel flusso in una pagina di
            // availabilities senza summary, e per ora lo lasciamo così, anche qui è lo stesso discorso fatto sotto, dovrebbe 
            // essere intercettato in altre pagine
            } else if (route && route.indexOf("availabilities") !== -1) {
              check = true;
            /*
              Questi due ultimi if sulla pagina del pagamento e sulla stampa appuntamento ci permettono
              di finire il giro della prenotazione, il caso che gestiscono è il seguente: sia il paziente
              principale che il familiare per cui si sta prenotando non hanno il profilo aggiornato, quindi
              cerchiamo le disponibilità, clicchiamo su uno slot che ci porta al login, una volta loggato la condizione
              del summary sopra ci permette di vedere il summary e di scegliere il paziente, scegliamo il familiare,
              già li dentro facciamo il redirect al completametno del profilo quindi abbiamo gestito li il flusso del redirect
              completiamo il profilo del familiare, e andiamo alla pagina di stampa o al pagamento in base a cosa avevamo selezionato,
              il problema senza le due condizioni sotto sarebbe stato proprio qui, che non ti portiamo a completare la prenotazione ma
              aggiorniamo il profilo del paziente principale e quindi la continuatà del flusso si rompe, in questo modo invece sblocchiamo
              le pagine e anche se rinunciamo a un po di controllo dovremmo cmq avere tutti i casi gestiti. Se così non fosse li gestiremo
              quando li troviamo.
            */
            } else if (route && route.indexOf("payment/pay") !== -1) {
              check = true;
            } else if (route && route.indexOf("reservations") !== -1 && route.indexOf("/print") !== -1) {
              check = true;
            }

            if (!check) {
              MOP.changePage('profile', 'profile/complete');
              return false;
            }
          }
        }

        return true;
      }

      MOP.checkIfPrivacyUpdated = function checkIfPrivacyUpdated(route = "") {

        // Se sono sloggato, torno sempre true (non devo fare nessun controllo)
        // Se sono OTU torno sempre true (non devo fare nessun controllo)
        // Se sono admin torno sempre true (non devo fare nessun controllo)
        if(!MOP.isLoggedIn() || MOP.isAdminLoggedIn() || MOP.isOneTimeUser()) return true;


        let enabled = false; 
        let minDate = 0;

        if(MOP.isSSOUser()){
          ({privacyPolicyConfigurations: { 
              keepPrivacyUpToDate: {
                enabled, minDate
              } 
            }} = MOP.config.getInstanceConfig('singleSignOn'));
        } else {
          ({ enabled, minDate } = MOP.config.getInstanceConfig('keepPrivacyUpToDate'));
        }

        // Se il config è disabilitato, torno true, non faccio altri controlli
        if(MOP.Utilities.empty(parseInt(enabled))) return true;

        const modified = MOP.getPrivacyHistoryPrivacyModified();
    
        if (
          (
            // Se privacy_history.privacy.modified è vuoto allora devo accettare la nuova privacy
            MOP.Utilities.empty(modified) || 
            // Se ho modificato la privacy prima che questa sia cambiata
            parseInt(modified) < parseInt(minDate)
          ) &&
          // Se la rotta è diversa dalla stessa update_privacy 
          route.indexOf("profile/update_privacy") === -1
        ) {
          MOP.changePage('profile', 'profile/update_privacy');
          return false;
        } 
      
        // In tutti gli altri casi torno true, privacy accettata, non faccio altri controlli
        return true;
      }

    MOP.routeGuard = function (_page, _route) {
      var page = _page || MOP.getCurrentPage();
      var route = _route || MOP.getCurrentRoute();
      
      var querystring = MOP.querystring;

      // in questo caso controllo anche il contenuto del campo otpLoginTimestamp della localstorage perchè quel campo viene settato quando si richiede
      // l'otp e viene fatto unset quando la verifica dell'otp va a buon fine
      if(route !== "login/otp" && MOP.isLoggedIn() && !MOP.isAdminLoggedIn() && !MOP.Utilities.empty(MOP.config.getInstanceConfig('externalUserOTPValidation').enabled) && !MOP.Utilities.empty(MOP.localStorage.get('otpLoginTimestamp'))) {
        MOP.changePage('login','otp');
        return false;
      }

      if(!MOP.isLoggedIn() && route !== 'profile/ot_registration' && route.indexOf('print') === -1 && MOP.config.isOtuFirst(MOP)) {
        MOP.changePage("profile","profile/ot_registration", page, route);
        return false;
      } 
      
      if (!MOP.isLoggedIn() && (Number(querystring['loginFirst']) == 1 || !MOP.Utilities.empty(MOP.config.getInstanceConfig('loginFirst'))) && !MOP.checkLoginFirstWhiteListPages(page, route)) {
        MOP.changePage('login', null, page, route);
        return false;
      }
      
      
      // Questo controllo reindirizza l'admin alla pagina di aggiunta numero di telefono se rispetta i requisiti della condizione
      if (MOP.isAdminLoggedIn() && !MOP.Utilities.empty(MOP.config.getInstanceConfig('backofficeUsersVcodeEnabled')) && MOP.Utilities.empty(MOP.getLoggedUserDataField('phone')) && route != 'profile/admin/phone') {
        MOP.changePage('profile', 'profile/admin/phone', page, route);
        return false;
      }  
      /*
      Questo è un caso speciale, che se simao non paziente e la password expired è da cambiare e il config del vcode è attimo allora bisogna andare alla pagina di modifica password e inserimento vcode e non ci interessa
      se è validato quindi quello non lo controlliamo
      */
     if (MOP.isAdminLoggedIn() && !MOP.Utilities.empty(MOP.getLoggedUserDataField('pwd_expired')) && !MOP.isFromTRD111() && !MOP.Utilities.empty(MOP.config.getInstanceConfig('backofficeUsersVcodeEnabled')) && route != 'profile/expired/otp' && route != 'profile/admin/phone') {
       MOP.changePage('profile', 'profile/expired/otp', page, route);
       return false;
      }
      
      if (!MOP.Utilities.empty(MOP.getLoggedUserData()) && !MOP.Utilities.empty(MOP.getLoggedUserDataField('pwd_expired')) && !MOP.isFromTRD111() && page != 'profile' && (route != 'profile/expired/otp' || route != 'profile/expired' || route != 'profile/invitation')) {
        MOP.changePage('profile', 'profile/expired', page, route);
        return false;
      }
      
      // Se sono loggato e non ho la password scaduta non vado al profile expired
      if (MOP.isLoggedIn() && MOP.Utilities.empty(MOP.getLoggedUserDataField('pwd_expired')) && route == 'profile/expired') {
        if (MOP.isAdminLoggedIn()) {
          MOP.notUserLandingPageAfterLogin();
           return false;
        } else {
          var page = MOP.getFirstActivePage();

          MOP.changePage(page.page, page.route);
          return false;
        }
      }
      
      if (MOP.isValidationEnabled() && !MOP.isLoggedUserValidated() && MOP.Utilities.empty(MOP.getLoggedUserDataField('pwd_expired'))) {
        if (page !== 'login' && route !== 'login/vcode' && page != 'profile' && route != 'profile/admin/phone') {
          if (MOP.config.isValidationRequired()) {
            
            MOP.changePage('login', 'vcode');
            return false;
            
          } else if (!MOP.config.isValidationInhibited()) {
            var vcodeAlert = {
              type: 'warning',
              msg: TTPolyglot.t('Vcode Pending Validation',[],true),
              showVcodeLink: true
            };

            MOP.execute('MOP:alert', vcodeAlert);
          }
        }
      }

      if(!MOP.checkIfPrivacyUpdated(route)) {
        return false;
      }
      
      if (!MOP.checkIfProfileIsComplete(route)) {
        return false;
      }

      
        // Se è presente doChangePageForSchemaUrl
        if (!MOP.Utilities.empty(MOP.queryStringSchemaUrl["doChangePageForSchemaUrl"])) {

            var forceRoute = null;

            if (!MOP.Utilities.empty(MOP.queryStringSchemaUrl["forceRoute"])) {

                forceRoute = MOP.queryStringSchemaUrl["forceRoute"];
            }

            delete MOP.queryStringSchemaUrl["doChangePageForSchemaUrl"];

            MOP.changePage(MOP.queryStringSchemaUrl["forcePage"],forceRoute);

            return false;
        }

       // Controlliamo se possiamo raggiungere questa pagina perchè potrebbe essere disabilitata dai config
       var canNavigateToPage = MOP.canNavigateToPage(page, route);

       // Se la pagina a cui vogliamo andare è index, non facciamo questo controllo perchè ha già le sue logiche
       // (non so se è fattibile che l'index passi da qui)
       if (page == "index") canNavigateToPage = true;

       //Se sono una Risorsa Base o Avanzata, oppure se sono Admin
       if (MOP.isAdminLoggedIn() && !canNavigateToPage) {
           MOP.notUserLandingPageAfterLogin();
           return false;
       } else if (!canNavigateToPage) {
           // Non posso raggiungere la pagina a cui voglio andare, recupero la prima di default
           var page = MOP.getFirstActivePage();
           
           MOP.changePage(page.page, page.route);
           return false;
       }

       return true;
       
    }
    
    MOP.executeAction = function (subApp, action, actionArg, checkAction) {
      
      var currentApp = subApp ? MOP.module(subApp) : null;
      
      /*
        Se il RouteGuard non ha ridirezioni da fare proseguiamo altrimenti ci fermiamo
      */
      if (!MOP.routeGuard(currentApp.page)) {
        return;
      }
      
      var check = checkAction ? checkAction() : true;
      
      if (check) {
        if (MOP.avoidFirstScrollingTop.moduleLoaded === null) {
          MOP.avoidFirstScrollingTop.moduleLoaded = subApp;
        } 
        MOP.startSubApp(subApp);
        action(actionArg);
      }
    }
  
    /**
     * Change the current page
     *
     * @param {String} [page=currentPage]
     * @param {String} [route]
     * @param {String} [anchor_page]
     * @param {String} [anchor_route]
     * @param {String} [alert]
     * @param {String} [forced_lang]
     * @param {Object} [forced_params={}]
     * @param {Array}  [excluded_params=[]]
     */
	MOP.changePage = function(page, route, anchor_page, anchor_route, alert, forced_lang, forced_params, excluded_params, goToExternalPage, omitCurrentQueryString, forceReload) {

    if (MOP.Utilities.isMobileApp() && !MOP.state.networkReachability) {
        return MOP.Utilities.disconnectAlertInformation(MOP);
    }
 
    var that = this;
    forced_params = forced_params || {};
    excluded_params = excluded_params || [];

    MOP.removeBeforeLeavingHandler();

    page = page || MOP.getCurrentPage();

    this.localStorage.set('lastPage', this.getCurrentPage());
    this.localStorage.set('lastPageRoute', this.getCurrentRoute());
		this.localStorage.set('lastPageUrl', document.URL);

		if( anchor_page ){
			this.localStorage.set('anchorPage', anchor_page);
			this.localStorage.set('anchorPageRoute', anchor_route);
		}

        if (alert) {
			this.localStorage.set('alert', alert);
		}

		var formatted_page_route = MOP.Utilities.formatPageRoute(MOP, page, route );
    var forcedParam = {};
    
    // Per il momento questo controllo non viene fatto nel changePage perchè è da analizzare se
    // esistono dei casi in cui i parametri che arrivano qui nel changePage vengono persi quando
    // il routeGuard fa il redirect, il trello di riferimento è questo: 
    // https://trello.com/c/9aDw3OpC/1727-nellapp-il-flusso-di-pagesstartup-ha-un-flusso-asincrono-12h-piero
    /*
      if (!MOP.routeGuard(formatted_page_route.page, formatted_page_route.route)) {
        return;
      }
    */
    
    forcedParam = _.extend({}, forced_params, forced_lang ? {lang: forced_lang} : {});
		MOP.Utilities.log(MOP, formatted_page_route);

    if (!MOP.Utilities.empty(forcedParam.lang)) {
      MOP.SessionStorage.removeItem('templates');
    }

		/*
		 * gestione login esterno => mopLoginLandingPage
		 */
		var mopLoginLandingPage = this.config.getInstanceConfig('mopLoginLandingPage');

		if((formatted_page_route.page == 'login') && !MOP.Utilities.empty(mopLoginLandingPage)){
			MOP.TM.blockLoading();
			var url = mopLoginLandingPage;
			return window.location = url;

		}

		if(goToExternalPage){
      MOP.TM.blockLoading();
      var url = this.getMopPageUrl( formatted_page_route, forcedParam, excluded_params, goToExternalPage);
      return window.open(encodeURI(url), '_system');

		/*
		 * gestione ONEPAGE
		 */
		}else {

      //SELECT INSTANCE CASE
      var pass = false;
      if (forcedParam && forcedParam.dbName && forcedParam.dbName != MOP.getDbName()) {
        pass = true;
      } else if (forcedParam && forcedParam.areaid && forcedParam.areaid != MOP.querystring['areaid']) {
        pass = true;
      } else if (forcedParam && forcedParam.province && forcedParam.province != MOP.querystring['province']) {
        pass = true;
      } else if (forcedParam && forcedParam.customHomeVary && forcedParam.customHomeVary != MOP.querystring['customHomeVary']) {
        pass = true;
      } else if (forcedParam && forcedParam.sessionid && forcedParam.sessionid != MOP.querystring['sessionid']) {
        pass = true;
      } else if (forcedParam && forcedParam.lang && forcedParam.lang != MOP.querystring['lang']) {
        pass = true;
      }

      if (pass) {
        MOP.TM.blockLoading();

        return setTimeout(function () {
            window.location = that.getMopPageUrl( formatted_page_route, forcedParam, excluded_params, null, omitCurrentQueryString );
        }, 1);
      }
     
      if (formatted_page_route.route === MOP.getCurrentRoute() && formatted_page_route.page === MOP.getCurrentPage() && forceReload !== true) {
        return true;
      }
      

			//Gestione Notifiche
			MOP.execute("MOP:clearAlert");
      if (MOP.localStorage.has('alert')) {
        var alert = MOP.localStorage.get('alert');

        MOP.execute('MOP:alert', alert);
        MOP.localStorage.unset('alert');
      }

            //Gestione Loading => sblocco un eventuale unblockLoading not-replace
			MOP.TM.unblockLoading(false);
			// + forzo un blockLoading replace
            MOP.TM.blockLoading({}, true);

            Index.pageLoader(MOP, formatted_page_route.page).then(() => {
                return setTimeout(function () {
                    window.MOP_globals.page = formatted_page_route.page;
                    if (forceReload !== true) {
                      MOP.navigate(formatted_page_route.route, {/*replace:true,*/trigger:true});
                    } else {
                      MOP.forceNavigate(formatted_page_route.route);
                    }
                }, 1);
            })
        }

    };

    MOP.getLastPage = function () {
        if (MOP.localStorage.has('lastPage')) {
            return MOP.localStorage.get('lastPage');
        }

        return "";
    };

    MOP.getLastPageRoute = function () {
      if (MOP.localStorage.has('lastPageRoute')) {
          return MOP.localStorage.get('lastPageRoute');
      }

      return "";
    };

    MOP.setLastPage = function (page,route) {
      MOP.localStorage.set('lastPage',page)
      MOP.localStorage.set('lastPageRoute',route)
  };

	MOP.goToLastPage = function( alert, default_page, forced_lang ){
		var last_page = MOP.localStorage.get('lastPage');
		var last_page_route = MOP.localStorage.get('lastPageRoute');
    var current_page = MOP.getCurrentPage();
    var current_route = MOP.getCurrentRoute();

		if( last_page && (last_page != current_page || last_page_route != current_route)){
			return this.changePage( last_page, last_page_route, null, null, alert, forced_lang );
		}else{
			var default_page = default_page || 'login';
			return this.changePage( default_page, null, null, null, alert, forced_lang );
		}
  };
  
	MOP.goToAnchorPage = function( alert, default_page, forced_lang ){
		var anchor_page =  this.localStorage.get('anchorPage');
    var anchor_page_route = this.localStorage.get('anchorPageRoute');
    
		this.localStorage.unset('anchorPage');
		this.localStorage.unset('anchorPageRoute');
 
    //FIXME Piero Utilizzato per gestire momentaneamente questo problema #10181
    if (anchor_page == 'dossier' && MOP.isAdminLoggedIn()) anchor_page = 'search';

		if( anchor_page ){
			return this.changePage( anchor_page, anchor_page_route, null, null, alert, forced_lang );
		}else{
			return this.goToLastPage( alert, default_page, forced_lang );
		}
	};

	MOP.isDownloadPageActivated = function(){
        return (!MOP.Utilities.empty(MOP.getLoggedUserData()) && !MOP.Utilities.empty(MOP.config.getInstanceConfig('enableAppDownloadBanner')) && !MOP.getLoggedUserData().device_uuid ? true : false);
    };

    //Andiamo alla pagina di Print Reservation
    MOP.goToPrintReservation = function( resid, userid, alert, forceReload, fromPage = null){

      if (MOP.isAdminLoggedIn() && MOP.TM.isMenuPageVisibileForUserRole("patients")) {

        const enableBetaMopPage = MOP.config.getInstanceConfig('enableBetaMopPage');

        if (MOP.Utilities.empty(enableBetaMopPage?.patients) && MOP.Utilities.empty(MOP.querystring['enableNewPatientShow'])) {
          // Se sono nel modulo vecchio faccio il redirect alle vecchie rotte
          //Andiamo alla pagina di Print Reservation
          var querystring = '&';
          if( userid ){
              querystring += 'userid='+userid;
          }
          var encoded_querystring = MOP.Utilities.encodeQuery(querystring);

          return MOP.changePage('patients', 'query/'+encoded_querystring+'/print/'+resid, null, null, alert, null, null, null, null, null, forceReload );
        } else {
          // Se sono nel modulo nuovo faccio il redirect alle nuove rotte

          if(userid) {
            return MOP.changePage('patients', `user/${userid}/print/${resid}`, null, null, alert, null, null, null, null, null, forceReload );
          }

          return MOP.changePage('patients', `print/${resid}`, null, null, alert, null, null, null, null, null, forceReload );
          
        }

      } else if (MOP.isAdminLoggedIn() && !MOP.TM.isMenuPageVisibileForUserRole("patients")) {
        return MOP.changePage('agenda', `agenda/${resid}/summary`, null, null, alert, null, null, null, null, null, forceReload );
      } else {
        let route = '';
        if (!MOP.Utilities.empty(userid)) {
          route = `userid/${userid}/`;
        }

        route += `print/${resid}`

        if (forceReload === true) route += '/force/true';
        
        if(!MOP.Utilities.empty(fromPage)) route += `/fromPage/${fromPage}`

        MOP.changePage('reservations', route, null, null, alert, null, null, null, null, null, forceReload);
      }
    };

    //Andiamo alla pagina di My Reservations
    MOP.goToMyReservations = function( userid, alert, goToCancelReservations, forceReload){

      if (MOP.isAdminLoggedIn()) {

        const enableBetaMopPage = MOP.config.getInstanceConfig('enableBetaMopPage');

        if (MOP.Utilities.empty(enableBetaMopPage?.patients) && MOP.Utilities.empty(MOP.querystring['enableNewPatientShow'])) {
          // Se sono nel modulo vecchio faccio il redirect alle vecchie rotte
          //Andiamo alla pagina di Print Reservation
          var querystring = '&';
          if( userid ){
              querystring += 'userid='+userid;
          }

          var encoded_querystring = MOP.Utilities.encodeQuery(querystring);
          MOP.changePage('patients', 'query/'+encoded_querystring, null, null, alert, null, null, null, null, null, forceReload);
        } else {
          // Se sono nel modulo nuovo faccio il redirect alle nuove rotte

          return MOP.changePage('patients', `${userid ? `user/${userid}` : ""}`, null, null, alert, null, null, null, null, null, forceReload );
          
        }

      } else {
        let route = '';
        if (!MOP.Utilities.empty(goToCancelReservations)) {
          route = "cancel";
        }
        if(userid) {
          route += `/userid/${userid}`;
        }

        if (forceReload === true) route += '/force/true';

        MOP.changePage('reservations', route, null, null, alert, null, null, null, null, null, forceReload);
      }
    };

    MOP.addBeforeLeavingHandler = function() {
        // Aggiungo l'evento di leavepage se non siamo in locale.
        if (typeof window.beforeLeavingHandler === 'function' && !MOP.Utilities.isMobileApp()) {
            window.addEventListener('beforeunload', window.beforeLeavingHandler);

            // Ad ogni click che proviene dalla app rimuovo il listener.
            /*$(window).on('click', function() {
             window.removeEventListener('beforeunload', window.beforeLeavingHandler);
             });*/
        }
    };

    MOP.removeBeforeLeavingHandler = function() {
        if (typeof window.beforeLeavingHandler === 'function' && !MOP.Utilities.isMobileApp()) {
            window.removeEventListener('beforeunload', window.beforeLeavingHandler);
        }
    };

	//------------------------------------------------------------------------------------------------
	MOP.forceSearchEntitiesVisibility = function( entities, is_visible ){
		this.localStorage.set(entities+'ForcedVisibility', is_visible);
	};
	MOP.restoreSearchEntitiesVisibility = function( entities ){
		if( this.localStorage.has(entities+'ForcedVisibility') ){
			this.localStorage.unset(entities+'ForcedVisibility');
		}
	};
	MOP.isSearchEntitiesVisible = function( entities ){
		var is_enabled = this.config.isSearchWidgetEnabled(entities);
		if( is_enabled && this.localStorage.has(entities+'ForcedVisibility') ){
			return this.localStorage.get(entities+'ForcedVisibility');
		}
		return is_enabled;
	};
 
    MOP.checkOpenAppRateDialog = function () {
      // Mostrare App Rating solo se il config è attivo e se ID iOS e Android sono pieni
      if (!MOP.Utilities.empty(MOP.config.getInstanceConfig('enableAppRating'))) {
        if (!MOP.Utilities.empty(window.MOP_globals.customerConfig.appRating_iOS) && !MOP.Utilities.empty(window.MOP_globals.customerConfig.appRating_Android)) {
          // Se siamo in stampa appuntamento
          if (!MOP.Utilities.empty(MOP.getCurrentRoute()) && MOP.getCurrentRoute().indexOf("reservations") != -1 && MOP.getCurrentRoute().indexOf("print") != -1) {
            // Leggo la versione salvata in local storage e se è diversa azzero il contatore e cambio la versione
            if ((MOP.localStorage.has('AppRateVersion') && MOP.localStorage.get('AppRateVersion') != window.MOP_globals.customerConfig.appVersion) || !MOP.localStorage.has('AppRateVersion')) {
              MOP.localStorage.set('AppRateVersion', window.MOP_globals.customerConfig.appVersion);
              MOP.localStorage.set('AppRateCounter', 0);
            }

            // Incremento comunque il contatore perchè sono in stampa appuntamento
            MOP.localStorage.set('AppRateCounter', MOP.localStorage.get('AppRateCounter')+1);

            // Se abbiamo raggiunto il valore che vogliamo del contatore mostriamo il dialog
            if (MOP.localStorage.get('AppRateCounter') == 3) {
              
              AppRate.preferences = {
                usesUntilPrompt: 3,
                promptAgainForEachNewVersion: true,
                inAppReview: false,
                storeAppURL: {
                  ios: window.MOP_globals.customerConfig.appRating_iOS,
                  android: 'market://details?id='+window.MOP_globals.customerConfig.appRating_Android
                },
                customLocale: {
                  title: TTPolyglot.t("App Rating Title", [], true),
                  message: TTPolyglot.t("App Rating Description", [], true),
                  cancelButtonLabel: TTPolyglot.t("App Alert Dismiss Forever", [], true),
                  laterButtonLabel: TTPolyglot.t("App Alert Not Now", [], true),
                  rateButtonLabel: TTPolyglot.t("App Rating Rate Button", [], true),
                  //yesButtonLabel: "Yes! [CHIAVE]",
                  //noButtonLabel: "Not really [CHIAVE]",
                  //appRatePromptTitle: 'Do you like using it [CHIAVE]',
                  //feedbackPromptTitle: 'Mind giving us some feedback? [CHIAVE]',
                },
                callbacks: {
                  handleNegativeFeedback: function(){
                    //window.open('mailto:feedback@example.com','_system');
                  },
                  onRateDialogShow: function(callback){
                    //callback(1) // cause immediate click on 'Rate Now' button
                  },
                  onButtonClicked: function(buttonIndex){
                    // Rate Now - 1
                    // Later - 2
                    // Cancel - 3
                    if (buttonIndex == 1) {
                      if (MOP.Utilities.isMobileApp(true)) {
                        MOP.Utilities.sendAppEvents(MOP, {eventType: MOP.config.constants.APP_EVENT_SHOW_RATING_VOTE, device_uuid: MOP.MAM.getDeviceDatas(['uuid']).device_uuid, memberid: MOP.isLoggedIn() ? MOP.getLoggedUserData().id : ""})
                      }
                    } else if (buttonIndex == 2) {
                      MOP.localStorage.set('AppRateCounter', 0);
                      if (MOP.Utilities.isMobileApp(true)) {
                        MOP.Utilities.sendAppEvents(MOP, {eventType: MOP.config.constants.APP_EVENT_SHOW_RATING_LATER, device_uuid: MOP.MAM.getDeviceDatas(['uuid']).device_uuid, memberid: MOP.isLoggedIn() ? MOP.getLoggedUserData().id : ""})
                      }
                    } else if (buttonIndex == 3) {
                      MOP.localStorage.set('AppRateCounter', 4);
                      if (MOP.Utilities.isMobileApp(true)) {
                        MOP.Utilities.sendAppEvents(MOP, {eventType: MOP.config.constants.APP_EVENT_SHOW_RATING_CANCEL, device_uuid: MOP.MAM.getDeviceDatas(['uuid']).device_uuid, memberid: MOP.isLoggedIn() ? MOP.getLoggedUserData().id : ""})
                      }
                    }
                  }
                }
              };

              // Se si mette false come parametro in ingresso NON viene mostrato sempre ma viene mostrato seguendo il counter del plugin,
              // Nel nostro caso invece abbiamo una nostra logica di apertura e quindi lo mostriamo subito quando vogliamo
              setTimeout(function(){ AppRate.promptForRating(); }, 3000);
              
              if (MOP.Utilities.isMobileApp(true)) {
                MOP.Utilities.sendAppEvents(MOP, {eventType: MOP.config.constants.APP_EVENT_SHOW_RATING, device_uuid: MOP.MAM.getDeviceDatas(['uuid']).device_uuid, memberid: MOP.isLoggedIn() ? MOP.getLoggedUserData().id : ""})
              }

            }
          }
        }
      }
    };

    // @see mop/js/common/utilities.js
    /****************************************************************************************************/
	/*
    app.pages = new Nav([
        {title: 'Home', name: 'home', active: true},
        {title: 'About', name: 'about'},
        {title: 'Contact', name: 'contact'}
    ]);
    var menu = new MenuView({collection: app.pages});



	app.addInitializer(function () {
        app.menu.show(menu);
		app.footer.show(new Footer());
	});

	app.vent.on('menu:activate', function (activePageModel) {
        menu.collection.findWhere({active: true})
            .set('active', false);
        activePageModel.set('active', true);
        menu.render();
	});

	*/

    MOP.vent.on('view:desktop', function() {
        MOP.refreshMOP();
    });

    MOP.vent.on('view:mobile', function() {
        MOP.refreshMOP();
    });

    // ****************************************************************************************************
    // App Common modules

    /**
     * A module containing DOM functionalities.
     * @module loginModule
     */
    MOP.loginModule = (function (MOP, $) {
        return {
            /**
             * Esegue il login relativamente ai dati passati
             *
             * @param {Object} login_data - it contain the login user data
             * @param {Object} [login_view=null|undefined]
             * @param {Object } [family_data=null|undefined]
             * @param {boolean} [is_automatic_login=null|undefined]
             * @param {boolean} [is_hidden_login=null|undefined]
             */
        	doLogin: function(login_data, login_view, family_data, is_automatic_login, is_hidden_login, doLogin_alert) {
        		var loginDeferred = new $.Deferred();
        		var loading_options = {};
                var replace_main_region = false;
                var _this = this;

                // Recupero eventuali parametri login_custom (da 0 a 3) da querystring 
                // e li appendo a login_data
                for (let index = 0; index <= 3; index++) {
                  const key = `login_custom_${index}`;
                  if (!MOP.Utilities.empty(MOP.querystring[key])) {
                    _.extend(login_data, { [key] : MOP.querystring[key] });
                  }
                }

                if (!is_hidden_login) {
                    MOP.TM.blockLoading(loading_options, replace_main_region);
                }

                if (MOP.Utilities.isMobileApp()) {
                    _.extend(login_data, MOP.MAM.getDeviceDatas(['model', 'platform', 'uuid', 'version', 'token']));
                }

                //Si è scelto di mandare i dati di login in post per Freshdesk #5634
                var ajax = {
                  options : {
                      type:     'POST',
                      dataType: 'json'
                  },
                  data: login_data
                };

                var loggin = MOP.ajaxRest({
                    fetch_fn: 'login',
                    ajax: ajax
                });

                $.when(loggin).done(function( resp ) {
                	//--- ERRORE ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----
                    if (resp['result'] !== 'OK' || MOP.Utilities.empty(resp['sessionid'])) {
                    	/*
                         * Se login NON nascosto => sblocchiamo il loading e notifichiamo l'utente
                         */

                      if(resp['exception'] !== 'TUOTEMPO_SERVICE_UNAVAILABLE') {
                        sendUserEvent(ERROR_LOGIN);
                      }

                        if (!is_hidden_login) {
                            MOP.TM.unblockLoading(replace_main_region);
                            if (is_automatic_login) {
                                var alert = {type: 'danger', msg: resp['msg']};

                                if (login_view) {
                                    MOP.execute('MOP:alert', alert);
                                    return MOP.mainRegion.show(login_view);
                                } else {
                                    return MOP.changePage('login', null, null, null, alert);
                                }

                            } else {
                                return login_view ? login_view.triggerMethod('form:data:invalid', resp) : true;
                            }
                        }

                        loginDeferred.resolve(false);

                    //--- SUCCESS ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----
                    } else {

                      if (!MOP.Utilities.empty(MOP.config.getInstanceConfig("dossierOTPVerification"))) {
                        // Resetto il timestamp e la scelta sulle biometriche dell'otp nella localstorage
                        resetStorageDossierAuth();
                      }

                        var user_data = $.extend(resp['return'], {
                            session: resp['session'],
                            is_operator_allowed_view_resource_agenda: resp['is_operator_allowed_view_resource_agenda'],
                            is_operator_allowed_view_statistics: 	  resp['is_operator_allowed_view_statistics'],
                            is_operator_use_same_compulsory_rules:	  resp['is_operator_use_same_compulsory_rules'],
                            is_operator_use_localization_research:	  resp['is_operator_use_localization_research'],
                            is_operator_use_show_only_first_doc_availability:	  resp['is_operator_use_show_only_first_doc_availability'],
                            is_advanded_resource_use_related_resource_actions:	  resp['is_advanded_resource_use_related_resource_actions'],
                            is_operator_allowed_to_cancel_reservations:	  resp['is_operator_allowed_to_cancel_reservations'],
                            is_operator_allowed_to_reschedule_reservations:	  resp['is_operator_allowed_to_reschedule_reservations'],
                            is_operator_mop_allowed_voip_calls:	  resp['is_operator_mop_allowed_voip_calls'],
                            is_operator_allowed_to_manager_dossier_and_privacy:	  resp['is_operator_allowed_to_manager_dossier_and_privacy'],
                            is_operator_allowed_to_upload_documents_while_booking: resp['is_operator_allowed_to_upload_documents_while_booking'],
                            is_operator_allowed_to_specify_comunication_contancts_on_booking: resp['is_operator_allowed_to_specify_comunication_contancts_on_booking'],
                            is_operator_allowed_to_change_own_contact_information: resp['is_operator_allowed_to_change_own_contact_information'],
                            is_operator_allowed_to_manage_only_assigned_resources: resp['is_operator_allowed_to_manage_only_assigned_resources'],
                            is_operator_allowed_to_manage_only_assigned_patients: resp['is_operator_allowed_to_manage_only_assigned_patients'],
                            is_allowed_to_access_my_reservations_page: resp['is_allowed_to_access_my_reservations_page'],
                            is_allowed_to_see_reserved_slots: resp['is_allowed_to_see_reserved_slots'],
                            is_allowed_to_create_patient: resp["is_allowed_to_create_patient"],
                            is_allowed_to_update_patient: resp["is_allowed_to_update_patient"],
                            is_allowed_to_access_sensitive_data: resp["is_allowed_to_access_sensitive_data"],
                            is_allowed_to_see_hidden_entities: resp['is_allowed_to_see_hidden_entities']
                          });

                        // Gestione login automatico da mobile app => manteniamo in localStorage i dati di login
                        if (MOP.Utilities.isMobileApp()) {
                            login_data.sessionid = resp['sessionid'];
                            MOP.setSession(resp['sessionid'], user_data, login_data);
                        }else{
                            MOP.setSession(resp['sessionid'], user_data, null);
                        }

                        /*
                         * Qui vengono svuotati questi campi di localStorage perchè se sono pieni vuol dire che vengo da un search availabilities
                         * nel caso in cui ho appena effettuato il login, e sono un paziente inaffidabile allora li svuoto per rifare la search availabilities
                         * in modo che il server faccia il controllo per i pazienti inaffidaibli ##9975
                         */
                        var availabilities_in_local_storage = MOP.localStorage.get('last_availabilities_searched');
                        var availabilities_multiple_in_local_storage = MOP.localStorage.get('last_solutions_searched');

                        if(!MOP.Utilities.empty(availabilities_in_local_storage) || !MOP.Utilities.empty(availabilities_multiple_in_local_storage))
                          sendUserEvent(LOGIN_DURING_RESERVATION);
                        else
                          sendUserEvent(LOGIN); 

                        if (availabilities_in_local_storage && !MOP.isUserTrusted()) {
                          MOP.localStorage.unset('last_availabilities_searched');
                          MOP.localStorage.unset('last_availability_selected');
                          MOP.localStorage.unset('last_availability_searchObject');
                          MOP.localStorage.unset('last_availabilities_additional_parameters');
                        }

                        var forced_lang = MOP.checkUserLanguage();

                        /*
                         * Se login NON nascosto => non sblocchiamo il loading perchè in ogni caso alla fine facciamo un cambio pagina
                         */
                        if (!is_hidden_login) {
	                        if (family_data) {
	                            return _this.saveFamilyAfterLogin(family_data);

	                        } else {
	                            if (!MOP.Utilities.empty(user_data['pwd_expired']) && !MOP.isFromTRD111()) {
	                                return MOP.changePage('profile', 'profile/expired',null,null,null,forced_lang);
	                            }

	                            //var alert = {type: "success", msg: MOP.translate("Your request was processed successfully.")};
	                            if (MOP.isValidationEnabled() && !MOP.isLoggedUserValidated()) {
	                                 if (MOP.config.isValidationRequired() || true) {
                                    // la chiave last_sent_vcode può essere vuota nel caso in cui gli utenti siano stati importati da BO, in questi casi, comunque dobbiamo inviare il vcode
                                    // (Ne abbiamo parlato con antonio cota a seguito di questo ticket https://tuotempo.freshdesk.com/a/tickets/93232)
                                     if(MOP.Utilities.empty(MOP.getLoggedUserData().last_sent_vcode) || (MOP.Utilities.getTimestamp() - MOP.getLoggedUserData().last_sent_vcode) > MOP.constants.VCODE_MIN_WAITING_TIME_TO_RESEND_VCODE) {
                                        MOP.sendVcode(MOP.getLoggedUserId());
                                     }
                                      return MOP.changePage('login', 'vcode', null, null, doLogin_alert,forced_lang);
	                                 } else {
                                   
                                    if(!MOP.isAdminLoggedIn() && !MOP.Utilities.empty(MOP.config.getInstanceConfig('externalUserOTPValidation').enabled)) {
                                      return MOP.changePage('login', 'otp')
                                    }
                                      return MOP.goToAnchorPage(null, MOP.getFirstActivePage().page, forced_lang);
	                                 }
	                            } else {

                                if(!MOP.isAdminLoggedIn() && !MOP.Utilities.empty(MOP.config.getInstanceConfig('externalUserOTPValidation').enabled)) {
                                  return MOP.changePage('login', 'otp')
                                } 

                                if (window.MOP_globals.customerConfig && window.MOP_globals.customerConfig.forcePageAfterLogin && window.MOP_globals.customerConfig.forcePageAfterLogin != "") {
                                  return MOP.changePage(window.MOP_globals.customerConfig.forcePageAfterLogin)
                                }

                                // Qui gestiamo i profili diversi da paziente che avranno una gestione a parte di pagina di atterraggio dopo il login
                                if (MOP.isAdminLoggedIn()) {
                                  return MOP.notUserLandingPageAfterLogin();
                                }

                                const last_page = MOP.localStorage.get('lastPage');
                                const last_page_route = MOP.localStorage.get('lastPageRoute');
                                if (last_page == 'profile' && last_page_route == 'profile/advanced_options') {
                                  MOP.localStorage.set('anchorPage','reservations')
                                  MOP.localStorage.set('anchortPageRoute',null)
                                }

	                              return MOP.goToAnchorPage(null, MOP.getFirstActivePage().page, forced_lang);
	                            }
	                        }
                        } else {
                          if (!MOP.Utilities.empty(forced_lang)) {

                            // Qui gestiamo i profili diversi da paziente che avranno una gestione a parte di pagina di atterraggio dopo il login
                            if (MOP.isAdminLoggedIn()) {
                              return MOP.notUserLandingPageAfterLogin(forced_lang);
                            }

                            return MOP.changePage(MOP.getCurrentPage(), MOP.getCurrentRoute(), null, null, null, forced_lang);
                          }
                        }

                        loginDeferred.resolve(true);
                    }
                });

                return loginDeferred;
    		},

            saveFamilyAfterLogin: function(data){
                var entities_user = require("entities/user");
                    // (NB) Aggiungiamo il parentid cioè l'Id del cliente loggato
                    data.parentid = MOP.getLoggedUserId();

                    // (NB) Rimuoviamo dalla localStorage i dati del famigliare che stiamo per registrare
                    MOP.localStorage.unset('familyToAdd');

                    var family_user = MOP.request("user:entity:new");
                    var saving = family_user.save(data);

                    if( !saving ){
                        var alert = {type: "danger", msg: MOP.TM.validationErrorToString(family_user.validationError)};
                        MOP.goToAnchorPage(alert, 'profile');
                    }else{
                        saving.done( function( resp ){
                            if( resp['result'] === 'OK' ){
                                var userid = resp['return'];
                                 // (NB) Aggiungiamo/Aggiorniamo il famigliare appena salvato alla lista dei parents dell'utente loggato

                                 MOP.state.loggedUser.updateLoggedUserDataParents(userid, data);
                                var alert = {type: "success", msg: TTPolyglot.t('Successfull Relative Registration')};
                            }else{
                                var alert = {type: "danger", msg: resp['msg']};
                            }
                            MOP.goToAnchorPage(alert, 'profile');
                        });
                    }
            },

            /**
             * Esegue il logout dell'utente attualmente loggato
             *
             * @returns {jQuery.Deferred}
             */
            doLogout: function (preventLoginRedirect, alert) {
                var deferred = new $.Deferred();

                var loggin = MOP.ajax({
                    fetch_fn: 'logout',
                    loadingOptions: {
                        enabled: true
                    }
                });

                $.when( loggin )
                    .done( function( resp ){
                        if (resp.result !== 'OK') {
                            window.alert(resp.msg);
                            deferred.resolve(false);
                        } else {
                            var querystringDisposableList = MOP.localStorage.get('querystringDisposableList');
                            MOP.resetSession();
                            
                            if (!preventLoginRedirect && !MOP.isSamlUser()) {
                                //Al logout vai alla pagina di login e costruisci la nuova querystring con eventuali excluded_params
                                MOP.changePage('login',null,null,null,alert,null,null,querystringDisposableList);
                            }

                            // Pulisco la localStorage da queste chiavi. FD associato:
                            // https://tuotempo.freshdesk.com/a/tickets/105364
                            // così risolviamo problemi di accesso a rotte non consentite quando viene fatto logout e login da un account diverso
                            // l'idea era di mettere questo codice dentro MOP.resetSession() visto che li dentro gia andiamo a cancellare la localStorage
                            // Però non posso farlo li perchè andrei a cancellare queste chiavi, ma poi la changePage ce le andrebbe a riaggiungere 
                            // quindi sarebbe inutile cancellarle. lo faccio qui dopo aver chiamato la changePage
                            MOP.localStorage.unset('lastPage');
                            MOP.localStorage.unset('lastPageRoute');
                            MOP.localStorage.unset('lastPageUrl');

                            // Se l'utente è loggato con SSO devo riportarlo alla login con SSO
                            // per farlo aggiunge sso=saml2 in querystring
                            if(MOP.isSamlUser() && !preventLoginRedirect) {
                              window.location.replace(MOP.getMopPageUrl({
                                page: 'login',
                                route: 'login'
                              }, {
                                sso: 'saml2'
                              }));
                            }

                            deferred.resolve(true);
                        }
                    });

                return deferred;
            },

            /**
             * Controlla che la password passata sia uguale a
             * quella salvata nella permanentLocalStorage. (solo per mobile app)
             * @param  {String} password
             * @return {Boolean} - se le password sono uguali
             */
            checkPasswordStored: function (password) {
              var deferred = new $.Deferred();

              if (!MOP.permanentLocalStorage.has('login_data')) {
                  deferred.reject();
              }

              $.when(MOP.permanentLocalStorage.get('login_data'))
                  .done(function (loginData) {
                      deferred.resolve(loginData.password == password);
                  })
                  .fail(deferred.reject);

              return deferred;
          }
        }
    })(MOP, $);

    MOP.commonViewModule = (function () {
        return {
            goToPage: function (e) {
                var index = $(e.target).attr('data-page-linked') || $(e.target).parent('[data-page-linked]').attr('data-page-linked'),
                    route = $(e.target).attr('data-page-route') || $(e.target).parent('[data-page-route]').attr('data-page-route') || null;

                if (MOP.config.isHttpURL(index)) {
                    MOP.DM.openURLNewTab(index, MOP.Utilities.isMobileApp());

                } else {
                    // Se mi trovo già nella pagina di ricerca e rivoglio andarci
                    // Triggero la search:change per rinfrescare l'intera ricerca
                    if( MOP.isSearchPage(MOP.getCurrentPage()) && MOP.isSearchPage(index) ){
                      if ((!MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage')) && !MOP.Utilities.empty(MOP.config.getInstanceConfig('enableBetaMopPage')['search'])) || 
                           !MOP.Utilities.empty(MOP.querystring['enableNewSearch'])) {
                        MOP.trigger('search:reset');
                      } else {
                        MOP.trigger('search:change');
                      }
                    }else{
                        MOP.changePage(index, route);
                    }
                }
            }
        };
    }());

    MOP.DOMModule = (function(){
        return {
            newIFrameElement: function(params){

                var iframe = document.createElement('IFRAME'),
                    $parent = $(params.container),
                    hideLoadingTimeout = params.hideLoadingTimeout || 2500;

                if (typeof params.loading === 'undefined') {
                    params.loading = true;
                }

                if(params.showClose){
                    var aCloseLink = document.createElement('A');
                    aCloseLink.href = "javascript:;";
                    aCloseLink.innerText = 'X';
                    aCloseLink.textContent = 'X';
                    MOP.TM.addEvent('click', aCloseLink, function(){
                        $parent.empty();
                    });
                    //console.log(params.closeFunc);
                    if(params.closeFunc){
                        MOP.TM.addEvent('click', aCloseLink, params.closeFunc);
                    }
                    $parent.append(aCloseLink);
                }

                if (params.onLoad) {
                    iframe.onLoad = params.onLoad;
                    iframe.onload = params.onLoad;
                }

                if (params.loading) {
                    MOP.TM.blockLoading();

                    //Nascondiamo il loading solo dopo un piccolo timeout
                    //in modo che il post eseguito su paypal sia concluso
                    _.delay(function(){
                        MOP.TM.unblockLoading();
                    }, hideLoadingTimeout);
                }

                iframe.src = params.url;

                if (window.location.href.indexOf('https') === -1 && iframe.src.indexOf('https') !== -1) {
                    iframe.src = iframe.src.replace('https', 'http');
                }

                if (MOP.Utilities.isAppleDevice()) {
                    iframe.scrolling = "no";
                    //iframe.style = "height: 540px;width: 100%;border:0";
                    iframe.style.height = "100%";
                    iframe.style.width = screen.width + "px";
                    iframe.style.border = "0";
                } else {
                    iframe.style.height = ($(window).height()-$(".navbar").height())+"px";
                    iframe.style.width = "99%";
                    iframe.style.border = "0";
                }

                $parent.append(iframe);
            },

            loadjscssfile: function () {
                return MOP.Utilities.loadjscssfile.apply(this, arguments);
            },

            /**
             * Inizializza l'elemento passato ad essere l'input per la ricerca sulle API
             * di google autocomplete
             *
             * @param elemId
             */
            startGoogleAutocomplete: function(elemId, onChangeCallback) {
                if (window.google.maps.places) {
                    var autocomplete = new window.google.maps.places.Autocomplete(document.getElementById(elemId), {fields: ['address_components']});

                    if (onChangeCallback && typeof onChangeCallback === 'function') {
                        autocomplete.addListener('place_changed', function() {
                            onChangeCallback(undefined, autocomplete.getPlace());
                        });
                    }
                } else {
                    if (onChangeCallback && typeof onChangeCallback === 'function') {
                        onChangeCallback(true);
                    }
                }
            },

            addListener: function(elem) {
                if (elem.addEventListener) {
                    elem.addEventListener.apply(elem, Array.prototype.slice.call(arguments, 1));
                } else {
                    elem.attachEvent.apply(elem, (Array.prototype.slice.call(arguments, 1, 3)));
                }
            },

            openURLNewTab: function (url, isApp) {
                var a = document.createElement('a');
                a.target = '_blank';
                a.href = isApp ? "#" : url;
                a.style.display = 'none';

                if (isApp) {
                    MOP.TM.addEvent('click', a, function () {
                        window.open(url, '_system');
                    });
                }
                document.body.appendChild(a);
                $(a)[0].click();
                $(a)[0].remove();
            }
        };
    }());

    MOP.LM 	= MOP.loginModule;
    MOP.CVM = MOP.commonViewModule;
    MOP.DM 	= MOP.DOMModule;

    /*
     * App Common modules
     * ----------------------
     *****************************************************************************************************/
    MOP.sendMessageToParentFrame = function(message) {
        var parent_location = MOP.getParentLocation();
        var url = !MOP.Utilities.empty(parent_location) ? parent_location : ((window.location != window.parent.location) ? document.referrer: document.location);

        if (!MOP.Utilities.empty(url)) {
            try {
                //console.log('Trying to send a message...');
                parent.postMessage(JSON.stringify(message), '*');
            } catch (e) {
                //console.log('postMessage method unavailable: '+e.message);
            }
        }
    };

    const handleEventMessage = (event) => {
      try{
        const object = JSON.parse(event.data)
        const {fn, data} = object
        switch(fn){
          case "deferredFlow": {
            const deferredObject = data

            MOP.localStorage.set('deferredObject', deferredObject);
            MOP.localStorage.set('anchorPage', "availabilities");
			      MOP.localStorage.set('anchorPageRoute', "deferred");
          }
        }    
      }catch(e){
        // console.log(e)
      }
    }

    MOP.getMessageFromParentFrame = function() {
      window.addEventListener("message", handleEventMessage)
    };
 
    MOP.backToApp = function backToApp() {
        return window.location = MOP.Utilities.getPublicURL(MOP) + '://';
    };

    MOP.fromMobileApp = function fromMobileApp() {
        return (MOP.querystring && MOP.querystring['mobileapp']);
    };

    MOP.fromBackOffice = function fromBackOffice() {
        return (MOP.querystring && MOP.querystring['fromApp']);
    };

    MOP.isKioskMop = function isKioskMop () {
        return (MOP.querystring && MOP.querystring['kiosk']) || false;
    };

    MOP.refreshMOP = function refreshMOP() {
        if (MOP.config.isInIFrame() && !MOP.config.isNewLauncher()) {
            MOP.sendMessageToParentFrame({
                fn: 'evalFunction',
                value: 'var iframe = document.getElementById("mop_iframe");iframe.src = iframe.src;'
            });
        } else {
            setTimeout(
              function(){
                  window.location.reload();
              }, 1);
        }
    };

    MOP.checkUserLanguage = function checkUserLanguage() {
      if (MOP.isLoggedIn() && !MOP.Utilities.empty(MOP.getLoggedUserData().language) && MOP.config.get("lang") != MOP.getLoggedUserData().language && MOP.config.getInstanceConfig('disableMopLanguageChoice') == 0) {
        return MOP.getLoggedUserData().language;
      }

      return null;
    };

    MOP.callCheckin = function callCheckin(dataRequest) {

      var deferred = new $.Deferred();

      if (!MOP.Utilities.empty(MOP.querystring['checkin_demo'])) {
        setTimeout(() => {
          deferred.resolve();
        }, 1000);
        return deferred;
      }
      
      var params = {
        searchPath: 'checkin',
        ajax: {
          options: {
            method: 'POST'
          },
          data: dataRequest
        }
      };

      MOP.ajaxRest(params).done(function (response) {

        if (response.result !== 'OK') {
          deferred.reject(response);
        } else {
          MOP.clearBackboneCacheItemsByJsonFn('reservations');
          deferred.resolve(response);
        }

      })
      .fail(function (e) {
        MOP.Utilities.log(MOP, e);
        deferred.reject(e);
      });

      return deferred;

    }


    function showAlert (jsonData) {
        if (typeof jsonData === 'object') {

            if (jsonData.type === 'success') {
                jsonData.msg = !MOP.Utilities.empty(jsonData.msg) ? jsonData.msg : TTPolyglot.t('Your request was processed successfully.');
            } else {
                jsonData.msg = !MOP.Utilities.empty(jsonData.msg) ? jsonData.msg : TTPolyglot.t('Mop not allowed');
            }

            var CommonViews = require('views').default;
            
            MOP.alertRegion.show(new CommonViews.Alert({
                model: new Backbone.Model(jsonData),
                showChangeSearch: jsonData.showChangeSearch,
                showReloadPage: jsonData.showReloadPage
            }));
        }

        $(window).scrollTop($('#alert-region').offset().top + MOP.config.isMobile() ? -30 : 0);
    }

    /**
     * Sample JSON Data
     * app.commands.execute("app:notify", {
     *           type: 'danger'    // Required. Can be info(default)|danger|success|warning
     *           msg: ''           // Required. It contain the alert message
     *       });
     */
    MOP.commands.setHandler('MOP:alert', showAlert);

    MOP.commands.setHandler('MOP:alert:danger', function (jsonData) {
        showAlert(Object.assign({}, jsonData, {type: 'danger'}))
    });

    MOP.commands.setHandler('MOP:alert:success', function (jsonData) {
        showAlert(Object.assign({}, jsonData, {type: 'success'}))
    });

    MOP.commands.setHandler('MOP:clearAlert', function() {
        MOP.alertRegion.closeAlert();
    });
 
    /**
     * Show the view passed as argument in the banner region
     */
    MOP.commands.setHandler('MOP:banner:show', function () {
      let mopBanner = MOP.config.getInstanceConfig('mopBanner');
    
      if (!MOP.Utilities.empty(mopBanner) && MOP.getCurrentPage() !== 'delay' && MOP.getCurrentPage() !== 'home') {
       
        const Banner = Marionette.ItemView.extend({
          template: MOP.loadRightTemplate('header', 'banner'),
          serializeData: function () {
            const data = { mopBanner: mopBanner };
            return data;
          }
        });
          
        MOP.bannerRegion.show(new Banner());
      }
    });

    MOP.commands.setHandler('MOP:permanentAlert:show', function (msg) {
      const view = require('views').default;
      const alert = new view.Alert({
        model: new Backbone.Model({ type: 'info', msg: msg }),
        hideCloseAlertButton: true
      })
      MOP.permanentAlert.show(alert);
    });

    /**
     * Show the view passed as argument in the dialog region
     */
    MOP.commands.setHandler("MOP:dialog:show", function (dialogView) {
        if (typeof dialogView.render === 'function') {
            //dialogView is view
            MOP.dialogRegion.show(dialogView);

        } else {
            //dialogView is object
            var Dialog = Marionette.ItemView.extend({
                template: dialogView.template == undefined ? MOP.loadRightTemplate('common', 'dialog') : dialogView.template,
                events: dialogView.events == undefined ? {} : dialogView.events,
                model: new Backbone.Model(),
                values: dialogView.values == undefined ? {} : dialogView.values,
                /*
                * The type of dialog: 0 is normal (dafault), 1 is alert
                */
                type: dialogView.type == undefined ? 0 : dialogView.type,

                initialize: function initialize () {
                    this.model.set('header', this.values['header']);
                    this.model.set('body', this.values['body']);
                    this.model.set('footer', this.values['footer']);
                    this.model.set('type', this.type);
                }

            });

            MOP.dialogRegion.show(new Dialog());
        }
    });

    /**
     * Destroy the actual dialog
     */
    MOP.commands.setHandler("MOP:dialog:destroy", function () {
        MOP.dialogRegion.reset();
    });

    MOP.commands.setHandler('MOP:contactus:show', function() {
        if (MOP.config.isMobile()) {
            MOP.MAM.startContactus();
        }
    });

    MOP.loadVoipWidget = (resid, memberid, name, cleanupCallback, fromNewPatientsPage = false) => {

      retry(() => import(/* webpackChunkName: "voip-widget" */ 'apps/voip-widget/index'))
      .then(module => {
        module.start(resid, memberid, name, cleanupCallback, fromNewPatientsPage);
      })
    }

    MOP.initGlobalVoipObject = cleanupCallback => {

      MOP.VoipWidget =  {
        active: false,
        call: null,
        conversation: null,
        cleanup: cleanupCallback,
        reactRoot: undefined
      };
    
      MOP.setGlobalVoipObjectActive(true);
    };

    MOP.setGlobalVoipObjectActive = state => {
      MOP.VoipWidget.active = state;
    };

    MOP.isGlobalVoipObjectActive = () => {
      return MOP.VoipWidget && MOP.VoipWidget.active;
    };

    MOP.destroyGlobalVoipObject = () => {
      MOP.VoipWidget.cleanup();
      delete MOP.VoipWidget;
    };

    MOP.loadPolicySelect = (userid = null) => {
      retry(() => import(/* webpackChunkName: "insurance-card-number-select" */ 'components-mop/PolicySelect/legacy'))
      .then(module => {
        module.start(userid);
      })
    };

    MOP.showCloseButtonLauncherEmbedded = () => {
      if (MOP.config.isOldLauncher(MOP)) {
        const mopOrigin = MOP.config.getMOPLauncherOrigin(MOP);
        if (mopOrigin === MOP_LAUNCHER_ORIGIN_LEAD_MANAGEMENT || mopOrigin === MOP_LAUNCHER_AXA_TEMPORARY) return
        // Aggiungo il pulsante al DOM
        const mopLauncherCloseButton = document.createElement('button');
        mopLauncherCloseButton.className = "tt-mop-launcher-close-button";
        mopLauncherCloseButton.setAttribute('tt-mop-launcher-close-button', true)
        mopLauncherCloseButton.addEventListener("click", e => {
          MOP.sendMessageToParentFrame({ fn: 'closeMopLauncher'});
        });
        const mopLauncherCloseButtonIcon = document.createElement('i');
        mopLauncherCloseButtonIcon.className = "tt-icon-times";
        mopLauncherCloseButton.appendChild(mopLauncherCloseButtonIcon);
        document.body.appendChild(mopLauncherCloseButton);
      }
    };

    MOP.toggleCloseButtonLauncherEmbedded = show => {
      if (MOP.config.isOldLauncher(MOP)) {
        // Aggiungo il pulsante al DOM
        const closeButton = document.querySelector("button[tt-mop-launcher-close-button]");

        if (closeButton) {
          if (show === true) {
            // Mostra
            closeButton.style.display = "block";
          } else {
            // Nascondere
            closeButton.style.display = "none";
          }
        }
      }
    };

    MOP.sendMOPLocation = () => {
      
      if (!MOP.Utilities.isMobileApp()) {

        const data = {};
        data.inclusion_type = MOP.config.getInclusionType(MOP);
        if (data.inclusion_type !== MOP.constants.MOP_LOCATION_STANDALONE) {
          data.location = data.location = MOP.getParentLocation();
        } else if (data.inclusion_type === MOP.constants.MOP_LOCATION_STANDALONE) {
          data.location = document.referrer;
        }
        
        data.inclusion_querystring = { ...MOP.querystring };
        if (!MOP.Utilities.empty(data.inclusion_querystring) && !MOP.Utilities.empty(data.inclusion_querystring.parent_location)) {
          delete data.inclusion_querystring.parent_location;
        }

        // Se non è presente la location vuol dire che siamo atterratti in app.tuotempo.com da un url pulita, questo caso al momento non lo vogliamo salvare
        if (!MOP.Utilities.empty(data.location)) {
          mopLocation(data);
        }

      }
    }

    MOP.loadRightTemplate = (module, name) => {
      try {
        var template = require(`handlebars-template/${module}/${name}${MOP.config.isMobile() ? '.mobile' : ''}.handlebars`);
        return template;
      } catch (error) {
      }

      var defaultTemplate = require(`handlebars-template/${module}/${name}.handlebars`);
      return defaultTemplate;

    }

	export default MOP;