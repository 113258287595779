import React from 'react';
import PropTypes from 'prop-types';
import * as style from "./style";

import { Label } from "ui/Label";

export const Announcement = ({ title = "", text = "" }) => {

  return (
    <div css={style.base}>
      <Label size="medium" weight="medium">{title}</Label>
      <div css={style.text}>
        <Label color="text_light" size="medium" dangerouslySetInnerHTML={text} />
      </div>
    </div>
  );
};

Announcement.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};