var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "is-8 is-offset-2";
},"3":function(container,depth0,helpers,partials,data) {
    return "";
},"5":function(container,depth0,helpers,partials,data) {
    return "error";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<form role=\"form\" class=\"tt-columns tt-block\" onsubmit=\"return false;\">\n  <div class=\"tt-column ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotMobile") || (depth0 != null ? lookupProperty(depth0,"isNotMobile") : depth0)) != null ? helper : alias2),(options={"name":"isNotMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":72}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNotMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n    <div class=\"tt-container\">\n      "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"First Access MOP Phone Required",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":68}}})) != null ? stack1 : "")
    + "\n    </div>\n\n    <div class=\"tt-form-divider\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Phone",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":33},"end":{"line":7,"column":69}}})) != null ? stack1 : "")
    + "</div>\n    <div class=\"tt-input-container\" data-nested-page=\"first\" data-config=\"mopMobile\" data-config-validator=\"phone\">\n      <input class=\"tt-input "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"bad_phone") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":29},"end":{"line":9,"column":58}}})) != null ? stack1 : "")
    + "\" autocomplete=\"off\" type=\"tel\" name=\"phone\"\n        data-validation=\"true\"\n        placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mobile",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":21},"end":{"line":11,"column":58}}})) != null ? stack1 : "")
    + "\"\n        data-validation-error=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mobile is required",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":31},"end":{"line":12,"column":80}}})) != null ? stack1 : "")
    + "\">\n    </div>\n\n    <div class=\"tt-container\">\n      <button type=\"button\" class=\"tt-button tt-button--primary\" id=\"updatePhone\">\n        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Proceed",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":17,"column":46}}})) != null ? stack1 : "")
    + "\n      </button>\n    </div>\n  </div>\n</form>";
},"useData":true});