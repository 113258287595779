var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"tt-action js-find-doctor-navigate\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"typologyid") || (depth0 != null ? lookupProperty(depth0,"typologyid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typologyid","hash":{},"data":data,"loc":{"start":{"line":1,"column":54},"end":{"line":1,"column":68}}}) : helper)))
    + "\" data-navigate=\"typologies_id\">\n    <div class=\"tt-action__content\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"typologyTitle") || (depth0 != null ? lookupProperty(depth0,"typologyTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typologyTitle","hash":{},"data":data,"loc":{"start":{"line":2,"column":36},"end":{"line":2,"column":53}}}) : helper)))
    + "</div>\n    <span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n</a>";
},"useData":true});