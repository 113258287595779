var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"selectedAvl") : depth0)) != null ? lookupProperty(stack1,"text") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":4,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-info tt-info--reminder tt-info--reminder--desktop tt-text-center\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"selectedAvl") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showLoginIntro") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":8},"end":{"line":89,"column":15}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"row\">\n                <div class=\"col-lg-12\" align=\"center\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Use Default Account",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":54},"end":{"line":87,"column":104}}})) != null ? stack1 : "")
    + "</div>\n            </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"fromRegister") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":8},"end":{"line":95,"column":20}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <!-- <div class=\"row\">\n					<div class=\"col-lg-12\" align=\"center\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Login Intro",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":43},"end":{"line":93,"column":85}}})) != null ? stack1 : "")
    + "</div>\n				</div> -->\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "tt-columns";
},"14":function(container,depth0,helpers,partials,data) {
    return "tt-container";
},"16":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-column is-8 is-offset-2\"";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<h2 class=\"h5 tt-deprecated-h2\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Mop Login Deferred",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":38},"end":{"line":112,"column":87}}})) != null ? stack1 : "")
    + "</h2>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<h2 class=\"h5 tt-deprecated-h2\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"panelTitle") || (depth0 != null ? lookupProperty(depth0,"panelTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"panelTitle","hash":{},"data":data,"loc":{"start":{"line":114,"column":38},"end":{"line":114,"column":52}}}) : helper)))
    + "</h2>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"samlButtons") : depth0),{"name":"each","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":4},"end":{"line":127,"column":13}}})) != null ? stack1 : "")
    + "      <div class=\"tt-separator-login\">\n        <hr class=\"tt-mr\">\n        <span class=\"tt-separator-login__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"Mop Login Or",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":130,"column":47},"end":{"line":130,"column":90}}})) != null ? stack1 : "")
    + "</span>\n        <hr class=\"tt-ml\">\n      </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "      <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":18},"end":{"line":122,"column":59}}})) != null ? stack1 : "");
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":59},"end":{"line":122,"column":97}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n        <div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":123,"column":13},"end":{"line":123,"column":78}}})) != null ? stack1 : "")
    + ">\n          <a class=\"tt-js-loginWithSaml tt-button tt-button--primary tt-button-login\" saml-id=\""
    + alias4(alias3((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" role=\"button\" style=\"background-color: #"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"button") : depth0)) != null ? lookupProperty(stack1,"color") : stack1), depth0))
    + ";\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"button") : depth0)) != null ? lookupProperty(stack1,"text") : stack1),{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":172},"end":{"line":124,"column":217}}})) != null ? stack1 : "")
    + "</a>\n        </div>\n      </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Or",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":115},"end":{"line":148,"column":148}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Idnumber",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":149},"end":{"line":148,"column":188}}})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"parentsPhone") || (depth0 != null ? lookupProperty(depth0,"parentsPhone") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"parentsPhone","hash":{},"data":data,"loc":{"start":{"line":149,"column":36},"end":{"line":149,"column":52}}}) : helper)));
},"29":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"parentsEmail") || (depth0 != null ? lookupProperty(depth0,"parentsEmail") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"parentsEmail","hash":{},"data":data,"loc":{"start":{"line":149,"column":79},"end":{"line":149,"column":95}}}) : helper)));
},"31":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"fromRegister") || (depth0 != null ? lookupProperty(depth0,"fromRegister") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fromRegister","hash":{},"data":data,"loc":{"start":{"line":149,"column":122},"end":{"line":149,"column":138}}}) : helper)));
},"33":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"fromForgot") || (depth0 != null ? lookupProperty(depth0,"fromForgot") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fromForgot","hash":{},"data":data,"loc":{"start":{"line":149,"column":163},"end":{"line":149,"column":177}}}) : helper)));
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"isStringNotEmpty")||(depth0 && lookupProperty(depth0,"isStringNotEmpty"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"legacyAuthenticationPlaceholder") : depth0),{"name":"isStringNotEmpty","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":6},"end":{"line":161,"column":27}}})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<div class=\"tt-input-container tt-input-login\">\n								<input\n									name=\"legacy_authentication_factor\"\n									type=\"text\"\n									class=\"tt-input tt-dynamic-text\"\n									placeholder=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"legacyAuthenticationPlaceholder") || (depth0 != null ? lookupProperty(depth0,"legacyAuthenticationPlaceholder") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"legacyAuthenticationPlaceholder","hash":{},"data":data,"loc":{"start":{"line":159,"column":22},"end":{"line":159,"column":57}}}) : helper)))
    + "\" />\n							</div>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Or",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":93},"end":{"line":171,"column":126}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Ticket",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":127},"end":{"line":171,"column":164}}})) != null ? stack1 : "");
},"40":function(container,depth0,helpers,partials,data) {
    return "tt-disabled";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"loginWithSaml") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":182,"column":12},"end":{"line":195,"column":24}}})) != null ? stack1 : "");
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"allowGuestRegistration") : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":183,"column":6},"end":{"line":194,"column":13}}})) != null ? stack1 : "");
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<div class=\"tt-separator-login\">\n								<hr class=\"tt-mr\">\n								<span class=\"tt-separator-login__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Login Or",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":186,"column":47},"end":{"line":186,"column":90}}})) != null ? stack1 : "")
    + "</span>\n								<hr class=\"tt-ml\">\n							</div>\n									<a data-e2eid=\"goto-registration\" class=\"js-GoToPage tt-avoid-inside-events tt-button tt-button--secondary tt-button-login "
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"notify","==",0,{"name":"checkConfig","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":189,"column":132},"end":{"line":189,"column":191}}})) != null ? stack1 : "")
    + "\" href=\"#\" role=\"button\" data-target-page=\"profile\" data-target-hash=\"register\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Register",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":189,"column":271},"end":{"line":189,"column":310}}})) != null ? stack1 : "")
    + "</a>\n								<!-- <div class=\"text-center\">\n										"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"First time user",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":191,"column":10},"end":{"line":191,"column":56}}})) != null ? stack1 : "")
    + "\n										<b><a class=\"js-GoToPage\" href=\"#\" role=\"button\" data-target-page=\"profile\" data-target-hash=\"register\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Ask for Password",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":192,"column":114},"end":{"line":192,"column":161}}})) != null ? stack1 : "")
    + "</a></b>\n								</div>-->\n";
},"46":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "      <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":204,"column":18},"end":{"line":204,"column":59}}})) != null ? stack1 : "");
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":204,"column":59},"end":{"line":204,"column":97}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n        <div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":205,"column":13},"end":{"line":205,"column":78}}})) != null ? stack1 : "")
    + ">\n            \n          <a class=\"tt-js-loginWithSpid tt-button tt-button--primary tt-button-login\" role=\"button\">\n            <img src=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"getImgPath") || (depth0 != null ? lookupProperty(depth0,"getImgPath") : depth0)) != null ? helper : alias2),(options={"name":"getImgPath","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":208,"column":22},"end":{"line":208,"column":52}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"getImgPath")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "/spid/spid-ico-circle-bb.svg\" style=\"width: 20px; margin-right: 5px\"/>\n            "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"MOP Login With Spid",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":209,"column":12},"end":{"line":209,"column":62}}})) != null ? stack1 : "")
    + "\n          </a>\n\n          <div class=\"tt-column tt-spid-buttons-container\" style=\"display: none\">\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"spidIdpAvailable") : depth0),{"name":"each","hash":{},"fn":container.program(47, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":214,"column":12},"end":{"line":218,"column":21}}})) != null ? stack1 : "")
    + "\n          </div>\n\n          \n          <div class=\"tt-spid-logo-container\" id=\"spid-agid-logo-lb\">\n            <img src=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"getImgPath") || (depth0 != null ? lookupProperty(depth0,"getImgPath") : depth0)) != null ? helper : alias2),(options={"name":"getImgPath","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":224,"column":22},"end":{"line":224,"column":52}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"getImgPath")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "/spid/spid-agid-logo-lb.png\" alt=\"spid-agid-logo-lb\" />\n          </div>\n\n        </div>\n      </div>\n\n";
},"47":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"tt-js-spid-button tt-spid-button\" id=\""
    + alias2(alias1(depth0, depth0))
    + "\">\n                <img src=\""
    + alias2(lookupProperty(helpers,"lookup").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"spidIdpLogos") : depths[1]),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":216,"column":26},"end":{"line":216,"column":57}}}))
    + "\" alt=\""
    + alias2(alias1(depth0, depth0))
    + "\" />\n              </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selectedAvl") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":7}}})) != null ? stack1 : "")
    + "<!-- primo tentativo -->\n<!-- Not waiting -->\n<div id=\"forgot-first-attempt-registration\" class=\"tt-hide\">\n    <div class=\"tt-alert tt-customer-alert\">\n        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Registration Completed",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":61}}})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n\n<div id=\"forgot-first-attempt\" class=\"tt-hide\">\n    <div class=\"tt-alert\">\n        <span id=\"translate1\"></span>\n        <a href='#' class=\"link showable\" data-showable=\"passNotReceivedQuest\">\n            "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Password Not Received Question",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":18,"column":73}}})) != null ? stack1 : "")
    + "\n        </a>\n    </div>\n\n    <div class=\"tt-alert tt-hide\" id=\"passNotReceivedQuest\">\n        <a class=\"link new-forgot-trigger\" href=\"#\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Ask for New Password",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":52},"end":{"line":23,"column":103}}})) != null ? stack1 : "")
    + " </a>\n        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Password Not Received 01",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":24,"column":63}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Signup Wrong Contact Alert",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":25,"column":65}}})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n\n<!-- Waiting -->\n<div id=\"forgot-first-waiting-attempt\" class=\"tt-alert tt-hide\">\n	<div>\n    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Wait For Password",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":32,"column":52}}})) != null ? stack1 : "")
    + "\n	</div>\n		<div>\n			<a class=\"link forgot-trigger\" href=\"#\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Ask for New Password",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":43},"end":{"line":35,"column":94}}})) != null ? stack1 : "")
    + "</a>\n			<span>\n				"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Password Not Received 01",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":4},"end":{"line":37,"column":59}}})) != null ? stack1 : "")
    + "\n			</span>\n		</div>\n    <span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Signup Wrong Contact Alert",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":10},"end":{"line":40,"column":67}}})) != null ? stack1 : "")
    + "\n        <!-- <a class=\"link change-address-trigger\" href=\"#\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Change Registration Number",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":61},"end":{"line":41,"column":118}}})) != null ? stack1 : "")
    + "</a> -->\n    </span>\n</div>\n<!-- fine primo tentativo -->\n\n<!-- secondo tentativo -->\n<div id=\"forgot-second-attempt\" class=\"tt-alert tt-hide\">\n    <div>\n        <span id=\"translate2\"></span>\n        <a data-config=\"notReceivedPasswordNotification\" href='#' class=\"link tt-hide showable\" data-showable=\"passNotReceivedQuest_2\">\n            "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Password Not Received Question 02",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":12},"end":{"line":51,"column":76}}})) != null ? stack1 : "")
    + "\n        </a>\n    </div>\n\n    <div data-config=\"notReceivedPasswordNotification\" class=\"tt-hide\" id=\"passNotReceivedQuest_2\">\n                    <span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Password Not Received 02",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":26},"end":{"line":56,"column":81}}})) != null ? stack1 : "")
    + "\n                      "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Signup Wrong Contact Alert",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":22},"end":{"line":57,"column":79}}})) != null ? stack1 : "")
    + "\n                        <!-- <a class=\"link change-address-trigger\" href=\"#\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Change Registration Number",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":77},"end":{"line":58,"column":134}}})) != null ? stack1 : "")
    + "</a><br /> -->\n                        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Otherwise Registration",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":24},"end":{"line":59,"column":77}}})) != null ? stack1 : "")
    + "\n                    </span>\n        <a class=\"link\" href=\"#\" id=\"js-contact-center-request\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Click Here",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":64},"end":{"line":61,"column":105}}})) != null ? stack1 : "")
    + "</a>\n        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Center Contact For Password",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":8},"end":{"line":62,"column":66}}})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n\n<!-- Waiting -->\n<div id=\"forgot-second-waiting-attempt\" class=\"tt-hide\">\n    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Wait For Password",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":4},"end":{"line":68,"column":52}}})) != null ? stack1 : "")
    + "\n    <a class=\"link new-forgot-trigger\" >"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Click Here",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":40},"end":{"line":69,"column":81}}})) != null ? stack1 : "")
    + "</a>\n</div>\n<!-- fine secondo tentativo -->\n\n<!-- terzo tentativo -->\n<!-- Invio ultimo tentativo -->\n<div id=\"forgot-third-attempt\" class=\"tt-hide new-forgot-trigger\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Center Contact For Password Received",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":66},"end":{"line":75,"column":133}}})) != null ? stack1 : "")
    + "</div>\n<!-- fine terzo tentativo -->\n\n<!-- Invio solo per email -->\n<div id=\"forgot-email-attempt\" class=\"tt-hide\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Your new passsword has been emailed to you.",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":47},"end":{"line":79,"column":121}}})) != null ? stack1 : "")
    + "</div>\n<!-- fine Invio solo per email -->\n\n\n<div id=\"loginContainer\" class=\"tt-login-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromParentPage") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":84,"column":4},"end":{"line":96,"column":11}}})) != null ? stack1 : "")
    + "		<div class=\"tt-hide\" id=\"login-alert-div\">\n			<div class=\"tt-alert tt-alert--danger\">\n				<div data-e2eid=\"login-alert\">\n					"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Incorrect login",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":5},"end":{"line":100,"column":51}}})) != null ? stack1 : "")
    + "\n				</div>\n			</div>\n		</div>\n		<div class=\"tt-hide\" id=\"login-alert-div-custom\">\n			<div class=\"tt-alert tt-alert--danger\" id=\"login-alert-div-custom-text\">\n			</div>\n		</div>\n		<div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":14},"end":{"line":108,"column":55}}})) != null ? stack1 : "");
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":55},"end":{"line":108,"column":93}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n			<div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":109,"column":8},"end":{"line":109,"column":73}}})) != null ? stack1 : "")
    + ">\n				<div class=\"tt-ph-2\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDeferred") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.program(20, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":111,"column":5},"end":{"line":115,"column":12}}})) != null ? stack1 : "")
    + "				</div>\n			</div>\n		</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loginWithSaml") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":120,"column":4},"end":{"line":133,"column":11}}})) != null ? stack1 : "")
    + "\n\n    <form role=\"form\" autocomplete=\"off\">\n        <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":20},"end":{"line":137,"column":61}}})) != null ? stack1 : "");
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":61},"end":{"line":137,"column":99}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n					<div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":138,"column":10},"end":{"line":138,"column":75}}})) != null ? stack1 : "")
    + ">\n					<div class=\"tt-container-list-radius tt-mb-20\">\n						<div class=\"tt-input-container tt-input-login\">\n							<input\n									name=\"user\"\n									type=\"email\"\n									class=\"tt-input tt-dynamic-text tt-no-border\"\n									id=\"inputUserAddress\"\n									autocomplete=\"new-user\"\n                  data-e2eid=\"username-input\"\n									placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Email address or Mobile number",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":22},"end":{"line":148,"column":83}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"idnumberMopRegistration") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":83},"end":{"line":148,"column":195}}})) != null ? stack1 : "")
    + "\"\n									value=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"parentsPhone") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":16},"end":{"line":149,"column":59}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"parentsEmail") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":59},"end":{"line":149,"column":102}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromRegister") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":102},"end":{"line":149,"column":145}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"fromForgot") : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":145},"end":{"line":149,"column":184}}})) != null ? stack1 : "")
    + "\" />\n						</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"legacyAuthentication") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":6},"end":{"line":162,"column":13}}})) != null ? stack1 : "")
    + "\n						<div class=\"tt-input-container tt-input-container--password tt-input-login\">\n							<input name=\"password\"\n									type=\"password\"\n									class=\"tt-input js-toggle-password-visibility-input\"\n									id=\"inputUserPassword\"\n									autocomplete=\"new-password\"\n                  data-e2eid=\"password-input\"\n									placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Password",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":22},"end":{"line":171,"column":61}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"idnumberMopRegistration") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":61},"end":{"line":171,"column":171}}})) != null ? stack1 : "")
    + "\">\n              <a href=\"javascript:;\" class=\"tt-input-container--password-text js-toggle-password-visibility-btn\" data-e2eid=\"show-hide-password\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Show",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":172,"column":145},"end":{"line":172,"column":180}}})) != null ? stack1 : "")
    + "</a>\n            </div>\n					</div>\n\n            <div id=\"login-forgot-link\" class=\"tt-login-container-forgot\">\n              <a data-e2eid=\"forgot-password-link\" href=\"#\" class=\"forgot-trigger "
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"notify","==",0,{"name":"checkConfig","hash":{},"fn":container.program(40, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":177,"column":82},"end":{"line":177,"column":141}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"I Forgot My Password",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":177,"column":143},"end":{"line":177,"column":194}}})) != null ? stack1 : "")
    + "</a>\n						</div>\n\n					  <button id=\"loginButton\" data-e2eid=\"login-button\" type=\"submit\" class=\"tt-button tt-button--primary tt-button-login tt-mt-20\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Start Session",{"name":"translate","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":180,"column":134},"end":{"line":180,"column":178}}})) != null ? stack1 : "")
    + "</button>\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"fromParentPage") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(42, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":181,"column":6},"end":{"line":196,"column":20}}})) != null ? stack1 : "")
    + "\n\n					</div>\n				</div>\n    </form>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loginWithSpid") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":203,"column":4},"end":{"line":230,"column":11}}})) != null ? stack1 : "")
    + "\n\n    \n\n</div>\n";
},"useData":true,"useDepths":true});