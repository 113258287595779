var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\"javascript:\" class=\"tt-button tt-button--paginator otherResults tt-button--primary js-prev-results\" data-results-direction=\"prev\" role=\"button\" style=\"font-size:16px;\">\n                    <i class=\"tt-button__icon tt-button__icon--left tt-icon-angle-left\"></i>\n                    <span class=\"tt-button__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Previous",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":50},"end":{"line":17,"column":89}}})) != null ? stack1 : "")
    + "</span>\n                </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"multiple-availabilities-container\" class=\"operator_availabilities\">\n    <div class=\"tt-container\">\n        <div class=\"tt-columns\" align=\"right\">\n            <div class=\"tt-column is-3 is-offset-9\">\n                <a href=\"javascript:\" id=\"changeSearch\" class=\"tt-button tt-button--primary tt-customer-change-search tt-mb-10\"><span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Search Again",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":134},"end":{"line":5,"column":177}}})) != null ? stack1 : "")
    + "</span></a>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class=\"tt-container\">\n    <div class=\"tt-columns tt-column-flex\">\n        <div class=\"tt-column is-half\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPrevResults") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + "         </div>\n        <div class=\"tt-column is-half\">\n            <a href=\"javascript:\" class=\"tt-button tt-button--paginator tt-button--primary otherResults js-next-results\" data-results-direction=\"next\" role=\"button\" style=\"font-size:16px;\">\n                <i class=\"tt-button__icon tt-button__icon--right tt-icon-angle-right\"></i>\n                <span class=\"tt-button__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Next",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":46},"end":{"line":24,"column":81}}})) != null ? stack1 : "")
    + "</span>\n            </a>\n        </div>\n    </div>\n</div>\n\n";
},"useData":true});