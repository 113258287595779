var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"tt-input-checkbox-container tt-deprecated-input-checkbox-container tt-mt-10\">\n              <li class=\"tt-checkbox tt-no-border tt-no-padding\">\n                <label class=\"tt-checkbox__label tt-pl-0 \" style=\"right: auto !important;\">\n                  <div class=\"tt-deprecated-loader\" style=\"display: none;\"></div>\n                  <input class=\"tt-checkbox__label-input js-oblivion-checkbox-parents\" type=\"checkbox\">\n                </label>\n                <div class=\"tt-checkbox__text\">\n                  "
    + alias4(((helper = (helper = lookupProperty(helpers,"checkbox_parents") || (depth0 != null ? lookupProperty(depth0,"checkbox_parents") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"checkbox_parents","hash":{},"data":data,"loc":{"start":{"line":18,"column":18},"end":{"line":18,"column":38}}}) : helper)))
    + "\n                  <span>\n                   ("
    + alias4(((helper = (helper = lookupProperty(helpers,"parents_list") || (depth0 != null ? lookupProperty(depth0,"parents_list") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parents_list","hash":{},"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":36}}}) : helper)))
    + ")\n                  </span>\n                </div>\n              </li>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-body\">\n        <div class=\"tt-info tt-info--column tt-no-border js-oblivion-dialog-start-msg-container tt-no-padding\">\n          <div class=\"tt-info__content\">\n            <div>\n              "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"msg") || (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":8,"column":14},"end":{"line":8,"column":23}}}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showCheckbox") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":25,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n        </div>\n        <div class=\"tt-info tt-info--column tt-no-border js-oblivion-dialog-response-success-container\"\n          style=\"display: none;\">\n          <i class=\"tt-info__icon fad tt-icon-check-circle\"></i>\n          <div class=\"tt-info__content js-oblivion-dialog-response-success-content\">\n          </div>\n        </div>\n        <div class=\"tt-info tt-info--column tt-no-border js-oblivion-dialog-response-error-container\"\n          style=\"display: none;\">\n          <i class=\"tt-info__icon tt-icon-exclamation-triangle\"></i>\n          <div class=\"tt-info__content js-oblivion-dialog-response-error-content\">\n          </div>\n        </div>\n      </div>\n      <div class=\"modal-footer\">\n        <div class=\"tt-flexend-container tt-dialog-button js-oblivion-dialog-btn-action-container\">\n\n            <button type=\"button\" class=\"tt-button tt-button--secondary js-oblivion-dialog-cancel-btn\">\n              "
    + alias4(((helper = (helper = lookupProperty(helpers,"cancel_btn_label") || (depth0 != null ? lookupProperty(depth0,"cancel_btn_label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cancel_btn_label","hash":{},"data":data,"loc":{"start":{"line":45,"column":14},"end":{"line":45,"column":34}}}) : helper)))
    + "\n            </button>\n          \n\n            <button type=\"button\" class=\"tt-button tt-button--danger js-oblivion-dialog-confirm-btn\">\n              "
    + alias4(((helper = (helper = lookupProperty(helpers,"confirm_btn_label") || (depth0 != null ? lookupProperty(depth0,"confirm_btn_label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"confirm_btn_label","hash":{},"data":data,"loc":{"start":{"line":50,"column":14},"end":{"line":50,"column":35}}}) : helper)))
    + "\n            </button>\n        \n        </div>\n        <div class=\"tt-flexend-container tt-dialog-button js-oblivion-dialog-btn-response\" style=\"display: none;\">\n\n            <button type=\"button\" class=\"tt-button tt-button--primary js-oblivion-dialog-cancel-btn\">\n              "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Ok",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":14},"end":{"line":57,"column":47}}})) != null ? stack1 : "")
    + "\n            </button>\n        \n        </div>\n        <div style=\"display: none; padding-bottom: 100px;\" class=\"js-oblivion-dialog-loader-container\">\n          <div class=\"tt-deprecated-loader\"></div>\n          <div class=\"tt-mt-10 tt-text-center\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Operation In Progress",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":47},"end":{"line":63,"column":103}}})) != null ? stack1 : "")
    + "</div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});