var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "   <a href=\"javascript:\" data-navigate=\"back\" class=\"closewaitingtimes icon tt-icon-times light\" style=\"font-size:33px;\"></a>\n\n    <table id=\"table-pagination\" class=\"table table-bordered table-striped\">\n        <thead>\n        <tr>\n            <th data-align=\"center\" >"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Typology",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":37},"end":{"line":6,"column":76}}})) != null ? stack1 : "")
    + "</th>\n            <th data-align=\"center\" >"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Concept",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":37},"end":{"line":7,"column":75}}})) != null ? stack1 : "")
    + "</th>\n            <th data-align=\"center\" >"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Price",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":37},"end":{"line":8,"column":73}}})) != null ? stack1 : "")
    + "</th>\n            <th data-align=\"center\" >"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Waiting time",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":37},"end":{"line":9,"column":80}}})) != null ? stack1 : "")
    + "</th>\n            <th data-align=\"center\" >"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Area Mop Title",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":37},"end":{"line":10,"column":82}}})) != null ? stack1 : "")
    + "</th>\n        </tr>\n        </thead>\n        <tbody></tbody>\n    </table>\n";
},"useData":true});