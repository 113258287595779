import React from 'react';
import PropTypes from 'prop-types';
import * as style from "./style";
import { Label } from '../Label';

/**
 * Primary UI component for user interaction
 */
export const Divider = ({ type = "horizontal", children = null }) => {

  if (children) {
    return (
      <div css={[style.containerWithChild]}>
        <div css={[style.base, style["horizontal"]]}></div>
        <div css={style.child}>
          {children}
        </div>
        <div css={[style.base, style["horizontal"]]}></div>
      </div>
    )
  }

  return (
    <div css={[style.base, style[type]]}>
    </div>
  );
};

Divider.propTypes = {
  type: PropTypes.oneOf(['horizontal', 'horizontalFlex', 'vertical']),
  children: PropTypes.string
};