import { useTheme } from '@emotion/react';
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";

import { Container } from '../Container';
import { Icon } from '../Icon';
import { Popover } from '../Popover';

import { OutClick } from 'common-lib/components/hoc/OutClick';


/**
 * Primary UI component for user interaction
 */
const ButtonPop = ({ children = <></>, open, hocRef, onClick }) => {

  const buttonRef = useRef(null);

  return (
    <div ref={hocRef}>
      <div css={style.base} onClick={onClick} ref={buttonRef}>
        <Icon name={"GearSolid"} color={"primary"} />
        <Icon name={"CaretDownSolid"} color={"primary"} />
      </div>
      <Popover open={open} anchorEl={buttonRef.current}>
        {children}
      </Popover>
    </div>
  );

};

ButtonPop.propTypes = {
  children: PropTypes.node,
};


export const ButtonPopover = OutClick(ButtonPop);