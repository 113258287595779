import React from 'react';
import PropTypes from 'prop-types';
import * as style from "./style";
import { Highlight } from '../Highlight';

/**
 * Primary UI component for user interaction
 */
export const PatientItem = ({ firstRow = null, secondRow = null, memberid = null, searchedString = "", onClick = null }) => {

  // searchedString mi serve per poi implementare la logica dell'evidenziazione 
  return (
    <div css={style.base} onClick={() => onClick(memberid)}>
      <div css={style.patient}>
        {
          firstRow &&
          <Highlight
            toHighlight={firstRow}
            highlightText={searchedString}
            size={"medium"}
            weight={"bold"}
          />
        }
        {
          secondRow &&
          <Highlight
            toHighlight={secondRow}
            highlightText={searchedString}
            color="text_light"
          />
        }
      </div>
    </div>
  );
};

PatientItem.propTypes = {
  firstRow: PropTypes.string,
  secondRow: PropTypes.string,
  memberid: PropTypes.string,
  searchedString: PropTypes.string,
  onClick: PropTypes.func
};