import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@emotion/react';

import * as style from "./style.js";

import { Label } from '../Label';

import { TTPolyglot } from "@frontend/tt-polyglot";

export const FilePreview = ({ children = "", onClick = null, disabled = false }) => {

  const theme = useTheme();

  return (
    <div css={style.inputContainer(theme)({ disabled })}>
      <div css={style.input(theme)({ disabled })}>
        <Label size={"medium"} color={disabled ? "text_light" : "text"}>
          {children}
        </Label>
      </div>
      <button css={style.button(theme)({ disabled })} onClick={onClick}>
        {TTPolyglot.t("Change")}
      </button>
    </div>
  );
};

FilePreview.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};