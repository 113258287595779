var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"social_name") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":15,"column":31}}})) != null ? stack1 : "")
    + "                        <span "
    + ((stack1 = (lookupProperty(helpers,"indexOf")||(depth0 && lookupProperty(depth0,"indexOf"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"duplicateFields") : depth0),"lname",{"name":"indexOf","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":30},"end":{"line":16,"column":105}}})) != null ? stack1 : "")
    + ">"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"lname") : stack1), depth0))
    + "</span>\n                        <span "
    + ((stack1 = (lookupProperty(helpers,"indexOf")||(depth0 && lookupProperty(depth0,"indexOf"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"duplicateFields") : depth0),"lname_2",{"name":"indexOf","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":30},"end":{"line":17,"column":107}}})) != null ? stack1 : "")
    + ">"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"lname_2") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"phone") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":129},"end":{"line":17,"column":200}}})) != null ? stack1 : "")
    + "</span>\n                        <span "
    + ((stack1 = (lookupProperty(helpers,"indexOf")||(depth0 && lookupProperty(depth0,"indexOf"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"duplicateFields") : depth0),"phone",{"name":"indexOf","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":30},"end":{"line":18,"column":105}}})) != null ? stack1 : "")
    + ">"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"phone") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"email") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":125},"end":{"line":18,"column":196}}})) != null ? stack1 : "")
    + "</span>\n                        <span "
    + ((stack1 = (lookupProperty(helpers,"indexOf")||(depth0 && lookupProperty(depth0,"indexOf"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"duplicateFields") : depth0),"email",{"name":"indexOf","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":30},"end":{"line":19,"column":105}}})) != null ? stack1 : "")
    + ">"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"idnumber") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":125},"end":{"line":19,"column":199}}})) != null ? stack1 : "")
    + "</span>\n                        <span "
    + ((stack1 = (lookupProperty(helpers,"indexOf")||(depth0 && lookupProperty(depth0,"indexOf"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"duplicateFields") : depth0),"idnumber",{"name":"indexOf","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":30},"end":{"line":20,"column":108}}})) != null ? stack1 : "")
    + ">"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"idnumber") : stack1), depth0))
    + "</span>\n                      </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                          <span "
    + ((stack1 = (lookupProperty(helpers,"indexOf")||(depth0 && lookupProperty(depth0,"indexOf"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"duplicateFields") : depth0),"social_name",{"name":"indexOf","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":32},"end":{"line":12,"column":113}}})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"social_name") : stack1), depth0))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-text-danger\"";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                          <span "
    + ((stack1 = (lookupProperty(helpers,"indexOf")||(depth0 && lookupProperty(depth0,"indexOf"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"duplicateFields") : depth0),"fname",{"name":"indexOf","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":32},"end":{"line":14,"column":107}}})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"fname") : stack1), depth0))
    + "</span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "<span class=\"tt-text-graydarker\">,</span>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"tt-text-bold tt-mb-10\">\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"There are duplicates",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":67}}})) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"modal-body\">\n                <ul id=\"duplicates-user-list\" class=\"tt-ml-16\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"users") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":22,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\n            </div>\n            <div class=\"modal-footer tt-flexend-container tt-dialog-button\">\n              <button type=\"button\" class=\"tt-button tt-button--secondary\" data-dismiss=\"modal\" id=\"close_dialog\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Clear",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":114},"end":{"line":26,"column":150}}})) != null ? stack1 : "")
    + "</button>\n              <button type=\"button\" class=\"tt-button tt-button--primary\" id=\"force_update\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Ignore Duplicates",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":91},"end":{"line":27,"column":139}}})) != null ? stack1 : "")
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});