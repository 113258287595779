import { useTheme } from '@emotion/react';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useMediaQuery } from "react-responsive";

import { BookButtonDesktop } from './desktop';
import { BookButtonMobile } from './mobile';

import { ButtonProvider } from './Context';

/**
 * Primary UI component for user interaction
 */
export const BookButton = React.memo(({ validate = null, onClick = null, disabled = false, data = null }) => {

  const theme = useTheme();

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.layout.mobileView})`,
  });

  return (
    <ButtonProvider validate={validate} onClick={onClick} disabled={disabled}>
      {
        isMobile ?
          <BookButtonMobile data={data} /> :
          <BookButtonDesktop data={data} />
      }
    </ButtonProvider>
  );
});

BookButton.propTypes = {
  validate: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    firstStepButtonTitle: PropTypes.string,
    secondStepButtonTitle: PropTypes.string
  }).isRequired
};