var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a class=\"tt-action js-instace_selection\" href=\"#\" data-index=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":13,"column":75},"end":{"line":13,"column":84}}}) : helper)))
    + "\">\n                <div class=\"tt-action__content\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":14,"column":48},"end":{"line":14,"column":56}}}) : helper)))
    + "</div>\n                <span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n            </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  " <div class=\"geo_logo tt-text-center\">\n        <img src=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"getImgPath") || (depth0 != null ? lookupProperty(depth0,"getImgPath") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"getImgPath","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":18},"end":{"line":2,"column":48}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"getImgPath")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "/customers/tt-vithas/logo-vithas-hospitales.jpg\" style=\"width:80%\"/>\n    </div>\n\n    <div class=\"geo_introduction\">\n        <span>Bienvenido</span>\n        19 hospitales y 27 centros médicos.\n        Más de 100 años de experiencia e investigación al cuidado de tu salud.\n    </div>\n\n    <div class=\"geolocations_container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"instances") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":17,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n";
},"useData":true});