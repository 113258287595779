import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Cleave from "cleave.js/react";

import * as style from "./style.js";
import { useTheme } from '@emotion/react';
import { Label } from "../Label";

export const DateInput = ({ placeholder = "", onChange = null, pattern = null, error = null, size = "normal", value = null, disabled = false }) => {
  const theme = useTheme();

  const [val, setValue] = useState(value || "");

  useEffect(() => {
    setValue(value || '');
  }, [value]);

  const handleOnChange = event => {

    event.preventDefault();

    const value = event.target.value;

    setValue(value);

    if (onChange)
      onChange(value);
  };

  return (
    <div css={style.base}>
      <div css={style.inputContainer(theme)({error: !!error, size})}>
        <Cleave
          css={style.input(theme)({ error: !!error, disabled })}
          placeholder={placeholder}
          options={{ date: true, datePattern: pattern }}
          onChange={handleOnChange}
          value={val}
          disabled={disabled}
        />
      </div>
      {!!error && <Label color={"danger"}>{error}</Label>}
    </div>
  );

};
DateInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  pattern: PropTypes.array,
  error: PropTypes.string,
  size: PropTypes.oneOf(["small", "normal", "big"]),
  value: PropTypes.string,
  disabled: PropTypes.bool,
};