import Marionette from 'backbone.marionette';
import Handlebars from 'handlebars';
import $ from 'jquery';

import { TTPolyglot } from "@frontend/tt-polyglot";

    var CommonBehaviour = function(MOP) {
        return Marionette.Behavior.extend({
            events: {
                'click [data-trigger]': 'mopTrigger',
                'click .pageLink':      'goToPage',
                'click #logout':        'logout',
                'click .contactus-link':'startContactus',
                'keyup .tt-dynamic-text': 'dynamicText',
                'click .js-toggle-password-visibility-btn': 'togglePasswordVisibility'
            },

            headerDisabled: false,

            initialize: function() {
                var _this = this;
            },
            
            togglePasswordVisibility: function togglePasswordVisibility(e) {
              
              e.preventDefault();
              
              var $field = $(MOP.TM.getTarget(e));
              
              var $input = $field.siblings(".js-toggle-password-visibility-input");
              
              var input_type = $input.attr('type');
              
              if (input_type == 'password') {
                $field.html(TTPolyglot.t("Hide"));
                $input.prop("type", "text")
              } else if (input_type == 'text') {
                $field.html(TTPolyglot.t("Show"));
                $input.prop("type", "password")
              }
              
            },
            
            dynamicText: function dynamicText (e) {
              
              var maxCount = 35;
              var $field = $(MOP.TM.getTarget(e));
              
              if ($field.val().length > maxCount) {
                //Smaller
              	$field.removeClass("textbox").addClass("textbox-smaller");
              } else {
                //Normal
                $field.removeClass("textbox-smaller").addClass("textbox");
              }
            },

            mopTrigger: function mopTrigger (e) {
                
                var $target = $(e.target);
                if ($target.attr('data-trigger') == null) $target = $(e.currentTarget);

                var option = $target.attr('data-trigger-option');
                var options = null;

                if (option) {
                    options = {};
                    options[option] = 1;
                }

                MOP.trigger($target.attr('data-trigger'), options);
            },

            goToPage: function goToPage(e) {
              MOP.TM.stopPropagation(e);
              $('body').click(); // Forzo la chiusura del menu laterale
              MOP.CVM.goToPage(e);
            },

            logout: function logout() {

                MOP.LM.doLogout();
                
                if (MOP.config.isMobile()) {
                  $('body').click(); // Forzo la chiusura del menu laterale
                }
            },

            showContactus: (function() {
                var template;
                var center_description;
                var adminPhone;
                var adminEmail;
                var schedule_info;
                var templateFilled;

                // Cacho template e dati.
                return function(areas) {

                    // Forzo la chiusura del menu laterale.
                    $('body').click();
                    MOP.TM.unblockLoading();

                    if (!template) {
                        template = MOP.loadRightTemplate('contactus', 'contactus');
                        center_description = MOP.config.getInstanceConfig('mopContactsDescription');
                        adminPhone = MOP.config.getInstanceConfig('adminPhone');
                        adminEmail = MOP.config.getInstanceConfig('adminEmail');
                        schedule_info = MOP.config.getInstanceScheduleInfo();

                        templateFilled = template({
                            areas: areas.toJSON(),
                            center_description: center_description,
                            adminPhone: adminPhone,
                            adminEmail: adminEmail,
                            schedule_info: schedule_info,
                            translations: {
                                email: TTPolyglot.t('email'),
                                MOPCall: TTPolyglot.t('MOP Call')
                            }
                        });
                    }

                    var $contactus_container = $('#contactus-container');

                    if ($contactus_container.hasClass('hide')) {
                        $contactus_container.html(templateFilled);
                        $('body').addClass('find');
                    } else {
                        $('body').removeClass('find');
                    }

                    MOP.TM.hide('.header-page');

                    MOP.TM.toggleMainRegion('#contactus-container');
                };
            }()),

            startContactus: (function startContactus() {
                var retrievedAreas;

                return function() {
                    var _this = this;
                    var deferred = new $.Deferred();

                    if (!retrievedAreas) {
                        var _entities_area = require('entities/area');
                        MOP.request('area:entities')
                        .done(function(areas) {
                            retrievedAreas = areas;
                            deferred.resolve(retrievedAreas);
                        })
                        .fail(function(e) {
                            deferred.reject(e);
                        });
                    } else {
                        deferred.resolve(retrievedAreas);
                    }

                    deferred
                        .done(function(areas) {
                            _this.showContactus(areas);
                        })
                        .fail(function(e) {
                            MOP.execute('MOP:alert', {type: 'danger', msg: e});
                        })
                        .always(function() {
                            MOP.loadingRegion.reset();
                        });

                };
            })()
        });
    };

    export default CommonBehaviour;
