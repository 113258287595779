import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";
import { useTheme } from '@emotion/react';

/**
 * Primary UI component for user interaction
 */
export const PatientSegmentTag = ({ color = null, children = "" }) => {

  const theme = useTheme();

  return (
    <div css={style.base(theme)({ color })} >
      <p css={style.content}>{children}</p>
    </div>
  );
};

PatientSegmentTag.propTypes = {
  color: PropTypes.string,
  children: PropTypes.string.isRequired
};