import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";
import { Icon } from 'ui/Icon/index.jsx';
import { useTheme } from '@emotion/react';

/**
 * Primary UI component for user interaction
 */
export const ButtonIcon = ({
  color = "primary",
  icon = null,
  iconColor = null,
  iconSize = null,
  onClick = () => console.log()
}) => {

  const theme = useTheme();

  return (
    <button
      type="button"
      css={style.base(theme, color)}
      onClick={onClick}>
      <Icon name={icon} color={iconColor} size={iconSize} />
    </button>
  );
};

ButtonIcon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  onClick: PropTypes.func
};
