import React from 'react';
import PropTypes from 'prop-types';

import { useTheme } from '@emotion/react';

import { Label } from '../../Label';
import { Dialog } from '../../Dialog';
import { Button } from '../../Button';

import {
  STATUS_POLICY_DELETE_FILE,
  STATUS_POLICY_ACCEPTED,
  STATUS_START
} from "../constants";

import { useUploadContext } from '../Context';

import { TTPolyglot } from "@frontend/tt-polyglot";


export const ConfirmDeleteDialog = () => {

  const {
    status,
    setStatus,
    isAdmin,
    deleteDoc,
    inputRef,
    uploadRef,
    setPrivacyAccepted
  } = useUploadContext();

  const handleDeleteDocument = async () => {
    inputRef.current.value = null;
    setStatus(STATUS_START);
    setPrivacyAccepted(isAdmin);
    deleteDoc && await deleteDoc();
  }

  return (
    <Dialog
      open={status === STATUS_POLICY_DELETE_FILE}
      closeButton={false}
      outClick={false}
      anchorEl={uploadRef?.current}
    >

      <Dialog.Content>
        <Label>
          {TTPolyglot.t('MOP Dialog Delete Document Description')}
        </Label>
      </Dialog.Content>

      <Dialog.Footer>
        <Button
          type={"light"}
          onClick={() => setStatus(STATUS_POLICY_ACCEPTED)}
        >
          {TTPolyglot.t("MOP Dialog Delete Document Cancel")}
        </Button>
        <Button
          type={"solid"}
          onClick={handleDeleteDocument}
        >
          {TTPolyglot.t("MOP Dialog Delete Document Confirm")}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

ConfirmDeleteDialog.propTypes = {
};