var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div>\n          "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Client",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":10},"end":{"line":4,"column":47}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"position") || (depth0 != null ? lookupProperty(depth0,"position") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"position","hash":{},"data":data,"loc":{"start":{"line":4,"column":48},"end":{"line":4,"column":60}}}) : helper)))
    + "\n            <span style=\"color:"
    + alias4(((helper = (helper = lookupProperty(helpers,"status_color") || (depth0 != null ? lookupProperty(depth0,"status_color") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status_color","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":47}}}) : helper)))
    + ";\">\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"status_text") || (depth0 != null ? lookupProperty(depth0,"status_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status_text","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":33}}}) : helper))) != null ? stack1 : "")
    + "\n            </span>\n      </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div>\n          <strong>\n              "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Client",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":14},"end":{"line":12,"column":51}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"position") || (depth0 != null ? lookupProperty(depth0,"position") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"position","hash":{},"data":data,"loc":{"start":{"line":12,"column":52},"end":{"line":12,"column":64}}}) : helper)))
    + "\n              <span style=\"color:"
    + alias4(((helper = (helper = lookupProperty(helpers,"status_color") || (depth0 != null ? lookupProperty(depth0,"status_color") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status_color","hash":{},"data":data,"loc":{"start":{"line":13,"column":33},"end":{"line":13,"column":49}}}) : helper)))
    + ";\">\n                  "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"status_text") || (depth0 != null ? lookupProperty(depth0,"status_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status_text","hash":{},"data":data,"loc":{"start":{"line":14,"column":18},"end":{"line":14,"column":35}}}) : helper))) != null ? stack1 : "")
    + "\n              </span>\n          </strong>\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tt-container\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"is_mine") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":18,"column":14}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});