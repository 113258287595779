var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a href=\"javascript:\" id=\"to-prescriptions\" class=\"tt-action\" data-nested-page=\"first\">\n        <span class=\"tt-action__icon tt-icon-angle-left\"></span>\n        <div class=\"tt-action__content\">\n            "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Rx More",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":50}}})) != null ? stack1 : "")
    + "\n        </div>\n    </a>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input type=\"hidden\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"legacyid") || (depth0 != null ? lookupProperty(depth0,"legacyid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"legacyid","hash":{},"data":data,"loc":{"start":{"line":13,"column":48},"end":{"line":13,"column":60}}}) : helper)))
    + "\" name=\"legacyid\" />";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-alert tt-customer-alert\">\n                    <div>\n                    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"addPaymentMethodBanner") || (depth0 != null ? lookupProperty(depth0,"addPaymentMethodBanner") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"addPaymentMethodBanner","hash":{},"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":19,"column":48}}}) : helper))) != null ? stack1 : "")
    + "\n                    </div>\n                </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-container\" data-nested-page=\"first\">\n            <div class=\"tt-alert tt-customer-alert\">\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"printReservationAdditionalInfo") || (depth0 != null ? lookupProperty(depth0,"printReservationAdditionalInfo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"printReservationAdditionalInfo","hash":{},"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":27,"column":52}}}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-container\" data-nested-page=\"first\">\n            <div class=\"tt-reservation-info\">\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"ot_printReservationAdditionalInfo") || (depth0 != null ? lookupProperty(depth0,"ot_printReservationAdditionalInfo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ot_printReservationAdditionalInfo","hash":{},"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":35,"column":55}}}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "/"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"social_name") || (depth0 != null ? lookupProperty(depth0,"social_name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"social_name","hash":{},"data":data,"loc":{"start":{"line":46,"column":86},"end":{"line":46,"column":101}}}) : helper)));
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"resource_face_img_link") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":51,"column":16},"end":{"line":55,"column":23}}})) != null ? stack1 : "")
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <img class=\"tt-appointment-info__avatar\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"resource_face_img_link") || (depth0 != null ? lookupProperty(depth0,"resource_face_img_link") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"resource_face_img_link","hash":{},"data":data,"loc":{"start":{"line":52,"column":62},"end":{"line":52,"column":88}}}) : helper)))
    + "\" alt=\"\">\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "                <img class=\"tt-appointment-info__avatar\" src=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"getImgPath") || (depth0 != null ? lookupProperty(depth0,"getImgPath") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"getImgPath","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":62},"end":{"line":54,"column":92}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"getImgPath")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "/doctor-avatar-default.svg\" alt=\"\">\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"area_face_img_link") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":58,"column":16},"end":{"line":62,"column":23}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <img class=\"tt-appointment-info__avatar\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"area_face_img_link") || (depth0 != null ? lookupProperty(depth0,"area_face_img_link") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"area_face_img_link","hash":{},"data":data,"loc":{"start":{"line":59,"column":62},"end":{"line":59,"column":84}}}) : helper)))
    + "\" alt=\"\">\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "                <img class=\"tt-appointment-info__avatar\" src=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"getImgPath") || (depth0 != null ? lookupProperty(depth0,"getImgPath") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"getImgPath","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":62},"end":{"line":61,"column":92}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"getImgPath")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "/hospital-avatar-default.svg\" alt=\"\">\n";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":70,"column":45},"end":{"line":70,"column":53}}}) : helper)));
},"26":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-appointment-info__content-text tt-mb-10\">\n                      "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"insuranceTitle") || (depth0 != null ? lookupProperty(depth0,"insuranceTitle") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"insuranceTitle","hash":{},"data":data,"loc":{"start":{"line":74,"column":22},"end":{"line":74,"column":40}}}) : helper)))
    + "\n                    </div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-appointment-info__content-image\">\n                        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"print_reservation_paid_stamp") || (depth0 != null ? lookupProperty(depth0,"print_reservation_paid_stamp") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"print_reservation_paid_stamp","hash":{},"data":data,"loc":{"start":{"line":80,"column":24},"end":{"line":80,"column":58}}}) : helper))) != null ? stack1 : "")
    + "\n                    </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isMobile","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":20},"end":{"line":99,"column":33}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"checkinCodeType","!=",2,{"name":"checkConfig","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":24},"end":{"line":98,"column":40}}})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <!-- QR code/BARCODE -->\n                            <a class=\"tt-action nestedPageLink noprint\" data-nested-page=\"first\" data-next-nested-page=\"checkinpage\" id=\"checkinNested\">\n                                <span class=\"tt-action__icon tt-icon-qrcode\"></span>\n                                <div class=\"tt-action__content\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"TRD53 Dropdown",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":64},"end":{"line":90,"column":109}}})) != null ? stack1 : "")
    + "</div>\n                                <span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n                            </a>\n                            <div class=\"item text-center "
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"forceShowCheckinButton") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":57},"end":{"line":93,"column":111}}})) != null ? stack1 : "")
    + "\">\n                                <button type=\"button\" id=\"checkinButton\" class=\"tt-button tt-button--primary\">\n                                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Menu Checkin",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":36},"end":{"line":95,"column":79}}})) != null ? stack1 : "")
    + "\n                                </button>\n                            </div>\n";
},"33":function(container,depth0,helpers,partials,data) {
    return "tt-hide";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"showWalletPassLink","==",1,{"name":"checkConfig","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":20},"end":{"line":109,"column":36}}})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isMobile","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":104,"column":24},"end":{"line":108,"column":37}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "                        <div style=\"text-align: center; height: 76px; padding: 10px;\">\n                        <button id=\"download_apple_wallet\" style='background: url(\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"getImgPath") || (depth0 != null ? lookupProperty(depth0,"getImgPath") : depth0)) != null ? helper : alias2),(options={"name":"getImgPath","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":83},"end":{"line":106,"column":113}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"getImgPath")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "/apple_wallet/apple_wallet_"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"lang") || (depth0 != null ? lookupProperty(depth0,"lang") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lang","hash":{},"data":data,"loc":{"start":{"line":106,"column":140},"end":{"line":106,"column":148}}}) : helper)))
    + ".svg\"); background-size: 100%; background-repeat: no-repeat; height: 56px; width: 183px; border: none;'></button>\n                        </div>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"toPrintReservationNotice") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":123,"column":20},"end":{"line":130,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"toShowReservationNotice") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":20},"end":{"line":139,"column":27}}})) != null ? stack1 : "")
    + "                                 \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"toPrintResourceNotice") : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":141,"column":20},"end":{"line":148,"column":27}}})) != null ? stack1 : "")
    + "					\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"toShowResourceNotice") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":150,"column":20},"end":{"line":157,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"insurance_instructions_for_appointment") : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":159,"column":20},"end":{"line":166,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"preparation") : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":168,"column":20},"end":{"line":175,"column":27}}})) != null ? stack1 : "");
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "											<div class=\"tt-info tt-info-text onlyprint\" style=\"display: none;\">\n												<div class=\"tt-info__content\">\n														<div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Announcement",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":126,"column":36},"end":{"line":126,"column":79}}})) != null ? stack1 : "")
    + "</div>\n														"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"toPrintReservationNotice") || (depth0 != null ? lookupProperty(depth0,"toPrintReservationNotice") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"toPrintReservationNotice","hash":{},"data":data,"loc":{"start":{"line":127,"column":14},"end":{"line":127,"column":44}}}) : helper))) != null ? stack1 : "")
    + "\n												</div>\n                      </div>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "											<div class=\"tt-info tt-info-text noprint\">\n												<div class=\"tt-info__content\">\n														<div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Announcement",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":135,"column":36},"end":{"line":135,"column":79}}})) != null ? stack1 : "")
    + "</div>\n														"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"toShowReservationNotice") || (depth0 != null ? lookupProperty(depth0,"toShowReservationNotice") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"toShowReservationNotice","hash":{},"data":data,"loc":{"start":{"line":136,"column":14},"end":{"line":136,"column":43}}}) : helper))) != null ? stack1 : "")
    + "\n												</div>\n                      </div>\n";
},"44":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "											<div class=\"tt-info tt-info-text onlyprint\" style=\"display: none;\">\n												<div class=\"tt-info__content\">\n														<div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Resource Announcement",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":36},"end":{"line":144,"column":92}}})) != null ? stack1 : "")
    + "</div>\n														"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"toPrintResourceNotice") || (depth0 != null ? lookupProperty(depth0,"toPrintResourceNotice") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"toPrintResourceNotice","hash":{},"data":data,"loc":{"start":{"line":145,"column":14},"end":{"line":145,"column":41}}}) : helper))) != null ? stack1 : "")
    + "\n												</div>\n                      </div>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "											<div class=\"tt-info tt-info-text noprint\">\n												<div class=\"tt-info__content\">\n														<div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Resource Announcement",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":36},"end":{"line":153,"column":92}}})) != null ? stack1 : "")
    + "</div>\n														"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"toShowResourceNotice") || (depth0 != null ? lookupProperty(depth0,"toShowResourceNotice") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"toShowResourceNotice","hash":{},"data":data,"loc":{"start":{"line":154,"column":14},"end":{"line":154,"column":40}}}) : helper))) != null ? stack1 : "")
    + "\n												</div>\n                      </div>\n";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                      <div class=\"tt-info tt-info-text\">\n												<div class=\"tt-info__content\">\n														<div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Insurance Instructions Reservation",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":162,"column":36},"end":{"line":162,"column":105}}})) != null ? stack1 : "")
    + "</div>\n														"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"insurance_instructions_for_appointment") || (depth0 != null ? lookupProperty(depth0,"insurance_instructions_for_appointment") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"insurance_instructions_for_appointment","hash":{},"data":data,"loc":{"start":{"line":163,"column":14},"end":{"line":163,"column":58}}}) : helper))) != null ? stack1 : "")
    + "\n												</div>\n                      </div>\n";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                      <div class=\"tt-info tt-info-text\">\n												<div class=\"tt-info__content\">\n														<div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Activity description",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":36},"end":{"line":171,"column":87}}})) != null ? stack1 : "")
    + "</div>\n														"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"preparation") || (depth0 != null ? lookupProperty(depth0,"preparation") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"preparation","hash":{},"data":data,"loc":{"start":{"line":172,"column":14},"end":{"line":172,"column":31}}}) : helper))) != null ? stack1 : "")
    + "\n												</div>\n                      </div>\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"price") : depth0),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":179,"column":19},"end":{"line":188,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"discountedLabel") : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":190,"column":20},"end":{"line":199,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"additional_surcharges") : depth0),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":201,"column":20},"end":{"line":212,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showTotalPriceBox") : depth0),{"name":"if","hash":{},"fn":container.program(60, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":214,"column":20},"end":{"line":223,"column":27}}})) != null ? stack1 : "")
    + "\n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                   <div class=\"tt-info tt-info-text\">\n											<div class=\"tt-info__content\">\n												<div class=\"tt-info__content-title\">\n													"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Total Price",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":183,"column":13},"end":{"line":183,"column":59}}})) != null ? stack1 : "")
    + ":\n												</div>\n												<span class=\"tt-info__content-text--right\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"currencySymbol") || (depth0 != null ? lookupProperty(depth0,"currencySymbol") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currencySymbol","hash":{},"data":data,"loc":{"start":{"line":185,"column":55},"end":{"line":185,"column":73}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"activityPrice") || (depth0 != null ? lookupProperty(depth0,"activityPrice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityPrice","hash":{},"data":data,"loc":{"start":{"line":185,"column":73},"end":{"line":185,"column":90}}}) : helper)))
    + "</span>\n											</div>\n										</div>\n";
},"55":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "										<div class=\"tt-info tt-info-text\">\n											<div class=\"tt-info__content\">\n												<div class=\"tt-info__content-title\">\n													"
    + alias4(((helper = (helper = lookupProperty(helpers,"discountedLabel") || (depth0 != null ? lookupProperty(depth0,"discountedLabel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"discountedLabel","hash":{},"data":data,"loc":{"start":{"line":194,"column":13},"end":{"line":194,"column":32}}}) : helper)))
    + "\n												</div>\n												<span class=\"tt-info__content-text--right\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"discountAmount") || (depth0 != null ? lookupProperty(depth0,"discountAmount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"discountAmount","hash":{},"data":data,"loc":{"start":{"line":196,"column":55},"end":{"line":196,"column":73}}}) : helper)))
    + "</span>\n											</div>\n										</div>\n";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"additional_surcharges") : depth0),{"name":"each","hash":{},"fn":container.program(58, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":202,"column":24},"end":{"line":211,"column":33}}})) != null ? stack1 : "");
},"58":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"tt-info tt-info-text\">\n                            <div class=\"tt-info__content\">\n                                <div class=\"tt-info__content-title\">\n                                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\n                                <span class=\"tt-info__content-text--right\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"amount") : depth0), depth0))
    + "</span>\n                                </div>\n                            </div>\n                        </div>\n";
},"60":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-info tt-info-text tt-mb-15\">\n												<div class=\"tt-info__content\">\n													<div class=\"tt-info__content-title tt-info__content-title--bold\">\n													"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Total",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":218,"column":13},"end":{"line":218,"column":49}}})) != null ? stack1 : "")
    + "\n													<span class=\"tt-info__content-text--right\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"currencySymbol") || (depth0 != null ? lookupProperty(depth0,"currencySymbol") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currencySymbol","hash":{},"data":data,"loc":{"start":{"line":219,"column":56},"end":{"line":219,"column":74}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"finalPrice") || (depth0 != null ? lookupProperty(depth0,"finalPrice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"finalPrice","hash":{},"data":data,"loc":{"start":{"line":219,"column":74},"end":{"line":219,"column":88}}}) : helper)))
    + "</span>\n													</div>\n												</div>\n                    </div>\n";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"item text-center noprint\" data-nested-page=\"first\">\n                    <div class=\"tt-container\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Onetime Kill Session Description",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":230,"column":24},"end":{"line":230,"column":87}}})) != null ? stack1 : "")
    + "\n                    </div>\n                    <button type=\"button\" class=\"tt-button tt-button--primary\" id=\"goToLogin\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Onetime Kill Session",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":233,"column":24},"end":{"line":233,"column":75}}})) != null ? stack1 : "")
    + "\n                    </button>\n                </div>\n";
},"64":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "					<div class=\"\" style=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotLoggedIn") || (depth0 != null ? lookupProperty(depth0,"isNotLoggedIn") : depth0)) != null ? helper : alias2),(options={"name":"isNotLoggedIn","hash":{},"fn":container.program(65, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":239,"column":26},"end":{"line":239,"column":84}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNotLoggedIn")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\" data-nested-page=\"first\">\n\n\n                    <a class=\"tt-no-border tt-action tt-action__no-spacing "
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"showMap") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(67, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":242,"column":75},"end":{"line":242,"column":125}}})) != null ? stack1 : "")
    + " tt-hide\" id=\"linkOpenMap\" href=\"javascript:void(0);\" data-nested-page=\"first\" style=\"border: none !important; margin: 16px !important;\">\n                        <div class=\"tt-action__content\">\n                            <div class=\"tt-action__content-title\">"
    + ((stack1 = (lookupProperty(helpers,"formattedAreaTitle")||(depth0 && lookupProperty(depth0,"formattedAreaTitle"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"webTitle") : depth0),(depth0 != null ? lookupProperty(depth0,"areaTitle") : depth0),{"name":"formattedAreaTitle","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":244,"column":66},"end":{"line":244,"column":131}}})) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAddressVisible") : depth0),{"name":"if","hash":{},"fn":container.program(69, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":245,"column":28},"end":{"line":252,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showMap") : depth0),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":254,"column":24},"end":{"line":256,"column":31}}})) != null ? stack1 : "")
    + "                    </a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showMap") : depth0),{"name":"if","hash":{},"fn":container.program(78, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":258,"column":20},"end":{"line":272,"column":27}}})) != null ? stack1 : "")
    + "\n                <div style=\"height: 250px; borderRadius: 3px; marginTop: 20px; overflow: hidden; position: relative; display: flex; justify-content: center; margin-bottom: 16px;\" id=\"google-static-map-container\" data-nested-page=\"first\">\n                  <div class=\"tt-force-print-background-img\" style=\"height: 100%\" id=\"google-static-map\"></div>\n                </div>\n            </div>\n";
},"65":function(container,depth0,helpers,partials,data) {
    return " pointer-events: none;";
},"67":function(container,depth0,helpers,partials,data) {
    return "tt-disable-pointer";
},"69":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"tt-action__content-text\">\n                                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"address") || (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"address","hash":{},"data":data,"loc":{"start":{"line":247,"column":36},"end":{"line":247,"column":47}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"city") : depth0),{"name":"if","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":247,"column":48},"end":{"line":247,"column":77}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"region") : depth0),{"name":"if","hash":{},"fn":container.program(72, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":247,"column":77},"end":{"line":247,"column":110}}})) != null ? stack1 : "")
    + "\n                                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"country") : depth0),{"name":"if","hash":{},"fn":container.program(74, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":248,"column":36},"end":{"line":248,"column":71}}})) != null ? stack1 : "")
    + "\n                                    <br>\n                                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"area_phone") || (depth0 != null ? lookupProperty(depth0,"area_phone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"area_phone","hash":{},"data":data,"loc":{"start":{"line":250,"column":36},"end":{"line":250,"column":50}}}) : helper)))
    + "\n                                </div>\n";
},"70":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ", "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"city") || (depth0 != null ? lookupProperty(depth0,"city") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"city","hash":{},"data":data,"loc":{"start":{"line":247,"column":62},"end":{"line":247,"column":70}}}) : helper)));
},"72":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ", "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"region") || (depth0 != null ? lookupProperty(depth0,"region") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"region","hash":{},"data":data,"loc":{"start":{"line":247,"column":93},"end":{"line":247,"column":103}}}) : helper)));
},"74":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ", "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"country") || (depth0 != null ? lookupProperty(depth0,"country") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"country","hash":{},"data":data,"loc":{"start":{"line":248,"column":53},"end":{"line":248,"column":64}}}) : helper)));
},"76":function(container,depth0,helpers,partials,data) {
    return "                        <span class=\"tt-action__arrow tt-icon-angle-down noprint\"></span>\n";
},"78":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a class=\"tt-no-border tt-action tt-action__no-spacing tt-hide\" id=\"linkCloseMap\" href=\"javascript:void(0);\" data-nested-page=\"first\" style=\"border: none !important; margin: 16px !important;\">\n                       <div class=\"tt-action__content\">\n                            <div class=\"tt-action__content-title\">"
    + ((stack1 = (lookupProperty(helpers,"formattedAreaTitle")||(depth0 && lookupProperty(depth0,"formattedAreaTitle"))||container.hooks.helperMissing).call(alias1,(depth0 != null ? lookupProperty(depth0,"webTitle") : depth0),(depth0 != null ? lookupProperty(depth0,"areaTitle") : depth0),{"name":"formattedAreaTitle","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":261,"column":66},"end":{"line":261,"column":131}}})) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAddressVisible") : depth0),{"name":"if","hash":{},"fn":container.program(79, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":262,"column":28},"end":{"line":268,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n                        <span class=\"tt-action__arrow tt-icon-angle-up noprint\"></span>\n                    </a>\n";
},"79":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"tt-action__content-text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"address") || (depth0 != null ? lookupProperty(depth0,"address") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"address","hash":{},"data":data,"loc":{"start":{"line":263,"column":69},"end":{"line":263,"column":80}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"city") : depth0),{"name":"if","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":263,"column":81},"end":{"line":263,"column":110}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"region") : depth0),{"name":"if","hash":{},"fn":container.program(72, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":263,"column":110},"end":{"line":263,"column":143}}})) != null ? stack1 : "")
    + "\n                                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"country") : depth0),{"name":"if","hash":{},"fn":container.program(74, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":264,"column":36},"end":{"line":264,"column":71}}})) != null ? stack1 : "")
    + "\n                                    <br>\n                                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"area_phone") || (depth0 != null ? lookupProperty(depth0,"area_phone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"area_phone","hash":{},"data":data,"loc":{"start":{"line":266,"column":36},"end":{"line":266,"column":50}}}) : helper)))
    + "\n                                </div>\n";
},"81":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(82, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":282,"column":8},"end":{"line":347,"column":13}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotMobile") || (depth0 != null ? lookupProperty(depth0,"isNotMobile") : depth0)) != null ? helper : alias2),(options={"name":"isNotMobile","hash":{},"fn":container.program(96, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":349,"column":16},"end":{"line":414,"column":32}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNotMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"82":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"checkinCodeType","!=",2,{"name":"checkConfig","hash":{},"fn":container.program(83, data, 0),"inverse":container.program(94, data, 0),"data":data,"loc":{"start":{"line":283,"column":8},"end":{"line":346,"column":16}}})) != null ? stack1 : "");
},"83":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <!-- QR/BARCODE -->\n        <div class=\"checkinnestedpage\" data-nested-page=\"checkinpage\" style=\"display:none;\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"checkin_code_displayed") : depth0),{"name":"if","hash":{},"fn":container.program(84, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":286,"column":12},"end":{"line":331,"column":15}}})) != null ? stack1 : "")
    + "</div>\n\n";
},"84":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "\n            <div class=\"tt-checkin\">\n                <div ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(85, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":289,"column":21},"end":{"line":290,"column":49}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n                    <div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Checkin Title",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":291,"column":42},"end":{"line":291,"column":90}}})) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"checkinCodeType","==",0,{"name":"checkConfig","hash":{},"fn":container.program(88, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":292,"column":20},"end":{"line":298,"column":36}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"checkinCodeType","==",1,{"name":"checkConfig","hash":{},"fn":container.program(90, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":299,"column":20},"end":{"line":308,"column":36}}})) != null ? stack1 : "")
    + "\n                    <div class=\"tt-checkin__content\">\n                        <div class=\"tt-checkin__content-number\" style=\"font-size: "
    + alias4(((helper = (helper = lookupProperty(helpers,"checkin_code_displayed_size") || (depth0 != null ? lookupProperty(depth0,"checkin_code_displayed_size") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"checkin_code_displayed_size","hash":{},"data":data,"loc":{"start":{"line":311,"column":82},"end":{"line":311,"column":113}}}) : helper)))
    + "\">\n                            "
    + alias4(((helper = (helper = lookupProperty(helpers,"checkin_code_displayed") || (depth0 != null ? lookupProperty(depth0,"checkin_code_displayed") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"checkin_code_displayed","hash":{},"data":data,"loc":{"start":{"line":312,"column":28},"end":{"line":312,"column":54}}}) : helper)))
    + "\n                        </div>\n"
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"checkinCodeType","==",0,{"name":"checkConfig","hash":{},"fn":container.program(92, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":314,"column":24},"end":{"line":319,"column":40}}})) != null ? stack1 : "")
    + "                    </div>\n\n															<div class=\"tt-container tt-hidden-xs\">\n																<div class=\"tt-button tt-button--secondary nestedPageLinkBack\">\n																		"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Back",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":324,"column":18},"end":{"line":324,"column":53}}})) != null ? stack1 : "")
    + "\n																</div>\n															</div>\n														</div>\n													</div>\n                        </div>\n\n";
},"85":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"checkinCodeType","==",1,{"name":"checkConfig","hash":{},"fn":container.program(86, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":289,"column":34},"end":{"line":290,"column":36}}})) != null ? stack1 : "");
},"86":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-checkin-barcode-container\"\n                    ";
},"88":function(container,depth0,helpers,partials,data) {
    return "                    <!-- QR code -->\n                    <div class=\"tt-checkin__code tt-checkin__code--qrcode tt-customer-checkin\">\n                        <div class=\"tt-deprecated-loader\" id=\"checkincode_image_loader\"></div>\n                        <img src=\"\" id=\"checkincode_image\" style=\"display: none;\">\n                    </div>\n";
},"90":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <!-- Codice a barre -->\n                    <div class=\"tt-checkin__content-text\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"configValue")||(depth0 && lookupProperty(depth0,"configValue"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"checkinMsgMobile","Checkin",{"name":"configValue","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":302,"column":24},"end":{"line":302,"column":85}}})) != null ? stack1 : "")
    + "\n                    </div>\n                    <div class=\"tt-checkin__code tt-checkin__code--barcode tt-customer-checkin\">\n                        <div class=\"tt-deprecated-loader\" id=\"checkincode_image_loader\"></div>\n                        <img src=\"\" id=\"checkincode_image\" style=\"display: none;\">\n                    </div>\n";
},"92":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <!-- Codice a barre -->\n                        <div class=\"tt-checkin__content-text\">\n                            "
    + ((stack1 = (lookupProperty(helpers,"configValue")||(depth0 && lookupProperty(depth0,"configValue"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"checkinMsgMobile","Checkin",{"name":"configValue","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":317,"column":28},"end":{"line":317,"column":89}}})) != null ? stack1 : "")
    + "\n                        </div>\n";
},"94":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- SOLO Numero -->\n<div class=\"tt-checkin\" data-nested-page=\"first\">\n    <div class=\"tt-checkin__content\">\n        <div class=\"tt-checkin__content-text\">\n            "
    + ((stack1 = (lookupProperty(helpers,"configValue")||(depth0 && lookupProperty(depth0,"configValue"))||alias2).call(alias1,"checkinMsgMobile","Checkin",{"name":"configValue","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":339,"column":12},"end":{"line":339,"column":73}}})) != null ? stack1 : "")
    + "\n        </div>\n    </div>\n    <div class=\"tt-checkin__content-number\" style=\"font-size: "
    + alias4(((helper = (helper = lookupProperty(helpers,"checkin_code_displayed_size") || (depth0 != null ? lookupProperty(depth0,"checkin_code_displayed_size") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"checkin_code_displayed_size","hash":{},"data":data,"loc":{"start":{"line":342,"column":62},"end":{"line":342,"column":93}}}) : helper)))
    + "\">\n        "
    + alias4(((helper = (helper = lookupProperty(helpers,"checkin_code_displayed") || (depth0 != null ? lookupProperty(depth0,"checkin_code_displayed") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"checkin_code_displayed","hash":{},"data":data,"loc":{"start":{"line":343,"column":8},"end":{"line":343,"column":34}}}) : helper)))
    + "\n    </div>\n</div>\n";
},"96":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"checkinCodeType","==",0,{"name":"checkConfig","hash":{},"fn":container.program(97, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":350,"column":20},"end":{"line":373,"column":36}}})) != null ? stack1 : "")
    + "                    "
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"checkinCodeType","==",1,{"name":"checkConfig","hash":{},"fn":container.program(100, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":374,"column":20},"end":{"line":395,"column":36}}})) != null ? stack1 : "")
    + "                    "
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"checkinCodeType","==",2,{"name":"checkConfig","hash":{},"fn":container.program(103, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":396,"column":20},"end":{"line":413,"column":36}}})) != null ? stack1 : "");
},"97":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <!-- QR code -->\n                    <div class=\"tt-checkin tt-checkin-desktop tt-margin-20\">\n											<div class=\"tt-columns tt-column-flex\" data-nested-page=\"first\">\n											<div class=\"tt-column is-4\">\n													<div class=\"tt-checkin__code tt-checkin__code--qrcode tt-customer-checkin\">\n															<div class=\"tt-deprecated-loader\" id=\"checkincode_image_loader\"></div>\n															<img class=\"tt-checkin__code-image\" src=\"\" id=\"checkincode_image\" style=\"display: none;\">\n													</div>\n											</div>\n												<div class=\"tt-column is-8\">\n													<div class=\"tt-checkin__content\">\n														<div class=\"tt-checkin__content-text\">\n														"
    + ((stack1 = (lookupProperty(helpers,"configValue")||(depth0 && lookupProperty(depth0,"configValue"))||container.hooks.helperMissing).call(alias1,"checkinMsgDesktop","Checkin",{"name":"configValue","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":362,"column":14},"end":{"line":362,"column":76}}})) != null ? stack1 : "")
    + "\n														</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"checkin_code_displayed") : depth0),{"name":"if","hash":{},"fn":container.program(98, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":364,"column":14},"end":{"line":368,"column":21}}})) != null ? stack1 : "")
    + "													</div>\n												</div>\n											</div>\n                    </div>\n";
},"98":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "															<div class=\"tt-checkin__content-number tt-customer-checkin-number\" style=\"font-size: "
    + alias4(((helper = (helper = lookupProperty(helpers,"checkin_code_displayed_size") || (depth0 != null ? lookupProperty(depth0,"checkin_code_displayed_size") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"checkin_code_displayed_size","hash":{},"data":data,"loc":{"start":{"line":365,"column":100},"end":{"line":365,"column":131}}}) : helper)))
    + "\">\n																	"
    + alias4(((helper = (helper = lookupProperty(helpers,"checkin_code_displayed") || (depth0 != null ? lookupProperty(depth0,"checkin_code_displayed") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"checkin_code_displayed","hash":{},"data":data,"loc":{"start":{"line":366,"column":17},"end":{"line":366,"column":43}}}) : helper)))
    + "\n															</div>\n";
},"100":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <!-- Codice a barre -->\n                    <div class=\"tt-checkin tt-checkin-desktop tt-margin-20\">\n											<div class=\"tt-columns tt-column-flex\" data-nested-page=\"first\">\n												<div class=\"tt-column\">\n                          <div class=\"tt-checkin__content\">\n														<div class=\"tt-checkin__content-text\">\n															"
    + ((stack1 = (lookupProperty(helpers,"configValue")||(depth0 && lookupProperty(depth0,"configValue"))||container.hooks.helperMissing).call(alias1,"checkinMsgDesktop","Checkin",{"name":"configValue","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":380,"column":15},"end":{"line":380,"column":77}}})) != null ? stack1 : "")
    + "\n														</div>\n													</div>\n													<div class=\"tt-checkin__code tt-checkin__code--barcode tt-customer-checkin tt-margin-top-8\">\n															<div class=\"tt-deprecated-loader\" id=\"checkincode_image_loader\"></div>\n															<img class=\"tt-checkin__code-image\" src=\"\" id=\"checkincode_image\" style=\"display: none;\">\n													</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"checkin_code_displayed") : depth0),{"name":"if","hash":{},"fn":container.program(101, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":387,"column":14},"end":{"line":391,"column":21}}})) != null ? stack1 : "")
    + "												</div>\n											</div>\n										</div>\n";
},"101":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "														<div class=\"tt-checkin__content-number tt-margin-top-8 tt-customer-checkin-number\" style=\"font-size: "
    + alias4(((helper = (helper = lookupProperty(helpers,"checkin_code_displayed_size") || (depth0 != null ? lookupProperty(depth0,"checkin_code_displayed_size") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"checkin_code_displayed_size","hash":{},"data":data,"loc":{"start":{"line":388,"column":115},"end":{"line":388,"column":146}}}) : helper)))
    + "\">\n															"
    + alias4(((helper = (helper = lookupProperty(helpers,"checkin_code_displayed") || (depth0 != null ? lookupProperty(depth0,"checkin_code_displayed") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"checkin_code_displayed","hash":{},"data":data,"loc":{"start":{"line":389,"column":15},"end":{"line":389,"column":41}}}) : helper)))
    + "\n														</div>\n";
},"103":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " <!-- Solo numero -->\n                    <div class=\"tt-checkin tt-checkin--number tt-checkin-desktop\">\n											<div class=\"tt-columns\" data-nested-page=\"first\">\n												<div class=\"tt-column is-12\">\n													"
    + ((stack1 = (lookupProperty(helpers,"configValue")||(depth0 && lookupProperty(depth0,"configValue"))||alias2).call(alias1,"checkinMsgDesktop","Checkin",{"name":"configValue","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":400,"column":13},"end":{"line":400,"column":75}}})) != null ? stack1 : "")
    + "\n												</div>\n												</div>\n												<div class=\"tt-columns\">\n												<div class=\"tt-column is-12\">\n													<div class=\"tt-checkin__code\">\n														<div class=\"tt-checkin__code-number tt-customer-checkin-number\">\n															"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"checkin_code_displayed") || (depth0 != null ? lookupProperty(depth0,"checkin_code_displayed") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"checkin_code_displayed","hash":{},"data":data,"loc":{"start":{"line":407,"column":15},"end":{"line":407,"column":41}}}) : helper)))
    + "\n														</div>\n													</div>\n												</div>\n                    	</div>\n										</div>\n";
},"105":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"summary") : depth0),{"name":"if","hash":{},"fn":container.program(106, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":418,"column":6},"end":{"line":425,"column":13}}})) != null ? stack1 : "");
},"106":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<div data-nested-page=\"first\" class=\"noprint\">\n								<div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Notes",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":420,"column":30},"end":{"line":420,"column":66}}})) != null ? stack1 : "")
    + "</div>\n								<div class=\"tt-container tt-container--16 tt-container--border\">\n										"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"summary") || (depth0 != null ? lookupProperty(depth0,"summary") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"summary","hash":{},"data":data,"loc":{"start":{"line":422,"column":10},"end":{"line":422,"column":23}}}) : helper))) != null ? stack1 : "")
    + "\n								</div>\n						</div>\n";
},"108":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"noprint\"><!-- per nascondere intero blocco in print_mop -->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMessages") : depth0),{"name":"if","hash":{},"fn":container.program(109, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":430,"column":16},"end":{"line":440,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMessages") : depth0),{"name":"if","hash":{},"fn":container.program(112, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":442,"column":20},"end":{"line":487,"column":27}}})) != null ? stack1 : "")
    + "                </span>\n";
},"109":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isMobile","hash":{},"fn":container.program(110, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":431,"column":20},"end":{"line":439,"column":33}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"110":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "										<div class=\"tt-container\">\n											<a class=\"tt-action nestedPageLink\" data-nested-page=\"first\" data-next-nested-page=\"reservation_communications\">\n												<span class=\"tt-action__icon tt-icon-info-circle positive\"></span>\n													<div class=\"tt-action__content\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Communications Summary",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":435,"column":45},"end":{"line":435,"column":98}}})) != null ? stack1 : "")
    + "</div>\n												<span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n											</a>\n										</div>\n";
},"112":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "                        <div data-nested-page=\"reservation_communications\" ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(113, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":443,"column":75},"end":{"line":443,"column":122}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n                            <div class=\"tt-label\">\n                                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"You received the following communication",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":445,"column":32},"end":{"line":445,"column":103}}})) != null ? stack1 : "")
    + "\n                            </div>\n                            <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"emailMessages") : depth0),{"name":"if","hash":{},"fn":container.program(115, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":448,"column":32},"end":{"line":457,"column":39}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"smsMessages") : depth0),{"name":"if","hash":{},"fn":container.program(118, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":458,"column":32},"end":{"line":467,"column":39}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"remindersMessages") : depth0),{"name":"if","hash":{},"fn":container.program(121, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":468,"column":32},"end":{"line":477,"column":39}}})) != null ? stack1 : "")
    + "                            </div>\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotLoggedIn") || (depth0 != null ? lookupProperty(depth0,"isNotLoggedIn") : depth0)) != null ? helper : alias2),(options={"name":"isNotLoggedIn","hash":{},"fn":container.program(124, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":479,"column":28},"end":{"line":485,"column":46}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNotLoggedIn")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                        </div>\n";
},"113":function(container,depth0,helpers,partials,data) {
    return "style=\"display:none;\"";
},"115":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"emailMessages") : depth0),{"name":"each","hash":{},"fn":container.program(116, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":449,"column":36},"end":{"line":456,"column":45}}})) != null ? stack1 : "");
},"116":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div class=\"tt-info\">\n                                          <i class=\"tt-info__icon tt-icon-envelope\"></i>\n                                          <div class=\"tt-info__content\">\n                                            "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Message at the end of the reservation","EMAIL",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":453,"column":44},"end":{"line":453,"column":120}}})) != null ? stack1 : "")
    + "\n                                          </div>\n                                        </div>\n";
},"118":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"smsMessages") : depth0),{"name":"each","hash":{},"fn":container.program(119, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":459,"column":36},"end":{"line":466,"column":45}}})) != null ? stack1 : "");
},"119":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                         <div class=\"tt-info\">\n                                          <i class=\"tt-info__icon tt-icon-mobile\"></i>\n                                          <div class=\"tt-info__content\">\n                                            "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Message at the end of the reservation","SMS",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":463,"column":44},"end":{"line":463,"column":118}}})) != null ? stack1 : "")
    + "\n                                          </div>\n                                        </div>\n";
},"121":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"remindersMessages") : depth0),{"name":"each","hash":{},"fn":container.program(122, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":469,"column":36},"end":{"line":476,"column":45}}})) != null ? stack1 : "");
},"122":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                                        <div class=\"tt-info\">\n                                          <i class=\"tt-info__icon tt-icon-bell\"></i>\n                                          <div class=\"tt-info__content\">\n                                            "
    + ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "")
    + "\n                                          </div>\n                                        </div>\n";
},"124":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"tt-container\">\n                                    <div class=\"tt-button tt-button--secondary nestedPageLinkBack\">\n                                        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Back",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":482,"column":40},"end":{"line":482,"column":75}}})) != null ? stack1 : "")
    + "\n                                    </div>\n                                </div>\n";
},"126":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-container tt-container--16\" align=\"left\" data-nested-page=\"first\" >\n                    <b>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Transaction",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":493,"column":23},"end":{"line":493,"column":65}}})) != null ? stack1 : "")
    + ":</b>\n                    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"paymentid") || (depth0 != null ? lookupProperty(depth0,"paymentid") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"paymentid","hash":{},"data":data,"loc":{"start":{"line":494,"column":20},"end":{"line":494,"column":33}}}) : helper)))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"payment_info_date") : depth0),{"name":"if","hash":{},"fn":container.program(127, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":495,"column":20},"end":{"line":497,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"payment_info") : depth0),{"name":"if","hash":{},"fn":container.program(129, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":499,"column":20},"end":{"line":506,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n";
},"127":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        - "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"payment_info_date") || (depth0 != null ? lookupProperty(depth0,"payment_info_date") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"payment_info_date","hash":{},"data":data,"loc":{"start":{"line":496,"column":26},"end":{"line":496,"column":47}}}) : helper)))
    + "\n";
},"129":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <br>\n                    <b>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"MOP Print Reservation Invoice ID",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":501,"column":23},"end":{"line":501,"column":86}}})) != null ? stack1 : "")
    + ":</b>\n                    "
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"payment_info") : depth0)) != null ? lookupProperty(stack1,"invoiceid") : stack1), depth0))
    + "\n                    <br>\n                    <b>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"MOP Print Reservation Installments",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":504,"column":23},"end":{"line":504,"column":88}}})) != null ? stack1 : "")
    + ":</b>\n                    "
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"payment_info") : depth0)) != null ? lookupProperty(stack1,"installments") : stack1), depth0))
    + "\n";
},"131":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a class=\"tt-action noprint\" data-nested-page=\"first\" id=\"reservationCustomButton\" data-model-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"resid") || (depth0 != null ? lookupProperty(depth0,"resid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"resid","hash":{},"data":data,"loc":{"start":{"line":511,"column":106},"end":{"line":511,"column":115}}}) : helper)))
    + "\">\n            <span class=\"tt-action__icon tt-icon-file-text\"></span>\n            <div class=\"tt-action__content\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"reservationCustomButton") || (depth0 != null ? lookupProperty(depth0,"reservationCustomButton") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reservationCustomButton","hash":{},"data":data,"loc":{"start":{"line":513,"column":44},"end":{"line":513,"column":71}}}) : helper)))
    + "</div>\n            <span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n        </a>\n";
},"133":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a class=\"tt-action noprint\" data-nested-page=\"first\" id=\"reservationCustomButton2\" data-model-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"resid") || (depth0 != null ? lookupProperty(depth0,"resid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"resid","hash":{},"data":data,"loc":{"start":{"line":519,"column":107},"end":{"line":519,"column":116}}}) : helper)))
    + "\">\n            <span class=\"tt-action__icon tt-icon-file-text\"></span>\n            <div class=\"tt-action__content\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"reservationCustomButton2") || (depth0 != null ? lookupProperty(depth0,"reservationCustomButton2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reservationCustomButton2","hash":{},"data":data,"loc":{"start":{"line":521,"column":44},"end":{"line":521,"column":72}}}) : helper)))
    + "</div>\n            <span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n        </a>\n";
},"135":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "            <div class=\"tt-container text-center tt-columns tt-flex noprint tt-mt-20\" data-nested-page=\"first\">\n"
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"mopShowReservationPrintButton","==",1,{"name":"checkConfig","hash":{},"fn":container.program(136, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":528,"column":16},"end":{"line":534,"column":32}}})) != null ? stack1 : "");
  stack1 = ((helper = (helper = lookupProperty(helpers,"isLoggedIn") || (depth0 != null ? lookupProperty(depth0,"isLoggedIn") : depth0)) != null ? helper : alias2),(options={"name":"isLoggedIn","hash":{},"fn":container.program(138, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":535,"column":16},"end":{"line":541,"column":31}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isLoggedIn")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </div>\n";
},"136":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-column\">\n                    <button type=\"button\" id=\"print\" class=\"tt-button submit tt-button--primary tt-clear-button-w\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Mop Reservation Patient Copy Print Button",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":531,"column":24},"end":{"line":531,"column":96}}})) != null ? stack1 : "")
    + "\n                    </button>\n                </div>\n";
},"138":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-column\">\n                        <a class=\"back_to_reservations tt-button tt-button--secondary tt-clear-button-w\" href=\"#\" id=\"reservations_BackBtn\" >\n                            "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Mop Reservation Back To Patient Button",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":538,"column":28},"end":{"line":538,"column":97}}})) != null ? stack1 : "")
    + "\n                        </a>\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "\n\n<div class=\"appointment_detail\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"searchByPrescription") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"legacyid") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":13,"column":87}}})) != null ? stack1 : "")
    + "\n\n    <form>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSaveUserPaymentPromotion") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":22,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"printReservationAdditionalInfo") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":30,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ot_printReservationAdditionalInfo") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":38,"column":15}}})) != null ? stack1 : "")
    + "\n\n        <div class=\"tt-appointment-card "
    + alias4(((helper = (helper = lookupProperty(helpers,"status_class") || (depth0 != null ? lookupProperty(depth0,"status_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status_class","hash":{},"data":data,"loc":{"start":{"line":41,"column":40},"end":{"line":41,"column":56}}}) : helper)))
    + "\" data-nested-page=\"first\">\n            <div class=\"tt-appointment-status tt-appointment-status--"
    + alias4(((helper = (helper = lookupProperty(helpers,"status_class") || (depth0 != null ? lookupProperty(depth0,"status_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status_class","hash":{},"data":data,"loc":{"start":{"line":42,"column":69},"end":{"line":42,"column":85}}}) : helper)))
    + "\">\n                <div class=\"tt-appointment-status__text\">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"status_text") || (depth0 != null ? lookupProperty(depth0,"status_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status_text","hash":{},"data":data,"loc":{"start":{"line":44,"column":20},"end":{"line":44,"column":35}}}) : helper)))
    + "\n                </div>\n                <div class=\"tt-appointment-status__note\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"fname") || (depth0 != null ? lookupProperty(depth0,"fname") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fname","hash":{},"data":data,"loc":{"start":{"line":46,"column":57},"end":{"line":46,"column":66}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"social_name") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":66},"end":{"line":46,"column":108}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"lname") || (depth0 != null ? lookupProperty(depth0,"lname") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lname","hash":{},"data":data,"loc":{"start":{"line":46,"column":109},"end":{"line":46,"column":118}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"lname_2") || (depth0 != null ? lookupProperty(depth0,"lname_2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lname_2","hash":{},"data":data,"loc":{"start":{"line":46,"column":119},"end":{"line":46,"column":130}}}) : helper)))
    + "</div>\n            </div>\n\n            <div class=\"tt-appointment-info\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showResources") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":50,"column":16},"end":{"line":63,"column":23}}})) != null ? stack1 : "")
    + "                <div class=\"tt-appointment-info__content\">\n                    <div class=\"tt-appointment-info__content-title\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"humanDateLongFormat")||(depth0 && lookupProperty(depth0,"humanDateLongFormat"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"start_date_day_week") : depth0),(depth0 != null ? lookupProperty(depth0,"start_date") : depth0),(depth0 != null ? lookupProperty(depth0,"start_date_month_abbr") : depth0),{"name":"humanDateLongFormat","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":24},"end":{"line":66,"column":125}}})) != null ? stack1 : "")
    + "\n                        - "
    + alias4(((helper = (helper = lookupProperty(helpers,"startTime") || (depth0 != null ? lookupProperty(depth0,"startTime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"startTime","hash":{},"data":data,"loc":{"start":{"line":67,"column":26},"end":{"line":67,"column":39}}}) : helper)))
    + "\n                    </div>\n                    <div class=\"tt-appointment-info__content-text\">\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showResources") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":24},"end":{"line":70,"column":60}}})) != null ? stack1 : "")
    + "\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showInsurance") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":20},"end":{"line":76,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"print_reservation_paid_stamp") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":20},"end":{"line":82,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showCheckinReservationBanner") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":16},"end":{"line":100,"column":23}}})) != null ? stack1 : "")
    + "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isAppleDevice") || (depth0 != null ? lookupProperty(depth0,"isAppleDevice") : depth0)) != null ? helper : alias2),(options={"name":"isAppleDevice","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":16},"end":{"line":110,"column":34}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isAppleDevice")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n								<div class=\"tt-info tt-info-text\">\n									<div class=\"tt-info__content\">\n									<div class=\"tt-info__content-title\">\n									"
    + alias4(((helper = (helper = lookupProperty(helpers,"typologyTitle") || (depth0 != null ? lookupProperty(depth0,"typologyTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typologyTitle","hash":{},"data":data,"loc":{"start":{"line":115,"column":9},"end":{"line":115,"column":26}}}) : helper)))
    + "\n									</div>\n									<div class=\"tt-info__content-text\">"
    + ((stack1 = (lookupProperty(helpers,"getActivityTitle")||(depth0 && lookupProperty(depth0,"getActivityTitle"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"activity_web_title") : depth0),(depth0 != null ? lookupProperty(depth0,"activityTitle") : depth0),{"name":"getActivityTitle","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":44},"end":{"line":117,"column":119}}})) != null ? stack1 : "")
    + "</div>\n									</div>\n            		</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_appointment_addoons_div") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":16},"end":{"line":176,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPrice") : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":178,"column":16},"end":{"line":225,"column":19}}})) != null ? stack1 : "")
    + "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotLoggedIn") || (depth0 != null ? lookupProperty(depth0,"isNotLoggedIn") : depth0)) != null ? helper : alias2),(options={"name":"isNotLoggedIn","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":227,"column":16},"end":{"line":236,"column":34}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNotLoggedIn")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "                \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAreaEntityVisible") : depth0),{"name":"if","hash":{},"fn":container.program(64, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":238,"column":16},"end":{"line":278,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showCheckinReservationBanner") : depth0),{"name":"if","hash":{},"fn":container.program(81, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":281,"column":8},"end":{"line":415,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_appointment_addoons_div") : depth0),{"name":"if","hash":{},"fn":container.program(105, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":417,"column":6},"end":{"line":426,"column":13}}})) != null ? stack1 : "")
    + "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isLoggedIn") || (depth0 != null ? lookupProperty(depth0,"isLoggedIn") : depth0)) != null ? helper : alias2),(options={"name":"isLoggedIn","hash":{},"fn":container.program(108, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":428,"column":12},"end":{"line":489,"column":27}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isLoggedIn")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"paymentid") : depth0),{"name":"if","hash":{},"fn":container.program(126, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":491,"column":12},"end":{"line":508,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"reservationCustomButton") : depth0),{"name":"if","hash":{},"fn":container.program(131, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":510,"column":8},"end":{"line":516,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"reservationCustomButton2") : depth0),{"name":"if","hash":{},"fn":container.program(133, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":518,"column":8},"end":{"line":524,"column":15}}})) != null ? stack1 : "")
    + "\n";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotMobile") || (depth0 != null ? lookupProperty(depth0,"isNotMobile") : depth0)) != null ? helper : alias2),(options={"name":"isNotMobile","hash":{},"fn":container.program(135, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":526,"column":8},"end":{"line":543,"column":24}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNotMobile")) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </form>\n";
},"useData":true});