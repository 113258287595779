var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"tt-doctor-details__avatar-image\" style=\"background-image:url("
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"face_img_link") || (depth0 != null ? lookupProperty(depth0,"face_img_link") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"face_img_link","hash":{},"data":data,"loc":{"start":{"line":5,"column":76},"end":{"line":5,"column":93}}}) : helper)))
    + ");\"></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "    <div class=\"tt-doctor-details__avatar-image\" style=\"background-image:url(";
  stack1 = ((helper = (helper = lookupProperty(helpers,"getImgPath") || (depth0 != null ? lookupProperty(depth0,"getImgPath") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"getImgPath","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":77},"end":{"line":7,"column":107}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"getImgPath")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "/doctor-avatar-default.svg);\"></div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a class=\"tt-dot-button\" role=\"button\" href=\"mailto:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":14,"column":64},"end":{"line":14,"column":73}}}) : helper)))
    + "\" "
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":75},"end":{"line":14,"column":113}}})) != null ? stack1 : "")
    + ">\n                <i class=\"tt-dot-button__icon tt-icon-envelope\"></i>\n            </a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"tt-dot-button favorite\" href=\"javascript:;\">\n                    <div class=\"tt-deprecated-loader\" style=\"display: none;\"></div>\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"favorite") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":25,"column":32}}})) != null ? stack1 : "")
    + "                </a>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                        <i class=\"tt-dot-button__icon tt-dot-button__icon--red tt-icon-heart\"></i>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                        <i class=\"tt-dot-button__icon tt-dot-button__icon--red tt-icon-solid-heart\"></i>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"showDoctorReviews","==",1,{"name":"checkConfig","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":47,"column":36}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "											<div class=\"tt-doctor-details__content-text__review tt-js-rating-container-"
    + alias4(((helper = (helper = lookupProperty(helpers,"rating_id") || (depth0 != null ? lookupProperty(depth0,"rating_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rating_id","hash":{},"data":data,"loc":{"start":{"line":37,"column":86},"end":{"line":37,"column":99}}}) : helper)))
    + "\" id=\"rating-container-"
    + alias4(((helper = (helper = lookupProperty(helpers,"rating_id") || (depth0 != null ? lookupProperty(depth0,"rating_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rating_id","hash":{},"data":data,"loc":{"start":{"line":37,"column":122},"end":{"line":37,"column":135}}}) : helper)))
    + "\"> \n													<div id=\"rating-loader-"
    + alias4(((helper = (helper = lookupProperty(helpers,"rating_id") || (depth0 != null ? lookupProperty(depth0,"rating_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rating_id","hash":{},"data":data,"loc":{"start":{"line":38,"column":36},"end":{"line":38,"column":49}}}) : helper)))
    + "\" class=\"tt-deprecated-loader\"></div>\n													<input id=\"doctor-rating-"
    + alias4(((helper = (helper = lookupProperty(helpers,"rating_id") || (depth0 != null ? lookupProperty(depth0,"rating_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rating_id","hash":{},"data":data,"loc":{"start":{"line":39,"column":38},"end":{"line":39,"column":51}}}) : helper)))
    + "\" class=\"rating\" style=\"display: none;\"\n																	data-min=\"0\"\n																	data-max=\"5\"\n																	data-step=\"0.5\"\n																	data-size=\"xs\"\n																	data-disabled=\"true\"\n																	data-showcaption=\"false\">\n											</div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"langs") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),">",0,{"name":"ifCond","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":20},"end":{"line":62,"column":31}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "										<div class=\"tt-doctor-details__content-text__lang\">\n											<div class=\"tt-doctor-details__content-text__lang-text\">\n												"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"Mop Doctor Spoken Languages",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":12},"end":{"line":53,"column":70}}})) != null ? stack1 : "")
    + "\n											</div>\n											<div class=\"tt-doctor-details__content-text__lang-flags\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"langs") : depth0),{"name":"each","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":58,"column":33}}})) != null ? stack1 : "")
    + "											</div>	\n\n										</div> \n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "                          <img class=\"tt-doctor-details__content-text__lang-flags__flag\" src=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"getImgPath") || (depth0 != null ? lookupProperty(depth0,"getImgPath") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"getImgPath","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":94},"end":{"line":57,"column":124}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"getImgPath")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "/countries-flags/"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + ".svg\">\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"isMenuPageVisibile")||(depth0 && lookupProperty(depth0,"isMenuPageVisibile"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"search",{"name":"isMenuPageVisibile","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":12},"end":{"line":73,"column":35}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-container\">\n                    <button type=\"button\" class=\"tt-doctor-details__content-button tt-button tt-button--primary\" id=\"make-reservation-btn\" data-machid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"machid") || (depth0 != null ? lookupProperty(depth0,"machid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"machid","hash":{},"data":data,"loc":{"start":{"line":69,"column":152},"end":{"line":69,"column":162}}}) : helper)))
    + "\" data-doctor-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":69,"column":182},"end":{"line":69,"column":190}}}) : helper)))
    + "\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Make a Reservation",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":24},"end":{"line":70,"column":73}}})) != null ? stack1 : "")
    + "\n                    </button>\n                </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\"javascript:\" id=\"goToArea\" data-areaid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"areaid") || (depth0 != null ? lookupProperty(depth0,"areaid") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"areaid","hash":{},"data":data,"loc":{"start":{"line":81,"column":65},"end":{"line":81,"column":75}}}) : helper)))
    + "\" class=\"tt-action\">\n                    <i class=\"tt-action__icon tt-icon-map-marker-alt\"></i>\n                    <div class=\"tt-action__content\">"
    + ((stack1 = (lookupProperty(helpers,"formattedAreaTitle")||(depth0 && lookupProperty(depth0,"formattedAreaTitle"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"webTitle") : depth0),(depth0 != null ? lookupProperty(depth0,"areaTitle") : depth0),{"name":"formattedAreaTitle","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":83,"column":52},"end":{"line":83,"column":117}}})) != null ? stack1 : "")
    + "</div>\n                    <i class=\"tt-action__arrow tt-icon-angle-right\"></i>\n                </a>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "                <a href=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotMobileApp") || (depth0 != null ? lookupProperty(depth0,"isNotMobileApp") : depth0)) != null ? helper : alias2),(options={"name":"isNotMobileApp","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":91,"column":25},"end":{"line":91,"column":83}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNotMobileApp")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobileApp") || (depth0 != null ? lookupProperty(depth0,"isMobileApp") : depth0)) != null ? helper : alias2),(options={"name":"isMobileApp","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":85},"end":{"line":91,"column":164}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobileApp")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isNotMobileApp") || (depth0 != null ? lookupProperty(depth0,"isNotMobileApp") : depth0)) != null ? helper : alias2),(options={"name":"isNotMobileApp","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":165},"end":{"line":91,"column":218}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isNotMobileApp")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + " class=\"tt-action\">\n									<i class=\"tt-action__icon tt-icon-clipboard-list\"></i>\n									<div class=\"tt-action__content\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"App CV Link",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":41},"end":{"line":93,"column":83}}})) != null ? stack1 : "")
    + "</div>\n									<i class=\"tt-action__arrow tt-icon-angle-right\"></i>\n                </a>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cv_link") || (depth0 != null ? lookupProperty(depth0,"cv_link") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cv_link","hash":{},"data":data,"loc":{"start":{"line":91,"column":44},"end":{"line":91,"column":55}}}) : helper)));
},"29":function(container,depth0,helpers,partials,data) {
    return "#";
},"31":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "onclick=\"window.open('"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cv_link") || (depth0 != null ? lookupProperty(depth0,"cv_link") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cv_link","hash":{},"data":data,"loc":{"start":{"line":91,"column":123},"end":{"line":91,"column":134}}}) : helper)))
    + "', '_system')\"";
},"33":function(container,depth0,helpers,partials,data) {
    return "target=\"_blank\"";
},"35":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a class=\"tt-tabs-content__item "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"active_element") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":52},"end":{"line":101,"column":102}}})) != null ? stack1 : "")
    + "\" href=\"javascript:\" id=\"cv_tab\">\n                      <span class=\"tt-tabs-content__item-icon tt-icon-clipboard-list\"></span> "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Resource Curriculum Tab",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":94},"end":{"line":102,"column":148}}})) != null ? stack1 : "")
    + "\n                    </a>\n";
},"38":function(container,depth0,helpers,partials,data) {
    return "active";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"showDoctorTextualReviews","==",1,{"name":"checkConfig","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":20},"end":{"line":110,"column":36}}})) != null ? stack1 : "");
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a class=\"tt-tabs-content__item "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"active_element") : depth0),"==",2,{"name":"ifCond","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":107,"column":56},"end":{"line":107,"column":106}}})) != null ? stack1 : "")
    + "\" href=\"javascript:\" id=\"review_tab\">\n                          <span class=\"tt-tabs-content__item-icon tt-icon-star-1\"></span> "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Resource Reviews Tab",{"name":"translate","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":108,"column":90},"end":{"line":108,"column":141}}})) != null ? stack1 : "")
    + "\n                        </a>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-tabs-item-content\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"active_element") : depth0),"!=",1,{"name":"ifCond","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":50},"end":{"line":116,"column":116}}})) != null ? stack1 : "")
    + " id=\"cv_container\">\n					<div class=\"tt-container\">\n                        <div class=\"tt-mt-10\">\n                        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"cv_text") || (depth0 != null ? lookupProperty(depth0,"cv_text") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"cv_text","hash":{},"data":data,"loc":{"start":{"line":119,"column":24},"end":{"line":119,"column":37}}}) : helper))) != null ? stack1 : "")
    + "\n                        </div>\n                    </div>\n                </div>\n";
},"45":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"showDoctorTextualReviews","==",1,{"name":"checkConfig","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":125,"column":16},"end":{"line":129,"column":32}}})) != null ? stack1 : "");
},"46":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-tabs-item-content\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"active_element") : depth0),"!=",2,{"name":"ifCond","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":126,"column":50},"end":{"line":126,"column":116}}})) != null ? stack1 : "")
    + " id=\"review_container\">\n								\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "\n<div class=\"tt-doctor-details\">\n	<div class=\"tt-doctor-details__avatar\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"face_img_link") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":8,"column":9}}})) != null ? stack1 : "")
    + "	</div>\n\n    <div class=\"tt-doctor-details__content\">\n        <div class=\"tt-doctor-details__content-actions\">\n"
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"mopShowResourceEmail","==",1,{"name":"checkConfig","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":17,"column":28}}})) != null ? stack1 : "");
  stack1 = ((helper = (helper = lookupProperty(helpers,"isLoggedIn") || (depth0 != null ? lookupProperty(depth0,"isLoggedIn") : depth0)) != null ? helper : alias2),(options={"name":"isLoggedIn","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":27,"column":27}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isLoggedIn")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </div>\n\n            <div class=\"tt-doctor-details__content-text\">\n							<div class=\"tt-doctor-details__content-text__title\">\n								"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":32,"column":16}}}) : helper)))
    + "\n							</div>\n                <div class=\"tt-doctor-details__content-text__text\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":34,"column":67},"end":{"line":34,"column":76}}}) : helper)))
    + "</div>\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"privacy_review") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":48,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"showResourceLangs","==",1,{"name":"checkConfig","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":16},"end":{"line":63,"column":32}}})) != null ? stack1 : "")
    + "            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showResources") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":8},"end":{"line":74,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n\n    </div>\n\n        <ul class=\"find_results tt-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"areas") : depth0),{"name":"each","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":12},"end":{"line":86,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n        <div class=\"tt-container\">\n        <div class=\"tt-bg-white tt-tabs-light-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cv_link") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":12},"end":{"line":96,"column":19}}})) != null ? stack1 : "")
    + "\n            <div class=\"tt-tabs tt-tabs--light\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"active_element") : depth0),"==",0,{"name":"ifCond","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":48},"end":{"line":98,"column":114}}})) != null ? stack1 : "")
    + ">\n                <div class=\"tt-tabs-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cv_text") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":16},"end":{"line":104,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"privacy_review") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":20},"end":{"line":111,"column":31}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cv_text") : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":12},"end":{"line":123,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"privacy_review") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":12},"end":{"line":130,"column":23}}})) != null ? stack1 : "")
    + "        </div>\n        </div>\n        <div class=\"schedules tt-hide\">\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"schedules_info") || (depth0 != null ? lookupProperty(depth0,"schedules_info") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"schedules_info","hash":{},"data":data,"loc":{"start":{"line":134,"column":12},"end":{"line":134,"column":32}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n</div>";
},"useData":true});