var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-body\">\n        <div class=\"tt-info tt-no-border tt-no-padding\" id=\"checkin_dialog_checkin_msg\">\n          <div class=\"tt-info__content\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"checkin_msg") || (depth0 != null ? lookupProperty(depth0,"checkin_msg") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"checkin_msg","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":27}}}) : helper)))
    + "\n          </div>\n        </div>\n        <div class=\"tt-info tt-info--column tt-no-border\" id=\"checkin_dialog_checked_msg\" style=\"display: none;\">\n          <i class=\"tt-info__icon tt-icon-check-circle\"></i>\n          <div class=\"tt-info__content\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"checkedin_msg") || (depth0 != null ? lookupProperty(depth0,"checkedin_msg") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"checkedin_msg","hash":{},"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":13,"column":29}}}) : helper)))
    + "\n          </div>\n        </div>\n        <div class=\"tt-info tt-info--column tt-no-border\" id=\"checkin_dialog_checked_msg_error\" style=\"display: none;\">\n          <i class=\"tt-info__icon tt-icon-exclamation-triangle\"></i>\n          <div class=\"tt-info__content\" id=\"checkin_dialog_checked_msg_error_content\">\n          </div>\n        </div>\n      </div>\n      <div class=\"modal-footer tt-flexend-container tt-dialog-button\">\n        <div id=\"checkin_dialog_button_container\">\n          <button type=\"button\" id=\"checkin_button\" class=\"tt-button tt-button--primary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Menu Checkin",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":89},"end":{"line":24,"column":132}}})) != null ? stack1 : "")
    + "</button>\n        </div>\n        <div id=\"checked_dialog_button_container\" style=\"display: none;\">\n          <button type=\"button\" id=\"checkedin_close_button\" class=\"tt-button tt-button--primary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Ok",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":97},"end":{"line":27,"column":130}}})) != null ? stack1 : "")
    + "</button>\n        </div>\n        <div style=\"display: none; padding-bottom: 30px\" id=\"checkin_dialog_loader_container\">\n          <div class=\"tt-deprecated-loader\" style=\"position: absolute; top: 50%; left: 50%; margin: 0px 0px 0px -15px;\"></div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});