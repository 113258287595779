var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "/"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"social_name") || (depth0 != null ? lookupProperty(depth0,"social_name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"social_name","hash":{},"data":data,"loc":{"start":{"line":4,"column":35},"end":{"line":4,"column":50}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"tt-label-segment-container tt-mt-30\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"segments_info") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":12,"column":17}}})) != null ? stack1 : "")
    + "      </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-label-segment\" style=\"background-color: #"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\">\n          "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "\n        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"phone") || (depth0 != null ? lookupProperty(depth0,"phone") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"phone","hash":{},"data":data,"loc":{"start":{"line":23,"column":87},"end":{"line":23,"column":96}}}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <button class=\"tt-button tt-button--link js-call-voip-widget\" js-voip-memberid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"memberid") || (depth0 != null ? lookupProperty(depth0,"memberid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"memberid","hash":{},"data":data,"loc":{"start":{"line":25,"column":100},"end":{"line":25,"column":112}}}) : helper)))
    + "\" js-voip-name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"fname") || (depth0 != null ? lookupProperty(depth0,"fname") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fname","hash":{},"data":data,"loc":{"start":{"line":25,"column":128},"end":{"line":25,"column":137}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"social_name") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":137},"end":{"line":25,"column":179}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"lname") || (depth0 != null ? lookupProperty(depth0,"lname") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lname","hash":{},"data":data,"loc":{"start":{"line":25,"column":180},"end":{"line":25,"column":189}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disableVoipButton") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":191},"end":{"line":25,"column":231}}})) != null ? stack1 : "")
    + ">\n                      <span class=\"tt-color-main tt-ml-4\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"MOP Voip Widget Call Now",{"name":"translate","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":24},"end":{"line":27,"column":79}}})) != null ? stack1 : "")
    + "\n                      </span>\n                    </button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <div class=\"tt-text-info\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"MOP Voip Widget Call Description",{"name":"translate","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":20},"end":{"line":34,"column":83}}})) != null ? stack1 : "")
    + "\n                  </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button type=\"button\" class=\"tt-button tt-button--small tt-button--secondary\" \n              data-memberid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"memberid") || (depth0 != null ? lookupProperty(depth0,"memberid") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"memberid","hash":{},"data":data,"loc":{"start":{"line":87,"column":29},"end":{"line":87,"column":41}}}) : helper)))
    + "\"\n              data-trigger=\"editPatient\"\n            >\n              "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Patients Search Edit",{"name":"translate","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":14},"end":{"line":90,"column":69}}})) != null ? stack1 : "")
    + "\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"tt-card-admin-patient-info\">\n    <div class=\"tt-card-admin-patient-info__content\">\n      <div class=\"tt-card-admin-patient-info__content-title\">\n      "
    + alias4(((helper = (helper = lookupProperty(helpers,"fname") || (depth0 != null ? lookupProperty(depth0,"fname") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fname","hash":{},"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":15}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"social_name") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":15},"end":{"line":4,"column":57}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"lname") || (depth0 != null ? lookupProperty(depth0,"lname") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lname","hash":{},"data":data,"loc":{"start":{"line":4,"column":58},"end":{"line":4,"column":67}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"lname_2") || (depth0 != null ? lookupProperty(depth0,"lname_2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lname_2","hash":{},"data":data,"loc":{"start":{"line":4,"column":68},"end":{"line":4,"column":79}}}) : helper)))
    + "\n      </div>\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"issues") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),"!=",0,{"name":"ifCond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":14,"column":17}}})) != null ? stack1 : "")
    + "        <div class=\"tt-columns tt-flex\">\n          <div class=\"tt-column\">\n            <div class=\"tt-card-admin-patient-info__content-subtitle\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Contacts",{"name":"translate","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":70},"end":{"line":17,"column":109}}})) != null ? stack1 : "")
    + "</div>\n            <div class=\"tt-card-admin-patient-info__content-text\">\n              <ul class=\"tt-card-admin-patient-info__content-text__list\">\n                \n                <li class=\"tt-card-admin-patient-info__content-text__list-item\">\n                  <div>\n                    <span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mobile",{"name":"translate","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":26},"end":{"line":23,"column":63}}})) != null ? stack1 : "")
    + ": "
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"hidePhone") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":65},"end":{"line":23,"column":108}}})) != null ? stack1 : "")
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showVoipButton") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":30,"column":27}}})) != null ? stack1 : "")
    + "                  </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showVoipButton") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":18},"end":{"line":36,"column":25}}})) != null ? stack1 : "")
    + "                </li>\n\n                <li class=\"tt-card-admin-patient-info__content-text__list-item\">\n                  <div>\n                    <span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Email",{"name":"translate","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":26},"end":{"line":41,"column":62}}})) != null ? stack1 : "")
    + ": "
    + alias4(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":41,"column":64},"end":{"line":41,"column":73}}}) : helper)))
    + "</span>\n                  </div>\n                </li>\n                <li class=\"tt-card-admin-patient-info__content-text__list-item\">\n                  <div>\n                    <span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Email 2",{"name":"translate","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":26},"end":{"line":46,"column":64}}})) != null ? stack1 : "")
    + ": "
    + alias4(((helper = (helper = lookupProperty(helpers,"email2") || (depth0 != null ? lookupProperty(depth0,"email2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email2","hash":{},"data":data,"loc":{"start":{"line":46,"column":66},"end":{"line":46,"column":76}}}) : helper)))
    + "</span>\n                  </div>\n                </li>\n                <li class=\"tt-card-admin-patient-info__content-text__list-item\">\n                  <div>\n                    <span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Home Telephone",{"name":"translate","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":26},"end":{"line":51,"column":71}}})) != null ? stack1 : "")
    + ": "
    + alias4(((helper = (helper = lookupProperty(helpers,"hometel") || (depth0 != null ? lookupProperty(depth0,"hometel") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hometel","hash":{},"data":data,"loc":{"start":{"line":51,"column":73},"end":{"line":51,"column":84}}}) : helper)))
    + "</span>\n                  </div>\n                </li>\n              </ul>\n            </div>\n          </div>\n          <div class=\"tt-column\">\n            <div class=\"tt-card-admin-patient-info__content-subtitle\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Personal Informations",{"name":"translate","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":70},"end":{"line":58,"column":122}}})) != null ? stack1 : "")
    + "</div>\n            <div class=\"tt-card-admin-patient-info__content-text\">\n              <ul class=\"tt-card-admin-patient-info__content-text__list\">\n                <li class=\"tt-card-admin-patient-info__content-text__list-item\">\n                  <div>\n                    <span>"
    + ((stack1 = (lookupProperty(helpers,"translateIDType")||(depth0 && lookupProperty(depth0,"translateIDType"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"idtype") : depth0),{"name":"translateIDType","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":26},"end":{"line":63,"column":73}}})) != null ? stack1 : "")
    + ": "
    + alias4(((helper = (helper = lookupProperty(helpers,"idnumber") || (depth0 != null ? lookupProperty(depth0,"idnumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"idnumber","hash":{},"data":data,"loc":{"start":{"line":63,"column":75},"end":{"line":63,"column":87}}}) : helper)))
    + "</span>\n                  </div>\n                </li>\n                <li class=\"tt-card-admin-patient-info__content-text__list-item\">\n                  <div>\n                    <span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Birthday",{"name":"translate","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":26},"end":{"line":68,"column":65}}})) != null ? stack1 : "")
    + ": "
    + alias4(((helper = (helper = lookupProperty(helpers,"birthday") || (depth0 != null ? lookupProperty(depth0,"birthday") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"birthday","hash":{},"data":data,"loc":{"start":{"line":68,"column":67},"end":{"line":68,"column":79}}}) : helper)))
    + "</span>\n                  </div>\n                </li>\n                <li class=\"tt-card-admin-patient-info__content-text__list-item\">\n                  <div>\n                    <span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Gender",{"name":"translate","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":26},"end":{"line":73,"column":63}}})) != null ? stack1 : "")
    + ": "
    + alias4(((helper = (helper = lookupProperty(helpers,"gender") || (depth0 != null ? lookupProperty(depth0,"gender") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"gender","hash":{},"data":data,"loc":{"start":{"line":73,"column":65},"end":{"line":73,"column":75}}}) : helper)))
    + "</span>\n                  </div>\n                </li>\n              </ul>\n            </div>\n          </div>\n        </div>\n      </div>\n\n      <div class=\"tt-card-admin-patient-info__buttons\">\n      <div class=\"tt-card-admin-patient-info__buttons-container\">\n        <div class=\"tt-card-admin-patient-info__buttons-container-direction\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"hideModifyPatient") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":10},"end":{"line":92,"column":22}}})) != null ? stack1 : "")
    + "\n          <button \n            type=\"button\" \n            class=\"tt-button tt-button--small tt-button--primary\" \n            data-memberid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"memberid") || (depth0 != null ? lookupProperty(depth0,"memberid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"memberid","hash":{},"data":data,"loc":{"start":{"line":97,"column":27},"end":{"line":97,"column":39}}}) : helper)))
    + "\" \n            data-preSelectLastBookedInsurance=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"insuranceid") || (depth0 != null ? lookupProperty(depth0,"insuranceid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"insuranceid","hash":{},"data":data,"loc":{"start":{"line":98,"column":47},"end":{"line":98,"column":62}}}) : helper)))
    + "\"\n            data-preSelectLastBookedArea=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"preSelectLastBookedArea") : depth0)) != null ? lookupProperty(stack1,"areaid") : stack1), depth0))
    + "\"\n            data-preSelectLastBookedProvince=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"preSelectLastBookedArea") : depth0)) != null ? lookupProperty(stack1,"province") : stack1), depth0))
    + "\"\n            data-preSelectLastBookedRegion=\""
    + alias4(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"preSelectLastBookedArea") : depth0)) != null ? lookupProperty(stack1,"region") : stack1), depth0))
    + "\" \n            data-trigger=\"bookForPatient\"\n          >\n            "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Patients Search New Appt",{"name":"translate","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":104,"column":12},"end":{"line":104,"column":71}}})) != null ? stack1 : "")
    + "\n          </button>\n        </div>\n        </div>\n      </div>\n    </div>\n  </div>";
},"useData":true});