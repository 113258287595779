import MOP from 'app';
import dayjs from 'dayjs';
import { TTPolyglot } from "@frontend/tt-polyglot";
import { payButtonLogic } from '../apps/reservations/common';
import { isManuallyPaid } from 'common-mop/reservations';

import { getDateFromFormatted, isBeforeToday } from "common-lib/dates";

export const getFutureReservations = reservations => {
  const now = dayjs().unix();
  return reservations.filter(({ start_date, startTime }) => getTimeStamp(start_date, startTime) > now);
};

export const getPastReservations = reservations => {
  const now = dayjs().unix();
  return reservations.filter(({ start_date, startTime }) => getTimeStamp(start_date, startTime) < now);
};

export const getActiveReservations = reservations => {
  return reservations.filter(r => [MOP.constants.RES_APPROVED, MOP.constants.RES_TO_APPROVE, MOP.constants.RES_USER_CONFIRMED, MOP.constants.RES_COMPLETED].includes(parseInt(r.is_pending)));
};

export const getCancelledReservations = reservations => {
  return reservations.filter(r => [MOP.constants.RES_USER_CANCELLED, MOP.constants.RES_ADMIN_CANCELLED].includes(parseInt(r.is_pending)));
};

const getTimeStamp = (start_date, startTime) => {
  return MOP.Utilities.humanDateToDateObj(MOP.config.getInstanceConfig('dateFormat'), start_date, startTime).getTime() / 1000;
};

export const getReservationDate = (reservation) => {
  const { start_date, startTime } = reservation
  return new Date(getTimeStamp(start_date, startTime) * 1000);
}

export const isReservationPast = ({ start_date, startTime }) => {
  var time = new Date();
  var t = time.getTime() / 1000;
  return getTimeStamp(start_date, startTime) < t;
};

export const isReservationBeforeToday = ({ start_date }) => {
  return isBeforeToday(getDateFromFormatted(MOP.config.getInstanceConfig('dateFormat'), start_date));
}

export const containsBundleSetInfo = ({ bundle_set_info }) => {
  return !MOP.Utilities.empty(bundle_set_info);
};

export const isCancelled = ({ is_pending }) => {
  return (parseInt(is_pending) === MOP.constants.RES_USER_CANCELLED || parseInt(is_pending) == MOP.constants.RES_ADMIN_CANCELLED);
};

export const isNoShow = ({ is_pending }) => {
  return (parseInt(is_pending) === MOP.constants.RES_NO_SHOW);
};

export const isCompleted = ({ is_pending }) => {
  return (parseInt(is_pending) === MOP.constants.RES_COMPLETED);
};

export const isApprovedByLegacy = ({ legacyid }) => {
  return !(MOP.Utilities.empty(legacyid));
};

export const isApproved = ({ is_pending }) => {
  return parseInt(is_pending) === MOP.constants.RES_APPROVED;
};

export const isConfirmed = ({ is_pending }) => {
  return parseInt(is_pending) === MOP.constants.RES_USER_CONFIRMED;
};

export const isCancelEnabled = ({ deferred_cancel_enabled, insurance_cancellation_disabled, is_cancellable }) => {
  return parseInt(deferred_cancel_enabled) !== MOP.constants.DISABLED_CANCEL && MOP.Utilities.empty(insurance_cancellation_disabled) && !MOP.Utilities.empty(is_cancellable);
};

export const isNotRemote = ({ isHiddenArea }) => {
  return parseInt(isHiddenArea) === MOP.constants.ACTIVITY_NOT_REMOTE;
};

export const isRemote = ({ isHiddenArea }) => {
  return parseInt(isHiddenArea) !== MOP.constants.ACTIVITY_NOT_REMOTE;
};

export const isRemoteBase = ({ isHiddenArea }) => {
  return parseInt(isHiddenArea) === MOP.constants.ACTIVITY_REMOTE_BASE;
};

export const isRemoteMeeting = ({ isHiddenArea }) => {
  return parseInt(isHiddenArea) === MOP.constants.ACTIVITY_REMOTE_MEETING;
};

export const isNotDeferred = ({ app_request_enable }) => {
  return parseInt(app_request_enable) === MOP.constants.RESERVATION_NOT_DEFERRED;
};

export const isPending = ({ is_pending }) => {
  return parseInt(is_pending, 10) === MOP.constants.RES_TO_APPROVE;
};

export const isPaymentDisabled = ({payment_required}) => {
  return MOP.Utilities.empty(payment_required);
};

export const getReservationStatusInfo = (res) => {

  const { is_pending, payment_status, checked_in, checked_out, payment_required } = res;
  const status = {};

  //RES_TO_APPROVE (Pending Payment)
  if ((is_pending == MOP.config.constants.RES_TO_APPROVE && payment_status == MOP.constants.RES_PENDING_PAYMENT) ||
    (is_pending == MOP.constants.RES_APPROVED && payment_required == MOP.constants.FIELD_COMPULSORY && payment_status != MOP.constants.RES_VERIFIED_PAYMENT && payment_status != MOP.constants.RES_AUTHORIZED)) {
    status.status_text = TTPolyglot.t('Payment pending');
    status.status_class = 'warning';
    status.new_status = APPOINTMENT_STATUS_TAG_PAYMENT_PENDING; // Serve per il nuovo refactoring della pagina "Patients" per stampare i tag corretti
  }
  //RES_TO_APPROVE (Sincro OFF or Resource wants Approve)
  else if (is_pending == MOP.config.constants.RES_TO_APPROVE) {
    status.status_text = TTPolyglot.t('Pending Approval');
    status.status_class = 'warning';
    status.new_status = APPOINTMENT_STATUS_TAG_PENDING_APPROVAL; // Serve per il nuovo refactoring della pagina "Patients" per stampare i tag corretti
  }
  //RES_ADMIN_CANCELLED (Refunded Payment)
  else if (is_pending == MOP.config.constants.RES_ADMIN_CANCELLED
    && payment_status == MOP.constants.RES_REFUNDED_PAYMENT) {
    status.status_text = TTPolyglot.t('Payment refunded');
    status.status_class = 'gray';
    status.new_status = APPOINTMENT_STATUS_TAG_PAYMENT_REFUNDED; // Serve per il nuovo refactoring della pagina "Patients" per stampare i tag corretti
  }
  // RES_NO_SHOW
  else if (is_pending == MOP.config.constants.RES_NO_SHOW) {
    status.status_text = TTPolyglot.t('No Show');
    status.status_class = 'gray';
    status.new_status = APPOINTMENT_STATUS_TAG_NO_SHOW; // Serve per il nuovo refactoring della pagina "Patients" per stampare i tag corretti
  }
  //RES_USER_CANCELLED
  else if (is_pending == MOP.config.constants.RES_USER_CANCELLED
    && payment_status == MOP.constants.RES_REFUNDED_PAYMENT) {
    status.status_text = TTPolyglot.t('Payment refunded');
    status.status_class = 'gray';
    status.new_status = APPOINTMENT_STATUS_TAG_PAYMENT_REFUNDED; // Serve per il nuovo refactoring della pagina "Patients" per stampare i tag corretti
  }
  //RES_SUBMITTED_REFUND
  else if (is_pending == MOP.config.constants.RES_ADMIN_CANCELLED
    && payment_status == MOP.constants.RES_SUBMITTED_REFUND) {
    status.status_text = TTPolyglot.t('Payment refund submitted');
    status.status_class = 'gray';
    status.new_status = APPOINTMENT_STATUS_TAG_PAYMENT_REFUND_SUBMITTED; // Serve per il nuovo refactoring della pagina "Patients" per stampare i tag corretti
  }
  //RES_SUBMITTED_REFUND
  else if (is_pending == MOP.config.constants.RES_USER_CANCELLED
    && payment_status == MOP.constants.RES_SUBMITTED_REFUND) {
    status.status_text = TTPolyglot.t('Payment refund submitted');
    status.status_class = 'gray';
    status.new_status = APPOINTMENT_STATUS_TAG_PAYMENT_REFUND_SUBMITTED; // Serve per il nuovo refactoring della pagina "Patients" per stampare i tag corretti
  }
  //RES_ADMIN_CANCELLED (Pending Payment)
  else if (is_pending == MOP.config.constants.RES_ADMIN_CANCELLED
    && payment_status == MOP.constants.RES_PENDING_PAYMENT) {
    status.status_text = TTPolyglot.t('Not paid');
    status.status_class = 'gray';
    status.new_status = APPOINTMENT_STATUS_TAG_NOT_PAID; // Serve per il nuovo refactoring della pagina "Patients" per stampare i tag corretti
  }
  //RES_ADMIN_CANCELLED || RES_USER_CANCELLED
  else if (is_pending == MOP.config.constants.RES_ADMIN_CANCELLED
    || is_pending == MOP.config.constants.RES_USER_CANCELLED) {
    status.status_text = TTPolyglot.t('Cancelled');
    status.status_class = 'gray';
    status.new_status = APPOINTMENT_STATUS_TAG_CANCELLED; // Serve per il nuovo refactoring della pagina "Patients" per stampare i tag corretti
    //RES_SUBMITTED_PAYMENT (Payment Pending Confirmation)
  } else if (payment_status == MOP.constants.RES_SUBMITTED_PAYMENT) {
    status.status_text = TTPolyglot.t('Payment Pending Confirmation');
    status.status_class = 'success';
    status.new_status = APPOINTMENT_STATUS_TAG_PAYMENT_SUBMITTED; // Serve per il nuovo refactoring della pagina "Patients" per stampare i tag corretti
  }
  //RES_APPROVED (Payment Submitted) OR (Payment Submitted + Checkin Done) PAYED ONLINE
  else if (!MOP.Utilities.empty(payment_status) && payment_status > MOP.constants.RES_PENDING_PAYMENT && !isManuallyPaid(res)) {
    var sessionLang = MOP.config.get('lang');

    const checkedIn = !MOP.Utilities.empty(checked_in);

    status.status_text = `${TTPolyglot.t('Paid')}${checkedIn ? ` - ${TTPolyglot.t('Checkin Status Checkedin')}` : ""}`;
    status.status_class = 'success';
    status.new_status = checkedIn ? APPOINTMENT_STATUS_TAG_PAID_CHECKED_IN : APPOINTMENT_STATUS_TAG_PAID ; // Serve per il nuovo refactoring della pagina "Patients" per stampare i tag corretti
  }
  //RES_APPROVED (Payment Submitted) OR (Payment Submitted + Checkin Done) AND PAYED MANUALLY
  else if (!MOP.Utilities.empty(payment_status) && payment_status > MOP.constants.RES_PENDING_PAYMENT && isManuallyPaid(res)) {
    var sessionLang = MOP.config.get('lang');

    const checkedIn = !MOP.Utilities.empty(checked_in);

    status.status_text = `${TTPolyglot.t('Mop Manual Payment Status')}${checkedIn ? ` - ${TTPolyglot.t('Checkin Status Checkedin')}` : ""}`;
    status.status_class = 'success';
    status.new_status = checkedIn ? APPOINTMENT_STATUS_TAG_PAID_MANUALLY_CHECKED_IN : APPOINTMENT_STATUS_TAG_PAID_MANUALLY ; // Serve per il nuovo refactoring della pagina "Patients" per stampare i tag corretti
  }
  // Checkout done (mostro questo caso solo per le risorse)
  else if (is_pending == MOP.config.constants.RES_COMPLETED && !MOP.Utilities.empty(checked_out) && MOP.isResourceLoggedIn()) {
    status.status_text = TTPolyglot.t('Checked-out');
    status.status_class = 'success';
    status.new_status = APPOINTMENT_STATUS_TAG_CHECKED_OUT; 
  }
  // Checkin done
  else if (!MOP.Utilities.empty(checked_in)) {
    status.status_text = TTPolyglot.t('Checkin Status Checkedin');
    status.status_class = 'success';
    status.new_status = APPOINTMENT_STATUS_TAG_CHECKED_IN; // Serve per il nuovo refactoring della pagina "Patients" per stampare i tag corretti
  }
  // RES_USER_CONFIRMED
  else if (is_pending == MOP.config.constants.RES_USER_CONFIRMED) {
    // A seguito di questo ticket: https://tuotempo.freshdesk.com/a/tickets/94278 abbiamo inserito una nuova chiave
    // Specifica per lo status "Confermato dal paziente". Di default questa chiave ha la traduzione identica alla chiave
    // "Confirmed" in questo modo tutti gli utenti continuano a vedere una traduzione uguale nei due casi (come è sempre stato)
    // Poi i clienti che vogliono differenziare i due comportamenti, potranno definirsi il proprio specific per questa chiave specifica
    status.status_text = TTPolyglot.t('MOP Confirmed by patient');
    status.status_class = 'success';
    status.new_status = APPOINTMENT_STATUS_TAG_CONFIRMED_BY_PATIENT; // Serve per il nuovo refactoring della pagina "Patients" per stampare i tag corretti
  }
  //RES_APPROVED
  else {
    status.status_text = TTPolyglot.t('Confirmed');
    status.status_class = 'success';
    status.new_status = APPOINTMENT_STATUS_TAG_CONFIRMED; // Serve per il nuovo refactoring della pagina "Patients" per stampare i tag corretti
  }

  if (status.status_class.indexOf('success') != -1) {
    if (isReservationPast(res)) {
      status.status_class = 'gray';
    }
  }

  return status;
};

export const getCheckinData = (reservation, checkinConfigs) => {

  const showCheckinCode = parseInt(checkinConfigs.showCheckinCode, 10);
  const userCheckinDisabled = MOP.getLoggedUserDataField("checkin_disabled");
  const { checkin_code, checkin_disabled, insurance_checkin_disabled, payment_status, resid, checkin_info } = reservation;
  // Gestione visualizzazione check-in code e pulsante di check-in in mobile

  const checkin = { visibility: false, type: parseInt(checkinConfigs.checkinCodeType), payBefore: false };
  const isPayButtonVisible = payButtonLogic(reservation);

  if (showCheckinCode !== 0 &&
    checkin_code &&
    MOP.Utilities.empty(userCheckinDisabled) &&
    MOP.Utilities.empty(checkin_disabled) &&
    MOP.Utilities.empty(insurance_checkin_disabled)
  ) {
    if ((showCheckinCode === 1 ||
      (showCheckinCode === 2 && (payment_status == MOP.constants.RES_SUBMITTED_PAYMENT || payment_status == MOP.constants.RES_VERIFIED_PAYMENT || payment_status == MOP.constants.RES_AUTHORIZED))
      || (showCheckinCode === 3 && (!MOP.Utilities.empty(MOP.getLoggedUserDataField("user_payment")) || payment_status == MOP.constants.RES_SUBMITTED_PAYMENT || payment_status == MOP.constants.RES_VERIFIED_PAYMENT || payment_status == MOP.constants.RES_AUTHORIZED)))
    ) {

      checkin.visibility = true;

      if(!MOP.Utilities.empty(checkin_info)) {

        // Se abbiamo le checkin_info vuol dire che il checkin è stato fatto e vogliamo vedere tali info al posto del codice per fare chekcin
        // Quindi inserisco le checkin_info nella chiave msg e checkin_code_displayed.
        // Poi setto checkincode_image_url a null e setto il tipo del checkin come "Numeric" perchè non vogliamo nessun qr code o barcode
        checkin.checkin_code_displayed = checkin_info.code || "";
        checkin.msg = checkin_info.description || "";
        checkin.checkincode_image_url = null;
        checkin.type = MOP.constants.CHECKIN_NUMERIC
      } else {
        checkin.checkin_code_displayed = checkin_code;
        let msg = MOP.config.isDesktop() ? checkinConfigs.checkinMsgDesktop : checkinConfigs.checkinMsgMobile;
        if (MOP.Utilities.empty(msg)) msg = 'Checkin';
        checkin.msg = msg;

        let checkincode_image_url = `${MOP.config.getAccessPointConfig("guest").checkincode_image}&dbName=${MOP.getDbName()}&checkin_code=${checkin_code}&target_dbName=`;

        if (MOP.config.isPortalInstance()) {
          if (resid.indexOf("@") !== -1) {
            const member_name_arr = resid.split("@");
            if (member_name_arr.length === 2) {
              const member_name = member_name_arr[1];
              checkincode_image_url += member_name;
            }
          }
        } else {
          checkincode_image_url += MOP.getDbName();
        }
  
        if (MOP.isFromTRD111()) {
          checkincode_image_url += `&auth_mode=${MOP.constants.AUTH_MODE_TRD111}`;
        }
  
        checkin.checkincode_image_url = checkincode_image_url;
      }

      if (checkin.checkin_code_displayed.length <= 10) {
        checkin.checkin_code_displayed_size = "2.4em";
      } else if (checkin.checkin_code_displayed.length <= 20) {
        checkin.checkin_code_displayed_size = "2em";
      } else {
        checkin.checkin_code_displayed_size = "1.2em";
      }

    }
    else if (
      (showCheckinCode === 2 ||
      (showCheckinCode === 3 && MOP.Utilities.empty(MOP.getLoggedUserDataField("user_payment"))))
      && isPayButtonVisible
    ) {
      /**
       * Visibilità banner Fast Checkin
       * 
       * Scenario 1 
       * Se una reservation ha il pulsante di pagamento “non presente” AND 
       * (il fast check-in è solo per chi ha pagato OR ha una carta salvata)
       * Allora non mostriamo il banner
       * 
       * Scenario 2
       * Se una reservation ha il pulsante di pagamento “non presente” AND  
       * (il fast check-in è solo per chi ha pagato OR ha una carta salvata);
       * Allora mostriamo il banner
       */

      checkin.payBefore = true;
    }
  }

  return checkin;

}

export const APPOINTMENTCONFIRMABILILITYSTATUS = {
  notConfirmable : 0,
  confirmableOutOfTimeWindow: 1,
  confirmable: 2
}

export const APPOINTMENT_STATUS_TAG_PAYMENT_PENDING = "APPOINTMENT_STATUS_TAG_PAYMENT_PENDING";
export const APPOINTMENT_STATUS_TAG_PAYMENT_REFUNDED = "APPOINTMENT_STATUS_TAG_PAYMENT_REFUNDED";
export const APPOINTMENT_STATUS_TAG_PAYMENT_REFUND_SUBMITTED = "APPOINTMENT_STATUS_TAG_PAYMENT_REFUND_SUBMITTED";
export const APPOINTMENT_STATUS_TAG_PAYMENT_SUBMITTED = "APPOINTMENT_STATUS_TAG_PAYMENT_SUBMITTED";
export const APPOINTMENT_STATUS_TAG_PAID = "APPOINTMENT_STATUS_TAG_PAID";
export const APPOINTMENT_STATUS_TAG_PAID_MANUALLY = "APPOINTMENT_STATUS_TAG_PAID_MANUALLY";
export const APPOINTMENT_STATUS_TAG_NOT_PAID = "APPOINTMENT_STATUS_TAG_NOT_PAID";
export const APPOINTMENT_STATUS_TAG_PENDING_APPROVAL = "APPOINTMENT_STATUS_TAG_PENDING_APPROVAL";
export const APPOINTMENT_STATUS_TAG_NO_SHOW = "APPOINTMENT_STATUS_TAG_NO_SHOW";
export const APPOINTMENT_STATUS_TAG_CANCELLED = "APPOINTMENT_STATUS_TAG_CANCELLED";
export const APPOINTMENT_STATUS_TAG_CHECKED_IN = "APPOINTMENT_STATUS_TAG_CHECKED_IN";
export const APPOINTMENT_STATUS_TAG_CHECKED_OUT = "APPOINTMENT_STATUS_TAG_CHECKED_OUT";
export const APPOINTMENT_STATUS_TAG_PAID_CHECKED_IN = "APPOINTMENT_STATUS_TAG_PAID_CHECKED_IN";
export const APPOINTMENT_STATUS_TAG_PAID_MANUALLY_CHECKED_IN = "APPOINTMENT_STATUS_TAG_PAID_MANUALLY_CHECKED_IN";
export const APPOINTMENT_STATUS_TAG_CONFIRMED = "APPOINTMENT_STATUS_TAG_CONFIRMED";
export const APPOINTMENT_STATUS_TAG_CONFIRMED_BY_PATIENT = "APPOINTMENT_STATUS_TAG_CONFIRMED_BY_PATIENT";

export const APPOINTMENT_TAG_REMOTE = "APPOINTMENT_TAG_REMOTE";

