var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "tt-dialog-fullscreen";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "\n<div class=\"modal fade ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":23},"end":{"line":2,"column":69}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\" id=\"policyCustomModal\" tabindex=\"-1\" aria-labelledby=\"exampleModalLabel\" aria-hidden=\"true\" role=\"dialog\">\n	 <div class=\"modal-dialog modal-dialog-scrollable\">\n    <div class=\"modal-content\">\n      <div class=\"modal-body tt-bg-white tt-p-24 tt-dialog-radius\">\n        <div class=\"modal-body tt-modal-content__body\">\n          "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"policy_custom_text") || (depth0 != null ? lookupProperty(depth0,"policy_custom_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"policy_custom_text","hash":{},"data":data,"loc":{"start":{"line":7,"column":10},"end":{"line":7,"column":34}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n        <div class=\"js-close-dialog tt-modal__close\" data-dismiss=\"modal\">\n          <span><i class=\"tt-icon-times\"></i></span>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n\n";
},"useData":true});