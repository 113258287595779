var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"tt-modal-content__body-link\">\n                <a style=\"cursor: pointer\" class=\"js-survey-selection\" survey=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</a>\n              </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n  <div class=\"modal-dialog tt-modal\">\n    <div class=\"modal-content tt-modal-content\">\n      <div class=\"modal-body tt-modal-content__body\">\n        <div class=\"survey-availabilities-content\">\n          <div class=\"js-survey-1\">\n            <div class=\"tt-modal-content__body-title\">\n              "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"ava_mop_choice_1") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\n            </div>\n            <div class=\"js-survey-1-content\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"ava_mop_choice_1") : depth0)) != null ? lookupProperty(stack1,"options") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":14},"end":{"line":15,"column":23}}})) != null ? stack1 : "")
    + "              <div>\n                <a class=\"js-survey-other\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias4).call(alias3,"Ava Mop Choice Other",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":43},"end":{"line":17,"column":94}}})) != null ? stack1 : "")
    + "</a>\n              </div>\n            </div>\n          </div>\n          <div class=\"js-survey-2\" style=\"display: none;\">\n            <div>\n              "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"ava_mop_choice_2") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\n            </div>\n            <div class=\"js-survey-2-content\" style=\"padding-bottom: 20px; padding-top: 10px;\">\n              <div>\n                <textarea class=\"js-survey-2-text\"\n                  placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias4).call(alias3,"Ava Mop Choice Other Placholder",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":31},"end":{"line":28,"column":93}}})) != null ? stack1 : "")
    + "\"\n                  style=\"width: 100%; border: none; min-height: 70px; resize: none;\"></textarea>\n              </div>\n              <div style=\"position: absolute; bottom: 10px; right: 10px\">\n                <a class=\"js-survey-2-send-btn\"\n                  style=\"pointer-events: none; cursor: default; color: gray;\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias4).call(alias3,"Ava Mop Choice Other Submit",{"name":"translate","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":78},"end":{"line":33,"column":136}}})) != null ? stack1 : "")
    + "</a>\n              </div>\n            </div>\n          </div>\n        </div>\n        <div class=\"survey-availabilities-response\" style=\"display: none;\">\n          <div style=\"text-align: center; font-size: 100px; color: "
    + alias2(((helper = (helper = lookupProperty(helpers,"icon_color") || (depth0 != null ? lookupProperty(depth0,"icon_color") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"icon_color","hash":{},"data":data,"loc":{"start":{"line":39,"column":67},"end":{"line":39,"column":81}}}) : helper)))
    + "\">\n            <span><i class=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"icon") || (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":40,"column":28},"end":{"line":40,"column":36}}}) : helper)))
    + "\"></i></span>\n          </div>\n          <div style=\"text-align: center; font-size: 20px; margin-top: 20px;\">\n          "
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":43,"column":10},"end":{"line":43,"column":19}}}) : helper)))
    + " \n          </div>\n          <div style=\"text-align: center; margin-bottom: 20px; margin-top: 10px;\">\n            "
    + alias2(((helper = (helper = lookupProperty(helpers,"subtitle") || (depth0 != null ? lookupProperty(depth0,"subtitle") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"subtitle","hash":{},"data":data,"loc":{"start":{"line":46,"column":12},"end":{"line":46,"column":24}}}) : helper)))
    + "\n          </div>\n        </div>\n      </div>\n      <div class=\"js-close-dialog tt-modal__close\">\n        <span><i class=\"tt-icon-times\"></i></span>\n      </div>\n    </div>\n    <div class=\"modal-footer\">\n\n    </div>\n  </div>\n</div>";
},"useData":true});