var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"tt-column\">\n                <a href=\"javascript:\" class=\"tt-button tt-button--paginator tt-button--primary otherResults js-prev-results\" data-results-direction=\"prev\">\n                    <i class=\"tt-button__icon tt-button__icon--left tt-icon-angle-left\"></i>\n                    <span class=\"tt-button__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Previous",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":50},"end":{"line":10,"column":89}}})) != null ? stack1 : "")
    + "</span>\n                </a>\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"multiple-availabilities-container\" class=\"operator_availabilities\"></div>\n\n\n<div class=\"tt-container\">\n    <div class=\"tt-columns is-mobile\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPrevResults") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"tt-column\">\n            <a href=\"javascript:\" class=\"tt-button tt-button--paginator tt-button--primary otherResults js-next-results\" data-results-direction=\"next\">\n                <i class=\"tt-button__icon tt-button__icon--right tt-icon-angle-right\"></i>\n                <span class=\"tt-button__text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"Next",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":46},"end":{"line":17,"column":81}}})) != null ? stack1 : "")
    + "</span>\n            </a>\n        </div>\n    </div>\n</div>";
},"useData":true});