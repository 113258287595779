import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone-shim';
import Utilities from 'common/utilities';
import ModelBase from 'models/model_base';
import { getBaseUrl, isNet, isProduction, isTestProduction } from 'common-lib/url';
import {
  MOP_LAUNCHER_ORIGIN_WORKING_LIST, 
  MOP_LAUNCHER_ORIGIN_VIDEO_CONSULTATION, 
  MOP_LAUNCHER_ORIGIN_CUSTOMER_EMBEDDED_FROM_MOBILE
} from 'common-lib/constants';

  /*
   * Implements base64 decode and encode in browser that
   * it hasn't support of window.btoa and window.atob
   * methods.
   * Based in Nick Galbreath
   * http://code.google.com/p/stringencoders/source/browse/#svn/
   * and Carlo Zottmann jQuery port
   * http://github.com/carlo/jquery-base64
   * Adapted by SeViR in DIGIO
   */
  if (!window.atob && !window.btoa) {
    (function(window) {
      var _PADCHAR = "=",
        _ALPHA = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

      function _getbyte64(s, i) {
        var idx = _ALPHA.indexOf(s.charAt(i));
        if (idx === -1) {
          throw "Cannot decode base64";
        }
        return idx;
      }

      function _decode(s) {
        var pads = 0,
          i,
          b10,
          imax = s.length,
          x = [];
        s = String(s);
        if (imax === 0) {
          return s;
        }
        if (imax % 4 !== 0) {
          throw "Cannot decode base64";
        }
        if (s.charAt(imax - 1) === _PADCHAR) {
          pads = 1;
          if (s.charAt(imax - 2) === _PADCHAR) {
            pads = 2;
          }
          // either way, we want to ignore this last block
          imax -= 4;
        }
        for (i = 0; i < imax; i += 4) {
          b10 = (_getbyte64(s, i) << 18) | (_getbyte64(s, i + 1) << 12) | (_getbyte64(s, i + 2) << 6) | _getbyte64(s, i + 3);
          x.push(String.fromCharCode(b10 >> 16, (b10 >> 8) & 0xff, b10 & 0xff));
        }
        switch (pads) {
          case 1:
            b10 = (_getbyte64(s, i) << 18) | (_getbyte64(s, i + 1) << 12) | (_getbyte64(s, i + 2) << 6);
            x.push(String.fromCharCode(b10 >> 16, (b10 >> 8) & 0xff));
            break;
          case 2:
            b10 = (_getbyte64(s, i) << 18) | (_getbyte64(s, i + 1) << 12);
            x.push(String.fromCharCode(b10 >> 16));
            break;
        }
        return x.join("");
      }

      function _getbyte(s, i) {
        var x = s.charCodeAt(i);
        if (x > 255) {
          throw "INVALID_CHARACTER_ERR: DOM Exception 5";
        }
        return x;
      }

      function _encode(s) {
        if (arguments.length !== 1) {
          throw "SyntaxError: exactly one argument required";
        }
        s = String(s);
        var i,
          b10,
          x = [],
          imax = s.length - s.length % 3;
        if (s.length === 0) {
          return s;
        }
        for (i = 0; i < imax; i += 3) {
          b10 = (_getbyte(s, i) << 16) | (_getbyte(s, i + 1) << 8) | _getbyte(s, i + 2);
          x.push(_ALPHA.charAt(b10 >> 18));
          x.push(_ALPHA.charAt((b10 >> 12) & 0x3F));
          x.push(_ALPHA.charAt((b10 >> 6) & 0x3f));
          x.push(_ALPHA.charAt(b10 & 0x3f));
        }
        switch (s.length - imax) {
          case 1:
            b10 = _getbyte(s, i) << 16;
            x.push(_ALPHA.charAt(b10 >> 18) + _ALPHA.charAt((b10 >> 12) & 0x3F) + _PADCHAR + _PADCHAR);
            break;
          case 2:
            b10 = (_getbyte(s, i) << 16) | (_getbyte(s, i + 1) << 8);
            x.push(_ALPHA.charAt(b10 >> 18) + _ALPHA.charAt((b10 >> 12) & 0x3F) + _ALPHA.charAt((b10 >> 6) & 0x3f) + _PADCHAR);
            break;
        }
        return x.join("");
      }
      window.btoa = _encode;
      window.atob = _decode;
    })(window);
  }

  /*
   * Ridefinizione del funzione nativa toJSON di backbone per gestire model innestati
   */
  Backbone.Model.prototype.toJSON = function() {
    return JSON.parse(JSON.stringify(this.attributes));
  };

  export default ModelBase.extend({
    constants: {
      WIDGET_INSURANCE: 'Insurance',
      WIDGET_TYPOLOGY_ACTIVITY: 'TypologyActivity',
      WIDGET_AREA: 'Area',
      WIDGET_AREA_LOCALIZATION: 'AreaLocalization',
      WIDGET_RESOURCE: 'Resource',
      WIDGET_DATE: 'Date',
      WIDGET_TIME: 'Time',
      WIDGET_PROVINCE: 'Province',

      WIDGET_STATUS_INIT: 'INIT',
      WIDGET_STATUS_FILL: 'FILL',
      WIDGET_STATUS_EMPTY: 'EMPTY',

      SECONDS_IN_A_DAY: 86400,

      MERGER_TYPE_DEFAULT: 'A', // FIVE_AVAILABILITIES
      MERGER_TYPE_RESOURCES: 'B', // WEEK_AVAILABILITIES
      MERGER_TYPE_RESOURCES_AREAS: 'BD', // WEEK_AVAILABILITIES
      MERGER_TYPE_AREAS: 'D', // WEEK_AVAILABILITIES
      MERGER_TYPE_INSURANCES: 'C', // SalutePerVeneto

      FIELD_INVISIBLE: 0,
      FIELD_VISIBLE: 1,
      FIELD_COMPULSORY: 2,

      AVAILABILITIES_WEEK_NUM_DAYS_TO_SHOW: 7,
      AVAILABILITIES_WEEK_MAX_RESULTS_PER_DAY: 7,
      AVAILABILITIES_WEEK_MAX_RESULTS_PER_DAY_MOBILE: 3,
      AVAILABILITIES_WEEK_MAX_RESULTS_TO_SHOW: 3,

      MOP_ACTIVITIES_CONFIGURATION: 'activity', // Configurazione Base dove le PRESTAZIONI rappresentano il primo criterio di ricerca
      MOP_ACTIVITIES_REGIONS: 'activity_2', // PRESTAZIONI + Regioni
      MOP_ACTIVITIES_REGIONS_PROVINCES: 'activity_2_1', // PRESTAZIONI + Regioni e Province
      MOP_ACTIVITIES_PROVINCES: 'province', // Province + PRESTAZIONI
      MOP_RESOURCES_CONFIGURATION: 'resource', // Configurazione Base dove le RISORSE rappresentano il primo criterio di ricerca
      MOP_RESOURCES_LOCALIZATIONSEARCH: 'resource_1', // RISORSE + Ricerca Dinamica Geografica
      MOP_RESOURCES_BEFORE_INSURANCES: 'resource_2', // RISORSE al primo posto e Assicurazioni se presenti al secondo
      MOP_AREAS_CONFIGURATION: 'area', // Configurazione Base dove le SEDI rappresentano il primo criterio di ricerca
      MOP_AREAS_LOCALIZATIONSEARCH: 'area_1', // SEDI + Ricerca Dinamica Geografica
      MOP_AREAS_REGIONS: 'area_2', // SEDI + Regioni
      MOP_AREAS_REGIONS_PROVINCES: 'area_2_1', // SEDI + Regioni e Province
      MOP_PRESCRIPTION_CONFIGURATION: 'prescription', // Numero di Ricetta Elettronica

      CLIENT_BROWSER_DESKTOP: 'desktop',
      CLIENT_BROWSER_MOBILE: 'mobile',
      CLIENT_APP_MOBILE: 'app',
      CLIENT_BROWSER_TABLET: 'tablet',
      CLIENT_APP_TABLET: 'app_tablet',

      RES_APPROVED: 0,
      RES_TO_APPROVE: 1,
      RES_USER_CONFIRMED: 2,
      RES_USER_CANCELLED: 3,
      RES_ADMIN_CANCELLED: 4,
      RES_COMPLETED: 5,
      RES_NO_SHOW: 6,

      RES_PENDING_PAYMENT: 0,
      RES_SUBMITTED_PAYMENT: 1,
      RES_VERIFIED_PAYMENT: 2,
      RES_REFUNDED_PAYMENT: 3,
      RES_SUBMITTED_REFUND: 4,
      RES_AUTHORIZED: 5,

      FROM_BACKOFFICE: 2, // Utilizzata dal BE per distinguere da dove arrivano le richieste
      FROM_MOP: 3, // Utilizzata dal BE per distinguere da dove arrivano le richieste
      FROM_APP: 12, // Utilizzata dal BE per distinguere da dove arrivano le richieste

      PAYMENT_MAX_MINUTES_TIMEOUT: 20, // NB => differisce di 10 min dalla omonima costante server perchè si è deciso
      								   // di mantenere i 20 minuti a livello di messaggio/frontend ma di utilizzare 
      								   // 30 minuti per effettuare la cancellazione vera e propria 
      								   // https://tuotempo.freshdesk.com/helpdesk/tickets/9461

      PASSWORD_SEND_TOLERANCE: 100,
      MESSAGES_ENABLED: 1,
      PASSWORD_SINCRO_OFF_SEND_TOLERANCE: (48 * 86400),

      PAYPAL_STANDARD: 'standard',
      PAYPAL_PRO: 'pro',

      PAYMENT_METHOD_PAYPAL: 'paypal',
      PAYMENT_METHOD_CARTASI: 'cartasi',
      PAYMENT_METHOD_TRANSBANK: 'transbank',
      PAYMENT_METHOD_REDSYS: 'redsys',
      PAYMENT_METHOD_PAYMILL: 'paymill',
      PAYMENT_METHOD_BRAINTREE: 'braintree',
      PAYMENT_METHOD_BRAINTREE_3D: 'braintree-3d',
      PAYMENT_METHOD_INGENICO: 'ingenico',
      PAYMENT_METHOD_CONEKTA: 'conekta',
      PAYMENT_METHOD_CONEKTA_HOSTED: 'conektaHosted',
      PAYMENT_METHOD_CONEKTA_EMBEDDED: 'conektaEmbedded',
      PAYMENT_METHOD_ADYEN: 'adyen',
      PAYMENT_METHOD_GETNET: 'getnet',
      PAYMENT_METHOD_PPPLUS: 'ppplus',
      PAYMENT_ITEM_RESERVATION: 'reservation',
      PAYMENT_ITEM_CREDITS: 'credits',
      PAYMENT_ITEM_DOWNLOAD: 'download',
      PAYMENT_ITEM_REQUEST: 'request',

      PAYMENT_LINK: 'payment_link',

      ORDER_WAY_ASC: 'ASC',
      ORDER_WAY_DESC: 'DESC',

      // livelli di Matching per la gestione dei Duplicati Utenti
      STRONG_MATCHING: 's',
      STRONG_PARENT_MATCHING: 'sp',
      CONTACT_MATCHING: 'c',
      CONTACT_COMPULSORY_MATCHING: 'cc',
      WEAK_MATCHING: 'w',
      LOGIN_MATCHING: 'l',
      UNIQUE_MATCHING: 'q',

      // Users profiles
      EXTERNAL_USER: 0, //Paziente
      ADMIN: 1,
      SUPER_ADMIN: 2,
      VIEWER: 3,
      OPERATOR: 4,
      HIDDEN_USER: 5,
      RESELLER: 1,
      CAMPAIGN_USER: 6,

      // Tipi di storages gestiti
      LOCAL_STORAGE: 'localStorage',
      COOKIE_STORAGE: 'cookie',
      SESSION_STORAGE: 'sessionStorage',

      // Prescription
      ELECTRONIC_PRESCRIPTION: 0,
      CLASSIC_PRESCRIPTION: 1,

      // Special Langs
      DOCTOR_SPECIAL_LANG: 'doctor',
      BEAUTY_SPECIAL_LANG: 'beauty',
      COMPANY_SPECIAL_LANG: 'company',

      SECURE_PROTOCOL: 'https',
      UNSECURE_PROTOCOL: 'http',
      
      MAX_MULTIPLE_RESERVATIONS: 5,

      MULTIPLE_RESERVATIONS_TARGET_FILTERS: ['activityid', 'activityTitle', 'activity_legacyid', 'typologyTitle', 'typologyid', 'resourceName', 'resourceid'],

      //Limite input registrazione
      LIMIT_CP: 10,
      LIMIT_ADDRESS: 255,
      LIMIT_CITY: 40,
      LIMIT_PROVINCE: 255,
      LIMIT_COUNTRY: 20,

      //HIDE_HISTORY
      HIDE_HISTORY_PARTIAL_REQUESTED: 1,
      HIDE_HISTORY_PARTIAL_PROCESSED: 2,
      HIDE_HISTORY_FULL_REQUESTED: 3,
      HIDE_HISTORY_FULL_PROCESSED: 4,
      HIDE_HISTORY_DOSSIER_REQUESTED: 5,
      HIDE_HISTORY_DOSSIER_PROCESSED: 6,

      //Servono per aprire direttamente i rispettivi store dal telefono
      APP_STORE_URL: 'itms-apps://itunes.com/apps/',
      GOOGLE_PLAY_STORE_URL: 'https://play.google.com/store/apps/',

      INPUT_FIELD_DATE_DDMMYYYY: 0,
      INPUT_FIELD_DATE_MMYYYY: 1,
      INPUT_FIELD_DATE_YYYY: 2,
      DESCRIPTION_SEPARATOR: '#####',
      MIN_PASSWORD_LENGTH: 8,
      
      REVERT_HOT_FUSION_USER_CREATION_INTERVAL: 86400,
      
      //Types of Carousel
      NO_CAROUSEL:                               0,
      IMAGES_CAROUSEL:                           1,
      IMAGES_AND_BUTTONS_CAROUSEL:                2,
      
      // SENTRY LOG "TAG"
      SENTRY_INFO_GOOGLEMAP: "MOP_INFO_GOOGLEMAP",
      SENTRY_INFO_TYPOLOGY_ACTIVITY_FILTER: "MOP_INFO_TYPOLOGY_ACTIVITY_FILTER",
      SENTRY_INFO_VIEW_SUMMARY: 'MOP_VIEW_SUMMARY',
      SENTRY_INFO_CLICK_BOOK_FROM_SUMMARY: 'MOP_CLICK_BOOK_FROM_SUMMARY',
      SENTRY_INFO_CLICK_PAY_FROM_SUMMARY: 'MOP_CLICK_PAY_FROM_SUMMARY',
      SENTRY_INFO_BACK_FROM_SUMMARY: 'MOP_BACK_FROM_SUMMARY',
      SENTRY_INFO_ADD_PARENT_FROM_SUMMARY: 'MOP_ADD_PARENT_FROM_SUMMARY',
      SENTRY_INFO_ADD_PAYMENT_FROM_SUMMARY: 'MOP_ADD_PAYMENT_FROM_SUMMARY',
      SENTRY_INFO_DO_LOGIN: 'MOP_DO_LOGIN',
      SENTRY_INFO_CHANGED_KEYS: 'CHANGED_KEYS',
      
      APP_EVENT_ACCESS: 0,
      APP_EVENT_SHOW_RATING: 1,
      APP_EVENT_SHOW_PROFILE: 2,
      APP_EVENT_SHOW_RATING_VOTE: 3,
      APP_EVENT_SHOW_RATING_LATER: 4,
      APP_EVENT_SHOW_RATING_CANCEL: 5,
      APP_EVENT_RESUME_APP: 6,
      APP_EVENT_CLOSE_APP: 7,
      APP_EVENT_HOMEPAGE_CAMPAIGN_SEEN: 8,
      APP_EVENT_HOMEPAGE_CAMPAIGN_CLICKED: 9,
      APP_EVENT_GOOGLE_ANALYTICS_EVENT_SENDING: 10,
      APP_EVENT_GOOGLE_ANALYTICS_EVENT_SENT: 11,

      NEW_DOCUMENT_DOSSIER_THRESHOLD: 604800, //1 Settimana in secondi
      
      SURVEY_TYPE_TEXT_BOX: 2,
      SURVEY_TYPE_RADIO: 4,
      SURVEY_AVAILABILITIES_MOP_NAME: 'ava_mop_satisfaction',
      SURVEY_AVAILABILITIES_QUESTION_CHOICE_1: 'ava_mop_choice_1',
      SURVEY_AVAILABILITIES_QUESTION_TEXT_1: 'ava_mop_text_1',
      SURVEY_SHOW_TIME: 60000,

      TALK_DOCTOR_STATUS_MESSAGE_OK: 0,
      TALK_DOCTOR_STATUS_MESSAGE_LOADING: 1,
      TALK_DOCTOR_STATUS_MESSAGE_ERROR: 2,
      CLUSTER_MAX_DELAY: 2000,

        GET_TOKEN_RESERVATIONS_ICS:                 3,
        GET_TOKEN_RESERVATIONS_ICS_RESOURCES:       8,
        GET_TOKEN_RESERVATIONS_ICS_RESOURCES_PATH: 'Resource',
        GET_TOKEN_DOWNLOAD_YOUR_DOCUMENT:           11,
        GET_TOKEN_ENTITY_DOWNLOAD:                  'Download',
        GET_TOKEN_GET_INVOICE:                      12,
        GET_TOKEN_ENTITY_RESERVATION:               'Reservation',
        TOKEN_REASON_PAYMENT_RESERVATION:                       21,
        TOKEN_REASON_PAYMENT_DOWNLOAD:                       22,

        GET_RESERVATION_CUSTOM_DOCUMENT:                  24,

        DOCUMENT_VERIFIED_PAYMENT: 2,

        MEETING_STATUS_NOT_STARTED: 0,
        MEETING_STATUS_STARTED: 1,
        MEETING_STATUS_ENDED: 2,
        MEETING_STATUS_WAITING_FOR_RESOURCE: 3,

        DOCUMENT_MEDICAL_REPORT: 0,        // referto
        DOCUMENT_INVOICE: 1,               // fattura
        DOCUMENT_DIAGNOSTIC_IMAGE: 2,      // immagine
        DOCUMENT_GENERIC: 3,               // documento generico
        DOCUMENT_PRESCRIPTION: 4,          // prescrizione

        //reset psw from backoffice
        RESET_PASSWORD_FROM_BACKOFFICE: 1,

        //link mop inviato da ivr
        REGISTRATION_FROM_IVR: 3,

        CHECKIN_QR: 0,
        CHECKIN_BARCODE: 1,
        CHECKIN_NUMERIC: 2,

        ENABLED_CANCEL: 0,
        DISABLED_CANCEL: 1,
        DEFERRED_CANCEL: 2,

        ACTIVITY_NOT_REMOTE: 0,
        ACTIVITY_REMOTE_BASE: 1,
        ACTIVITY_REMOTE_MEETING: 2,

        AUTH_MODE_TRD111: 'from_trd111',
        AUTH_MODE_FROM_PAYMENT: 'from_payment',

        RESERVATION_NOT_DEFERRED: 0,

        CONTENT_TYPE_BINARY: 'application/octet-stream',
        CONTENT_TYPE_APPLICATION_PDF: 'application/pdf',
        CONTENT_TYPE_APPLICATION_PS: 'application/postscript',
        CONTENT_TYPE_APPLICATION_P7M: 'application/pkcs7-mime',
        CONTENT_TYPE_APPLICATION_X_P7M: 'application/x-pkcs7-mime',
        CONTENT_TYPE_APPLICATION_WORD: 'application/msword',
        CONTENT_TYPE_APPLICATION_ZIP: 'application/zip',
        CONTENT_TYPE_IMAGE_JPG: 'image/jpeg',
        CONTENT_TYPE_IMAGE_PNG: 'image/png',
        CONTENT_TYPE_IMAGE_TIFF: 'image/tiff',
        CONTENT_TYPE_IMAGE_BMP: 'image/bmp',
        CONTENT_TYPE_MP4: 'video/mp4',

        NOTE_OWNER_RESOURCE: 0,
        NOTE_OWNER_OPERATOR: 1,
        NOTE_OWNER_PATIENT: 2,

        AJAX_OFFLINE_REJECT: 'AJAX_OFFLINE_REJECT',

        PAYMENT_RESERVATION: 'reservation',
        PAYMENT_DOCUMENT: 'document',
        PAYMENT_REQUEST: 'request',
        PAYMENT_COMPULSORY: 2,
        PAYMENT_OPTIONAL: 1,
        PAYMENT_OFF: 0,
        OTU_SESSION_DURATION: 1200, // 20 minuti
        VCODE_MIN_WAITING_TIME_TO_RESEND_VCODE: 20, // 20 secondi

        MOP_LOCATION_EMBEDDED: 'embedded',
        MOP_LOCATION_LAUNCHER: 'launcher',
        MOP_LOCATION_STANDALONE: 'standalone',


        COM_MOP_GOOGLE_STATIC_MAP_API_KEY: 'AIzaSyBEdmmnJjGFBbCtj3bt4C33iDzTfPUv_XQ',
        NET_MOP_GOOGLE_STATIC_MAP_API_KEY: 'AIzaSyBd8rfVos1cq2dK2coFPqHeo_HrqQgiejw',
        ANDROID_MOP_GOOGLE_STATIC_MAP_API_KEY: 'AIzaSyAZK-Op_gosNUesWs6aRvpCkrRcUyOlydE',
        IOS_MOP_GOOGLE_STATIC_MAP_API_KEY: 'AIzaSyDvXVh6slfrAxZxYzm1H3P8FLegAN7jK04',

        COM_MOP_PLACES_API_KEY: 'AIzaSyBjs1WadaAOLseBsVNYmlcfnZr3DQXMaXo',
        NET_MOP_PLACES_API_KEY: 'AIzaSyDRu1PuL90KPGo62_MJS7Vyl5JEeBqGhkg',
        ANDROID_MOP_PLACES_API_KEY: 'AIzaSyB5DcY-Mxe9EbhpenouAXfOW0AM2fubtb8',
        IOS_MOP_PLACES_API_KEY: 'AIzaSyAFk4D7xn1U1qwfMZ7IjU8pM7l3XC0KcT8',

        COM_MOP_MAPS_JAVASCRIPT_API_KEY: 'AIzaSyATlk6r5uL7AJqP89qmBskmd7L3aJhoWvQ',
        NET_MOP_MAPS_JAVASCRIPT_API_KEY: 'AIzaSyD9aWK8MqqsU6dtTSjgp5F15omGU7yAWGM',
        ANDROID_MOP_MAPS_JAVASCRIPT_API_KEY: 'AIzaSyByAWr213BZztkwDGq-ZGxYU-qMmsIFKUY',
        IOS_MOP_MAPS_JAVASCRIPT_API_KEY: 'AIzaSyA6QQDrvGtvJF_KAkC3_N6Z4KX2-Gw_Ax4',

        COM_MOP_AREA_CRITERION_ADDRESS_API_KEY: 'AIzaSyBJHAJgTRWqzZTwcHb_AHlv9qc7qUOC6cM',
        NET_MOP_AREA_CRITERION_ADDRESS_API_KEY: 'AIzaSyDQn3XAclNEQ0_gpN16qiNjyyajkRbXm84',
        ANDROID_MOP_AREA_CRITERION_ADDRESS_API_KEY: 'AIzaSyDx_ttx1L1VvJIMtV6L1xF6wQgfdPkalbk',
        IOS_MOP_AREA_CRITERION_ADDRESS_API_KEY: 'AIzaSyDPhtt6Pa1YtgIuUjw5pfIEaLKC1XWhxAo',
        
        MOP_UPLOAD_DOCUMENT_ONLINE_MODE: "online",
        MOP_UPLOAD_DOCUMENT_OFFLINE_MODE: "offline",

        MOP_PRIVACY_TYPE_EXTERNAL: 2,

        MOP_PAYMENT_MODE_OFFLINE_CREDIT_CARD: 2,
        MOP_PAYMENT_MODE_OFFLINE_WIRE_TRANSFER: 3,
        MOP_PAYMENT_MODE_OFFLINE_CHECK: 4,
        MOP_PAYMENT_MODE_OFFLINE_CASH: 5,
        MOP_PAYMENT_MODE_OFFLINE_OTHER: 6,

        WEB_MEDIA_MIN_WIDTH: 699
    },

    fetch_fn: 'start_new_mop',

    from_mobile: false,
    from_mobile_app: false,

    rootFS: null,
    cookieEnabled: null,

    initialize: function() {
      this.from_mobile = this.isMobile();
      this.from_mobile_app = Utilities.isMobileApp();

      if (window.MOP_globals.customerConfig && window.MOP_globals.customerConfig.version) {
        this.set("version", window.MOP_globals.customerConfig.version, {
          silent: true
        });
      }

      /*
       * Getting file system root from mobile app in phonegap
       */
      // disattivato plugin cordova-plugin-file per interfferenze con evento deviceready
      if (this.from_mobile_app && false) {
        var self = this;
        var gotFS = function(fileSystem) {
          // save the file system for later access
          self.rootFS = fileSystem.root;
        };
        window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;
        document.addEventListener("deviceready", function() {
          window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, gotFS, null);
        }, false);
      }
    },

    defaults: {
      version: "1.1",
      dbName: window.MOP_globals.dbName,
      hostname: window.MOP_globals.hostname,
      restapi: {
        subpath: 'api/v3'
      },
      ajax: {
        destination: 'json.php',
        mode: 'GET',
        server_cache: false, // Default Server Cache Disabled
        cache: false, // Default Client Cache Disabled
        expires: 3600, // Default Client Cache Expiration
        polling: 300, // Default Polling 5 min
        cache_without_filters: [],
        recache: false,
        fns: {
          start_new_mop: {
            cache: false, //Client Cache Enabled
            // La fn start_new_mop viene eseguita solo per mobile
            // app per questo togliamo l'expiration inquanto vogliamo
            // che la prima chiamata venga recuperata dalla cache
            // mentre le successive vengano fatte in background.
            //expires: 		false, //Al momento per sicurezza non andiamo più a farla in background
            polling: 300 //Polling 5 min.
          },
          insurances: {
            cache: true, //Client Cache Enabled
            cache_without_filters: ['sessionid'],
            recache: true
          },
          typologies: {
            cache: true, //Client Cache Enabled
            cache_without_filters: ['sessionid'],
            recache: true
          },
          activities: {
            cache: true, //Client Cache Enabled
            cache_without_filters: ['sessionid'],
            recache: true
          },
          areas: {
            cache: true, //Client Cache Enabled
            cache_without_filters: ['sessionid'],
            recache: true
          },
          resources: {
            cache: true, //Client Cache Enabled
            cache_without_filters: ['sessionid'],
            recache: true
          },
          workingtime: {
            cache: true, //Client Cache Enabled
            cache_without_filters: ['sessionid'],
            recache: true
          },
          provinces: {
            cache: true, //Client Cache Enabled
            cache_without_filters: ['sessionid'],
            recache: true
          },
          availabilities: {
            cache: false, //Client Cache Disabled
            expires: 3600 //seconds => 1 hour
          },
          reservations: {
            cache: false, //Client Cache Disabled
            expires: 3600, //seconds => 1 hour
            polling: 31536000 //Polling 1 year, perchè l'invalidazione e la rigenerazione della cache è gestita lato server
          },
          check_recache: {
            cache: false, //Client Cache Enabled
            polling: 300 //Polling 5 min.
          }
          // Rest routes.
          /*,episodes: {
              cache: true,            //Client Cache Enabled
              // cache_without_filters:  ['sessionid'],
              // recache: true
          }*/
        }
      },
      //////////////////////////////////////////////////////////////
      //sessionid: null,
      //logged_user: null,
      //////////////////////////////////////////////////////////////
      lang: null,
      langs: {},
      instance: {
        configs: {},
        schedule_info: '',
        members: {}
      }
    },

    areCookiesEnabled: function() {

      if (this.cookieEnabled === null) {
        document.cookie = "testcookie";
        this.cookieEnabled = (document.cookie.indexOf("testcookie") != -1) ? true : false;
      }

      return this.cookieEnabled;
    },

    isHttpURL: function(url) {
      return url.indexOf('http') !== -1;
    },

    getProtocol: function getProtocol() {
      return window.location.href.split('//')[0];
    },

    // FIXME [mattia]: sono stati settati indirizzi locali statici
    isLocalHost: function isLocalHost() {
      var hostname = this.get('hostname');

      return (hostname.indexOf('localhost') >= 0 ||
        hostname.indexOf('192.168.33.10') >= 0 ||
        hostname.indexOf('213.172.48.245') >= 0 ||
        hostname.indexOf('127.0.0.1') >= 0 ||
        hostname.indexOf('192.168.1.224') >= 0); // Ambiente locale di sviluppo di @Pierpaolo Vanni
    },

    /** getStaticPath
      * * Si occupa di restituire il path corretto da cui ricavare le risorse
      * @returns {string} Path corretto (cdn se siamo in produzione)
    */
    getStaticPath: function getStaticPath() {
      // Se siamo in app tutte le risorse sono già state salvate localmente, quindi restituiamo stringa vuota
      if(Utilities.isMobileApp()) return "";

      // Se siamo su beta o su net restituiamo l'origin della pagina stessa
      if(!isProduction() && !isTestProduction()) return `${window.location.origin}/mop/`;

      // Se siamo in produzione restituiamo il cdn
      return "https://d2q7bvpn94ug9o.cloudfront.net/mop/";
    },

    isSecureConnection: function() {
      if (this.isInIFrame()) {
        var parentLocation = this.get('parent_location');

        return (parentLocation.indexOf('https:') >= 0);
      } else {
        return (window.location.protocol == 'https:');
      }
    },

    getRightProtocol: function() {
      return this.isSecureConnection() ? this.constants.SECURE_PROTOCOL : this.constants.UNSECURE_PROTOCOL;
    },

    getMopImageBaseUrl: function() {
      if(Utilities.isMobileApp()){
        return `assets/img`;
      }
      return `${getBaseUrl()}/mop/assets/img`;
    },

    isAllowedGuestRegistration: function() {

      var notify = this.getInstanceConfig('notify');
      var allowGuestRegistration = this.getInstanceConfig('allowGuestRegistration');
      var passwordsSent = this.getInstanceConfig('passwordsSent');
      var lastSyncTimestamp = this.getInstanceConfig('lastSyncTimestamp');
      lastSyncTimestamp = Utilities.empty(lastSyncTimestamp) ? 0 : lastSyncTimestamp;
      var last_sync_interval = Date.now() - parseInt(lastSyncTimestamp);

      var memo_condition_met =
        (notify == this.constants.MESSAGES_ENABLED ||
          (notify != this.constants.MESSAGES_ENABLED && last_sync_interval < this.constants.PASSWORD_SINCRO_OFF_SEND_TOLERANCE));

      if (!Utilities.empty(allowGuestRegistration) && passwordsSent < this.constants.PASSWORD_SEND_TOLERANCE && memo_condition_met) {
        allowGuestRegistration = true;
      }

      return allowGuestRegistration;
    },

    getInstanceShowDefaultArea: function() {
      return this.get('instance').show_default_area;
    },

    getInstanceScheduleInfo: function() {
      return this.get('instance').schedule_info;
    },

    isSearchByPrescriptionCodeEnabled: function(MOP) {
      return !MOP.Utilities.empty(this.get('instance').search_by_prescription_code_enabled);
    },

    getInstanceConfig: function(key) {

      if (window.MOP_globals.customerConfig && window.MOP_globals.customerConfig.instanceConfig && window.MOP_globals.customerConfig.instanceConfig[key] !== undefined) {
        return window.MOP_globals.customerConfig.instanceConfig[key];
      }

      return this.get('instance').configs[key];
    },

    isDossierIntegrated: function(MOP) {
      return !MOP.Utilities.empty(this.get('dossier')['integrated']);
    },

    getAccessPointConfig: function(key) {
      return this.get('accesspoint')[key];
    },

    isPortalInstance: function() {
      return (this.getInstanceConfig('isPortal') == 1);
    },

    isMemberInstance: function() {
      return (this.getInstanceConfig('isMember') == 1);
    },

    getCurrencySymbol: function() {
      return this.getInstanceConfig('currencySymbol');
    },

    isSearchWidgetEnabled: function(entities) {
      var entities_config = this.getInstanceConfig(entities);
      
      if (entities_config == undefined) return false;
      
      var is_enabled = entities_config['visibility'] || 1;
      //          switch (entities){
      //	            case "areas":
      //	            	if( window.MOP_globals.page == 'search_area'/*	||
      //	            		this.has('areaid')							||
      //	            		this.has('area_legacyid')					||
      //	            		this.has('areaTitle')*/
      //	            	){
      //	            		is_enabled = 1;
      //	            	}
      //	                break;
      //	            case "resources":
      //	            	if( window.MOP_globals.page == 'search_resource'/*	||
      //		            	this.has('resourceid')							||
      //		            	this.has('resource_legacyid')					||
      //		            	this.has('resourceName')*/
      //		            ){
      //	            		is_enabled = 1;
      //		            }
      //	                break;
      //	            default:
      //	            	break;
      //	        }
      return (parseInt(is_enabled) > 0);
    },

    // Al momento utilizzato solo per le assicurazioni
    getSearchWidgetOrder: function(entities) {
      var entities_config = this.getInstanceConfig(entities);
      var order = entities_config ? parseInt(entities_config['order']) : 0;
      return order;
    },

    isSearchWidgetCompulsory: function(entities) {
      var entities_config = this.getInstanceConfig(entities);
      var is_compulsory = entities_config && entities_config['default'];
      return (parseInt(is_compulsory) > 0);
    },

    isCompulsory: function(value) {
      return value == this.constants.FIELD_COMPULSORY;
    },

    isProvinceWidgetEnabled: function() {
      // var currentPage = window.MOP_globals.page;

      if (this.get('template') == this.constants.MOP_ACTIVITIES_PROVINCES) {
        return true;
      }
      /*else if (currentPage == this.configurationTemplateToSearchPage(this.getDefaultSearchPage())) {
       if (this.getDefaultSearchPage() == this.constants.MOP_ACTIVITIES_PROVINCES) {
       return true;
       }
       } else if (currentPage == this.configurationTemplateToSearchPage(this.getSecondSearchPage())) {
       if (this.getSecondSearchPage() == this.constants.MOP_ACTIVITIES_PROVINCES) {
       return true;
       }
       }*/

      return false;
    },

    isPrescriptionPriorityConvenzionato: function() {
      var mopPriorityOptions = this.getInstanceConfig('mopPriorityOptions');
      return !mopPriorityOptions ? true : false;
    },

    isActivateLocalizationResearch: function() {
      var is_activate = this.getInstanceConfig('activateLocalizationResearch');
      return (parseInt(is_activate) > 0);
    },

    isServerCacheEnabled: function isServerCacheEnabled() {
      return this.getInstanceConfig('wantsRecache');
    },

    isServerSearchReservationsCacheEnable: function isServerSearchReservationsCacheEnable() {

      return this.getInstanceConfig('searchReservationsCache');
    },

    isSincroInstance: function isSincroInstance() {
      return !!parseInt(this.getInstanceConfig('legacySync'), 10);
    },

    isInternalMop: function isInternalMop() {
      return this.has('fromApp') ? Boolean(this.get('fromApp')) : false;
    },

    isLanzaderaMop: function isLanzaderaMop() {
      return this.has('fromLanzadera') ? Boolean(this.get('fromLanzadera')) : false;
    },

    isAllowMultipleReservationsEnabled: function isAllowMultipleReservationsEnabled() {
      if (this.has('mopAllowMultipleReservations')) {
        return (parseInt(this.get('mopAllowMultipleReservations')) > 0);
      }
      return (parseInt(this.getInstanceConfig('mopAllowMultipleReservations')) > 0);
    },

    allowFamilies: function() {
      var allow_families = this.getInstanceConfig('allowFamilies');
      return (parseInt(allow_families) == 1);
    },

    getInstanceMemberConfig: function(member, key) {
      return this.get('instance').members[member][key];
    },

    getAjaxUrl: function() {
      var hostname = this.get('hostname'),
        protocol = '';

      if (this.isLocalHost()) {
        protocol = 'http://';
      } else if (Utilities.isIE() == 8 || Utilities.isIE() == 9) {
        protocol = this.getProtocol() + '//';
      } else {
        protocol = 'https://';
      }

      return protocol + hostname + '/' + this.get('ajax').destination;
    },

    /**
     * Restistuisce i default params da aggiungere alle chiamate ajax
     * @return {Object}
     */
    getAjaxDefaultParams: function(MOP, removeSessionID) {

      var params = {};

      params.dbName = this.get('dbName');
      params.version = this.get('version');
      params.lang = this.get('lang');
      
      if (!removeSessionID) params.sessionid = MOP.getSessionid();

      if (this.has('fromApp')) {
        params.fromApp = this.get('fromApp');
      }
      
      // Ammettiamo anche solo la presenza del parametro nocache perchè anche il server ammette solo la presenza chiave senza valutare il valore
      if (this.has('nocache') || (MOP.Utilities.existParamInQueryString(window.location.search.substr(1), "nocache") == true)) {
        params.nocache = 1
      }

      if (MOP.isKioskMop()) {
        params.kiosk = this.get('kiosk');
      }

      //TODO => sviluppare una logica generica per accettare qualunque tipo di config in querystring
      if (this.has('mopAllowMultipleReservations')) {
        params.mopAllowMultipleReservations = this.get('mopAllowMultipleReservations');
      }

      if (Utilities.isMobileApp()) {
        params.client = this.constants.CLIENT_APP_MOBILE;
      } else if (this.isMobile()) {
        params.client = this.constants.CLIENT_BROWSER_MOBILE;
      } else {
        params.client = this.constants.CLIENT_BROWSER_DESKTOP;
      }

      if (MOP.isAuthModeEnabled()) {
        params.auth_mode = MOP.getLoggedUserDataField('auth_mode');
      }

      return params;
    },

    getAjaxDefaultParamsV3: function (MOP) {

      var params = {};

      params.version = this.get('version');
      params.lang = this.get('lang');

      if (MOP.isKioskMop()) {
        params.kiosk = this.get('kiosk');
      }
      
      // Ammettiamo anche solo la presenza del parametro nocache perchè anche il server ammette solo la presenza chiave senza valutare il valore
      if (MOP.config.has('nocache') || (MOP.Utilities.existParamInQueryString(window.location.search.substr(1), "nocache") == true)) {
        params.nocache = 1;
      }

      if (MOP.config.has('fromApp')) {
        params.fromApp = MOP.config.get('fromApp');
      }else{
        params.application = MOP.Utilities.isMobileApp() ? 'MOP_APP' : 'MOP';
      }

      if (MOP.Utilities.isMobileApp()) {
        params.client = MOP.config.constants.CLIENT_APP_MOBILE;
      } else if (MOP.config.isMobile()) {
        params.client = MOP.config.constants.CLIENT_BROWSER_MOBILE;
      } else {
        params.client = MOP.config.constants.CLIENT_BROWSER_DESKTOP;
      }

      if (MOP.isAuthModeEnabled()) {
        params.auth_mode = MOP.getLoggedUserDataField('auth_mode');
      }

      return params;

    },

    getAjaxServerCache: function(fn) {
      if (this.has('nocache')) {
        return false;
      }
      var ajax_fns = this.get('ajax').fns;
      if (_.has(ajax_fns, fn)) {
        var server_cache = ajax_fns[fn]['server_cache'];
      } else {
        var server_cache = this.get('ajax').server_cache;
      }
      return server_cache;
    },

    getAjaxCache: function(fn) {
      var ajax_fns = this.get('ajax').fns;
      if (_.has(ajax_fns, fn)) {
        var cache = ajax_fns[fn]['cache'];
      } else {
        var cache = this.get('ajax').cache;
      }
      return cache;
    },

    getAjaxExpires: function(fn) {
      var ajax_fns = this.get('ajax').fns;

      if (_.has(ajax_fns, fn)) {
        if (ajax_fns[fn]['recache'] && this.isServerCacheEnabled()) {
          return false;
        }

        var expires = ajax_fns[fn]['expires'];
      } else {
        var expires = this.get('ajax').expires;
      }
      return expires;
    },

    getAjaxWithoutFilters: function(fn, isAdminLoggedIn) {
      var ajax_fns = this.get('ajax').fns;
      var cache_without_filters = [];

      if (_.has(ajax_fns, fn) && !isAdminLoggedIn) {
        cache_without_filters = ajax_fns[fn]['cache_without_filters'];
      } else {
        cache_without_filters = this.get('ajax').cache_without_filters;
      }

      return _.isArray(cache_without_filters) ? cache_without_filters.concat(['nocache']) : ['nocache'];
    },

    getAjaxPolling: function getAjaxPolling(fn) {
      var ajax_fns = this.get('ajax').fns;
      if (_.has(ajax_fns, fn)) {
        var polling = ajax_fns[fn]['polling'];
      } else {
        var polling = this.get('ajax').polling;
      }
      return polling;
    },

    getDefaultSearchEntity: function () {
      //Qui potrebbe tornare anche la pagina prescriptions che al momento non gestiamo
      var search_page = this.getDefaultSearchPage();

      return search_page.replace("search_", "");
    },

    getSecondSearchEntity: function () {
      //Qui potrebbe tornare anche la pagina prescriptions che al momento non gestiamo
      var search_page = this.getSecondSearchPage();

      return search_page.replace("search_", "");
    },

    getDefaultSearchPage: function() {
      //Se forziamo in querystring un template => allora vogliamo sempre visualizzare quello
      if (this.has('forceTemplate')) {
        var mopConfigurationTemplate = this.get('forceTemplate');
        //Altrimenti lo recuperiamo dalla configurazione dell'istanza	
      } else {
        var mopConfigurationTemplate = this.getInstanceConfig('mopConfigurationTemplate');
      }

      return this.configurationTemplateToSearchPage(mopConfigurationTemplate);
    },

    getSecondSearchPage: function() {
      var mopSecondConfigurationTemplate = this.getInstanceConfig('mopSecondConfigurationTemplate');
      if (mopSecondConfigurationTemplate != "") {
        return this.configurationTemplateToSearchPage(mopSecondConfigurationTemplate);
      } else {
        return null;
      }
    },

    getPrescriptionCode: function() {
      var prescriptionPolicy = this.getInstanceConfig('prescriptionPolicy');
      return prescriptionPolicy['mopCode'];
    },

    getPrescriptionPriorityValue: function() {
      var prescriptionPolicy = this.getInstanceConfig('prescriptionPolicy');
      return prescriptionPolicy['mopPriority'];
    },

    getPrescriptionDoctor: function() {
      var prescriptionPolicy = this.getInstanceConfig('prescriptionPolicy');
      return prescriptionPolicy['mopDoctor'];
    },

    getPrescriptionSummaryValue: function() {
      var prescriptionPolicy = this.getInstanceConfig('prescriptionPolicy');
      return prescriptionPolicy['mopSummary'];
    },

    configurationTemplateToSearchPage: function(configuration_template) {
      switch (configuration_template) {
        case this.constants.MOP_PRESCRIPTION_CONFIGURATION:
          var page = 'prescriptions';
          break;

        case this.constants.MOP_AREAS_CONFIGURATION:
        case this.constants.MOP_AREAS_LOCALIZATIONSEARCH: //FIXME[GIO]
        case this.constants.MOP_AREAS_REGIONS: //FIXME[GIO]
        case this.constants.MOP_AREAS_REGIONS_PROVINCES: //FIXME[GIO]
          var page = 'search_area';
          break;

        case this.constants.MOP_RESOURCES_CONFIGURATION:
        case this.constants.MOP_RESOURCES_LOCALIZATIONSEARCH: //FIXME[GIO]
        case this.constants.MOP_RESOURCES_BEFORE_INSURANCES: //FIXME[GIO]
          var page = 'search_resource';
          break;

        case this.constants.MOP_ACTIVITIES_CONFIGURATION:
        case this.constants.MOP_ACTIVITIES_REGIONS: //FIXME[GIO]
        case this.constants.MOP_ACTIVITIES_REGIONS_PROVINCES: //FIXME[GIO]
        case this.constants.MOP_ACTIVITIES_PROVINCES: //FIXME[GIO]
        default:
          var page = 'search_activity';
          break;
      }
      return page;
    },

    getAvailabilitiesMergerType: function(MOP, forceShowResources) {

      var merger_type = this.getInstanceConfig('availabilitiesMergerType');
      merger_type = merger_type ? merger_type : this.constants.MERGER_TYPE_DEFAULT;

      // NB. Ricalcoliamo sempre a runtime il merger type se l'istanza
      // è settata con uno di questi che rappresentano i raggruppamenti
      // di default
      if (merger_type == MOP.config.constants.MERGER_TYPE_DEFAULT ||
          merger_type == MOP.config.constants.MERGER_TYPE_RESOURCES ||
          merger_type == MOP.config.constants.MERGER_TYPE_RESOURCES_AREAS ||
          merger_type == MOP.config.constants.MERGER_TYPE_AREAS
      ) {

        //Forziamo quindi il merger_type in base alla visibilità dell'istanza
        if (this.isSearchWidgetEnabled('areas') && this.isSearchWidgetEnabled('resources')) {
          merger_type = this.constants.MERGER_TYPE_RESOURCES_AREAS;
        } else if (this.isSearchWidgetEnabled('areas')) {
          merger_type = this.constants.MERGER_TYPE_AREAS;
        } else {
          merger_type = this.constants.MERGER_TYPE_RESOURCES;
        }

        //NB => In caso la visibilità di sedi o risorse non fosse permessa a causa di una entità selezionata
        //      configurata per tal motivo => dobbiamo allineare il merger_type per non trovarci slot
        //      raddoppiati proprio a causa dell'invisibilità dell'informazione su sede o risorsa
        var showResources = MOP.isSearchEntitiesVisible('resources');
        if (forceShowResources != null && forceShowResources != undefined) {
          showResources = forceShowResources;
        }

        if (merger_type == this.constants.MERGER_TYPE_RESOURCES_AREAS) {
          if (!MOP.isSearchEntitiesVisible('areas')) {
            merger_type = this.constants.MERGER_TYPE_RESOURCES;
          } else if (!showResources) {
            merger_type = this.constants.MERGER_TYPE_AREAS;
          }
        }

      }

      return merger_type;
    },

    getAvailabilitiesGroupByKey: function(mergerType) {
      var availabilitiesGroupByKey = '';

      switch (mergerType) {
        case this.constants.MERGER_TYPE_AREAS:
          availabilitiesGroupByKey = 'areaid';
          break;
        case this.constants.MERGER_TYPE_RESOURCES_AREAS:
          availabilitiesGroupByKey = 'resourceid_areaid';
          break;
        default:
          availabilitiesGroupByKey = 'resourceid';
          break;
      }

      return availabilitiesGroupByKey;
    },

    instanceWantsFiveAvailabilities: function(merger_type) {
      return (merger_type == this.constants.MERGER_TYPE_DEFAULT || merger_type == this.constants.MERGER_TYPE_INSURANCES);
    },

    instanceWantsWeekAvailabilities: function(merger_type) {
      return !(this.instanceWantsFiveAvailabilities(merger_type));
    },

    isDesktop: function() {
      return !this.isMobile() && !Utilities.isMobileApp() && !Utilities.isMobileNavigator();
    },

    isMobileNavigator: function() {
      return Utilities.isMobileNavigator();
    },

    isMobileApp: function() {
      return Utilities.isMobileApp();
    },
    isAndroidDevice: function() {
      return Utilities.isAndroidDevice();
    },
    isAppleDevice: function() {
      return Utilities.isAppleDevice();
    },
    isIpad: function() {
      return Utilities.isIpad();
    },
    isUndefined: function() {
      return Utilities.isUndefined();
    },
    isOpera: function() {
      return Utilities.isOpera();
    },
    isFirefox: function() {
      return Utilities.isFirefox();
    },
    isSafari: function() {
      return Utilities.isSafari();
    },
    isChrome: function() {
      return Utilities.isChrome();
    },
    isIE: function() {
      return Utilities.isIE();
    },
    isMobile: function() {
      // In app forziamo sempre true perchè vogliamo sempre le logiche mobile
      if(this.isMobileApp()) return true;
      return $(window).width() < this.constants.WEB_MEDIA_MIN_WIDTH;
    },

    isLoginFirst: function() {
      return (Number(this.getInstanceConfig('loginFirst')) == 1 || Number(this.get('loginFirst')) == 1);
    },

    // TODO Pierpaolo: Andrà tolto con il passaggio al nuovo dossier finale
    isParentsDossierEnabledWithManualUpdate: function (MOP) {
      return MOP.config.getInstanceConfig('parentsDossierEnabled') == 2;
    },

    // TODO Pierpaolo: Andrà tolto con il passaggio al nuovo dossier finale
    isDossierDropdownEnabled: function (MOP) {
      return !MOP.Utilities.empty(MOP.config.getInstanceConfig('parentsDossierEnabled'));
    },

    reverseOrderWay: function(MOP, way) {
      if (way == this.constants.ORDER_WAY_ASC) {
        return this.constants.ORDER_WAY_DESC;
      } else {
        return this.constants.ORDER_WAY_ASC;
      }
    },

    // file system methods from mobile app (phonegap)
    getFileSystemRootNativeURL: function() {
      if (this.rootFS) {
        return this.rootFS.toURL(); //example ->  file:///Users/myuser/Library/Application%20Support/iPhone%20Simulator/7.1/Applications/1717CB5F-4032-45C7-8CA2-342502447F36/Documents/
        //return this.rootFS.nativeURL;
      }
      return null;
    },
    getFileSystemRootInternalURL: function() {
      if (this.rootFS) {
        return this.rootFS.toInternalURL(); //example ->  cdvfile://localhost/persistent/
      }
      return null;
    },

    // geolocation methods
    getGeolocator: function() {
      return navigator.geolocation;
    },

    // TODO [Mattia] - da rifattorizzare, confusa e vengono restituiti Tipi
    // di dato diversi.
    geolocatorFactory: (function() {
      var _this = this;
      var navGeolocator;
      var deferredPosition = new $.Deferred();
      var deferredPlaceData = new $.Deferred();
      var geolocator;
      var position;
      var placeData;
      var language;
      var config;

      // private methods
      var setPosition = function setPosition(pos) {
        if (pos) {
          position = pos;
        }

        deferredPosition.resolve(position);
      };

      /**
       * @deprecated a favore di Utilities.loadGoogleAPI
       */
      var _loadGoogleMapsAPI = function _loadGoogleMapsAPI(MOP, lang) {
        var googleAPIDeferred = new $.Deferred();

        if (window.google) {
          googleAPIDeferred.resolve();
        } else {
          Utilities.loadGoogleAPI(MOP, lang)
            .done(function() {
              googleAPIDeferred.resolve();
            });
        }

        return googleAPIDeferred;
      };

      var setPlaceData = function(MOP, position) {
        if (!placeData) {
          var getPlaceData = function getPlaceData() {
            var googleGeocoder = new window.google.maps.Geocoder();
            var googleLatlng = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);

            googleGeocoder.geocode({
                latLng: googleLatlng
              },
              function(results, status) {
                if (status == window.google.maps.GeocoderStatus.OK) {
                  if (results[1]) {
                    placeData = results[1].formatted_address;
                    deferredPlaceData.resolve(placeData);
                  } else {
                    console.log('No results found');
                  }
                } else {
                  console.log('Geocoder failed due to: ' + status);
                }
              }
            );
          };

          Utilities.loadGoogleAPI(MOP, language)
            .done(function() {
              getPlaceData.call();
            });
        } else {
          deferredPlaceData.resolve(placeData);
        }
      };

      // singleton object
      var init = function init() {
        navGeolocator = navigator.geolocation;

        return {

          getPosition: function() {
            return position;
          },

          loadCoordinates: function loadCoordinates() {
            if (navGeolocator) {
              if (!position) {
                if (window.MOP_globals.fromMobileApp) {
                  document.addEventListener('deviceready', function() {
                    navGeolocator.getCurrentPosition(
                      function(coords) {
                        setPosition(coords);
                      },
                      function() {
                        deferredPosition.reject();
                      }, {
                        enableHighAccuracy: true
                      }
                    );
                  }, false);
                } else {
                  navGeolocator.getCurrentPosition(
                    function(coords) {
                      setPosition(coords);
                    },
                    function(error) {
                      deferredPosition.reject(error);
                    }, {
                      enableHighAccuracy: true
                    }
                  );
                }
              } else {
                setPosition();
              }
            } else {

              // non esiste la geolocation
              console.log('Non esiste la geolocalizzazione');
            }

            return deferredPosition;
          },

          loadPlaceData: function loadPlaceData(MOP, lang) {
            language = lang;

            if (!placeData) {
              if (!position) {
                this.loadCoordinates()
                  .done(function(coords) {
                    setPosition(coords);
                    setPlaceData(MOP, coords);
                  })
                  .fail(function() {
                    deferredPlaceData.reject();
                  });
              } else {
                setPlaceData(MOP, position);
              }
            } else {
              setPlaceData(MOP);
            }

            return deferredPlaceData;
          },

          loadGoogleMapsAPI: Utilities.loadGoogleAPI,
          loadGooglePlacesAPI: Utilities.loadGooglePlacesAPI,
          loadGoogleCriterionAddressAPI: Utilities.loadGoogleCriterionAddressAPI
        }
      };

      // factory method
      return function() {
        //meoization
        if (!geolocator) {
          geolocator = init;
        }
        return geolocator();
      }
    }()),


    // Vcode utilities
    isValidationRequired: function isValidationRequired() {
      return parseInt(this.getInstanceConfig('validation_required'), 10) === 1;
    },

    isPatientValidationConfigEnabled: function isPatientValidationConfigEnabled() {
      return parseInt(this.getInstanceConfig('validation_required'), 10) >= 0;
    },

    isValidationInhibited: function isValidationInhibited() {
      return parseInt(this.getInstanceConfig('validation_required'), 10) === 2;
    },
    
    // TODO Da eliminare, funzione non più utilizzata
    getAlertTypeForException: function getAlertTypeForException(exception) {
      
      var type = "";
      
      switch (exception) {
        case 'TUOTEMPO_SENDING_MESSAGE_ERROR':
          type = "warning";
          break;
        default:
          type = "danger";
      }
      
      return type;
    },
    // TODO Da eliminare, funzione non più utilizzata

    canPatientUploadDocument: function canPatientUploadDocument (MOP) {
      return !MOP.Utilities.empty(this.getInstanceConfig('dossierPatientDownloadUploads')['enabled'])
    },
    
    canPatientDownloadDocuments: function canPatientDownloadDocuments (MOP) {
      return !MOP.Utilities.empty(this.getInstanceConfig('dossierPatientShowDownloadOnReservation'))
    },

    canOperatorUploadDocuments: function canOperatorUploadDocuments (MOP) {
      return !MOP.Utilities.empty(MOP.getLoggedUserDataField("is_operator_allowed_to_upload_documents_while_booking"))
    },

    canResourceSeeDocument: function canResourceSeeDocument (MOP) {
      if (MOP.isBaseResourceLoggedIn() || 
        !MOP.Utilities.empty(MOP.getLoggedUserDataField("is_operator_allowed_to_manager_dossier_and_privacy"))) {
        return true;
      }

      return false;
    },

    canAdvancedResourceSeeRelatedActions: function canAdvancedResourceSeeRelatedActions(MOP, resourceid) {

      if (MOP.getLoggedUserId() !== resourceid &&
        MOP.isAdvancedResourceLoggedIn() &&
        MOP.Utilities.empty(MOP.getLoggedUserDataField("is_advanded_resource_use_related_resource_actions"))) {
        return false;
      }

      return true;

    },

    getGoogleStaticMapAPIKey: function (MOP) {
      
      if (MOP.Utilities.isMobileApp(true)) {
        if (MOP.Utilities.isAppleDevice()) {
          return MOP.constants.IOS_MOP_GOOGLE_STATIC_MAP_API_KEY;
        } else if (MOP.Utilities.isAndroidDevice()) {
          return MOP.constants.ANDROID_MOP_GOOGLE_STATIC_MAP_API_KEY;
        }
      }

      return !isNet() ? MOP.constants.COM_MOP_GOOGLE_STATIC_MAP_API_KEY : MOP.constants.NET_MOP_GOOGLE_STATIC_MAP_API_KEY;

    },

    getGoogleMapsJavascriptAPIKey: function (MOP) {
      
      if (MOP.Utilities.isMobileApp(true)) {
        if (MOP.Utilities.isAppleDevice()) {
          return MOP.constants.IOS_MOP_MAPS_JAVASCRIPT_API_KEY;
        } else if (MOP.Utilities.isAndroidDevice()) {
          return MOP.constants.ANDROID_MOP_MAPS_JAVASCRIPT_API_KEY;
        }
      }

      return !isNet() ? MOP.constants.COM_MOP_MAPS_JAVASCRIPT_API_KEY : MOP.constants.NET_MOP_MAPS_JAVASCRIPT_API_KEY;

    },


    getGooglePlacesCriteriaAPIKey: function (MOP) {
      
      if (MOP.Utilities.isMobileApp(true)) {
        if (MOP.Utilities.isAppleDevice()) {
          return MOP.constants.IOS_MOP_AREA_CRITERION_ADDRESS_API_KEY;
        } else if (MOP.Utilities.isAndroidDevice()) {
          return MOP.constants.ANDROID_MOP_AREA_CRITERION_ADDRESS_API_KEY;
        }
      }

      return !isNet() ? MOP.constants.COM_MOP_AREA_CRITERION_ADDRESS_API_KEY : MOP.constants.NET_MOP_AREA_CRITERION_ADDRESS_API_KEY;

    },


    getGooglePlacesAPIKey: function (MOP) {
      
      if (MOP.Utilities.isMobileApp(true)) {
        if (MOP.Utilities.isAppleDevice()) {
          return MOP.constants.IOS_MOP_PLACES_API_KEY;
        } else if (MOP.Utilities.isAndroidDevice()) {
          return MOP.constants.ANDROID_MOP_PLACES_API_KEY;
        }
      }

      return !isNet() ? MOP.constants.COM_MOP_PLACES_API_KEY : MOP.constants.NET_MOP_PLACES_API_KEY;

    },

    isExternalUserAllowed: function(MOP, service, param) {
      const config = MOP.config.getInstanceConfig('externalUserCertification');
      
      if(parseInt(config.enabled, 10) === 0) return true;
      
      return parseInt(config.services[service][param].enabled, 10) === 1;
    },


    isOtuFirst: function(MOP) {
     return !MOP.Utilities.empty(MOP.querystring.otufirst);
    },

    isOtuPost: function(MOP) {
      return !MOP.Utilities.empty(MOP.querystring.otupost);
     },

    getInclusionType: function(MOP) {

      let inclusionType = null

      if (this.isInIFrame() && !MOP.config.isNewLauncher(MOP)) {
        if (MOP.Utilities.empty(MOP.config.isOldLauncher(MOP))) {
          inclusionType = MOP.constants.MOP_LOCATION_EMBEDDED;
        } else {
          inclusionType =  MOP.constants.MOP_LOCATION_LAUNCHER;
        }
      } else {
        inclusionType = MOP.constants.MOP_LOCATION_STANDALONE;
      }

      return inclusionType
    },

    canChangePatient: function(MOP) {
      return MOP.Utilities.empty(MOP.querystring.userid);
    },
    
    // TOREMOVE: integrated-custom-button
    isOldCustomButton: function (MOP) {
      const config = MOP.config.getInstanceConfig("reservationCustomButton");

      // Se il config è un oggetto allora è attiva la nuova struttura 
      const isObj = typeof config === 'object' &&
        config !== null &&
        !Array.isArray(config);

      return !isObj;
    },

    isInIFrame: function () {
      return (this.has('parent_location'));
    },

    isOldLauncher: function (MOP) {
      return !MOP.Utilities.empty(MOP.config.getMOPLauncherOrigin(MOP));
    },

    getMOPLauncherOrigin: function (MOP) {
      return MOP.querystring['mopLauncher'];
    },

    isMOPLauncherFromWorkingList: function (MOP) {
      if (MOP.config.isOldLauncher(MOP)) {
        if (MOP.config.getMOPLauncherOrigin(MOP) === MOP_LAUNCHER_ORIGIN_WORKING_LIST) {
          return true;
        }
      }
      return false;
    },

    isMOPLauncherFromVideoConsultation: function (MOP) {
      if (MOP.config.isOldLauncher(MOP)) {
        if (MOP.config.getMOPLauncherOrigin(MOP) === MOP_LAUNCHER_ORIGIN_VIDEO_CONSULTATION) {
          return true;
        }
      }
      return false;
    },

    isMOPLauncherFromMobileEmbedded: function (MOP) {
      if (MOP.config.isOldLauncher(MOP)) {
        if (MOP.config.getMOPLauncherOrigin(MOP) === MOP_LAUNCHER_ORIGIN_CUSTOMER_EMBEDDED_FROM_MOBILE) {
          return true;
        }
      }
      return false;
    },
    
    isNewLauncher: function () {
      return (this.has('new_launcher'));
    }
    
  });