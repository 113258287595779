var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "tt-container";
},"3":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-container\"";
},"5":function(container,depth0,helpers,partials,data) {
    return "tt-columns";
},"7":function(container,depth0,helpers,partials,data) {
    return "tt-column is-8 is-offset-2";
},"9":function(container,depth0,helpers,partials,data) {
    return "";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"tt-action tt-no-margin "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_payment") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":63},"end":{"line":21,"column":196}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||container.hooks.helperMissing).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"user_payment") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"!=","paypal",{"name":"ifCond","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":22,"column":30},"end":{"line":30,"column":41}}})) != null ? stack1 : "")
    + "                            </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "card-number-"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"user_payment") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"!=","paypal",{"name":"ifCond","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":21,"column":95},"end":{"line":21,"column":189}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"user_payment") : depth0)) != null ? lookupProperty(stack1,"card_type") : stack1), depth0));
},"15":function(container,depth0,helpers,partials,data) {
    return "paypal";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "                                <i class=\"tt-action__icon "
    + ((stack1 = (lookupProperty(helpers,"getCreditCardIcon")||(depth0 && lookupProperty(depth0,"getCreditCardIcon"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"user_payment") : depth0)) != null ? lookupProperty(stack1,"card_type") : stack1),{"name":"getCreditCardIcon","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":58},"end":{"line":23,"column":125}}})) != null ? stack1 : "")
    + "\"></i>\n                                <div class=\"tt-action__content\">\n                                ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"creditCardStars") || (depth0 != null ? lookupProperty(depth0,"creditCardStars") : depth0)) != null ? helper : alias2),(options={"name":"creditCardStars","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":32},"end":{"line":25,"column":72}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"creditCardStars")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"user_payment") : depth0)) != null ? lookupProperty(stack1,"last_four") : stack1), depth0))
    + "\n                                </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                              <i class=\"tt-action__icon\"></i>\n                              <div class=\"tt-action__content\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"user_payment") : depth0)) != null ? lookupProperty(stack1,"account") : stack1), depth0))
    + "</div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\"javascript:\" id=\"braintree_user_payment_link\" data-action=\"showForm\" class=\"tt-action\">\n                              <i class=\"tt-action__icon tt-icon-credit-card\"></i>\n                              <div class=\"tt-action__content\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Pay Add",{"name":"translate","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":62},"end":{"line":35,"column":100}}})) != null ? stack1 : "")
    + "</div>\n                              <i class=\"tt-action__arrow tt-icon-angle-right\"></i>\n                            </a>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "                        <div class=\"payment-condition\" id=\"paymentConditionContainer\">\n                          <div id=\"common_condition_checkbox_container\" class=\"tt-mb-20\">\n                          </div>\n                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div id=\"billing_information_regions\"></div>\n<div data-nested-page=\"first\">\n  <div id=\"payment_summary_container\" data-e2eid=\"payment-summary\" class=\"payment-resume tt-padding-top-30 tt-mb-30 ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":116},"end":{"line":3,"column":154}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n  </div>\n  <div id=\"billing_information_container\" ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":42},"end":{"line":5,"column":88}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "></div>\n      <div id=\"payment_container\" ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":34},"end":{"line":6,"column":80}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n\n        <div id=\"braintree_example_form\">\n          <div id=\"braintree_user_payment_container\">\n                <div class=\"payment-no-border\">\n\n                    <a id=\"braintree_user_payment_link\" href=\"javascript:\" data-action=\"showForm\">\n                  \n                    <div id=\"braintree_user_payment_wrapper\" class=\"tt-hide "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":76},"end":{"line":14,"column":117}}})) != null ? stack1 : "")
    + "\">\n                      <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":34},"end":{"line":15,"column":91}}})) != null ? stack1 : "")
    + "\">\n                        <div class=\"tt-form-divider\">\n                          "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Pay Mode",{"name":"translate","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":26},"end":{"line":17,"column":65}}})) != null ? stack1 : "")
    + "\n                        </div>\n                        <div id=\"braintree_user_payment_list_container\" class=\"tt-m-input\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_payment") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":20,"column":26},"end":{"line":38,"column":33}}})) != null ? stack1 : "")
    + "                        </div>\n\n                        </a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"enablePaymentTerm") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":24},"end":{"line":47,"column":31}}})) != null ? stack1 : "")
    + "                      </div>\n                    </div>\n                </div>\n          </div>\n\n          <div id=\"braintree_payment_form_container\" class=\"tt-hide\">\n              <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":26},"end":{"line":54,"column":83}}})) != null ? stack1 : "")
    + "\">\n              <div>\n                <form id=\"payment-form\">\n                    <div id=\"braintree_form\">\n                    </div>\n                    <div id=\"braintree_card_submit_container\" align=\"center\">\n                    </div>\n                </form>\n              </div>\n              </div>\n          </div>\n\n        </div>\n\n        <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":20},"end":{"line":68,"column":61}}})) != null ? stack1 : "")
    + "\">\n          <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":22},"end":{"line":69,"column":79}}})) != null ? stack1 : "")
    + "\">\n            <div id=\"submit_clear_container\" class=\"tt-hide\">\n            </div>\n          </div>\n        </div>\n      </div>\n\n  <div id=\"dialog_payment_condition_container\">\n  </div>\n</div>";
},"useData":true});