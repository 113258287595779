var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "tt-dialog-fullscreen";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-js-modal-spid-button tt-spid-button\" id=\""
    + alias2(alias1(depth0, depth0))
    + "\">\n                  <img src=\""
    + alias2(lookupProperty(helpers,"lookup").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"spidIdpLogos") : depths[1]),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":16,"column":28},"end":{"line":16,"column":59}}}))
    + "\" alt=\""
    + alias2(alias1(depth0, depth0))
    + "\" />\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "\n<div class=\"modal fade ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":23},"end":{"line":2,"column":69}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\" id=\"\" tabindex=\"-1\" aria-labelledby=\"exampleModalLabel\" aria-hidden=\"true\" role=\"dialog\">\n	 <div class=\"modal-dialog modal-dialog-scrollable\">\n    <div class=\"modal-content modal-nobottombar\">\n      <div class=\"modal-body tt-bg-white tt-p-24 tt-dialog-radius\">\n        <div class=\"modal-body tt-modal-content__body\">\n        </div>\n        <div class=\"js-close-dialog tt-modal__close\" data-dismiss=\"modal\">\n          <span><i class=\"tt-icon-times\"></i></span>\n        </div>\n\n          <div class=\"tt-spid-modal-buttons-container\">\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"spidIdpAvailable") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":14},"end":{"line":18,"column":23}}})) != null ? stack1 : "")
    + "\n          </div>\n      </div>\n    </div>\n  </div>\n</div>\n\n";
},"useData":true,"useDepths":true});