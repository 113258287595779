var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button type=\"button\" class=\"tt-button tt-button--secondary js-dialog-cancel-btn\">\n              "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cancel_btn_label") || (depth0 != null ? lookupProperty(depth0,"cancel_btn_label") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cancel_btn_label","hash":{},"data":data,"loc":{"start":{"line":21,"column":14},"end":{"line":21,"column":34}}}) : helper)))
    + "\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-body\">\n        <div class=\"tt-info tt-info--column tt-no-border tt-no-padding\">\n          <i class=\"tt-info__icon fad tt-icon-info-circle\"></i>\n          <div class=\"tt-info__content\">\n            <div>\n              "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"msg") || (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":9,"column":14},"end":{"line":9,"column":23}}}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n          </div>\n        </div>\n      </div>\n      <div class=\"modal-footer\">\n        <div class=\"tt-flexend-container tt-dialog-button\">\n            <button type=\"button\" class=\"tt-button tt-button js-dialog-confirm-btn\">\n              "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"confirm_btn_label") || (depth0 != null ? lookupProperty(depth0,"confirm_btn_label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"confirm_btn_label","hash":{},"data":data,"loc":{"start":{"line":17,"column":14},"end":{"line":17,"column":35}}}) : helper)))
    + "\n            </button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cancel_btn_label") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":10},"end":{"line":23,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});