import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";
import { Icon } from 'ui/Icon';

export const Action =
  forwardRef((
    {
      children,
      onClick = null,
      arrow = false
    }, ref) => {

    return (
      <a
        css={style.base}
        onClick={onClick ? () => onClick() : null}
        ref={ref}>
        <div css={style.content}>
          {children}
        </div>
        {arrow &&
          <div css={[style.iconContainer, style.arrowRightContainer]}>
            <Icon name="ChevronRightSolid" color="primary" size="medium" />
          </div>
        }
      </a >
    );
  });

Action.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  arrow: PropTypes.bool
};