var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "tt-columns tt-column-flex";
},"3":function(container,depth0,helpers,partials,data) {
    return "tt-flex tt-flex-column";
},"5":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-column is-three-quarters\"";
},"7":function(container,depth0,helpers,partials,data) {
    return "";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":21,"column":29},"end":{"line":21,"column":37}}}) : helper)));
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"formattedAreaTitle")||(depth0 && lookupProperty(depth0,"formattedAreaTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"webTitle") : depth0),(depth0 != null ? lookupProperty(depth0,"areaTitle") : depth0),{"name":"formattedAreaTitle","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":25},"end":{"line":24,"column":90}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-column\"";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"tt-column\">\n              <button type=\"button\" class=\"tt-button tt-button--primary\" data-action=\"toReschedule\" data-model-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":34,"column":115},"end":{"line":34,"column":121}}}) : helper)))
    + "\">\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Reschedule Button",{"name":"translate","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":35,"column":68}}})) != null ? stack1 : "")
    + "\n              </button>\n            </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showCancelButton") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":38},"end":{"line":45,"column":17}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        \n            <div class=\"tt-column\">\n              <button type=\"button\" data-action=\"toCancel\" data-model-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":41,"column":74},"end":{"line":41,"column":80}}}) : helper)))
    + "\" class=\"tt-button tt-button--secondary\">\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Cancel Reservation",{"name":"translate","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":16},"end":{"line":42,"column":65}}})) != null ? stack1 : "")
    + "\n              </button>\n            </div>\n          ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div class=\"tt-card-admin-patient-appointment-info\">\n	<div class=\"tt-appointment-status tt-appointment-status--"
    + alias4(((helper = (helper = lookupProperty(helpers,"status_class") || (depth0 != null ? lookupProperty(depth0,"status_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status_class","hash":{},"data":data,"loc":{"start":{"line":2,"column":58},"end":{"line":2,"column":74}}}) : helper)))
    + "\">\n		<div class=\"tt-appointment-status__text\">\n				"
    + alias4(((helper = (helper = lookupProperty(helpers,"status_text") || (depth0 != null ? lookupProperty(depth0,"status_text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status_text","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":19}}}) : helper)))
    + "\n		</div>\n	</div>\n\n	<div class=\"tt-card-admin-patient-appointment-info__content\">\n		<div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":14},"end":{"line":9,"column":70}}})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":71},"end":{"line":9,"column":119}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n			<div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":74}}})) != null ? stack1 : "")
    + ">\n				<div class=\"tt-card-admin-patient-appointment-info__content-title\">\n					"
    + alias4(((helper = (helper = lookupProperty(helpers,"start_date_day_abbr_week") || (depth0 != null ? lookupProperty(depth0,"start_date_day_abbr_week") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start_date_day_abbr_week","hash":{},"data":data,"loc":{"start":{"line":12,"column":5},"end":{"line":12,"column":33}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"start_date") || (depth0 != null ? lookupProperty(depth0,"start_date") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start_date","hash":{},"data":data,"loc":{"start":{"line":12,"column":36},"end":{"line":12,"column":50}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"startTime") || (depth0 != null ? lookupProperty(depth0,"startTime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"startTime","hash":{},"data":data,"loc":{"start":{"line":12,"column":53},"end":{"line":12,"column":66}}}) : helper)))
    + "\n				</div>\n				<div class=\"tt-card-admin-patient-appointment-info__content-text\">\n					<div class=\"tt-card-admin-patient-appointment-info__content-text\">\n						<ul class=\"tt-card-admin-patient-appointment-info__content-text__list\">\n							<li class=\"tt-card-admin-patient-appointment-info__content-text__list-item\">\n								"
    + alias4(((helper = (helper = lookupProperty(helpers,"typologyTitle") || (depth0 != null ? lookupProperty(depth0,"typologyTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typologyTitle","hash":{},"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":18,"column":25}}}) : helper)))
    + " - "
    + ((stack1 = (lookupProperty(helpers,"getActivityTitle")||(depth0 && lookupProperty(depth0,"getActivityTitle"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"activity_web_title") : depth0),(depth0 != null ? lookupProperty(depth0,"activityTitle") : depth0),{"name":"getActivityTitle","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":28},"end":{"line":18,"column":103}}})) != null ? stack1 : "")
    + "\n							</li>\n							<li class=\"tt-card-admin-patient-appointment-info__content-text__list-item\">\n								"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showResources") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":21,"column":44}}})) != null ? stack1 : "")
    + "\n							</li>\n              <li class=\"tt-card-admin-patient-appointment-info__content-text__list-item\">\n								"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showAreas") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":24,"column":97}}})) != null ? stack1 : "")
    + "\n							</li>\n						</ul>\n					</div>\n				</div>\n			</div>\n			<div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":30,"column":56}}})) != null ? stack1 : "")
    + ">\n				<div class=\"tt-card-admin-patient-appointment-info__buttons tt-columns\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showRescheduleButton") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":10},"end":{"line":38,"column":17}}})) != null ? stack1 : "")
    + "          "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"canCancelReservation") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":10},"end":{"line":45,"column":24}}})) != null ? stack1 : "")
    + "\n					<div class=\"tt-column\">\n						<button type=\"button\" data-action=\"toPrint\" data-model-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":47,"column":65},"end":{"line":47,"column":71}}}) : helper)))
    + "\" class=\"tt-button tt-button--secondary\">\n							"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"View",{"name":"translate","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":7},"end":{"line":48,"column":42}}})) != null ? stack1 : "")
    + "\n						</button>\n					</div>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true});