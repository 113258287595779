var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "tt-margin-top-20";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"tt-talk-doctor-messaging-container__chat-header\">\n      <a href=\"javascript:\" class=\"js-back-desktop-chat\">\n        <i class=\"tt-talk-doctor-messaging-container__chat-header__icon tt-icon-chevron-left\"></i>\n      </a>\n      <div class=\"tt-talk-doctor-messaging-container__chat-header__content\">\n        <div class=\"tt-talk-doctor-messaging-container__chat-header__content-title\">\n          "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"resource_name") || (depth0 != null ? lookupProperty(depth0,"resource_name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"resource_name","hash":{},"data":data,"loc":{"start":{"line":9,"column":10},"end":{"line":9,"column":27}}}) : helper)))
    + "\n        </div>\n      </div>\n    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "tt-talk-doctor-messaging--desktop";
},"7":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"tt-talk-doctor-messaging-type\">\n    <div class=\"tt-talk-doctor-messaging-type__input\">\n      <textarea type=\"text\" class=\"js-text-area\" placeholder=\"Type a message\"></textarea>\n    </div>\n    <button type=\"button\" class=\"tt-talk-doctor-messaging-type__input-button msg_send_btn js-send-note-btn\" disabled>\n      <i class=\"fas tt-icon-paper-plane\" aria-hidden=\"true\"></i>\n    </button>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tt-talk-doctor-messaging-container "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":47},"end":{"line":1,"column":94}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":13,"column":18}}})) != null ? stack1 : "")
    + "  <div class=\"tt-talk-doctor-messaging js-talk-doctor-messaging-container "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":74},"end":{"line":14,"column":138}}})) != null ? stack1 : "")
    + "\">\n    \n\n\n  </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"status") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":2},"end":{"line":34,"column":13}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true});