import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";
import { useTheme } from '@emotion/react';
import { Dialog } from '../Dialog';
import { LineButton } from '../LineButton';
import { ForwardRef } from '../ForwardRef'
import { Loading } from '../Loading';
import { Label } from '../Label';

import { useMediaQuery } from "react-responsive";

import { TTPolyglot } from "@frontend/tt-polyglot";


export const Survey = ({ title = null, description = null, isRequired = false, src = null, onError = null, onCompleted = null, fullScreen = false, error = null }) => {
  const [open, setOpen] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(true);

  const dialogTitleRef = useRef(null);
  const iframeRef = useRef(null);
  const surveyRef = useRef(null);

  const theme = useTheme();

  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.layout.mobileView})`,
  });

  const handleError = () => {
    onError && onError();
    setTimeout(() => setOpen(false), 2000);
  }

  const handleClose = () => {
    setOpen(false);
    // setLoading(true);
  }

  useEffect(() => {
    if (open === false) return;

    // Se open = true, vado a settare sempre il loader all'inizio, poi questo verrà disattivato dall'eventListener, quando il survey è stato caricato
    setLoading(true);

    window.addEventListener('message', e => {
      if (typeof e.data !== 'object') return;

      if (('surveyCompleted' in e.data) && parseInt(e.data.surveyCompleted, 10) === 1 && iframeRef.current) {
        onCompleted && onCompleted(e.data.respData);
        setCompleted(true);
        setOpen(false);
      }
      if (('stopLoading' in e.data) && parseInt(e.data.stopLoading, 10) === 1) {
        setLoading(false);

        if (iframeRef.current) {
          const dialogTitleHeight = dialogTitleRef.current.clientHeight;
          iframeRef.current.style.top = `${dialogTitleHeight}px`;
          if (fullScreen) {
            iframeRef.current.style.height = `calc(100% - ${dialogTitleHeight}px)`;
          } else {
            iframeRef.current.style.height = `calc(500px - ${dialogTitleHeight}px)`;
          }

          iframeRef.current.style.display = 'block';
        }


      }
    }, false);
  }, [open]);

  const renderButton = () => {

    if (completed) {
      return (
        <LineButton icon={"CheckSolid"}>
          {TTPolyglot.t('MOP Summary Survey Completed')}
        </LineButton>
      )
    }

    return (
      <LineButton icon={"ClipboardLight"} onClick={() => setOpen(true)}>
        {TTPolyglot.t('MOP Summary Take Me To Survey')}
      </LineButton>
    )
  }

  return (
    <>
      <Dialog
        open={open}
        outClick={true}
        closeButton={true}
        customStyle={ isMobile ? {} : {
          width: '620px',
          height: '500px',
        }}
        onClose={handleClose}
        fullScreen={fullScreen}
        anchorEl={surveyRef?.current}
        titleAnchorEl={dialogTitleRef}
        title={TTPolyglot.t("MOP Survey Summary Title")}
      >
        <Dialog.Content>
          {loading &&
            <div css={style.loading}>
              <Loading />
            </div>
          }
          <iframe ref={iframeRef} css={style.iframe} style={{ display: "none" }} onError={handleError} src={src} />
        </Dialog.Content>
      </Dialog>

      <div css={style.base}>
        {title &&
          <Label size={"medium"} weight={"medium"}>
            {title} {isRequired ? " * " : ""}
          </Label>
        }
        {description && <Label size={"medium"} color={"text_light"} dangerouslySetInnerHTML={description} />}
        <ForwardRef ref={surveyRef}>
          {renderButton()}
        </ForwardRef>
        {!!error && <Label color={"danger"}>{error}</Label>}
      </div>

    </>
  );
};

Survey.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isRequired: PropTypes.bool,
  error: PropTypes.string,
  src: PropTypes.string,
  onError: PropTypes.func,
  onCompleted: PropTypes.func,
  fullScreen: PropTypes.bool
};
