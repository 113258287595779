var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "tt-container";
},"3":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-container\"";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "      <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":18},"end":{"line":40,"column":59}}})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":60},"end":{"line":40,"column":98}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n        <div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":13},"end":{"line":41,"column":78}}})) != null ? stack1 : "")
    + ">\n          <div class=\"payment-condition\" id=\"paymentConditionContainer\">\n\n            <div id=\"common_condition_checkbox_container\" class=\"tt-mb-20\">\n            </div>\n          </div>\n        </div>\n        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "tt-columns";
},"8":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-column is-8 is-offset-2\"";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"tt-column\">\n              <a href=\"javascript:\" class=\"tt-button tt-button--secondary\" mopSelector=\"clearPayment\" data-default-page=\"reservations\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Clear",{"name":"translate","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":20},"end":{"line":58,"column":56}}})) != null ? stack1 : "")
    + "\n                </a>\n              </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, alias5=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<div id=\"billing_information_regions\"></div>\n<div data-nested-page=\"first\">\n  <div id=\"payment_summary_container\" class=\"payment-resume tt-padding-top-30 tt-mb-30 ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":87},"end":{"line":3,"column":125}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n  </div>\n  <div id=\"billing_information_container\" ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":42},"end":{"line":5,"column":88}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "></div>\n    <div id=\"payment_container\" ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":78}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n\n      <form action=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"endpoint") || (depth0 != null ? lookupProperty(depth0,"endpoint") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"endpoint","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":32}}}) : helper)))
    + "\" method=\"post\" id=\"paypalStandardForm\" name=\"paypalStandardForm\" target=\"_parent\" class=\"tt-hide\">\n\n        <input type=\"hidden\" name=\"cmd\" value=\"_xclick\" />\n        <input type=\"hidden\" name=\"amount\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"amount") || (depth0 != null ? lookupProperty(depth0,"amount") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":11,"column":50},"end":{"line":11,"column":60}}}) : helper)))
    + "\" />\n        <input type=\"hidden\" name=\"quantity\" value=\"1\" />\n\n        <input type=\"hidden\" name=\"currency_code\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"currency_code") || (depth0 != null ? lookupProperty(depth0,"currency_code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currency_code","hash":{},"data":data,"loc":{"start":{"line":14,"column":57},"end":{"line":14,"column":74}}}) : helper)))
    + "\" />\n        <input type=\"hidden\" name=\"business\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"business") || (depth0 != null ? lookupProperty(depth0,"business") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"business","hash":{},"data":data,"loc":{"start":{"line":15,"column":52},"end":{"line":15,"column":64}}}) : helper)))
    + "\" />\n\n        <input type=\"hidden\" name=\"charset\" value=\"utf-8\" />\n\n        <input type=\"hidden\" name=\"no_note\" value=\"1\" />\n        <input type=\"hidden\" name=\"lc\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"lc") || (depth0 != null ? lookupProperty(depth0,"lc") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lc","hash":{},"data":data,"loc":{"start":{"line":20,"column":46},"end":{"line":20,"column":52}}}) : helper)))
    + "\" />\n        <input type=\"hidden\" name=\"no_shipping\" value=\"1\" />\n\n        <input type=\"hidden\" name=\"cbt\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"cbt") || (depth0 != null ? lookupProperty(depth0,"cbt") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cbt","hash":{},"data":data,"loc":{"start":{"line":23,"column":47},"end":{"line":23,"column":54}}}) : helper)))
    + "\" />\n        <input type=\"hidden\" name=\"notify_url\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"notify_url") || (depth0 != null ? lookupProperty(depth0,"notify_url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"notify_url","hash":{},"data":data,"loc":{"start":{"line":24,"column":54},"end":{"line":24,"column":68}}}) : helper)))
    + "\" />\n\n        <input type=\"hidden\" name=\"item_name\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"item_name") || (depth0 != null ? lookupProperty(depth0,"item_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"item_name","hash":{},"data":data,"loc":{"start":{"line":26,"column":53},"end":{"line":26,"column":66}}}) : helper)))
    + "\" />\n\n        <input type=\"hidden\" name=\"custom\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"custom") || (depth0 != null ? lookupProperty(depth0,"custom") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom","hash":{},"data":data,"loc":{"start":{"line":28,"column":50},"end":{"line":28,"column":60}}}) : helper)))
    + "\" />\n        <input type=\"hidden\" name=\"return\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"return") || (depth0 != null ? lookupProperty(depth0,"return") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"return","hash":{},"data":data,"loc":{"start":{"line":29,"column":50},"end":{"line":29,"column":60}}}) : helper)))
    + "\" />\n        <input type=\"hidden\" name=\"cancel_return\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"cancel_return") || (depth0 != null ? lookupProperty(depth0,"cancel_return") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cancel_return","hash":{},"data":data,"loc":{"start":{"line":30,"column":57},"end":{"line":30,"column":74}}}) : helper)))
    + "\" />\n\n        <input type=\"hidden\" name=\"first_name\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"first_name") || (depth0 != null ? lookupProperty(depth0,"first_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"first_name","hash":{},"data":data,"loc":{"start":{"line":32,"column":54},"end":{"line":32,"column":68}}}) : helper)))
    + "\" />\n        <input type=\"hidden\" name=\"last_name\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"last_name") || (depth0 != null ? lookupProperty(depth0,"last_name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"last_name","hash":{},"data":data,"loc":{"start":{"line":33,"column":53},"end":{"line":33,"column":66}}}) : helper)))
    + "\" />\n        <input type=\"hidden\" name=\"email\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":34,"column":49},"end":{"line":34,"column":58}}}) : helper)))
    + "\" />\n\n        <input type=\"hidden\" name=\"night_phone_a\" value=\""
    + alias5(((helper = (helper = lookupProperty(helpers,"night_phone_a") || (depth0 != null ? lookupProperty(depth0,"night_phone_a") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"night_phone_a","hash":{},"data":data,"loc":{"start":{"line":36,"column":57},"end":{"line":36,"column":74}}}) : helper)))
    + "\" />\n      </form>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"enablePaymentTerm") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":6},"end":{"line":49,"column":13}}})) != null ? stack1 : "")
    + "\n\n      <div class=\""
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":18},"end":{"line":52,"column":59}}})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":60},"end":{"line":52,"column":98}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n        <div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":13},"end":{"line":53,"column":78}}})) != null ? stack1 : "")
    + ">\n          <div class=\"tt-columns tt-flex\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"payment_required") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":12},"end":{"line":61,"column":24}}})) != null ? stack1 : "")
    + "            <div class=\"tt-column\">\n              <button data-e2eid=\"payment-proceed\" type=\"button\" id=\"paypal_standard-submit-payment-button\" mopSelector=\"submitPaymentButton\" class=\"tt-button tt-button--primary\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Proceed",{"name":"translate","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":20},"end":{"line":64,"column":58}}})) != null ? stack1 : "")
    + "\n                </button>\n            </div>\n          </div>\n        </div>\n      </div>\n\n\n    </div>\n  <div id=\"dialog_payment_condition_container\">\n  </div>\n</div>\n";
},"useData":true});