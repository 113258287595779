import MOP from "app";
import ModelBase from 'models/model_base';
import CollectionBase from 'collections/collection_base';

import { TTPolyglot } from "@frontend/tt-polyglot";
import { isManuallyPaid } from 'common-mop/reservations';

	MOP.module("Entities", function(Entities, MOP, Backbone, Marionette, $, _){
		  
		Entities.Reservation = ModelBase.extend({
				
			fetch_fn: 'search_reservation',
			searchPath: 'reservations',
			
			initialize: function() {
				// Invoke parent initialize();
				ModelBase.prototype.initialize.apply(this, arguments);
			},
			
			getIdFieldName : function() {
				return "resid";
			},
			  
			defaults: {
				  resid: 			null,
				  legacyid:			null,
				  start_date:		"",
				  end_date:			"",
				  startTime:		"",
				  endTime:			"",
				  summary:			"",
				  is_pending:		0,
				  payment_status:	null,
				 /*
				  * NB Questi vengono valorizzati solo dopo un operazione
				  *    di save, la fetch non restituisce queste proprietà
				  */
				  warnings:			"",
				  ticket:			"",
				  sincro_on:		0
			},
			
			getTimestamp: function(){
				var start_date = this.get('start_date');
				var startTime = this.get('startTime');
				return MOP.Utilities.humanDateToDateObj(MOP.config.getInstanceConfig('dateFormat'), start_date, startTime).getTime();
			},
			
			isApprovedByLegacy: function(){
				return !(MOP.Utilities.empty(this.get('legacyid')));
			},
			
			isApproved: function(){
				return this.get('is_pending') == MOP.constants.RES_APPROVED;
			},
			
			isPending: function(){
				return this.get('is_pending') == MOP.constants.RES_TO_APPROVE;
			},
			
			isCancelled: function(){
				return (this.get('is_pending') == MOP.constants.RES_USER_CANCELLED || this.get('is_pending') == MOP.constants.RES_ADMIN_CANCELLED);
      },
      
      isPaymentDisabled: function(){
				return (MOP.Utilities.empty(this.get('payment_required')));
			},
			
			isReservationPast:  function(){
				var time = new Date();
				var t = time.getTime();
				return (this.getTimestamp() < t);
			},
			
			validate: function(attrs, options, multiple_id) {
				var errors = MOP.TM.validatePrescriptionFields(attrs);
				
				if ( !attrs.userid ) {
					errors.userid = TTPolyglot.t('User is required.');
				}
				
				//Qui riempo l'array errors solo per fermare il processo, ma per visualizzare l'errore utilizziamo il banner
				var missing_custom_fields = MOP.TM.validateCustomReservationFields(attrs.res_custom_policy, multiple_id);
				
				if (!MOP.Utilities.empty(missing_custom_fields)) {
					errors['missing_custom_fields'] = missing_custom_fields;
				}
				
				if( this.isNew) {
					//TODO
				}else{
					// Per il momento non gestiamo gli errori di validazione nell'update
					return false;
				}
								
				if( !_.isEmpty(errors) ){
					return errors;
				}
				
			},
			
			save: function(attributes) {
				
				this.validationError = this.validate(attributes);
			  if( this.validationError ){
				 return false;
			  }
				
			  if(this.isNew){
				  var ajax_method = 'POST';
					var searchPath = 'reservations';
			  }else{
				  var ajax_method = 'PUT';
					var searchPath = 'reservations/'+attributes.id;
					delete attributes.id
			  }
			  
				if (!MOP.Utilities.empty(MOP.querystring['reservation_tags'])) {
					attributes.tags = MOP.querystring['reservation_tags'];
				}
				
				var params = {
						searchPath: searchPath,
						ajax: {
								options: {
										method: ajax_method
								},
								data: attributes
						}
				};
				
				if (!this.isNew) params.preventThrowException = true;
				
			  var self = this;
	       	  var requesting = MOP.ajaxRest(params);
        	  var saving = requesting.then( function( resp ){
        	  		if( resp['result'] == 'OK' ){
        	  			
        	  			var resid = resp['return'];
        	  			
        	  			//NB => La risposta contiene tutti i dati della Reservation 
        	  			//      mentre nel campo return troviamo il nuovo resid
        				var blacklisted_keys = [
        				    'return',                    
	                        'result',
	                        'msg',
	                        'exception',
	                        'execution_time',
	                        'debug'
        				];
        				var reservation_data_to_set = _.omit(resp, blacklisted_keys);
        				
        				//Settiamo il resid
        				reservation_data_to_set.resid = resid;
        				
        				//Gestione sincro_on:
        				//- acceso => 		1
        				//- non presente => 0
        				//- spento => 	   -1 
        				//In caso non fosse presente la chiave mettiamo come default: 0 => spento
        				if( !(_.has(reservation_data_to_set, 'sincro_on')) ){
        					reservation_data_to_set.sincro_on = 0;
        				}
								
								// Se è presente la chiave additional_return allora prendo il contenuto della reservation da li e lo setto al modello
								if (!MOP.Utilities.empty(resp.additional_return)) {
									if (!MOP.Utilities.empty(resp.additional_return.reservations) && !MOP.Utilities.empty(resp.additional_return.reservations[resid])) {

										self.set(resp.additional_return.reservations[resid]);

										// Qui nella seconda condizione controlliamo non con la funzione empty, perchè vogliamo prendere anche l'array vuoto, quindi controlliamo solo
										// l'esistenza della chiave
										if (!MOP.Utilities.empty(resp.additional_return.messages) && resp.additional_return.messages[resid]) {
											self.set("messages", resp.additional_return.messages[resid]);
										}

										// Come la spiegazione sopra
										if (!MOP.Utilities.empty(resp.additional_return.reminders) && resp.additional_return.reminders[resid]) {
											self.set("reminders", resp.additional_return.reminders[resid]);
										}
									}
								} else {
									self.set( reservation_data_to_set );
								}
        				
        				self.trigger("sync", self, resp);
        	  		}else{
        	  			self.trigger("invalid", self, resp['msg']);
        	  		}
        	  		return resp;
        	  });
        	  return saving;

			},
			/*
			cancel: function(reason){
				var self = this;
		       	
				var ajax_fn = 'delete_reservation';
				
				var params = {};
				params.resid = this.get('resid');
				if( !MOP.Utilities.empty(reason) ){
					params.reason = reason;
				}
				
				var requesting = MOP.ajax({
					fetch_fn: ajax_fn,
					ajax: {
						data: params
					}
				});
	        	var cancelling = requesting.then( function( resp ){

	        			 //* FIXME => Finchè l'API sia in OK che in ERROR non ci ritorna i dati del modello modificati 
	        			 //*          ma solo il resid => dobbiamo gestire manualmente l'aggiornamento del modello
	        			 //* FIXME => Adesso che generiamo un ERROR quando il rimborso fallisce ma la disdetta è stata
	        			 //*          comunque processata, dobbiamo continuare con questo abuso ==> REFACTORING !!
	        			 
	        	  		if( resp['result'] == 'OK' || resp['result'] == 'ERROR' ){
	        	  			var resid = resp['return'];
	        	  			if( MOP.isAdminLoggedIn() ){
	        	  				self.set('is_pending', MOP.constants.RES_ADMIN_CANCELLED);
	        	  			}else{
	        	  				self.set('is_pending', MOP.constants.RES_USER_CANCELLED);
	        	  			}

	        	  			if ((self.get('payment_status') == MOP.constants.RES_VERIFIED_PAYMENT || self.get('payment_status') == MOP.constants.RES_AUTHORIZED)&& resp['result'] == 'OK') {
								self.set('payment_status', MOP.constants.RES_REFUNDED_PAYMENT);
							}

	        				self.trigger("sync", self, resp);
	        	  		}else{
	        	  			self.trigger("invalid", self, resp['msg']);
	        	  		}
	        	  		return resp;
	        	  });
	        	  return cancelling;
			},*/
			
			cancel: function(reason){
				
				var _this = this;
				var deferred = new $.Deferred();
				
				var params = {};
        params.resid = this.get('resid');
				if(this.has("reserved")) {
					params.reserved_legacyid = this.get("reserved_legacyid");
				}
        if(this.has('bundle_setid')) {
          params.bundle_setid = this.get('bundle_setid');
        }
				if( !MOP.Utilities.empty(reason) ){
					params.reason = reason;
				}

				// se la prenotazione è reserved o meno cambia l'api da chiamare
				const reserved = this.get("reserved");
				const _id = reserved && reserved == 1 ? this.get("reserved_legacyid") : this.get("resid");

				var ajaxRestParams = {
					searchPath: `${reserved && reserved == 1 ? "reserved" : "reservations"}/`+_id, 
					ajax: {
						options: {
							method: 'DELETE'
						}
					},
          querystring: params
				};

				return MOP.ajaxRest(ajaxRestParams)
					.done(function (resp) {
						/*
						 * FIXME => Finchè l'API sia in OK che in ERROR non ci ritorna i dati del modello modificati 
						 *          ma solo il resid => dobbiamo gestire manualmente l'aggiornamento del modello
						 * FIXME => Adesso che generiamo un ERROR quando il rimborso fallisce ma la disdetta è stata
						 *          comunque processata, dobbiamo continuare con questo abuso ==> REFACTORING !!
						 */
						 if( resp['result'] == 'OK' || resp['result'] == 'ERROR' ){
							 var resid = resp['return'];
							 if( MOP.isAdminLoggedIn() ) {
								 _this.set('is_pending', MOP.constants.RES_ADMIN_CANCELLED);
							 } else {
								 _this.set('is_pending', MOP.constants.RES_USER_CANCELLED);
							 }
               
               // Siccome la cancel non ci ritorna il modello aggiornato dobbiamo aggioranre noi a mano i dati vedere nota qui sopra.
               if ((_this.get('payment_status') == MOP.constants.RES_VERIFIED_PAYMENT || _this.get('payment_status') == MOP.constants.RES_AUTHORIZED) && resp['result'] == 'OK') {

                // Per Adyen il rimborso è sempre incerto quindi forziamo noi lo stato aspettando il futuro refactoring in cui sarà il server
                // a darci i dati aggiornati
                if (_this.get('payment_method') == MOP.constants.PAYMENT_METHOD_ADYEN) {
                  _this.set('payment_status', MOP.constants.RES_SUBMITTED_REFUND);
                } else {
                  _this.set('payment_status', MOP.constants.RES_REFUNDED_PAYMENT);
                }
								 
							 }
							 
							 _this.trigger("sync", _this, resp);
						 } else {
							 _this.trigger("invalid", _this, resp['msg']);
						 }
						 
						 deferred.resolve(resp);
						 return deferred;
					 })
					.fail(function (e) {
						return deferred.reject(e);
					});
			},
			
			setAsCheckedIn: function () {
				this.set('checked_in', +new Date());
			},

			getReservationStatusInfo: function () {
		        var is_pending = this.get('is_pending');
		        var payment_status = this.get('payment_status');
		        var payment_required = this.get('payment_required');
		        var checked_in = this.get('checked_in');
		        var statusInfo = {};

		      //RES_TO_APPROVE (Pending Payment)
		      if ((is_pending == MOP.config.constants.RES_TO_APPROVE && payment_status == MOP.constants.RES_PENDING_PAYMENT) ||
          (is_pending == MOP.constants.RES_APPROVED && payment_required == MOP.constants.FIELD_COMPULSORY && payment_status != MOP.constants.RES_VERIFIED_PAYMENT && payment_status != MOP.constants.RES_AUTHORIZED)) {
		            statusInfo.status_text = TTPolyglot.t('Payment pending');
		            statusInfo.status_class = 'warning';
								statusInfo.status_color = '#ffc900';
		            statusInfo.reservation_status = TTPolyglot.t('Payment pending');
                statusInfo.reservation_status_class = 'alert alert-warning';
		        }
		        //RES_TO_APPROVE (Sincro OFF or Resource wants Approve)
		        else if (is_pending == MOP.config.constants.RES_TO_APPROVE) {
		            statusInfo.status_text = TTPolyglot.t('Pending Approval');
		            statusInfo.status_class = 'warning';
								statusInfo.status_color = '#ffc900';
		            statusInfo.reservation_status = TTPolyglot.t('Pending Approval')+' '+TTPolyglot.t('Appointment SMS Confirmation');
		            statusInfo.reservation_status_class = 'alert alert-warning';
		        }
		        //RES_ADMIN_CANCELLED (Refunded Payment)
		        else if (is_pending == MOP.config.constants.RES_ADMIN_CANCELLED
		            && payment_status == MOP.constants.RES_REFUNDED_PAYMENT) {
		            statusInfo.status_text = TTPolyglot.t('Payment refunded');
		            statusInfo.status_class = 'gray';
								statusInfo.status_color = '#ef473a';
		        }
            // RES_NO_SHOW
            else if (is_pending == MOP.config.constants.RES_NO_SHOW) {
              statusInfo.status_text = TTPolyglot.t('No Show');
              statusInfo.status_class = 'gray';
              statusInfo.status_color = '#ef473a';
            }
		        //RES_USER_CANCELLED
		        else if (is_pending == MOP.config.constants.RES_USER_CANCELLED
		            && payment_status == MOP.constants.RES_REFUNDED_PAYMENT) {
		            statusInfo.status_text = TTPolyglot.t('Payment refunded');
		            statusInfo.status_class = 'gray';
								statusInfo.status_color = '#ef473a';
		        }
		        //RES_SUBMITTED_REFUND
		        else if (is_pending == MOP.config.constants.RES_ADMIN_CANCELLED
		            && payment_status == MOP.constants.RES_SUBMITTED_REFUND) {
		            statusInfo.status_text = TTPolyglot.t('Payment refund submitted');
		            statusInfo.status_class = 'gray';
								statusInfo.status_color = '#ef473a';
		        }
		        //RES_SUBMITTED_REFUND
		        else if (is_pending == MOP.config.constants.RES_USER_CANCELLED
		            && payment_status == MOP.constants.RES_SUBMITTED_REFUND) {
		            statusInfo.status_text = TTPolyglot.t('Payment refund submitted');
		            statusInfo.status_class = 'gray';
								statusInfo.status_color = '#ef473a';
		        }
		        //RES_ADMIN_CANCELLED (Pending Payment)
		        else if (is_pending == MOP.config.constants.RES_ADMIN_CANCELLED
		            && payment_status == MOP.constants.RES_PENDING_PAYMENT) {
		            statusInfo.status_text = TTPolyglot.t('Not paid');
		            statusInfo.status_class = 'gray';
								statusInfo.status_color = '#ef473a';
		            statusInfo.reservation_status = TTPolyglot.t('Not paid');
		            statusInfo.reservation_status_class = 'alert alert-danger';
		        }
		        //RES_ADMIN_CANCELLED || RES_USER_CANCELLED
		        else if (is_pending == MOP.config.constants.RES_ADMIN_CANCELLED
		            || is_pending == MOP.config.constants.RES_USER_CANCELLED) {
		            statusInfo.status_text = TTPolyglot.t('Cancelled');
		            statusInfo.status_class = 'gray';
								statusInfo.status_color = '#ef473a';
		            statusInfo.reservation_status = TTPolyglot.t('Cancelled');
		            statusInfo.reservation_status_class = 'alert alert-danger';
            //RES_SUBMITTED_PAYMENT (Payment Pending Confirmation)
		        } else if (payment_status == MOP.constants.RES_SUBMITTED_PAYMENT) {
              statusInfo.status_text = TTPolyglot.t('Payment Pending Confirmation');
              statusInfo.status_class = 'success';
              statusInfo.status_color = '#85ca19';
              statusInfo.reservation_status = TTPolyglot.t('Payment Pending Confirmation');
              statusInfo.reservation_status_class = 'alert alert-warning';
            }
		        //RES_APPROVED (Payment Submitted) Payed Online
		        else if (!MOP.Utilities.empty(payment_status) && payment_status > MOP.constants.RES_PENDING_PAYMENT && !isManuallyPaid(this.toJSON())) {
		            var sessionLang = MOP.config.get('lang');

		            statusInfo.status_text = TTPolyglot.t('Paid');
		            statusInfo.status_class = 'success';
								statusInfo.status_color = '#85ca19';
		            statusInfo.reservation_status = "";
		            statusInfo.print_reservation_paid_stamp = '<img src="'+MOP.config.getMopImageBaseUrl()+'/paid-'+sessionLang+'.jpg" border="0" />';

		            statusInfo.price = this.get('online_activityPrice') || this.get('activityPrice');
		        }
            //RES_APPROVED (Payment Submitted) Manually Payed
		        else if (!MOP.Utilities.empty(payment_status) && payment_status > MOP.constants.RES_PENDING_PAYMENT && isManuallyPaid(this.toJSON())) {
              var sessionLang = MOP.config.get('lang');

              statusInfo.status_text = TTPolyglot.t('Mop Manual Payment Status');
              statusInfo.status_class = 'success';
              statusInfo.status_color = '#85ca19';
              statusInfo.reservation_status = "";
              statusInfo.print_reservation_paid_stamp = '<img src="'+MOP.config.getMopImageBaseUrl()+'/paid-'+sessionLang+'.jpg" border="0" />';

              statusInfo.price = this.get('online_activityPrice') || this.get('activityPrice');
          }
		        // Checkin done
		        else if (!MOP.Utilities.empty(checked_in)) {
		            statusInfo.status_text = TTPolyglot.t('Checkin Status Checkedin');
		            statusInfo.status_class = 'success';
                statusInfo.status_color = '#85ca19';
                
            }
            // RES_USER_CONFIRMED
            else if (is_pending == MOP.config.constants.RES_USER_CONFIRMED) {
              // A seguito di questo ticket: https://tuotempo.freshdesk.com/a/tickets/94278 abbiamo inserito una nuova chiave
              // Specifica per lo status "Confermato dal paziente". Di default questa chiave ha la traduzione identica alla chiave
              // "Confirmed" in questo modo tutti gli utenti continuano a vedere una traduzione uguale nei due casi (come è sempre stato)
              // Poi i clienti che vogliono differenziare i due comportamenti, potranno definirsi il proprio specific per questa chiave specifica
              statusInfo.status_text = TTPolyglot.t('MOP Confirmed by patient');
              statusInfo.status_class = 'success';
              statusInfo.status_color = '#85ca19'
            }
		        //RES_APPROVED
		        else {
		            statusInfo.status_text = TTPolyglot.t('Confirmed');
		            statusInfo.status_class = 'success';
								statusInfo.status_color = '#85ca19';
            }
            
            if (statusInfo.status_class.indexOf('success') != -1) {
              if (this.isReservationPast()) {
                statusInfo.status_class = 'gray';
              }
            }

		        return statusInfo;
		    },
				
				getMainKey: function () {
					return ['activityid','insuranceid','activityTitle','typologyTitle','areaid','areaTitle','activityPrice','end_date','endTime','fname','lname','lname_2','resourceName','start_date','startTime'];
				}
		});
		
		
	    Entities.MultipleReservations = CollectionBase.extend({
	    	
	        model: Entities.Reservation,

	        load: function (array) {
				var _this = this;

				_.each(array, _.bind(_this.add, _this));

				return this;
			},

	    	/*
	    	 * TODO [mattia] => sarebbe da gestire attraverso il metodo nativo sync
	    	 */
	        save: function (reservations_data, other_data) {
	          var deferred = new $.Deferred();
	          var _this = this;
	          
						for (var i = 0; i < reservations_data.length; i++) {
							if (!MOP.Utilities.empty(MOP.querystring['reservation_tags'])) {
								reservations_data[i].tags = MOP.querystring['reservation_tags'];
							}
						}
						
	          _.each(reservations_data, function(reservation_data, index, list){
	        	  
	        	  var reservation = new Entities.Reservation();
	        	  
	        	  _this.validationError = reservation.validate( reservation_data, null, reservation_data.multiple_tmp_id );
	        	  
	        	  _this.add( reservation );
	          });
	          
	          if( _this.validationError ){
	        	  return false;
	          }	          
						
						var params = {
	              searchPath: 'reservations',
								querystring: other_data,
	              ajax: {
	                  options: {
	                      method: 'POST'
	                  },
	                  data: {availabilities: reservations_data}
	              },
	              loading: {
	                  enabled: true
	              }
	          };
						
						
	          MOP.ajaxRest(params)
	            .done(function (resp) {
	              if( resp['result'] === 'OK' ){
									deferred.resolve(resp);
		            } else {
		              deferred.reject(resp);
		            }
								
	            })
							.fail(function (e) {
		              deferred.reject(e);
		          });

	          return deferred;
	        }
	    });
			  
		Entities.Reservations = CollectionBase.extend({
			model: Entities.Reservation,
			
			fetch_fn: 'search_reservations',
			searchPath: 'reservations',
			/*
			 * ---- FILTRI ----
			 */
			filterActiveReservations: function(){
				return this.filterByStatus([MOP.constants.RES_APPROVED, MOP.constants.RES_TO_APPROVE, MOP.constants.RES_USER_CONFIRMED, MOP.constants.RES_COMPLETED]);
			},
			
			filterCancelledReservations: function(){
				return this.filterByStatus([MOP.constants.RES_USER_CANCELLED, MOP.constants.RES_ADMIN_CANCELLED]);
			},
			
			filterByStatus: function( status ){
				var filtered = this.filter(function(model){
					var is_pending = parseInt(model.get('is_pending'));
					if( _.indexOf(status, is_pending) != -1 ){
						return true;
					}
				});
				return new Entities.Reservations(filtered);
			},
			
			filterByTimestamp: function( timestamp, greater ){
				var filtered = this.filter(function(model){
					var t = model.getTimestamp();
					if( greater ){
						if( t >= timestamp ){
							return true;
						}
					}else{
						if( t < timestamp ){
							return true;
						}
					}
				});
				return new Entities.Reservations(filtered);
			},
			
			/*
			 * ---- ORDINAMENTI ----
			 */
			orderStrategies: {
				start_date: function(model) {
					return model.getTimestamp();
				},
				startTime: function(model) {
					return model.getTimestamp();
				}
			}

		});
		
		var API = {
			getReservations: function( params ){
				var deferred = new $.Deferred();
				var reservations = new Entities.Reservations();

		    reservations.fetch(MOP, {
            querystring: params
        }).always(function () {
        	deferred.resolve(reservations);
        });

        return deferred;
			},
			getReservation: function( id, userid, more_params ){
				
				var deferred = new $.Deferred();
				var reservation = new Entities.Reservation();
				var params = {};

				if( userid ){
					params.userid = userid;
				}
				
				if( more_params ){
					_.extend(params, more_params);
				}

		    reservation.fetch(MOP, {
					searchPath: "reservations/"+id,
		    	querystring: params
		    }).always(function (a) {
		    	deferred.resolve(reservation);
		    });
		    
		    return deferred;
			}
		};
		
		MOP.reqres.setHandler("reservation:entities", function( params ){
			return API.getReservations( params );
		});

	
		MOP.reqres.setHandler("reservation:entity", function( id, userid, more_params ){
			return API.getReservation( id, userid, more_params );
		});
		
		MOP.reqres.setHandler("reservation:entities:new", function(){
			return new Entities.Reservations();
		});
		
		MOP.reqres.setHandler("reservation:entity:new", function(){
			return new Entities.Reservation();
		});
		
	    MOP.reqres.setHandler('multiple_reservations:entities:new', function () {
	    	return new Entities.MultipleReservations();
	    });
  	});
