var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"tt-text tt-text-middlegrey tt-no-padding tt-mb-14\">\n      "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Pay Link Refund Policy Booking Description",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":9,"column":79}}})) != null ? stack1 : "")
    + "\n    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"tt-mb-20\">\n    <div class=\"tt-text tt-text-middlegrey tt-no-padding\">\n        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"cancellation_advance") || (depth0 != null ? lookupProperty(depth0,"cancellation_advance") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cancellation_advance","hash":{},"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":16,"column":32}}}) : helper)))
    + "\n    </div>\n  </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"tt-form-divider\">\n      "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Pay Link Refund Policy Booking Title",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":73}}})) != null ? stack1 : "")
    + "\n  </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPolicyRefundDescription") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":11,"column":9}}})) != null ? stack1 : "")
    + "  \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"cancellation_advance") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":2},"end":{"line":19,"column":9}}})) != null ? stack1 : "")
    + "\n  <div class=\"tt-input-checkbox-container tt-deprecated-input-checkbox-container\">\n    <li class=\"tt-checkbox\">\n      <label class=\"tt-checkbox__label\">\n        <input data-e2eid=\"payment-condition-checkbox\" class=\"tt-checkbox__label-input\" type=\"checkbox\" id=\"paymentConditionCheckbox\">\n      </label>\n      <div class=\"tt-checkbox__text\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"paymentConditionText") || (depth0 != null ? lookupProperty(depth0,"paymentConditionText") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"paymentConditionText","hash":{},"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":27,"column":34}}}) : helper))) != null ? stack1 : "")
    + "\n      </div>\n    </li>\n  </div>\n";
},"useData":true});