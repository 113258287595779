import Backbone from 'backbone';
import Syphon from './backbone.syphon';
import Picky from './backbone.picky';

// Assegna jQuery a Backbone
Backbone.$ = $;
Backbone.Syphon = Syphon;
Backbone.Picky = Picky;

// Esporta Backbone come ESModule
export default Backbone;