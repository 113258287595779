import dayjs from 'dayjs';
import { TTPolyglot } from "@frontend/tt-polyglot";

export const getCurrentStartOfDay = () => {
  return dayjs().startOf('day');
};

export const formatFromDate = (date, format) => {
  return date.format(format);
};

export const getMonthAbbrTranslated = (MOP, m) => {
  const months = [
    TTPolyglot.t('January Abbreviated'),
    TTPolyglot.t('February Abbreviated'),
    TTPolyglot.t('March Abbreviated'),
    TTPolyglot.t('April Abbreviated'),
    TTPolyglot.t('May Abbreviated'),
    TTPolyglot.t('June Abbreviated'),
    TTPolyglot.t('July Abbreviated'),
    TTPolyglot.t('August Abbreviated'),
    TTPolyglot.t('September Abbreviated'),
    TTPolyglot.t('October Abbreviated'),
    TTPolyglot.t('November Abbreviated'),
    TTPolyglot.t('December Abbreviated')
  ];

  return months[m];
};

export const getMonthTranslated = (m) => {
  const months = [
    TTPolyglot.t('January'),
    TTPolyglot.t('February'),
    TTPolyglot.t('March'),
    TTPolyglot.t('April'),
    TTPolyglot.t('May'),
    TTPolyglot.t('June'),
    TTPolyglot.t('July'),
    TTPolyglot.t('August'),
    TTPolyglot.t('September'),
    TTPolyglot.t('October'),
    TTPolyglot.t('November'),
    TTPolyglot.t('December')
  ];

  return months[m];
};

export const getWeekDaysArrayFromSunday = (d) => {
  const weekDays = [
    TTPolyglot.t('Sunday'),
    TTPolyglot.t('Monday'),
    TTPolyglot.t('Tuesday'),
    TTPolyglot.t('Wednesday'),
    TTPolyglot.t('Thursday'),
    TTPolyglot.t('Friday'),
    TTPolyglot.t('Saturday')
  ];

  return weekDays[d];
};

export const getWeekDaysAbbreviatedArrayFromSunday = (MOP, d) => {
  const weekDays = [
    TTPolyglot.t('Sunday Abbr'),
    TTPolyglot.t('Monday Abbr'),
    TTPolyglot.t('Tuesday Abbr'),
    TTPolyglot.t('Wednesday Abbr'),
    TTPolyglot.t('Thursday Abbr'),
    TTPolyglot.t('Friday Abbr'),
    TTPolyglot.t('Saturday Abbr')
  ];

  return weekDays[d];
};

export const getMainDateFormat = dateFormat => {

  let format = '';
  switch (dateFormat) {
    case "us":
      format = 'MM/DD/YYYY';
      break;
    case "jp":
      format = 'YYYY/MM/DD';
      break;
    case "eu":
    default:
      format = 'DD/MM/YYYY';
      break;
  }

  return format;
};

export const getMainDateFormatWithTime = MOP => {
  let format = '';

  switch (MOP.config.getInstanceConfig('dateFormat')) {
    case "us":
      format = 'MM/DD/YYYY H:mm';
      break;
    case "jp":
      format = 'YYYY/MM/DD H:mm';
      break;
    case "eu":
    default:
      format = 'DD/MM/YYYY H:mm';
      break;
  }

  return format;
};

export const getCleaveDateFormat = MOP => {
  let format = []
  switch (MOP.config.getInstanceConfig('dateFormat')) {
    case "us":
      format = ['m', 'd', 'Y'];
      break;
    case "jp":
      format = ['Y', 'm', 'd'];
      break;
    case "eu":
    default:
      format = ['d', 'm', 'Y'];
      break;
  }

  return format;
}

export const getDateFromFormatted = (dateFormat, _date) => {

  const customParseFormat = require('dayjs/plugin/customParseFormat');
  dayjs.extend(customParseFormat);

  const date = dayjs(_date, getMainDateFormat(dateFormat));

  return date;
};

export const getCurrentDateFormatted = dateFormat => {

  const date = getCurrentStartOfDay();
  const format = getMainDateFormat(dateFormat);
  return date.format(format);

};

export const getDateFormatted = (dateFormat, _date) => {

  const date = dayjs(_date);
  const format = getMainDateFormat(dateFormat);
  return date.format(format);

};

export const getDateIsoFormat = (dateFormat, _date) => {
  return dayjs(_date, getMainDateFormat(dateFormat)).toISOString();
};

export const getDateFormattedLong = (MOP, _date) => {

  const getFormat = (day, month, year) => {
    let format = '';
    switch (MOP.config.getInstanceConfig('dateFormat')) {
      case "us":
        format = `${month} ${day} ${year}`;
        break;
      case "jp":
        format = `${year} ${month} ${day}`;
        break;
      case "eu":
      default:
        format = `${day} ${month} ${year}`;
        break;
    }

    return format;
  };

  const date = getDateFromFormatted(MOP.config.getInstanceConfig('dateFormat'), _date);

  const d = date.get('date');
  const m = getMonthTranslated(date.get('month'));
  const y = date.get('year');

  return getFormat(d, m, y);
};

export const getDayAndMonthAbbrFromFormatted = (MOP, _date) => {

  const date = getDateFromFormatted(MOP.config.getInstanceConfig('dateFormat'), _date);
  const d = date.$D;

  return `${d} ${getMonthAbbrTranslated(MOP, date.get('month'))}`;

};

export const getDateAndDayWeekFromFormat = (MOP, _date) => {

  const date = getDateFromFormatted(MOP.config.getInstanceConfig('dateFormat'), _date);

  const weekday = require('dayjs/plugin/weekday');
  dayjs.extend(weekday);

  return `${getWeekDaysArrayFromSunday(date.day())} ${formatFromDate(date, 'DD')} ${getMonthTranslated(date.month())}`;
};

export const getDayAndDateFormattedFromDate = (MOP, _date) => {

  const date = getDateFromFormatted(MOP.config.getInstanceConfig('dateFormat'), _date);

  const weekday = require('dayjs/plugin/weekday');
  dayjs.extend(weekday);

  return `${getWeekDaysArrayFromSunday(date.day())} ${formatFromDate(date, 'DD')} ${getMonthAbbrTranslated(MOP, date.month())} ${date.year()}`;
};

export const getDayAndMonthFormattedFromDateSecondVersion = (MOP, _date) => {

  const date = getDateFromFormatted(MOP.config.getInstanceConfig('dateFormat'), _date);

  const weekday = require('dayjs/plugin/weekday');
  dayjs.extend(weekday);

  return `${formatFromDate(date, 'DD')} ${getMonthAbbrTranslated(MOP, date.month())}`;
};



export const getTimestamp = () => new Date().getTime() / 1000;

export const timestampExpired = timestamp => parseInt(timestamp, 10) < parseInt(getTimestamp(), 10);

export const getLongDateFromFormatted = (_date, dayWeek, month) => {
  const date = getDateFromFormatted(MOP.config.getInstanceConfig('dateFormat'), _date);
  const d = date.$D;

  return `${dayWeek}, ${d} ${month}`;
};

export const getLongDateFromTimestamp = (dateFormat, dateTimestamp) => {

  const date = getDateFormatted(dateFormat, new Date(dateTimestamp * 1000));
  const m = getDateFromFormatted(MOP.config.getInstanceConfig('dateFormat'), date);
  const dayWeek = getWeekDaysArrayFromSunday(m.$W);

  return getLongDateFromFormatted(date, dayWeek, getMonthTranslated(m.get('month')));
};

export const getDateObjFromFormatted = (dateFormat, formatted) => {

  const customParseFormat = require('dayjs/plugin/customParseFormat');
  dayjs.extend(customParseFormat);
  const weekday = require('dayjs/plugin/weekday');
  dayjs.extend(weekday);

  const date = dayjs(formatted, getMainDateFormat(dateFormat));

  return {
    day: getWeekDaysArrayFromSunday(date.day()),
    dayAbbr: getWeekDaysAbbreviatedArrayFromSunday(MOP, date.day()),
    dayNum: formatFromDate(date, 'DD'),
    monthAbbr: getMonthAbbrTranslated(MOP, date.get('month')),
    month: getMonthTranslated(date.get('month')),
    year: `${date.year()}`
  };

};

export const getDateWithTimeFromTimestamp = (dateFormat, dateTimestamp) => {
  return dayjs(new Date(dateTimestamp * 1000)).format(`${getMainDateFormat(dateFormat)} - HH:mm`);
};

export const getHourFromTimestamp = (MOP, dateTimestamp) => {
  return dayjs(new Date(dateTimestamp * 1000)).format(`HH:mm`);
};
/**
 * Convert seconds in hh "hours" mm "min" format.
 * @param {Number} seconds - the seconds to format
 * @return {String}
 */
export const formatTimeFromSeconds = (MOP, seconds) => {

  if (typeof seconds !== 'number') {
    throw new TypeError('The parameter has to be a number');
  }
  if (seconds < 0) {
    throw new RangeError('The parameter has to be >= 0');
  }

  const minutes = (seconds / 60) % 60;
  const hours = Math.floor(seconds / 3600);

  return `${hours > 0 ? `${hours} ${TTPolyglot.t("hours")}` : ''} ${minutes > 0 ? `${minutes} ${TTPolyglot.t("min")}` : ''}`;
};

export const getDateInEuFormat = (dateFormat, _date) => {
  
  // Controllo per gestire null/undefined/stringa vuota
  if(MOP.Utilities.empty(_date)) return _date;

  const date = dayjs(_date, getMainDateFormat(dateFormat));

  return `${formatFromDate(date, 'DD')}/${formatFromDate(date, 'MM')}/${formatFromDate(date, 'YYYY')}`;
};

export const getHourFromMinutes = minutes => {
  return dayjs().startOf('day').add(minutes, 'minute').format(`HH:mm`);
};

export const getMinutesFromHours = hour => {

  const customParseFormat = require('dayjs/plugin/customParseFormat');
  dayjs.extend(customParseFormat);


  const startDate = dayjs().startOf('day');
  const workDate = dayjs(hour, "HH:mm");

  const elapsed = workDate.diff(startDate, "minute");

  return elapsed;
};

export const getElapsedTimeBetweenTwoHours = (MOP, start, end) => {
  const customParseFormat = require('dayjs/plugin/customParseFormat');
  dayjs.extend(customParseFormat);

  const startDate = dayjs(start, "HH:mm");
  const endDate = dayjs(end, "HH:mm");

  const elapsedSeconds = endDate.diff(startDate, "second");

  return formatTimeFromSeconds(MOP, elapsedSeconds);
};

export const getFormattedCounterFromSeconds = (MOP, counter) => {
  const minutes = Math.floor(counter / 60);
  const seconds = counter - (60*Math.floor(counter / 60));
  return `${minutes > 0 ? `${minutes}:` : ""}${seconds > 9 ? seconds : `0${seconds}`} ${TTPolyglot.t(minutes > 0 ? "Minutes" : "Seconds")}`;
};

export const isToday = (date) => {
  const isToday = require('dayjs/plugin/isToday')
  dayjs.extend(isToday)
  return dayjs(date).isToday()
}

export const isBeforeToday = date => {
  return date.isBefore(dayjs(), 'day');
};

export const getSecondsBetweenNowAndLocalTimestamp = (MOP, timestamp) => {

  const utcPlugin = require('dayjs/plugin/utc');
  const timezonePlugin = require('dayjs/plugin/timezone');
  dayjs.extend(utcPlugin);
  dayjs.extend(timezonePlugin);

  // l'offset tra la timezone dell'istanza e utc
  const instanceOffsetToUtc = dayjs.tz(dayjs.utc(), MOP.config.getInstanceConfig('timeZone')).utcOffset() * 60;

  // il timestamp dell'istanza riportato ad utc
  const utcTimestamp = timestamp - instanceOffsetToUtc;

  // NOW in utc
  const utcNow = dayjs.utc().unix();

  return Math.abs(utcNow - utcTimestamp);
};

export const createDateWithTimezoneWithTimestamp = (timestampUtc, timeZone) => {
  const utc = require('dayjs/plugin/utc');
  const timezone = require('dayjs/plugin/timezone'); 
  const customParseFormat = require('dayjs/plugin/customParseFormat');
 
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(customParseFormat)

  // Dayjs assumes timestamp is in UTC.
  const dateWithTimezone = dayjs.tz(timestampUtc * 1000, timeZone);
  return dateWithTimezone;
}

export const createDateWithTimezone = (MOP, startDate, startTime, timeZone) => {
  const utc = require('dayjs/plugin/utc');
  const timezone = require('dayjs/plugin/timezone'); 
  const customParseFormat = require('dayjs/plugin/customParseFormat');
 
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(customParseFormat)

  const dateRaw = `${startDate} ${formatTime(startTime)}`

  const dateWithTimezone = dayjs.tz(dateRaw, getMainDateFormatWithTime(MOP), timeZone);
  return dateWithTimezone;
}

export const getInstanceTimezone = (MOP) => {
  return MOP.config.getInstanceConfig('timeZone')
}

// 22:00
export const getHourAndMinuteFormattedFromDate = (date) => {
  return date.format('HH:mm');
}
//Wednesday 6 January
export const getDayAndMonthFormattedFromDate = (MOP, date) => {
  return `${getWeekDaysArrayFromSunday(date.day())} ${date.date()} ${getMonthTranslated(date.get('month'))}`
}

export const getInstanceUtcOffset = (MOP) => {
  const utcPlugin = require('dayjs/plugin/utc');
  const timezonePlugin = require('dayjs/plugin/timezone');
  dayjs.extend(utcPlugin);
  dayjs.extend(timezonePlugin);

  const instanceTimeZone = getInstanceTimezone(MOP)

  // l'offset tra la timezone dell'istanza e utc
  const instanceOffsetToUtc = dayjs.tz(dayjs.utc(), instanceTimeZone).utcOffset() * 60;
  return instanceOffsetToUtc;
}

const formatTime = (time) => {
  if (time) {
    if (time.indexOf('am') !== -1 || time.indexOf('pm') !== -1) {
      var pm = time.indexOf('pm') !== -1;
      time = time.replace(pm ? 'pm' : 'am', '');
    }

    var resTime = time.split(":");
    // FD 87641
    // gestita casistica 12:xxPM per non aggiungere 12 ore nel parsing format 12h to 24h
    resTime[0] = pm && parseInt(resTime[0], 10) < 12 ? parseInt(resTime[0], 10) + 12 : resTime[0];
  } else {
    var resTime = [0,0];
  }
  const formatted = `${resTime[0]} : ${resTime[1]} }`
  return formatted;
}

export const isSameDay = (date1, date2) => {
  return date1.getFullYear() === date2.getFullYear() &&
         date1.getMonth() === date2.getMonth() &&
         date1.getDate() === date2.getDate();
}