import "globals";
import Backbone from 'backbone-shim';
import "backbone.fetch-cache";
import MOP from 'app';
import Bootstrap from 'bootstrap';
import Handlebars from 'handlebars';
import _storage from 'storage';
import { querystringToObject, isProduction } from 'common-lib/url';
import { _ajaxRest } from 'api/http';
import { TTPolyglot } from "@frontend/tt-polyglot";

// [FIXME] Mattia - Oggetto globale usata da callback per tt_ico
if (typeof window.docCookies === 'undefined') {
    window.docCookies = {
        getItem: function (sKey) {
            if (!sKey) {
                return null;
            }

            return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
        },

        setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
            if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
                return false;
            }
            var sExpires = "";
            if (vEnd) {
                switch (vEnd.constructor) {
                    case Number:
                        sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
                        break;
                    case String:
                        sExpires = "; expires=" + vEnd;
                        break;
                    case Date:
                        sExpires = "; expires=" + vEnd.toUTCString();
                        break;
                }
            }

            document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");

            return true;
        },

        removeItem: function (sKey, sPath, sDomain) {
            if (!this.hasItem(sKey)) {
                return false;
            }

            document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");

            return true;
        },

        hasItem: function (sKey) {
            if (!sKey) {
                return false;
            }
            return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
        },

        keys: function () {
            var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
            for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) {
                aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
            }
            return aKeys;
        }
    };
}

// var originalTimeout = window.setTimeout;

// // Passando come terzo parametro true si fa in modo
// // che non vengano eseguite altre setTimeout fintanto
// // che la funzione chiamata con true non è stata eseguita.
// // -------------------------------------------------------
// // utile per debuggare l'ordine in cui vengono eseguite
// // una serie di setTimeout
// window.setTimeout = (function () {
//     var paused = false;

//     return function () {
//         var _this = this;
//         var toPause = arguments[2] || false;
//         var originalFunction = arguments[0];
//         var functionName = originalFunction.toString()
//                                 .replace(/\r?\n|\r/g, '')
//                                 .replace(/function\s*(\S*)\s*\(.*/, '$1');

//         console.log('Timeout function:', functionName, arguments[0] || arguments[0].toString());
//         console.trace();

//         function myFunction() {
//             paused = false;
//             originalFunction.call(_this);
//         }

//         var wrappedFunction = !toPause ? originalFunction : myFunction;

//         !paused && originalTimeout.call(this, wrappedFunction, arguments[1]);

//         paused = toPause;
//     };
// }());

// Se questo caricamento deriva da un secondo caricamento
// fatto non eseguo lo start dell'applicazione
var disableMainBuildStart = window.docCookies.getItem('disableMainBuildStart');
var mainLoaded = window.docCookies.getItem('mainLoaded');
var startApplication = true;

// Se true la build proviene da caricamento parziale.
if (disableMainBuildStart) {
    window.docCookies.removeItem('disableMainBuildStart');

    // La build corrente è una build parziale e non la main build.
    if (disableMainBuildStart == 1) {
        window.docCookies.setItem('disableMainBuildStart', 2);
    }
    // La build corrente è la build totale ed è già stata startata la build parziale,
    // voglio che non venga ri-eseguito lo start dell'applicazione in questo caso.
    else if (disableMainBuildStart == 2) {
        startApplication = false;
    }
}

if (startApplication) {

        //http://redmine.tuotempo.net/issues/10857
        // Questa funzione ci permette utilizzando un plugin SchemaUrl per cordova di aprire l'applicazione tramite schemaUrl
        // Questo plugin ha un bug che non è stato risolto dal creatore, in pratica intercetta tutti i window.open("", "_system")
        // che vengono aperti nell'app, questo comporta che se apriamo un qualsiasi url che contiene un sessionid o un forcePage
        // andiamo a far scattare tutta la logica che c'è qui sotto, per questo motivo andiamo a mettere dei controlli sul customerConfig
        window.handleOpenURL = function (url) {
          setTimeout(function() {
            console.log("received url: " + url);
            MOP.Utilities.handleSchemaUrl(MOP, url);
            
          }, 0);
        }

        // Funzioni aggiuntive jQuery
        $.fn.selectRange = function(start, end) {
            if (!end) {
                end = start;
            }

            return this.each(function() {
                if (this.setSelectionRange) {
                    this.focus();
                    this.setSelectionRange(start, end);
                } else if (this.createTextRange) {
                    var range = this.createTextRange();

                    range.collapse(true);
                    range.moveEnd('character', end);
                    range.moveStart('character', start);
                    range.select();
                }
            });
        };
        $.cssHooks.backgroundColor = {
            get: function(elem) {
                function hex(x) {
                    return ("0" + parseInt(x).toString(16)).slice(-2);
                }

                if (elem.currentStyle)
                    var bg = elem.currentStyle["backgroundColor"];
                else if (window.getComputedStyle)
                    var bg = document.defaultView.getComputedStyle(elem,
                        null).getPropertyValue("background-color");
                if (bg.search("rgb") == -1)
                    return bg;
                else {
                    bg = bg.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

                    return "#" + hex(bg[1]) + hex(bg[2]) + hex(bg[3]);
                }
            }
        };
        // Funzioni aggiuntive Underscore

        /**
         * GroupBy innestato
         *
         * @param {Object} obj - literal da ordinare
         * @param {Array} values - elementi su cui eseguire gli ulteriori groupBy
         * @param context
         * @returns {Object}
         */
        _.groupByMulti = function(obj, values, context) {
            if (!values.length) {
                return obj;
            }

            var byFirst = _.groupBy(obj, values[0], context);
            var rest = values.slice(1);

            for (var prop in byFirst) {
                byFirst[prop] = _.groupByMulti(byFirst[prop], rest, context);
            }

            return byFirst;
        };

        /**
         * Ritorna il primo elemento di un oggetto
         *
         * @param {Object}
         * @returns {Object}
         */
        _.firstElem = function(obj) {
            function firstKey(obj) {
                return _.keys(obj)[0];
            }

            function elemByKey(obj, elem) {
                return obj[elem];
            }

            return _.compose(_.partial(elemByKey, obj), firstKey)(obj);
        };

        // Handler globale sul resize orizzontale della window.
        $(window).on('resize', function() {
            if (MOP.config) {
                if (MOP.config.isMobile() && !MOP.config.from_mobile) {
                    MOP.vent.trigger('view:mobile');
                    MOP.config.from_mobile = true;
                    MOP.Utilities.log(MOP, 'mobile');
                } else if (!MOP.config.isMobile() && MOP.config.from_mobile){
                    MOP.vent.trigger('view:desktop');
                    MOP.config.from_mobile = false;
                    MOP.Utilities.log(MOP, 'desktop');
                }
            }
        });

        // Handler globale sulla pressione del pulsante invio,
        // attivato solo nella pagina login e profile.
        $(window).on('keypress', function (e) {
            var enabledPage = ['profile'];

            if (e.keyCode === 13 && enabledPage.indexOf(MOP.getCurrentPage()) !== -1) {
                e.stopPropagation();
                e.preventDefault();
            }
        });


        if (MOP.Utilities.isMobileApp() && MOP.Utilities.isAppleDevice()) {
          $(document).on('focus', 'input', function() {
            $('body').addClass('fixfixed');
          });

          $(document).on('blur', 'input', function() {
            $('body').removeClass('fixfixed');
          });

        }

        var start = function start(querystring_params) {
          Backbone.ajax = _ajaxRest;
        
          // il controllo della connessione all'apertutra dell'app viene fatto qui così possiamo richiamare lo start
          
          if (MOP.Utilities.isMobileApp() && !window.navigator.onLine) {
            MOP.Utilities.loadRecoveryDeviceLang(MOP).then(() => {
              return setTimeout(() => navigator.notification.confirm(
                TTPolyglot.t("MOP App Device Offline On Start Message"),  // message
                () => start(querystring_params),         // callback
                TTPolyglot.t("MOP App Device Offline On Start Title"),            // title
                TTPolyglot.t("Retry")            // buttonName
            ), 0)
            })
          }
          if (disableMainBuildStart == 1 && !Boolean(mainLoaded)) {
            window.bundleUtilities2(window.MOP_globals).downloadAndCacheMainBuild();
          }
            //wrapper per poter scrivere insuranceTitle piuttosto che insurance_title
            if (!MOP.Utilities.empty(querystring_params['insuranceTitle'])) {
                querystring_params['insurance_title'] = querystring_params['insuranceTitle'];
            }

            // Se è forzato il forceLog=true setto l'env a development,
            // in questo modo i MOP.log sono visibili in console.
            var forceLog = querystring_params['forceLog'];
            var env = (forceLog && forceLog === 'true') ? 'developement' : 'production';
            var initOptions = {
                querystring: querystring_params,
                environment: env
            };

            //NB Prime the cache from localStorage on initialization => commentato nella libreria
            $.when(Backbone.fetchCache.getLocalStorage())
                .done(function() {
                    // Inizializzo il MOP, tra cui:
                    //     1) inizializzazione *Storage,
                    //     2) startNewMop(),
                    //     3) new Config()
                    $.when(MOP.init(initOptions))
                        .done(async function() {

                          if (!MOP.Utilities.empty(MOP.config.getInstanceConfig('MopParentLocationsTrackingEnabled'))) {
                            MOP.sendMOPLocation();
                          }
                            // Gestione globale di exception, in questo punto possiamo
                            // catturare tutte le eventuali eccezioni che bloccherebbero il
                            // flusso e non gestite e salvarle in un log.
                            /*window.onerror = function (msg, url, lineNo, columnNo, error) {
                                if (msg + ''.indexOf('TUOTEMPO_') == -1) {
                                    var data = {};
                                    var body = '';

                                    body += 'Message: ' + msg + '\n';
                                    body += 'Url: ' + url + '\n';
                                    body += 'Line number: ' + lineNo + '\n';
                                    body += 'Column number: ' + columnNo + '\n';
                                    body += 'Error: ' + error + '\n';
                                    body += 'dbName: ' + MOP_globals.dbName + '\n';
                                    body += 'Current Page: ' + MOP_globals.page + '\n';
                                    body += 'Browser: ' + window.navigator.userAgent + '\n';
                                    body += 'Environment: ' + MOP.Utilities.isMobileApp() ? 'App' : 'Web' + '\n';

                                    data.body = body;

                                    MOP.ajax('send_mop_log_email', data);
                                }

                                return false;
                            }*/

                            // Aggiungo un css dinamico alla pagina con dimensioni relative all'altezza della view.
                            MOP.TM.init();

                            var doneFunction = function () {
                              // Controlliamo in questo punto se ci sono delle notifiche da visualizzare perchè qui abbiamo caricato le lingue
                              if (MOP.localStorage.has('alert')) {
                                  var alert = MOP.localStorage.get('alert');

                                  MOP.execute('MOP:alert', alert);
                                  MOP.localStorage.unset('alert');
                              }
                              return MOP.start(initOptions);
                            }

                            var lang = MOP.config.get('lang');
                            var special_lang = MOP.config.getInstanceConfig('special_lang');
                            var defaultLang = MOP.config.getInstanceConfig('defaultLanguage');
                            var now = Date.now();

                            const configObj = {
                              version: MOP.config.get("langSpecificVersion"),
                              lang,
                              defaultLang,
                              instanceType: special_lang
                            }

                            // Se siamo su ambiente non produzione andiamo a mettere nel basepath il window.location.origin
                            // Perchè altrimenti i CORS ci bloccano l'accesso al CDN
                            if(!isProduction() && !MOP.Utilities.isMobileApp()) {
                              configObj.basePath = window.location.origin;
                            }

                            const phrases = await TTPolyglot.loadPhrases(configObj);
                            
                            TTPolyglot.extendPhrases(phrases);


                            const specifics = MOP.config.get('instance').spec_lang;
                            TTPolyglot.extendPhrases(specifics);
                            
                            // Usato per risolvere il problema della lettura di questo da Handlebars
                            window.TTPolyglot = TTPolyglot;
                            window.MOP = MOP;
                            
                            doneFunction();
                            

                            
                            // Altre gestioni nel caso di app mobile
                            if (MOP.Utilities.isMobileApp()) {
                            	MOP.MAM.init( initOptions );
                              MOP.on('subapp:start', function (page) {
                                // Fix momentaneo per questo collegato a un FD: https://git.tuotempo.net:8060/tuotempo/trunk/issues/164
                                if ((page == 'home' || page.indexOf("search") != -1) && MOP.isLoggedIn() && !MOP.isAdminLoggedIn()) MOP.MAM.initCheckinBanner();
                              });
                            }

                            MOP.addBeforeLeavingHandler();
                        })
                        .fail(function() {
                            MOP.Utilities.log(MOP, 'querystring_params failed!');
                        });
                })
                .fail(function() {
                    MOP.Utilities.log(MOP, 'localstorage failed!');
                });
        };

        var querystring_params = querystringToObject(window.location.search.substr(1));

        if (!MOP.Utilities.empty(querystring_params['forceMobileApp'])) {
            window.MOP_globals.fromMobileApp   = true;
            window.MOP_globals.isFakeMobileApp = true;
        }

        if (window.MOP_globals.fromMobileApp) {
          // Se siamo su Cordova aspettiamo l'evento deviceready
          // per startare il MOP altrimenti lo facciamo subito.

          var dbName = (querystring_params['dbName'] ? querystring_params['dbName'] : MOP_globals.dbName);

          MOP.DM.loadjscssfile('js/customers/' + dbName + '.js',
            function () {
              // NB => se stiamo simulando una mobile app => allora startiamo subito
              if ( window.MOP_globals.isFakeMobileApp ){
                start(querystring_params);
              } else{
                document.addEventListener('deviceready', function () {
                  //puglin Cordova che disabilita la visualizzazione dell'app in dark mode
                  WebViewManager.setForceDarkAllowed(false);

                  if (process.env.DISABLE_SSL_PINNING) {
                    start(querystring_params);
                  } else {
                    // il plugin ha bisogno del certificato nella cartella www/certificates del progetto cordova
                    // procede sempre con la callback di successo ma nel caso in cui non ci sia il certificato
                    // allora non permette le chiamate API
                    cordova.plugin.http.setServerTrustMode('pinned', 
                    () => {
                      // Possiamo chiamare la start mop
                      start(querystring_params);
                    }, 
                    () => {
                      console.log('error');
                    });
                  }
                }, false);
              }
            },
            function (err) {
              start(querystring_params);
            });
        } else {
            start(querystring_params);
        }
}
