var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <label class=\"tt-input-radio\">\n              <input type=\"radio\" name=\"region\" value=\""
    + container.escapeExpression(alias1(depth0, depth0))
    + "\" class=\"tt-input-radio__input nestedPageLinkBack writeNestedPageSelection\" writeNested-ToId=\"regionField\"\n                  nestedDataToWriteBack=\": "
    + ((stack1 = alias1(depth0, depth0)) != null ? stack1 : "")
    + "\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"region") : depths[1]),"==",(data && lookupProperty(data,"key")),{"name":"ifCond","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":55},"end":{"line":9,"column":105}}})) != null ? stack1 : "")
    + ">\n              <div class=\"tt-input-radio__content\">\n                  <div class=\"tt-input-radio__content-text\">\n                      "
    + ((stack1 = alias1(depth0, depth0)) != null ? stack1 : "")
    + "\n                  </div>\n                  <i class=\"tt-input-radio__content-icon tt-icon-check\"></i>\n              </div>\n          </label>\n\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "selected";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tt-column is-8 is-offset-2\">\n  <span data-nested-page=\"address_region\" style=\"display: none;\">\n      <div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Region",{"name":"translate","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":28},"end":{"line":3,"column":65}}})) != null ? stack1 : "")
    + "</div>\n      <div class=\"tt-container\">\n        <div class=\"tt-input-group tt-container-list-radius\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"regions") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":10},"end":{"line":18,"column":19}}})) != null ? stack1 : "")
    + "      </div>\n      </div>\n\n      <div class=\"tt-container tt-hidden-xs\">\n          <div class=\"tt-button tt-button--secondary nestedPageLinkBack\">\n              "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Back",{"name":"translate","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":14},"end":{"line":24,"column":49}}})) != null ? stack1 : "")
    + "\n          </div>\n      </div>\n  </span>\n</div>";
},"useData":true,"useDepths":true});