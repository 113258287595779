import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";

import { Avatar } from "../Avatar";
import { Label } from "../Label";


export const AvatarWithTitle = ({ imgSrc = "", title = "", text = "", e2eid }) => {

  return (
    <div css={style.base}>
      <Avatar src={imgSrc} />
      <div {...e2eid ? {"data-e2eid":e2eid } : null} css={style.text}>
        <Label size={"medium"} weight={"medium"}>{title}</Label>
        <Label size={"medium"} color={"text_light"}>{text}</Label>
      </div>
    </div>
  );
};

AvatarWithTitle.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  imgSrc: PropTypes.string,
};