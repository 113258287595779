import React from 'react';
import PropTypes from 'prop-types';
import * as style from "./style";
import * as icons from "./icons";
import { useTheme } from '@emotion/react';

export const Icon = ({ e2eid, name = "", color = null, size = "small", rotate = false }) => {

  const theme = useTheme();

  if (!name) return <span />;
  
  return (
    <span {...e2eid ? {"data-e2eid":e2eid } : null} css={style.base(theme)({color, size, rotate})}>
      {icons[name]({})}
    </span>);
};


Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  rotate: PropTypes.bool
};