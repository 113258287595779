var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "required";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div data-nested-page=\"first\">\n            <div class=\"tt-container\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description_title") || (depth0 != null ? lookupProperty(depth0,"description_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description_title","hash":{},"data":data,"loc":{"start":{"line":4,"column":38},"end":{"line":4,"column":59}}}) : helper)))
    + "</div>\n            <div class=\"tt-input-container tt-input-container--link\">\n                <input "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"chars_limit") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":23},"end":{"line":6,"column":76}}})) != null ? stack1 : "")
    + " id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_id") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":81},"end":{"line":6,"column":123}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"custom_field") || (depth0 != null ? lookupProperty(depth0,"custom_field") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom_field","hash":{},"data":data,"loc":{"start":{"line":6,"column":123},"end":{"line":6,"column":139}}}) : helper)))
    + "\"\n                       class=\"tt-input\"\n                       name=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_id") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":29},"end":{"line":8,"column":71}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"custom_field") || (depth0 != null ? lookupProperty(depth0,"custom_field") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom_field","hash":{},"data":data,"loc":{"start":{"line":8,"column":71},"end":{"line":8,"column":87}}}) : helper)))
    + "\"\n                       placeholder=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"placeholder") || (depth0 != null ? lookupProperty(depth0,"placeholder") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placeholder","hash":{},"data":data,"loc":{"start":{"line":9,"column":36},"end":{"line":9,"column":51}}}) : helper)))
    + "\"\n                       value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"actualValue") || (depth0 != null ? lookupProperty(depth0,"actualValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"actualValue","hash":{},"data":data,"loc":{"start":{"line":10,"column":30},"end":{"line":10,"column":45}}}) : helper)))
    + "\"\n                       type=\"text\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"chars_type") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":35},"end":{"line":11,"column":76}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"cannot_edit") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":77},"end":{"line":11,"column":126}}})) != null ? stack1 : "")
    + " />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description_link") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":17,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description_link") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":29,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "maxlength=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"chars_limit") || (depth0 != null ? lookupProperty(depth0,"chars_limit") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"chars_limit","hash":{},"data":data,"loc":{"start":{"line":6,"column":53},"end":{"line":6,"column":68}}}) : helper)))
    + "\"";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"multiple_id") || (depth0 != null ? lookupProperty(depth0,"multiple_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"multiple_id","hash":{},"data":data,"loc":{"start":{"line":6,"column":100},"end":{"line":6,"column":115}}}) : helper)))
    + "_";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = ((helper = (helper = lookupProperty(helpers,"chars_type") || (depth0 != null ? lookupProperty(depth0,"chars_type") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"chars_type","hash":{},"data":data,"loc":{"start":{"line":11,"column":53},"end":{"line":11,"column":69}}}) : helper))) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a href=\"javascript:;\" \n									 class=\"tt-input-container--link-text tt-icon-question-circle\" \n									 id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_id") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":14},"end":{"line":15,"column":56}}})) != null ? stack1 : "")
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"custom_field") || (depth0 != null ? lookupProperty(depth0,"custom_field") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"custom_field","hash":{},"data":data,"loc":{"start":{"line":15,"column":56},"end":{"line":15,"column":72}}}) : helper)))
    + "_link\"></a>\n                \n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_id") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":25},"end":{"line":20,"column":67}}})) != null ? stack1 : "")
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"custom_field") || (depth0 != null ? lookupProperty(depth0,"custom_field") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom_field","hash":{},"data":data,"loc":{"start":{"line":20,"column":67},"end":{"line":20,"column":83}}}) : helper)))
    + "_description\" style=\"display: none;\" class=\"tt-container\">\n                    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":21,"column":37}}}) : helper))) != null ? stack1 : "")
    + "\n                </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":28,"column":23}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-container\">\n                        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":26,"column":41}}}) : helper))) != null ? stack1 : "")
    + "\n                    </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a class=\"tt-action nestedPageLink\" data-nested-page=\"first\" data-next-nested-page=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"custom_field") || (depth0 != null ? lookupProperty(depth0,"custom_field") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom_field","hash":{},"data":data,"loc":{"start":{"line":34,"column":92},"end":{"line":34,"column":108}}}) : helper)))
    + "\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"cannot_edit") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":110},"end":{"line":34,"column":180}}})) != null ? stack1 : "")
    + ">\n            <div class=\"tt-action__content\">\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"description_title") || (depth0 != null ? lookupProperty(depth0,"description_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description_title","hash":{},"data":data,"loc":{"start":{"line":36,"column":16},"end":{"line":36,"column":37}}}) : helper)))
    + "<span id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_id") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":47},"end":{"line":36,"column":89}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"custom_field") || (depth0 != null ? lookupProperty(depth0,"custom_field") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom_field","hash":{},"data":data,"loc":{"start":{"line":36,"column":89},"end":{"line":36,"column":105}}}) : helper)))
    + "_preview\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"actualValue") || (depth0 != null ? lookupProperty(depth0,"actualValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"actualValue","hash":{},"data":data,"loc":{"start":{"line":36,"column":115},"end":{"line":36,"column":130}}}) : helper)))
    + "</span>\n            </div>\n            <span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n        </a>\n        <div data-nested-page=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_id") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":31},"end":{"line":40,"column":73}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"custom_field") || (depth0 != null ? lookupProperty(depth0,"custom_field") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom_field","hash":{},"data":data,"loc":{"start":{"line":40,"column":73},"end":{"line":40,"column":89}}}) : helper)))
    + "\" style=\"display: none;\">\n            <div class=\"tt-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description_title") || (depth0 != null ? lookupProperty(depth0,"description_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description_title","hash":{},"data":data,"loc":{"start":{"line":41,"column":34},"end":{"line":41,"column":55}}}) : helper)))
    + "</div>\n            <div class=\"tt-container\">\n                <div class=\"tt-input-container\">\n                    <textarea "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"chars_limit") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":30},"end":{"line":44,"column":83}}})) != null ? stack1 : "")
    + " style=\"height: 60px;\"\n                              id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_id") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":34},"end":{"line":45,"column":76}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"custom_field") || (depth0 != null ? lookupProperty(depth0,"custom_field") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom_field","hash":{},"data":data,"loc":{"start":{"line":45,"column":76},"end":{"line":45,"column":92}}}) : helper)))
    + "\"\n                              name=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_id") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":36},"end":{"line":46,"column":78}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"custom_field") || (depth0 != null ? lookupProperty(depth0,"custom_field") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom_field","hash":{},"data":data,"loc":{"start":{"line":46,"column":78},"end":{"line":46,"column":94}}}) : helper)))
    + "\"\n                              placeholder=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"placeholder") || (depth0 != null ? lookupProperty(depth0,"placeholder") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placeholder","hash":{},"data":data,"loc":{"start":{"line":47,"column":43},"end":{"line":47,"column":58}}}) : helper)))
    + "\"\n                              "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"cannot_edit") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":30},"end":{"line":48,"column":79}}})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"actualValue") || (depth0 != null ? lookupProperty(depth0,"actualValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"actualValue","hash":{},"data":data,"loc":{"start":{"line":48,"column":80},"end":{"line":48,"column":95}}}) : helper)))
    + "</textarea>\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":16},"end":{"line":54,"column":23}}})) != null ? stack1 : "")
    + "                <button type=\"button\" class=\"tt-button tt-button--primary nestedPageLinkBack writeNestedPageSelection\" writenested-toid=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_id") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":137},"end":{"line":55,"column":179}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"custom_field") || (depth0 != null ? lookupProperty(depth0,"custom_field") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom_field","hash":{},"data":data,"loc":{"start":{"line":55,"column":179},"end":{"line":55,"column":195}}}) : helper)))
    + "_preview\" getnesteddata-fromid=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_id") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":227},"end":{"line":55,"column":269}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"custom_field") || (depth0 != null ? lookupProperty(depth0,"custom_field") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom_field","hash":{},"data":data,"loc":{"start":{"line":55,"column":269},"end":{"line":55,"column":285}}}) : helper)))
    + "\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Insert",{"name":"translate","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":20},"end":{"line":56,"column":57}}})) != null ? stack1 : "")
    + "\n                </button>\n            </div>\n        </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "style=\"pointer-events: none;\"";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-container\">\n                      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":52,"column":22},"end":{"line":52,"column":39}}}) : helper))) != null ? stack1 : "")
    + "\n                    </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "";
},"26":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <a class=\"tt-action nestedPageLink\" data-nested-page=\"first\" data-next-nested-page=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_id") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":90},"end":{"line":63,"column":132}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"custom_field") || (depth0 != null ? lookupProperty(depth0,"custom_field") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom_field","hash":{},"data":data,"loc":{"start":{"line":63,"column":132},"end":{"line":63,"column":148}}}) : helper)))
    + "\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"cannot_edit") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":150},"end":{"line":63,"column":220}}})) != null ? stack1 : "")
    + ">\n          <div class=\"tt-action__content\">\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"description_title") || (depth0 != null ? lookupProperty(depth0,"description_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description_title","hash":{},"data":data,"loc":{"start":{"line":65,"column":16},"end":{"line":65,"column":37}}}) : helper)))
    + "<span id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_id") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":47},"end":{"line":65,"column":89}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"custom_field") || (depth0 != null ? lookupProperty(depth0,"custom_field") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom_field","hash":{},"data":data,"loc":{"start":{"line":65,"column":89},"end":{"line":65,"column":105}}}) : helper)))
    + "\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"actualValue") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":107},"end":{"line":65,"column":135}}})) != null ? stack1 : "")
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"actualValue") || (depth0 != null ? lookupProperty(depth0,"actualValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"actualValue","hash":{},"data":data,"loc":{"start":{"line":65,"column":135},"end":{"line":65,"column":152}}}) : helper))) != null ? stack1 : "")
    + "</span>\n          </div>\n          <span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n      </a>\n      <span data-nested-page=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_id") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":30},"end":{"line":69,"column":72}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"custom_field") || (depth0 != null ? lookupProperty(depth0,"custom_field") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom_field","hash":{},"data":data,"loc":{"start":{"line":69,"column":72},"end":{"line":69,"column":88}}}) : helper)))
    + "\" style=\"display: none;\">\n        <div class=\"tt-label\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description_title") || (depth0 != null ? lookupProperty(depth0,"description_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description_title","hash":{},"data":data,"loc":{"start":{"line":70,"column":30},"end":{"line":70,"column":51}}}) : helper)))
    + "</div>\n        <div class=\"tt-container\">\n            <div class=\"tt-input-group\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"selectFieldOptions") : depth0),{"name":"each","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":10},"end":{"line":83,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        </div>\n      </span>\n";
},"27":function(container,depth0,helpers,partials,data) {
    return ": ";
},"29":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <label class=\"tt-input-radio js-no-required-validation\">\n              <input class=\"tt-input-radio__input nestedPageLinkBack writeNestedPageSelection\" type=\"radio\" name=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"multiple_id") : depths[1]),{"name":"if","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":114},"end":{"line":75,"column":162}}})) != null ? stack1 : "")
    + alias3(alias2((depths[1] != null ? lookupProperty(depths[1],"custom_field") : depths[1]), depth0))
    + "\" value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\" writeNested-ToId=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"multiple_id") : depths[1]),{"name":"if","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":224},"end":{"line":75,"column":272}}})) != null ? stack1 : "")
    + alias3(alias2((depths[1] != null ? lookupProperty(depths[1],"custom_field") : depths[1]), depth0))
    + "\" nestedDataToWriteBack=\": "
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0)) != null ? stack1 : "")
    + "\">\n              <div class=\"tt-input-radio__content\">\n                  <div class=\"tt-input-radio__content-text\">\n                      "
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0)) != null ? stack1 : "")
    + "\n                  </div>\n                  <i class=\"tt-input-radio__content-icon tt-icon-check\"></i>\n              </div>\n          </label>\n";
},"30":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"multiple_id") : depths[1]), depth0))
    + "_";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div data-nested-page=\"first\">\n      <div class=\"tt-input-container\">\n        <input class=\"tt-input tt-no-border-top tovalidate tt-mt-0\" name=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_id") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":74},"end":{"line":92,"column":116}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"custom_field") || (depth0 != null ? lookupProperty(depth0,"custom_field") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom_field","hash":{},"data":data,"loc":{"start":{"line":92,"column":116},"end":{"line":92,"column":132}}}) : helper)))
    + "\" placeholder=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"description_title") || (depth0 != null ? lookupProperty(depth0,"description_title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description_title","hash":{},"data":data,"loc":{"start":{"line":92,"column":147},"end":{"line":92,"column":168}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"placeholder") || (depth0 != null ? lookupProperty(depth0,"placeholder") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placeholder","hash":{},"data":data,"loc":{"start":{"line":92,"column":169},"end":{"line":92,"column":184}}}) : helper)))
    + "\" type=\"tel\" id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multiple_id") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":201},"end":{"line":92,"column":243}}})) != null ? stack1 : "")
    + alias4(((helper = (helper = lookupProperty(helpers,"custom_field") || (depth0 != null ? lookupProperty(depth0,"custom_field") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"custom_field","hash":{},"data":data,"loc":{"start":{"line":92,"column":243},"end":{"line":92,"column":259}}}) : helper)))
    + "\"\n             value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"actualValue") || (depth0 != null ? lookupProperty(depth0,"actualValue") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"actualValue","hash":{},"data":data,"loc":{"start":{"line":93,"column":20},"end":{"line":93,"column":35}}}) : helper)))
    + "\" "
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"cannot_edit") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":37},"end":{"line":93,"column":86}}})) != null ? stack1 : "")
    + " date-validator=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"date_validator") || (depth0 != null ? lookupProperty(depth0,"date_validator") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date_validator","hash":{},"data":data,"loc":{"start":{"line":93,"column":103},"end":{"line":93,"column":121}}}) : helper)))
    + "\" data-validation-error=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Inserire il giusto formato [CHIAVE]",{"name":"translate","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":146},"end":{"line":93,"column":212}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"placeholder") || (depth0 != null ? lookupProperty(depth0,"placeholder") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placeholder","hash":{},"data":data,"loc":{"start":{"line":93,"column":213},"end":{"line":93,"column":228}}}) : helper)))
    + "\">\n      </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":6},"end":{"line":99,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"tt-container\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":97,"column":8},"end":{"line":97,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"mandatory") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":44}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"inputFieldType") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":31,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"textareaFieldType") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":4},"end":{"line":60,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selectFieldType") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":4},"end":{"line":87,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"dataFieldType") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":4},"end":{"line":101,"column":11}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true,"useDepths":true});