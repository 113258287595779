import { useTheme } from '@emotion/react';
import React, { useState, useRef, useLayoutEffect } from "react";
import PropTypes from 'prop-types';

import * as style from "./style";

export const Tooltip = ({ children = null, text = null, maxWidth }) => {

  const theme = useTheme();
  const [visible, setVisible] = useState(false);
  const [childrenSizeHeight, setChildrenSizeHeight] = useState(0);
  const childrenRef = useRef(null);

  if (!children || !text) return null;

  useLayoutEffect(() => {
    if (!childrenRef || !childrenRef.current) return;

    // Prendo l'altezza e l'offsetTop del children per calcolare il posizionamento corretto del tooltip
    const { height } = childrenRef?.current?.getBoundingClientRect();
    const top = childrenRef?.current?.offsetTop;

    setChildrenSizeHeight(height + top);
  }, []);

  const onMouseEnterHandler = () => {
    setVisible(true);
  };

  const onMouseLeaveHandler = () => {
    setVisible(false);
  };

  return (
    <div css={style.container}>
      <div
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        ref={childrenRef}
        css={style.childrenContainer}
      >
        {children}
      </div>

      <div
        css={style.base(theme)({
          isVisible: visible,
          top: childrenSizeHeight + 10,
          maxWidth
        })}
      >
        <p css={style.text}>{text}</p>
      </div>
    </div>
  );
};


Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  maxWidth: PropTypes.string
};