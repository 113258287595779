var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n          <div class=\"tt-container\">\n            <div class=\"modal-body\">\n                <div class=\"tt-text-bold tt-mb-10\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":51},"end":{"line":6,"column":60}}}) : helper)))
    + "</div>\n                <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"msg") || (depth0 != null ? lookupProperty(depth0,"msg") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"msg","hash":{},"data":data,"loc":{"start":{"line":7,"column":21},"end":{"line":7,"column":28}}}) : helper)))
    + "</div>\n                <div class=\"tt-input-container js-dialog-input-container\" style=\"margin-top: 20px\">\n                    <input class=\"tt-input js-input-dossier-manual-update\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Dossier Check Updates Code Placeholder",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":88},"end":{"line":9,"column":157}}})) != null ? stack1 : "")
    + "\">\n                </div>\n            </div>\n            <div class=\"modal-footer\">\n              <div class=\"tt-flexend-container tt-dialog-button js-buttons-container\">\n                <button type=\"button\" class=\"tt-button tt-button--secondary\" data-dismiss=\"modal\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Clear",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":98},"end":{"line":14,"column":134}}})) != null ? stack1 : "")
    + "</button>\n                <button type=\"button\" class=\"tt-button tt-button--primary\" id=\"yes_click_modal\" disabled>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Dossier Check Updates Check Button",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":105},"end":{"line":15,"column":170}}})) != null ? stack1 : "")
    + "</button>\n              </div>\n              <div style=\"display: none; padding-bottom: 30px\" id=\"loader_container\">\n                <div class=\"favorite_loader loader_ajax_small\" style=\"position: absolute; top: 50%; left: 50%; margin: 0px 0px 0px -15px;\"></div>\n              </div>\n            </div>\n          </div>\n        </div>\n    </div>\n</div>";
},"useData":true});