import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { debounce } from "lodash";
import { useTheme } from '@emotion/react';
import { TTPolyglot } from "@frontend/tt-polyglot";

import * as style from "./style.js";
import { Label } from '../Label';
import { Icon } from '../Icon';
import { Loading } from '../Loading/index.jsx';


export const PasswordInput = ({
  onChange = null,
  placeholder = "",
  loading = false,
  rules = [],
  resetRules = null
}) => {

  const theme = useTheme();
  const [inputValue, setInputValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const debounceInput = useCallback(debounce(val => {
    // Per evitare problemi di concorrenza tra resetRules e onChange, il controllo se chiamare una o l'altra
    // è stato messo direttamente dentro la debounce. così la debounce stessa sa quale callback deve chiamare
    // Altrimenti se questo fosse stato fatto fuori dalla debounce, ci sarebbero stati problemi di concorrenza
    // per i quali la onChange veniva chiamata, ma tornava dopo che la resetRules era già stata eseguita

    // Se ho passato un input vuoto allora vado a resettare tutte le regole
    if(val.length === 0 && resetRules) return resetRules();

    // Altrimenti vado a chiamare l'onChange
    return onChange(val);
  }, 500), []);

  useEffect(() => {
    if (inputValue.length >= 3 || inputValue.length === 0) {
      debounceInput(inputValue);
    }
  }, [inputValue]);


  const getCheckIcon = check => {
    if (check === "OK")
      return "SquareCheckSolid";
    if (check === "ERROR")
      return "SquareXmarkSolid";
    return "SquareSolid";
  };

  const getCheckColor = check => {
    if (check === "OK")
      return "success";
    if (check === "ERROR")
      return "danger";
    return "text_light";
  };

  return (
    <div css={style.base(theme)}>
      <div css={style.labelBase(theme)}>
        <Label color="text_light" size="medium">
          {TTPolyglot.t('MOP New Password Must Contain')}
        </Label>
        {
          rules.length > 0 &&
          <div css={style.checkLabelsContainer(theme)}>
            {rules.map(({ label, check }, index) => (
              <div css={style.checkLabelsContent(theme)} key={index}>
                <Icon size="micro" name={getCheckIcon(check)} color={getCheckColor(check)} />
                <Label color={getCheckColor(check)} size="medium">
                  {label}
                </Label>
              </div>
            )
            )}
          </div>
        }
      </div>
      <div css={style.passwordBase(theme)}>
        <div css={style.passwordContainer(theme)}>
          <div css={style.containerContent}>
            <input
              css={style.input(theme)}
              onChange={e => setInputValue(e.target.value)}
              placeholder={placeholder}
              type={showPassword ? "text" : "password"}
            />
            {loading &&
              <div css={style.iconContainer()}>
                <Loading />
              </div>
            }
            <div css={style.iconContainer(true)} onClick={() => setShowPassword(!showPassword)}>
              <Icon name={showPassword ? "EyeSlashSolid" : "EyeSolid"} color="primary" size="l" />
            </div>
          </div>
        </div>
      </div>
    </div >

  );
};

PasswordInput.propTypes = {
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  rules: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    check: PropTypes.oneOf(["ERROR", "OK", "INIT"]),
  })),
  resetRules: PropTypes.func
};
