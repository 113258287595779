const Handlebars = require('../../../node_modules/handlebars/runtime.js'); // ho messo ../ andando dentro node_modules perchè altrimenti non vede la libreria

Handlebars.registerHelper('currencySymbol', function() {
  return window.MOP.config.getCurrencySymbol();
});


Handlebars.registerHelper('isLoggedIn', function(options) {
  return window.MOP.isLoggedIn() ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('isNotLoggedIn', function(options) {
  return !window.MOP.isLoggedIn() ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('isAdminLoggedIn', function(options) {
  return window.MOP.isAdminLoggedIn() ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('isNotAdminLoggedIn', function(options) {
  return !window.MOP.isAdminLoggedIn() ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('isNotOneTimeUser', function(options) {
  return !window.MOP.isOneTimeUser() ? options.fn(this) : options.inverse(this);
});


Handlebars.registerHelper('isAppleDevice', function(options) {
  return window.MOP.Utilities.isAppleDevice() ? options.fn(this) : options.inverse(this);
});


Handlebars.registerHelper('ifFalse', function(property, options) {
  if (!property) {
      return options.fn(this);
  }

  return options.inverse(this);
});

Handlebars.registerHelper('ifCond', function(v1, operator, v2, options) {
  switch (operator) {
      case '!=':
          return (v1 != v2) ? options.fn(this) : options.inverse(this);
      case '==':
          return (v1 == v2) ? options.fn(this) : options.inverse(this);
      case '===':
          return (v1 === v2) ? options.fn(this) : options.inverse(this);
      case '<':
          return (v1 < v2) ? options.fn(this) : options.inverse(this);
      case '<=':
          return (v1 <= v2) ? options.fn(this) : options.inverse(this);
      case '>':
          return (v1 > v2) ? options.fn(this) : options.inverse(this);
      case '>=':
          return (v1 >= v2) ? options.fn(this) : options.inverse(this);
      case '&&':
          return (v1 && v2) ? options.fn(this) : options.inverse(this);
      case '||':
          return (v1 || v2) ? options.fn(this) : options.inverse(this);
      default:
          return options.inverse(this);
  }
});

Handlebars.registerHelper('configValue', function(configKey, defaultValue, options) {
  var configValue = window.MOP.config.getInstanceConfig(configKey);
  if (!window.MOP.Utilities.empty(configValue)) {
      return configValue;
  } else {
      return defaultValue;
  }
});

Handlebars.registerHelper('isDesktop', function(value, options) {
  if (value == 'web') {
      return (!window.MOP.config.isMobile() && !window.MOP.Utilities.isMobileApp()) ? options.fn(this) : options.inverse(this);
  } else {
      return window.MOP.config.isDesktop() ? options.fn(this) : options.inverse(this);
  }
});

Handlebars.registerHelper('translateIDType', function(value) {
  return window.MOP.Utilities.translateIDType(window.MOP.config.getInstanceConfig('idtypes'), value);
});


Handlebars.registerHelper('checkConfig', function(configKey, operator, value, options) {
  var configValue = window.MOP.config.getInstanceConfig(configKey);

  switch (operator) {
      case '!=':
          return (configValue != value) ? options.fn(this) : options.inverse(this);
      case '==':
          return (configValue == value) ? options.fn(this) : options.inverse(this);
      case '===':
          return (configValue === value) ? options.fn(this) : options.inverse(this);
      case '<':
          return (configValue < value) ? options.fn(this) : options.inverse(this);
      case '<=':
          return (configValue <= value) ? options.fn(this) : options.inverse(this);
      case '>':
          return (configValue > value) ? options.fn(this) : options.inverse(this);
      case '>=':
          return (configValue >= value) ? options.fn(this) : options.inverse(this);
      case '&&':
          return (configValue && value) ? options.fn(this) : options.inverse(this);
      case '||':
          return (configValue || value) ? options.fn(this) : options.inverse(this);
      default:
          return options.inverse(this);
  }
});

Handlebars.registerHelper('translate', function(value) {
  var args = Array.prototype.slice.call(arguments, 1, arguments.length - 1);
  var newArgs = [];

  _.reduce(args,
      function(memo, elem) {
          if (elem.indexOf('conf:') !== -1) {
              elem = window.MOP.config.getInstanceConfig(elem.substring(5));
          }

          memo.push(elem);

          return memo;
      }, newArgs);

  return window.TTPolyglot.t(value, newArgs, true);
});

Handlebars.registerHelper('indexOf', function(left, right, options) {
  if (left.indexOf(right) !== -1) {
      return options.fn(this);
  }

  return options.inverse(this);
});

Handlebars.registerHelper('humanDateLongFormat', function (start_date_day_abbr_week, start_date, start_date_month) {
  const dateFormat = window.MOP.config.getInstanceConfig('dateFormat');
  return window.MOP.Utilities.humanDateLongFormat(dateFormat, start_date_day_abbr_week, window.MOP.Utilities.humanDateToDateObj(dateFormat, start_date).getDate(), start_date_month, window.MOP.Utilities.humanDateToDateObj(dateFormat, start_date).getFullYear());
});

Handlebars.registerHelper('getLongDateFromTimestamp', function (timestamp) {
  const {getLongDateFromTimestamp} = require('common-lib/dates');
  return getLongDateFromTimestamp(timestamp);
});

Handlebars.registerHelper('humanDateToDateObjYear', function (params) {
  const dateFormat = window.MOP.config.getInstanceConfig('dateFormat');
  return window.MOP.Utilities.humanDateToDateObj(dateFormat, params).getFullYear();
});


Handlebars.registerHelper('isConfigTrue', function (configFunction, options) {
  return window.MOP.config[configFunction]() ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('getConfigConstant', function(constant) {
  return window.MOP.config.constants[constant];
});

Handlebars.registerHelper('isMobileApp', function(options) {
  return window.MOP.Utilities.isMobileApp() ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('isNotMobileApp', function(options) {
  return !window.MOP.Utilities.isMobileApp() ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('isMobile', function(options) {
  return window.MOP.config.isMobile() ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('isNotMobile', function(options) {
  return !window.MOP.config.isMobile() ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper('isMenuPageVisibile', function (page, options) {
  if (window.MOP.TM.isMenuPageVisibileForUserRole(page)) {
      return options.fn(this);
  }

  return options.inverse(this);
});

Handlebars.registerHelper('isNotFromTRD111', function (options) {
if (!window.MOP.isFromTRD111()) {
    return options.fn(this);
}

return options.inverse(this);
});

Handlebars.registerHelper('getImgPath', function () {
  return `${window.MOP.config.getMopImageBaseUrl()}`;
});


Handlebars.registerHelper('mopLocalStorageKeyIsTrue', function (key, options) {
  if (window.MOP.localStorage.has(key) && !window.MOP.Utilities.empty(window.MOP.localStorage.get(key))) {
      return options.fn(this);
  }

  return options.inverse(this);
});

Handlebars.registerHelper('mopLocalStorageKeyNotExistOrTrue', function (key, options) {
if (!window.MOP.localStorage.has(key) || (window.MOP.localStorage.has(key) && !window.MOP.Utilities.empty(window.MOP.localStorage.get(key)))) {
    return options.fn(this);
}

return options.inverse(this);
});

Handlebars.registerHelper('isNotEmpty', function (value, options) {
  if (!window.MOP.Utilities.empty(value)) {
      return options.fn(this);
  }

  return options.inverse(this);
});

Handlebars.registerHelper('isEmpty', function (value, options) {
  if (window.MOP.Utilities.empty(value)) {
      return options.fn(this);
  }

  return options.inverse(this);
});

Handlebars.registerHelper('showResources', function(options) {
  return window.MOP.isSearchEntitiesVisible('resources') ? options.fn(this) : options.inverse(this);
});


Handlebars.registerHelper('convertBR', function (msg) {
  var _msg = window.MOP.Utilities.empty(msg) ? '' : msg;
  return _msg.replace(/\n/ig, '<br>');;
});

Handlebars.registerHelper('getCreditCardIcon', function (creditCardType) {
  const { getCreditCardIcon } = require('../common/payment.js');
  return getCreditCardIcon(creditCardType)
});

Handlebars.registerHelper('creditCardStars', function () {
return '**** **** **** ';
});

Handlebars.registerHelper('isStringNotEmpty', function (string, options) {
if (string === undefined || string.trim().length === 0) {
  return options.inverse(this);
}

return options.fn(this); 
});

Handlebars.registerHelper('formattedAreaTitle', function(webTitle, areaTitle) {
  return !!webTitle ? webTitle : (!!areaTitle ? areaTitle : '');
});

Handlebars.registerHelper('getActivityTitle', function(activity_web_title, activityTitle) {
  return !!activity_web_title ? activity_web_title : (!!activityTitle ? activityTitle : '');
});

module.exports = Handlebars;