import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import * as style from "./style.js";
import { useTheme } from '@emotion/react';
import { Icon } from '../Icon';
import { Label } from '../Label';
import { Dialog } from '../Dialog';
import { Button } from '../Button';
import { TextArea } from '../TextArea';
import { TTPolyglot } from "@frontend/tt-polyglot";

export const DialogTextArea = ({ isRequired = false, title = null, description = null, placeholder = null, error = null, onSubmit = null, value = null }) => {
  const theme = useTheme();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [val, setVal] = useState(value || null);

  const textAreaRef = useRef(null);

  // TODO: Sarebbe bello poter usare il ref della TextArea al posto dello state, purtroppo non sono riuscito a
  // farlo, in quanto ho necessità di aggiornare lo stato del componente al cambio di value della TextArea
  // e questa cosa non riesco a farla con uno useRef.
  // Devo aggiornare il componente ad ogni onChange della TextArea, in quanto il pulsante per fare submit
  // deve essere abilitato/disabilitato in base al valore della textArea ed in base al valore in input

  const handleSubmit = () => {
    setDialogOpen(false);
    onSubmit && onSubmit(val);
  }

  return (
    <>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        closeButton={true}
        outClick={true}
        anchorEl={textAreaRef?.current}
        title={title}
      >
        <Dialog.Content>
          <TextArea placeholder={placeholder} value={val} onChange={setVal} />
          {description && <span dangerouslySetInnerHTML={{ __html: description }}/>}
        </Dialog.Content>
        <Dialog.Footer>
          <div css={style.buttonFullContainer}>
            <Button type={"solid"} disabled={false} disabled={!val && !value} onClick={handleSubmit}>{TTPolyglot.t("Insert")}</Button>
          </div>
        </Dialog.Footer>
      </Dialog>

      <div css={style.base}>

        {title &&
          <Label size={"medium"} weight={"medium"}>
            {title} {isRequired ? " * " : ""}
          </Label>
        }

        <div css={style.containerBase} ref={textAreaRef}>
          <div css={style.container} onClick={() => setDialogOpen(true)}>
            <div css={style.containerContent}>
              <div css={style.firstLine}>
                <Label color={"primary"}>
                  {TTPolyglot.t("Note Placeholder")}
                </Label>
                
                <Icon name='ChevronRightSolid' color='text_light' size={"medium"}></Icon>
              </div>

              {
                value &&
                <div css={style.containerContentNote}>
                  {value}
                </div>
              }
            </div>



          </div>
          {!!error && <Label color={"danger"}>{error}</Label>}
        </div>
      </div>
    </>
  )

};

DialogTextArea.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  onSubmit: PropTypes.func,
  isRequired: PropTypes.bool,
  /*
  il props VALUE serve solo per al mount del componente per settare un valore iniziale.
  senza di questo, quando si scrive qualcosa nella textArea, e poi si chiude il dialog
  alla successiva riapertura del dialog, la textArea risulta vuota.
  Questo perchè il componente viene smontato e rimontato, quindi riparte con lo stato iniziale
  mentre noi vogliamo che alla seconda apertura mostri il valore scritto precedentemente.
  */
  value: PropTypes.string
};
