var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tt-container\">\n<div class=\"tt-title-page-container\">\n  <div class=\"tt-title-page-text\">\n    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":13}}}) : helper)))
    + "\n  </div> \n</div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-columns\"";
},"5":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-column is-8 is-offset-2\"";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":55,"column":17}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"tt-talk-doctor-container\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"status") : depth0),"==",1,{"name":"ifCond","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":28,"column":23}}})) != null ? stack1 : "")
    + "            <div class=\"tt-talk-doctor talk_doctor_box js-click-doctor\" resourceid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"machid") || (depth0 != null ? lookupProperty(depth0,"machid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"machid","hash":{},"data":data,"loc":{"start":{"line":29,"column":84},"end":{"line":29,"column":94}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"face_img_link") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":30,"column":14},"end":{"line":34,"column":21}}})) != null ? stack1 : "")
    + "\n              <div class=\"tt-talk-doctor__content\">\n                <div class=\"tt-talk-doctor__content-title\">\n                  "
    + alias4(((helper = (helper = lookupProperty(helpers,"resourceName") || (depth0 != null ? lookupProperty(depth0,"resourceName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"resourceName","hash":{},"data":data,"loc":{"start":{"line":38,"column":18},"end":{"line":38,"column":34}}}) : helper)))
    + "\n                </div>\n                <div class=\"tt-talk-doctor__content-text\">\n                  <div class=\"tt-talk-doctor__content-text__date talk_past_appointments\">\n                    <div>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Talk To Doctor Last Appointment",{"name":"translate","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":25},"end":{"line":42,"column":87}}})) != null ? stack1 : "")
    + ":</div>\n                    <div>"
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"last_reservation") : depth0)) != null ? lookupProperty(stack1,"start_date") : stack1), depth0))
    + "</div>\n                  </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"new_msg") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":18},"end":{"line":49,"column":25}}})) != null ? stack1 : "")
    + "                </div>\n              </div>\n              <span class=\"tt-talk-doctor__arrow tt-icon-angle-right\"></span>\n            </div>\n          </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"tt-talk-doctor-status tt-talk-doctor-status--active\">\n                "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Talk Doctor Chat In Progress",{"name":"translate","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":20,"column":75}}})) != null ? stack1 : "")
    + "\n              </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"issues") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),"!=",0,{"name":"ifCond","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":14},"end":{"line":27,"column":25}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-talk-doctor-status tt-talk-doctor-status--completed\">\n                  "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Talk Doctor All Closed Chats",{"name":"translate","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":18},"end":{"line":25,"column":77}}})) != null ? stack1 : "")
    + "\n                </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <img class=\"tt-talk-doctor__avatar\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"face_img_link") || (depth0 != null ? lookupProperty(depth0,"face_img_link") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"face_img_link","hash":{},"data":data,"loc":{"start":{"line":31,"column":55},"end":{"line":31,"column":72}}}) : helper)))
    + "\" data-machid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"machid") || (depth0 != null ? lookupProperty(depth0,"machid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"machid","hash":{},"data":data,"loc":{"start":{"line":31,"column":87},"end":{"line":31,"column":97}}}) : helper)))
    + "\" />\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"tt-talk-doctor__avatar tt-talk-doctor__avatar--default tt-icon-user-md\" data-machid=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"machid") || (depth0 != null ? lookupProperty(depth0,"machid") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"machid","hash":{},"data":data,"loc":{"start":{"line":33,"column":111},"end":{"line":33,"column":121}}}) : helper)))
    + "\"></div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-talk-doctor__content-text__updates\">\n                      "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Talk Doctor New Message",{"name":"translate","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":22},"end":{"line":47,"column":76}}})) != null ? stack1 : "")
    + "\n                    </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tt-container-centered\">\n          <div class=\"tt-card-info\">\n            <div class=\"tt-card-info__icon tt-icon-comments-alt\"></div>\n            <div class=\"tt-card-info__text\">\n              "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"noDoctorText") || (depth0 != null ? lookupProperty(depth0,"noDoctorText") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"noDoctorText","hash":{},"data":data,"loc":{"start":{"line":61,"column":14},"end":{"line":61,"column":32}}}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n"
    + ((stack1 = (lookupProperty(helpers,"isMenuPageVisibile")||(depth0 && lookupProperty(depth0,"isMenuPageVisibile"))||alias2).call(alias1,"search",{"name":"isMenuPageVisibile","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":12},"end":{"line":68,"column":35}}})) != null ? stack1 : "")
    + "          </div>\n        </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button type=\"button\" class=\"tt-card-info__button tt-button tt-button--primary pageLink\" data-page-linked=\"search\"\n              href=\"javascript:\">\n              "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Make a Reservation",{"name":"translate","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":14},"end":{"line":66,"column":63}}})) != null ? stack1 : "")
    + "\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":9,"column":13}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "<div class=\"tt-container\">\n  <div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":7},"end":{"line":11,"column":56}}})) != null ? stack1 : "")
    + ">\n    <div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":9},"end":{"line":12,"column":74}}})) != null ? stack1 : "")
    + ">\n      <div id=\"recent-resources\">\n        <div class=\"tt-list\">\n"
    + ((stack1 = (lookupProperty(helpers,"ifCond")||(depth0 && lookupProperty(depth0,"ifCond"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"length") : depth0),"!=","0",{"name":"ifCond","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":71,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n      </div>\n  </div>\n  </div>\n</div>\n";
},"useData":true});