import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from 'common-lib/store';
import rootReducer from './store/reducers';
import { Mobile } from './mobile';
import { Desktop } from './desktop';
import HeaderListener from './common/components/HeaderListener';
import MOP from 'app';
import { isHeaderDisabled, hideHeader } from './common';

import Idle from './common/components/Timeouts/Idle';
import Absolute from './common/components/Timeouts/Absolute';


export const Start = (root, params) => {


  const { forceDisabled = false } = params;

  const preloadedState = {
    loggedUser: MOP.getLoggedUserData() || null,
    disabled: forceDisabled || isHeaderDisabled(MOP.getLoggedUserData() || {}),
    activePage: {
      page: null,
      route: null,
      title: null
    },
    showBack: false,
    hideFamilyItem: false,
    unreadNotifications: 0,
    hideHeader: hideHeader(),
    hideBottomBar: hideHeader(),
    showSideMenu: false
  };

  const store = configureStore(preloadedState, rootReducer);

  root.render(
    <Provider store={store}>
      <HeaderListener />
      { // Se siamo in app per ora disabilitiamo il flusso dei timers. faremo uno sviluppo apposito lato APP
        // Per gestire cordova ed i timeouts, e per ribuildare tutte le app
        // abbiamo visto che tanto le app con una sessione scaduta lato BE muoiono senza problemi, 
        // fanno redirect alla pagina login dopo aver ricevuto eccezione TUOTEMPO_NOT_LOGGED_IN
        !MOP.Utilities.isMobileApp() ?
          <>
            <Idle />
            <Absolute />
          </> :
          null
      }
      {MOP.config.isMobile() ? <Mobile /> : <Desktop />}
    </Provider>
  );
};