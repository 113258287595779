var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "/"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"social_name") || (depth0 != null ? lookupProperty(depth0,"social_name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"social_name","hash":{},"data":data,"loc":{"start":{"line":3,"column":65},"end":{"line":3,"column":80}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data,"loc":{"start":{"line":3,"column":136},"end":{"line":3,"column":145}}}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"javascript:\" class=\"change-patient item item-icon-left item-icon-right\"\n   data-memberid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"memberid") || (depth0 != null ? lookupProperty(depth0,"memberid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"memberid","hash":{},"data":data,"loc":{"start":{"line":2,"column":18},"end":{"line":2,"column":30}}}) : helper)))
    + "\"\n   data-trigger=\"editPatient:click\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"fname") || (depth0 != null ? lookupProperty(depth0,"fname") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fname","hash":{},"data":data,"loc":{"start":{"line":3,"column":36},"end":{"line":3,"column":45}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"social_name") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":45},"end":{"line":3,"column":87}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"lname") || (depth0 != null ? lookupProperty(depth0,"lname") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lname","hash":{},"data":data,"loc":{"start":{"line":3,"column":88},"end":{"line":3,"column":97}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"lname_2") || (depth0 != null ? lookupProperty(depth0,"lname_2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lname_2","hash":{},"data":data,"loc":{"start":{"line":3,"column":98},"end":{"line":3,"column":109}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"phone") || (depth0 != null ? lookupProperty(depth0,"phone") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"phone","hash":{},"data":data,"loc":{"start":{"line":3,"column":112},"end":{"line":3,"column":121}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"email") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":121},"end":{"line":3,"column":152}}})) != null ? stack1 : "")
    + "</a>\n<a href=\"javascript:\" class=\"tt-action change-patient\" data-trigger=\"changePatient:click\">\n    <span class=\"tt-action__icon tt-icon-user\"></span>\n    <div class=\"tt-action__content\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Change Client",{"name":"translate","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":36},"end":{"line":6,"column":80}}})) != null ? stack1 : "")
    + "</div>\n    <span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n</a>";
},"useData":true});