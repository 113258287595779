var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div>\n                        "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "\n                        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Results Until",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":58},"end":{"line":17,"column":102}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"downloads") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"online_expiration") : stack1), depth0))
    + "\n                    <!-- Stampo solo la prima data di disponibilità -->\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "\n        <div class=\"chrome_tutorial\">\n            <img src=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"getImgPath") || (depth0 != null ? lookupProperty(depth0,"getImgPath") : depth0)) != null ? helper : alias2),(options={"name":"getImgPath","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":22},"end":{"line":33,"column":52}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"getImgPath")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "/downloads_chrome_it.jpg\" />\n            <div>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Results Chrome",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":17},"end":{"line":34,"column":62}}})) != null ? stack1 : "")
    + "</div>\n        </div>\n        ";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "\n        <div class=\"firefox_tutorial\">\n            <img src=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"getImgPath") || (depth0 != null ? lookupProperty(depth0,"getImgPath") : depth0)) != null ? helper : alias2),(options={"name":"getImgPath","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":22},"end":{"line":38,"column":52}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"getImgPath")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "/downloads_firefox_it.jpg\" />\n            <div>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Results Firefox",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":17},"end":{"line":39,"column":63}}})) != null ? stack1 : "")
    + "</div>\n        </div>\n        ";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "\n        <div class=\"explorer_tutorial\">\n            <img src=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"getImgPath") || (depth0 != null ? lookupProperty(depth0,"getImgPath") : depth0)) != null ? helper : alias2),(options={"name":"getImgPath","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":22},"end":{"line":43,"column":52}}}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"getImgPath")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "/downloads_explorer_it.jpg\" />\n            <div>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Results Explorer",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":17},"end":{"line":44,"column":64}}})) != null ? stack1 : "")
    + "</div>\n        </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "class=\"tt-column is-8 is-offset-2\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- INSERIMENTO PASSWORD -->\n<form role=\"form\">\n    <a id=\"back-to-download-search\" class=\"tt-mb-20\" href=\"javascript:\">\n        <span class=\"tt-icon-chevron-left tt-mr-6\"></span>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Search Other Medical Result",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":58},"end":{"line":4,"column":116}}})) != null ? stack1 : "")
    + "\n    </a>\n\n    <div class=\"tt-alert\">\n        <span>\n            <div class=\"tt-js-download-result-box\">\n                    <div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"downloads") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":15,"column":33}}})) != null ? stack1 : "")
    + "                    </div>\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"downloads") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"online_expiration") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":20},"end":{"line":19,"column":27}}})) != null ? stack1 : "")
    + "            </div>\n        </span>\n    </div>\n\n    <div id=\"share-downloads\"></div>\n\n\n    <div class=\"downloads_tutorial tt-hide\">\n        <div class=\"download_tutorial_title\">\n            <strong>"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Results Help",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":20},"end":{"line":29,"column":63}}})) != null ? stack1 : "")
    + "</strong>\n        </div>\n        <br> "
    + ((stack1 = (lookupProperty(helpers,"isConfigTrue")||(depth0 && lookupProperty(depth0,"isConfigTrue"))||alias2).call(alias1,"isChrome",{"name":"isConfigTrue","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":13},"end":{"line":36,"column":25}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = (lookupProperty(helpers,"isConfigTrue")||(depth0 && lookupProperty(depth0,"isConfigTrue"))||alias2).call(alias1,"isFirefox",{"name":"isConfigTrue","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":26},"end":{"line":41,"column":25}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = (lookupProperty(helpers,"isConfigTrue")||(depth0 && lookupProperty(depth0,"isConfigTrue"))||alias2).call(alias1,"isIE",{"name":"isConfigTrue","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":26},"end":{"line":46,"column":25}}})) != null ? stack1 : "")
    + "    </div>\n\n\n    <!-- richiesta password -->\n    <div id=\"require-download-actions\">\n        <br>\n        <div>\n            <div class=\"tt-text-center\">\n                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"message") || (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"message","hash":{},"data":data,"loc":{"start":{"line":55,"column":16},"end":{"line":55,"column":27}}}) : helper)))
    + "\n            </div>\n        </div>\n        <div>\n            <div class=\"tt-columns\">\n                <div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":21},"end":{"line":60,"column":86}}})) != null ? stack1 : "")
    + ">\n                    <div class=\"tt-input-container\">\n                        <input id=\"downloads-password\" type=\"password\" class=\"tt-input\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Password",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":101},"end":{"line":62,"column":140}}})) != null ? stack1 : "")
    + "\"/>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div>\n            <div class=\"tt-columns\">\n                <div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":21},"end":{"line":69,"column":86}}})) != null ? stack1 : "")
    + ">\n                    <button type=\"button\" id=\"execute-download-link\" class=\"tt-button tt-button--primary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Show Medical Result",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":106},"end":{"line":70,"column":156}}})) != null ? stack1 : "")
    + "</button>\n                </div>\n            </div>\n        </div>\n        <div class=\"tt-text-center tt-pt-20\">\n            <span class=\"smalltitle\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Registration Waiting",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":37},"end":{"line":75,"column":88}}})) != null ? stack1 : "")
    + "</span>\n            <div class=\"tt-columns\">\n                <div "
    + ((stack1 = (lookupProperty(helpers,"isDesktop")||(depth0 && lookupProperty(depth0,"isDesktop"))||alias2).call(alias1,"",{"name":"isDesktop","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":21},"end":{"line":77,"column":86}}})) != null ? stack1 : "")
    + ">\n                    <button type=\"button\" class=\"tt-button tt-button--secondary\" id=\"downloads-new-password\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Ask for New Password",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":24},"end":{"line":79,"column":75}}})) != null ? stack1 : "")
    + "\n                    </button>               \n                </div>\n            </div>\n            <div class=\"tt-text-center\" id=\"timer-container\" style=\"display: none;\">\n            </div>\n        </div>\n    </div>\n\n</form>";
},"useData":true});