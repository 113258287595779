var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div data-nested-page=\"first\">\n                <div class=\"tt-label-container\">\n                  <div class=\"tt-form-divider\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"App Your Search",{"name":"translate","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":47},"end":{"line":5,"column":93}}})) != null ? stack1 : "")
    + "</div>\n                  <div class=\"tt-label tt-label--middle appointment_service tt-mb-10\">\n                      "
    + alias4(((helper = (helper = lookupProperty(helpers,"typologyTitle") || (depth0 != null ? lookupProperty(depth0,"typologyTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"typologyTitle","hash":{},"data":data,"loc":{"start":{"line":7,"column":22},"end":{"line":7,"column":39}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"activityTitle") || (depth0 != null ? lookupProperty(depth0,"activityTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityTitle","hash":{},"data":data,"loc":{"start":{"line":7,"column":42},"end":{"line":7,"column":59}}}) : helper)))
    + "\n                  </div>\n                </div>\n              </div>\n              <div class=\"appointment_block item-text-wrap\">\n                <div data-nested-page=\"first\">\n\n                <div class=\"tt-availabilities-doctor-info\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"showResource") : depths[1]),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":20,"column":23}}})) != null ? stack1 : "")
    + "\n                <div class=\"tt-availabilities-doctor-info__content\">\n                  <div class=\"tt-availabilities-doctor-info__content-name\">\n                    <div class=\"tt-availabilities-doctor-info__content-name-text\">\n                      "
    + alias4(((helper = (helper = lookupProperty(helpers,"start_date_day_abbr_week") || (depth0 != null ? lookupProperty(depth0,"start_date_day_abbr_week") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start_date_day_abbr_week","hash":{},"data":data,"loc":{"start":{"line":25,"column":22},"end":{"line":25,"column":50}}}) : helper)))
    + " "
    + alias4((lookupProperty(helpers,"start_date_day")||(depth0 && lookupProperty(depth0,"start_date_day"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"start_date_timestamp") : depth0),{"name":"start_date_day","hash":{},"data":data,"loc":{"start":{"line":25,"column":51},"end":{"line":25,"column":90}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"start_date_month_abbr") || (depth0 != null ? lookupProperty(depth0,"start_date_month_abbr") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"start_date_month_abbr","hash":{},"data":data,"loc":{"start":{"line":25,"column":91},"end":{"line":25,"column":116}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"startTime") || (depth0 != null ? lookupProperty(depth0,"startTime") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"startTime","hash":{},"data":data,"loc":{"start":{"line":25,"column":119},"end":{"line":25,"column":132}}}) : helper)))
    + " data\n                    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"showResource") : depths[1]),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":31,"column":27}}})) != null ? stack1 : "")
    + "                  </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"showAreas") : depths[1]),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":18},"end":{"line":42,"column":25}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"showPrice") : depths[1]),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":16},"end":{"line":51,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":16},"end":{"line":56,"column":23}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"activityPriceInfo") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":16},"end":{"line":58,"column":117}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPrice") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":18},"end":{"line":61,"column":25}}})) != null ? stack1 : "")
    + "\n                </div>\n              </div>\n\n\n"
    + ((stack1 = (lookupProperty(helpers,"checkConfig")||(depth0 && lookupProperty(depth0,"checkConfig"))||alias2).call(alias1,"disableReservationsNotes","==",0,{"name":"checkConfig","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":18},"end":{"line":75,"column":34}}})) != null ? stack1 : "")
    + "                </div>\n                <div class=\""
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"summaryFromLStorage") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":28},"end":{"line":77,"column":79}}})) != null ? stack1 : "")
    + " summary_textarea\" id=\"summary_textarea\" data-nested-page=\"summary_note\" style=\"display:none;\">\n                    <div class=\"tt-label\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Write Note",{"name":"translate","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":42},"end":{"line":78,"column":87}}})) != null ? stack1 : "")
    + "</div>\n                    <div class=\"tt-container\">\n                        <div class=\"tt-input-container\">\n                            <textarea name=\"summary\" id=\"summary\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"summaryFromLStorage") || (depth0 != null ? lookupProperty(depth0,"summaryFromLStorage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"summaryFromLStorage","hash":{},"data":data,"loc":{"start":{"line":81,"column":66},"end":{"line":81,"column":89}}}) : helper)))
    + "</textarea>\n                        </div>\n                    </div>\n                    <div class=\"tt-container\">\n                        <button type=\"button\" class=\"tt-button tt-button--primary nestedPageLinkBack writeNestedPageSelection\" writeNested-ToId=\"summary_text\" getNestedData-fromId=\"summary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Insert",{"name":"translate","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":190},"end":{"line":85,"column":227}}})) != null ? stack1 : "")
    + "</button>\n                    </div>\n                </div>\n              </div><!-- end appointment_block -->\n\n\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <img class=\"tt-availabilities-doctor-info__avatar\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"resource_face_img_link") || (depth0 != null ? lookupProperty(depth0,"resource_face_img_link") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"resource_face_img_link","hash":{},"data":data,"loc":{"start":{"line":17,"column":74},"end":{"line":17,"column":100}}}) : helper)))
    + "\"/>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                  <div class=\"tt-availabilities-doctor-info__avatar--default tt-icon-user-md\"></div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-availabilities-doctor-info__content-name-text\">\n                      "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"resourceName") || (depth0 != null ? lookupProperty(depth0,"resourceName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"resourceName","hash":{},"data":data,"loc":{"start":{"line":29,"column":22},"end":{"line":29,"column":38}}}) : helper)))
    + "\n                    </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <div class=\"tt-availabilities-doctor-info__content-location\">\n                    <div class=\"tt-availabilities-doctor-info__content-location__data\">\n                        <div class=\"tt-availabilities-doctor-info__content-location__data-name\">\n                          <span class=\"tt-icon-map-marker-alt\"></span>"
    + ((stack1 = (lookupProperty(helpers,"formattedAreaTitle")||(depth0 && lookupProperty(depth0,"formattedAreaTitle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"webTitle") : depth0),(depth0 != null ? lookupProperty(depth0,"areaTitle") : depth0),{"name":"formattedAreaTitle","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":70},"end":{"line":38,"column":135}}})) != null ? stack1 : "")
    + "\n                        </div>\n                    </div>\n                  </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"activityPrice") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":18},"end":{"line":50,"column":25}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tt-availabilities-doctor-info__content-price\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Mop Total Price",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":24},"end":{"line":47,"column":70}}})) != null ? stack1 : "")
    + ":\n                        "
    + alias4(((helper = (helper = lookupProperty(helpers,"currencySymbol") || (depth0 != null ? lookupProperty(depth0,"currencySymbol") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currencySymbol","hash":{},"data":data,"loc":{"start":{"line":48,"column":24},"end":{"line":48,"column":42}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"activityPrice") || (depth0 != null ? lookupProperty(depth0,"activityPrice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityPrice","hash":{},"data":data,"loc":{"start":{"line":48,"column":42},"end":{"line":48,"column":59}}}) : helper)))
    + "\n                    </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tt-availabilities-doctor-info__content-text\">\n                  "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":54,"column":18},"end":{"line":54,"column":35}}}) : helper))) != null ? stack1 : "")
    + "\n                </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"insurance_isHiddenPrice") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":41},"end":{"line":58,"column":110}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"activityPriceInfo") || (depth0 != null ? lookupProperty(depth0,"activityPriceInfo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"activityPriceInfo","hash":{},"data":data,"loc":{"start":{"line":58,"column":77},"end":{"line":58,"column":98}}}) : helper)));
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                      <div class=\"tt-availabilities-doctor-info__content-price\">"
    + ((stack1 = (lookupProperty(helpers,"ifFalse")||(depth0 && lookupProperty(depth0,"ifFalse"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"insurance_isHiddenPrice") : depth0),{"name":"ifFalse","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":60,"column":80},"end":{"line":60,"column":235}}})) != null ? stack1 : "")
    + "</div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias4(((helper = (helper = lookupProperty(helpers,"currencySymbol") || (depth0 != null ? lookupProperty(depth0,"currencySymbol") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"currencySymbol","hash":{},"data":data,"loc":{"start":{"line":60,"column":116},"end":{"line":60,"column":134}}}) : helper)))
    + alias4(((helper = (helper = lookupProperty(helpers,"activityPrice") || (depth0 != null ? lookupProperty(depth0,"activityPrice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"activityPrice","hash":{},"data":data,"loc":{"start":{"line":60,"column":134},"end":{"line":60,"column":151}}}) : helper)));
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Mop Insurance Price Hidden Mobile",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":159},"end":{"line":60,"column":223}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <div class=\"tt-padding-75-h\">\n                    <a href=\"#\" id=\"new_note_link\" class=\"tt-action nestedPageLink summary-note-link tt-no-margin-bottom\" role=\"button\" data-display=\"summary_textarea\" data-nested-page=\"first\" data-next-nested-page=\"summary_note\">\n                        <span class=\"tt-action__icon tt-icon-pen tt-mr-4\"></span>\n                        <div class=\"tt-action__content\" id=\"summary_text\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"MOP Note Button",{"name":"translate","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":74},"end":{"line":71,"column":120}}})) != null ? stack1 : "")
    + "</div>\n                        <span class=\"tt-action__arrow tt-icon-angle-right\"></span>\n                    </a>\n                  </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "tt-hide";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"list summary_container item-text-wrap\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"availabilities") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":92,"column":21}}})) != null ? stack1 : "")
    + "\n\n        <div data-nested-page=\"first\">\n            <div class=\"tt-alert tt-customer-alert\">\n              <div>\n              "
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Untrustable Alert Text",{"name":"translate","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":14},"end":{"line":98,"column":67}}})) != null ? stack1 : "")
    + "\n              </div>\n            </div>\n        </div>\n        <div class=\"tt-container\" data-nested-page=\"first\">\n          <button type=\"button\" class=\"tt-button submit tt-button--primary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Deferred Email Btn",{"name":"translate","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":76},"end":{"line":103,"column":125}}})) != null ? stack1 : "")
    + "</button>\n        </div>\n</div>\n";
},"useData":true,"useDepths":true});