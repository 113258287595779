import React from 'react';
import PropTypes from 'prop-types';
import { Transition as ReactTransition } from 'react-transition-group';
import * as style from "./style";

const timeout = {
  fade: { enter: 500, exit: 300 },
  fade300: { enter: 300, exit: 300 },
  slideFade: 500
};

export const FadeTransition = ({
  children,
  inProp,
  type,
  unmountOnExit = true,
}) => {
  // DRAFT - Bozza di codice da non utilizzare
  return (
    <ReactTransition
      in={inProp}
      unmountOnExit={unmountOnExit}
      timeout={timeout[type]}
    >
      {state => (
        <div
          css={style[type][state]} >
          {children}
        </div>
      )
      }
    </ReactTransition >
  );
};


FadeTransition.propTypes = {
  children: PropTypes.node.isRequired,
  inProp: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["fade", "fade300", "slideFade"]).isRequired,
  unmountOnExit: PropTypes.bool,
};