var Handlebars = require("../../lib/handlebars-helpers");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"myModalLabel\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"tt-mb-10\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Results Share Email",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":34},"end":{"line":4,"column":84}}})) != null ? stack1 : "")
    + "</div>\n            <div class=\"modal-body\">\n                <div class=\"tt-input-container\">\n                    <input class=\"tt-input\" type=\"text\" id=\"share-contact\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Results Share Address",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":88},"end":{"line":7,"column":140}}})) != null ? stack1 : "")
    + "\" />\n                </div>\n                <div class=\"tt-input-container\">\n                    <textarea id=\"share-body\" placeholder=\""
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Results Share Body",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":59},"end":{"line":10,"column":108}}})) != null ? stack1 : "")
    + "\" ></textarea>\n                </div>\n\n                <div class=\"downloads_note\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Results Share Privacy",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":44},"end":{"line":13,"column":96}}})) != null ? stack1 : "")
    + "</div>\n            </div>\n            <div class=\"modal-footer tt-flexend-container tt-dialog-button\">\n                <button type=\"button\" id=\"share-download-file\" class=\"tt-button tt-button--primary\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Results Share Send",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":100},"end":{"line":16,"column":149}}})) != null ? stack1 : "")
    + "</button>\n                <a class=\"tt-button tt-button--secondary dismiss\">"
    + ((stack1 = (lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"Clear",{"name":"translate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":66},"end":{"line":17,"column":102}}})) != null ? stack1 : "")
    + "</a>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});