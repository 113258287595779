import React from 'react';
import PropTypes from 'prop-types';

import * as style from "./style";
import { useTheme } from '@emotion/react';
import { Icon } from 'ui/Icon/index.jsx';
import { TTPolyglot } from "@frontend/tt-polyglot";


export const LabelDateAppointment = ({ children = "", color = null, icon = { name: "CalendarLight", color: "manatee" }, size = "medium", isCancelled = false, isNoShow = false }) => {

  const theme = useTheme();

  return (
    <div
      css={style.base(theme)({
        color,
        size,
      })}
    >
      <Icon name={icon.name} color={icon.color} size={size} />
      <div css={style.textContainer}>
        <span css={style.text(theme)(isCancelled, isNoShow)}>{children}</span>
        {(isCancelled || isNoShow) &&
          <span css={style.note(theme)(isCancelled)}>
            {isNoShow ? TTPolyglot.t("No Show") : TTPolyglot.t("Cancelled")}
          </span>
        }
      </div>
    </div>
  );
};

LabelDateAppointment.propTypes = {
  icon: PropTypes.shape(
    {
      name: PropTypes.string.isRequired,
      color: PropTypes.string
    }
  ),
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'standard', 'medium', 'large', 'xl', 'xxl']),
  isCancelled: PropTypes.bool,
  isNoShow: PropTypes.bool,
};
